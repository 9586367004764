
import CardResultadoBusca from '../../atoms/CardResultadoBusca';
import LabelQuadrado from '../../atoms/LabelQuadrado';
import ProviderGroup from '../ProviderGroup';
import './style.scss';

const ItemResultadoBusca = ({ posterPath, name, onClick, numeroNaLista = 1, other = {}, providers = [], genres = [], releaseDate, type }) => {

    return (
        <>
            <div className="container-item-resultado-busca">
                <div className="container-item-resultado-busca-body">
                    <div className="card-resultado-busca">
                        <CardResultadoBusca key={`card-resultado-${numeroNaLista}`} name={name} posterPath={posterPath} onClick={onClick} />
                    </div>

                    <div className="container-item-resultado-descricao">
                        <div className="container-item-resultado-linha-titulo">

                            <div className="container-item-resultado-titulo" onClick={onClick}>{name}</div>
                        </div>


                        <div className="generos">
                            <LabelQuadrado>{type === 'movie' ? 'Filme' : 'Série'}</LabelQuadrado>
                            {releaseDate && <LabelQuadrado>{releaseDate?.substr(0, 4)}</LabelQuadrado>}
                            {genres && genres.filter(genre => genre).map(genre => (
                                <LabelQuadrado key={`${name}-${genre?.id}`}>{genre?.name}</LabelQuadrado>
                            ))}
                        </div>

                        {providers && providers.length > 0 &&
                            <>
                                <div className="container-item-resultado-onde-assistir">Onde assistir</div>
                                <div className="container-item-resultado-providers">
                                    <ProviderGroup className="container-item-resultado-provider-item" providers={providers} titulo="" />
                                    {/* <ProviderGroup className="container-item-resultado-provider-item" providers={providers.filter(p => p.type.indexOf('Assinatura') > -1)} titulo="Assinatura" />
                                    <ProviderGroup className="container-item-resultado-provider-item" providers={providers.filter(p => p.type.indexOf('Compra') > -1)} titulo="Compre ou alugue" /> */}

                                </div>
                            </>}
                        {providers && providers.length === 0 &&
                            <>
                                <div className="container-item-resultado-nao-disponivel">Em cartaz nos cinemas ou não disponível.</div>

                            </>}


                    </div>

                </div>

            </div>
            <div className="divisor"></div>
        </>
    )
};

export default ItemResultadoBusca;