import { useMediaQuery } from "@mui/material";
import "./style.scss";

export default function Index() {

  const isMobile = useMediaQuery('(max-width:768px)');

  const COMO_FUNCIONA = [
    {
      number: "1",
      title: "Crie sua conta gratuitamente",
      description:
        "Acesse nosso site e crie sua conta de forma rápida e prática. Com apenas alguns dados, você já começa a aproveitar todos os benefícios que preparamos para você."
    },
    {
      number: "2",
      title: "Explore os benefícios gratuitos",
      description:
        "Com nossos descontos exclusivos, você gasta menos em suas compras do dia a dia, enquanto acumula pontos que podem ser convertidos em gift cards."
    },
    {
      number: "3",
      title: "Compre e use!",
      description:
        "Assine o plano das empresas parceiras e tenha acesso a serviços como atendimento médico 24h, consultoria jurídica, assistência funeral e participação em sorteios mensais."
    },
    {
      number: "4",
      title: "Benefícios quando e onde quiser",
      description:
        "Seus benefícios estarão disponíveis durante o período do seu plano, utilize a qualquer momento no celular ou na web."
    }
  ];


  return (
    <div className="flex flex-col items-center mt-8 sm:mt-24 max-w-full w-full transform" id="howWorks" >
      <div 
        className="flex flex-col items-center w-[90%] mx-auto self-stretch pt-16 md:shadow-lg md:bg-[#FFF9F9] rounded-[48px] max-md:px-5 max-md:max-w-full bg-"
        style={isMobile ? {} :{
          backgroundImage: "url('/usemais/bg-bubbles.png')",
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: '75%',
          backgroundSize: '800px'
        }}
      >
        
        {/* Título da Seção */}
        <div className="flex gap-2 max-md:flex-wrap -mt-24 mx-auto w-6/12 py-2 bg-white rounded-full">
          <div className="my-auto mx-auto text-center text-[16px] sm:text-[18px] md:text-2xl text-gray-900 max-md:max-w-full font-bold">
            Como funciona?
          </div>
        </div>

        <div 
          className="flex flex-col z-10 max-w-full md:w-[1485px] max-md:mb-2.5 mb-[100px] mt-[40px]"
        >
          <div className="flex flex-col md:flex-row h-max">

            {/* Coluna dos Tópicos */}
            <div className="flex flex-col mt-5 lg:pl-[80px] 2xl:pl-20 md:mr-6 text-gray-900 max-md:max-w-full md:w-[40%]">
              {/* Cada Tópico */}
              {COMO_FUNCIONA.map((item, index) => (
                <div
                  key={index}
                  className={"flex gap-4"}
                >
                  <div className="flex flex-col items-center w-[80px]">
                    <div className="bg-gradient-custom bg-clip-text text-transparent text-[36px] lg:text-[36px] 2xl:text-[40px] font-semibold w-full flex justify-start">
                      <span>{item.number}</span>
                    </div>
                    {COMO_FUNCIONA.length -1 != index && (
                      <div className="w-[2px] h-full bg-gradient-custom"></div>
                    )}
                  </div>
                  <div className="p-2 pb-4">
                    <div className="font-bold lg:text-[20px] 2xl:text-[24px]">
                      {item.title}
                    </div>
                    <div className="lg:text-[16px] 2xl:text-[20px]">
                      {item.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Imagem Lateral - visível apenas no desktop */}
            <div className="hidden md:flex md:min-w-[300px] lg:min-w-[400px] w-[60%]">
              <img 
                src="usemais/home_produto.png" 
                alt="Imagem ilustrativa"
                className="mx-auto h-[720px] -mt-[100px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
