'use client'
import React, { ButtonHTMLAttributes } from 'react'
import { CircularProgress } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { twMerge } from 'tailwind-merge'
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
interface LinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string,
    path: string,
    isLoading?: boolean,
    handleLogout?: any,
    isLogged?: boolean,
    icon: IconDefinition,
    isPerfil?: boolean
}

const paths = [
    '/',
    'login',
    'gifts',
    'minha-conta',
];

const pathsAnchor = [
    'services',
    'howWorks',
    'home-start'
];

export default function Index({ label, path, isLoading, handleLogout, isLogged, icon, isPerfil = false, ...rest }: LinkProps) {
    // const router = useRouter()
    let history = useHistory();
    let location = useLocation();

    let pathname = location?.pathname?.replace("/", "")

    const handleClick = () => {
        window.scrollTo(0, 0);

        history.push({
            pathname: path
        });
        // router.push(path)
    }

    const gotToElement = () => {
        console.log(pathsAnchor.includes(path))
        if (pathsAnchor.includes(path) && pathname.length != 0) {
            history.push({
                pathname: '/'
            });

            setTimeout(() => {
                var element = document.getElementById(path)
                element?.scrollIntoView({ behavior: 'smooth', block: path == 'services' ? 'center' : 'start' })
            }, 500)
        } else {
            var element = document.getElementById(path)
            element?.scrollIntoView({ behavior: 'smooth', block: path == 'services' ? 'center' : 'start' })
        }
    }

    return (
        <>
            {!isPerfil && (
                <div className='hidden md:flex'>
                    {isLoading ? (
                        <button {...rest} className={twMerge("w-full my-auto", rest.className)} onClick={label == 'login' ? handleClick : gotToElement} >
                            <CircularProgress size="1rem" style={{ color: '#FFFFFF' }} />
                        </button>
                    ) : (
                        <button onClick={paths.includes(path) ? handleClick : gotToElement} className={twMerge("w-full my-auto cursor-pointer text-[14px] lg:text-[16px] font-medium hover:font-semibold", rest.className)}>
                            {label}
                        </button>
                    )}
                </div>
            )}

            <button className='flex flex-col items-center justify-center gap-[4px] max-w-[60px] md:hidden' onClick={paths.includes(path) ? handleClick : gotToElement}>
                <FontAwesomeIcon 
                    icon={icon} 
                    className={twMerge(
                        'text-[20px] text-[#8A8A8A]',
                        pathname?.includes(path) && "text-[#151A25]",
                        pathname.length == 0 && path == '/' && "text-[#151A25]",
                    )} 
                />
                <span 
                    className={twMerge(
                        'text-[14px] leading-[16px] text-[#8A8A8A]',
                        pathname?.includes(path) && "text-[#151A25]",
                        pathname.length == 0 && path == '/' && "text-[#151A25]",
                    )}
                >
                    {label}
                </span>
            </button>
        </>
    )
}