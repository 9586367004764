import React from 'react';
import ProviderGroup from '../../molecules/ProviderGroup';
import './style.scss';


const OndeAssistir = ({ providers }) => {

    return (
        <div className="onde-assistir">
            <h1>Onde Assistir</h1>
            {providers?.length > 0 &&
                <>
                    <p>Confira a disponibilidade do título nas plataformas de streaming. <br />
                        Ao clicar na plataforma escolhida você sairá do Use Mais</p>
                    <div className="onde-assistir-providers">
                        <ProviderGroup className="onde-assistir-provider" providers={providers} titulo="" />
                        {/* {obterAssinaturas().length > 0 &&
                            <ProviderGroup className="onde-assistir-provider" providers={obterAssinaturas()} titulo="Assinatura" />
                        }
                        {obterCompras().length > 0 &&
                            <ProviderGroup className="onde-assistir-provider" providers={obterCompras()} titulo="Alugue ou compre" />
                        } */}
                    </div>
                </>
            }
            {providers?.length === 0 &&
                <div className="onde-assistir-nao-disponivel">Em cartaz nos cinemas ou não disponível.</div>
            }
        </div>
    )
};

export default OndeAssistir;