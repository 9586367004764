import React, { useState } from 'react';
// import ReactStars from "react-rating-stars-component";
import { Rating } from 'react-simple-star-rating';
import config from '../../../config/default';
import SinopseFilmeSerie from '../../atoms/SinopseFilmeSerie';
import TituloFilmeSerie from '../../atoms/TituloFilmeSerie';
import LinhaInformativa from '../../molecules/LinhaInformativa';
import RatingFilmeSerie from '../../molecules/RatingFilmeSerie';
import './style.scss';



const DadosGerais = ({ filme, staff, poster }) => {

    const [rating, setRating] = useState(0)

    const formatRunTime = (duracao) => {
        const horas = Math.trunc(duracao / 60);
        const minutos = duracao - (horas * 60);

        let resultado = horas > 0 ? `${horas}h` : '';
        resultado += minutos > 0 ? `${minutos}min` : '';

        return resultado;
    }

    const obterClassificacao = (filme) => {
        try {
            if(filme.content_ratings) {
                return filme.content_ratings.results.find(r => r.iso_3166_1 === 'BR').rating;
            } else {
                return filme.release_dates.results.find(r => r.iso_3166_1 === 'BR').release_dates[0].certification;

            }
        } catch (err) {
            return "";
        }
    }

    return (
        <div className="dados-filme">
            <LinhaInformativa
                codigoDaClassificacao={obterClassificacao(filme)}
                ano={filme?.release_date?.substr(0, 4) || filme?.first_air_date?.substr(0, 4)}
                tempoDeFilme={formatRunTime(filme.runtime)}
                generos={filme.genres}
            />

            <TituloFilmeSerie>{filme.title || filme.original_name} </TituloFilmeSerie>
            <SinopseFilmeSerie className="dados-gerais-sinopse">{filme.overview}</SinopseFilmeSerie>
            <RatingFilmeSerie className="dados-gerais-votacao" votacaoTMDB={filme.vote_average} />

            <div className="staff-poster" style={{ display: 'none' }}>
                <div className="poster">
                    <img className="poster-image" src={`${config.imageURL}${filme.poster_path || poster}`} alt={filme.title} />
                </div>
                <div className="sessao-staff-compartilhar">
                    {staff &&
                        <div className="staff">
                            <div>Diretor: {staff.diretor.map(d => d.name).join(", ")}</div>
                            <div>ROTEIRO: {staff.roteiro.map(r => r.name).join(", ")}</div>
                            <div>ESTRELAS: <span className="nomes">{staff.estrelas.map(e => e.name).join(", ")}</span></div>
                        </div>
                    }
                    <div className="compartilhar">
                        <div className="rating">Sua nota para esse título:
                            {/* <ReactStars
                                count={5}
                                onChange={(e) => console.log(e)}
                                size={32}
                                activeColor="#fccd0a"
                            /> */}
                            <Rating
                                onClick={(e) => setRating(e)}
                                ratingValue={rating}
                                size={32}
                                fillColor="#fccd0a"
                                emptyColor="#e4e5e9"
                                
                            />
                        </div>

                        <div className="share">
                            <a href=".">Compartilhar</a>
                            <a href=".">Adicionar a minha lista</a>
                            <a href=".">Adicionar aos meus favoritos</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default DadosGerais;