import { useEffect, useRef } from "react";
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import VideoPopup from "./componentes/atoms/VideoPopup";
import { MessageApiProvider } from "./contexts/MessageContext";
import { ApiProvider } from "./contexts/SessionContext";
import Ajuda from "./paginas/Ajuda";
import Anuncie from "./paginas/Anuncie";
import BlogNoticia from "./paginas/BlogNoticia";
import ComoFunciona from './paginas/ComoFunciona';
import ConfirmacaoEmail from "./paginas/ConfirmacaoEmail";
import ContaUsuario from "./paginas/ContaUsuario";
import ConteudoPost from "./paginas/ConteudoPost";
import DetalheDaSerie from './paginas/DetalheDaSerie';
import DetalheDoFilme from './paginas/DetalheDoFilme';
import EditarPerfil from "./paginas/EditarPerfil";
import Home from './paginas/Home';
import Cadastro from './paginas/Cadastro';
import Login from './paginas/Login';
import HomeAssista from './paginas/HomeAssista';
import ListasPersonalizadas from "./paginas/ListasPersonalizadas";
import ListaTitulosFavoritos from "./paginas/ListaTitulosFavoritos";
import ListaTitulosLancamentos from "./paginas/ListaTitulosLancamentos";
import ListaTitulosListaPersonalizada from "./paginas/ListaTitulosListaPersonalizada";
import ListaTitulosPopulares from "./paginas/ListaTitulosPopulares";
import ListaTituloPorPlataforma from "./paginas/ListaTitulosPorPlataforma";
import LoginAutomatico from "./paginas/LoginAutomatico";
import Pesquisa from './paginas/Pesquisa';
import ResetSenha from "./paginas/ResetSenha";
import TituloPorGenero from "./paginas/TitulosPorGenero";
import TituloPorGeneroGenero from "./paginas/TitulosPorGeneroEGenero";
import TituloPorPlataformaGenero from "./paginas/TitulosPorPlataformaEGenero";
import Gifts from "./paginas/Gifts";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Header from './componentes/usemais/Header/index'

import ReactGA from 'react-ga';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./index.css"
import ContaUsuarioUsemais from "./paginas/ContaUsuarioUsemais";
import EditarPerfilUsemais from "./paginas/EditarPerfilUsemais";
import { ToastContainer, toast } from "react-toastify";
import CentraisAtendimento from "./paginas/CentraisAtendimento";
import { LoaderProvider } from "./hooks/useLoader";
import useSession from "./hooks/useSession";
import { obterDados } from "./utils/Session";
import { PrivateRouteTheme } from "./routes/PrivateRouteTheme";
import { RotaTemaPadrao } from "./routes/RotaTemaPadrao";
import { Routes } from "./routes/Routes";

ReactGA.initialize('UA-222641016-1'); // G-3YQ7TH17EW
const CHAVE = '@usemaisbrasil';

localStorage.removeItem('@assistamais');
sessionStorage.removeItem('@assistamais');

const tagManagerArgs = {
  gtmId: 'GTM-PS26QK2'
}
TagManager.initialize(tagManagerArgs)

const clientId = '175857597463-qsf2pu0o23camne76motu6r3rtf6m9sm.apps.googleusercontent.com';

function App() {

  // const RotaTemaPadrao = ({ component: Component, titulo, completo, headerShow = true, headerFixed = false, ...rest }) => {

  //   const videoRef = useRef()

  //   return (
  //     <Route
  //       {...rest}
  //       render={props => (
  //         <>
  //           {headerShow && <Header fixed={headerFixed} />}
  //           <VideoPopup ref={videoRef} />
  //           <Component {...props} videoRef={videoRef} />

  //         </>
  //       )}
  //     />
  //   );
  // }

  window.dataLayer.push({
    event: 'pageview'
  });

  ReactGA.pageview(window.location.pathname + window.location.search);

  // const meta = {
  //   title: 'Some Meta Title',
  //   description: 'I am a description, and I can create multiple tags',
  //   canonical: 'http://example.com/path/to/page',
  //   meta: {
  //       charset: 'utf-8',
  //       name: {
  //           keywords: 'react,meta,document,html,tags'
  //       }
  //   }
  // }

  // const RotaPrivada = ({ component: Component, titulo, completo, headerShow = true, headerFixed = false, ...rest }) => {
  //   const isAuthenticated = JSON.parse(localStorage.getItem(CHAVE));;
  //   const videoRef = useRef();
  //   console.log(isAuthenticated);
  //   return (
  //     <Route
  //       {...rest}
  //       render={(props) =>
  //         isAuthenticated ? (
  //           <>
  //             {headerShow && <Header fixed={headerFixed} />}
  //             <VideoPopup ref={videoRef} />
  //             <Component {...props} videoRef={videoRef} />

  //           </>
  //         ) : (
  //           <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
  //         )
  //       }
  //     />
  //   );
  // };

  // const RotaDeslogada = ({ component: Component, titulo, completo, headerShow = true, headerFixed = false, ...rest }) => {
  //   const isAuthenticated = JSON.parse(localStorage.getItem(CHAVE));;
  //   const videoRef = useRef();
  //   console.log(isAuthenticated);
  //   return (
  //     <Route
  //       {...rest}
  //       render={(props) =>
  //         !isAuthenticated ? (
  //           <>
  //             {headerShow && <Header fixed={headerFixed} />}
  //             <VideoPopup ref={videoRef} />
  //             <Component {...props} videoRef={videoRef} />

  //           </>
  //         ) : (
  //           <Redirect to={{ pathname: "/", state: { from: props.location } }} />
  //         )
  //       }
  //     />
  //   );
  // };
  

  return (
    <Provider store={store}>
      <LoaderProvider>
        <ApiProvider>
          <MessageApiProvider>
            <GoogleOAuthProvider clientId={clientId}>
              {/* <Router>
                <RotaTemaPadrao exact path="/" component={Home} headerFixed={true}/>
                <RotaTemaPadrao exact path="/assista-mais" component={HomeAssista} headerShow={true} headerFixed={true} />
                <RotaDeslogada exact path="/cadastro" component={Cadastro} headerShow={false} />
                <RotaDeslogada exact path="/login" component={Login} headerShow={false} />
                <RotaTemaPadrao exact path="/ajuda" component={Ajuda} forceRefresh={true} />
                <RotaTemaPadrao exact path="/anuncie" component={Anuncie} />
                <RotaTemaPadrao exact path="/comofunciona" component={ComoFunciona} forceRefresh={true} />
                <RotaTemaPadrao exact path="/conteudoPost" component={ConteudoPost} forceRefresh={true} />
                <RotaPrivada exact path="/editarperfil" component={EditarPerfilUsemais} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/email-verification" component={ConfirmacaoEmail} forceRefresh={true} />
                <RotaTemaPadrao exact path="/favoritos" component={ListaTitulosFavoritos} forceRefresh={true} />
                <RotaTemaPadrao exact path="/filme" component={DetalheDoFilme} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/listas" component={ListasPersonalizadas} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaPrivada exact path="/minha-conta" component={ContaUsuarioUsemais} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/noticias" component={BlogNoticia} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/pesquisa" component={Pesquisa} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/reset-password" component={ResetSenha} forceRefresh={true} headerShow={false} />
                <RotaTemaPadrao exact path="/serie" component={DetalheDaSerie} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/titulos-genero-genero" component={TituloPorGeneroGenero} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/titulos-lista-personalizada" component={ListaTitulosListaPersonalizada} forceRefresh={true} />
                <RotaTemaPadrao exact path="/titulos-plataforma-genero" component={TituloPorPlataformaGenero} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/titulosLancamentos" component={ListaTitulosLancamentos} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/titulosPopulares" component={ListaTitulosPopulares} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/titulosPorGenero" component={TituloPorGenero} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/titulosPorPlataforma" component={ListaTituloPorPlataforma} forceRefresh={true} headerShow={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/transparent-login" component={LoginAutomatico} forceRefresh={true} />
                <RotaTemaPadrao exact path="/gifts" component={Gifts} forceRefresh={true} headerFixed={true} />
                <RotaTemaPadrao exact path="/central-atendimento" component={CentraisAtendimento} forceRefresh={true} headerFixed={true} />

              </Router> */}
              <Routes />
            </GoogleOAuthProvider>
          </MessageApiProvider>
        </ApiProvider>
      </LoaderProvider>
      <ToastContainer 
        position="top-right"
        theme="colored"
      />
    </Provider>
  );
}

export default App;
