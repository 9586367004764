import { useState } from 'react';
import BotaoArredondado from '../../atoms/BotaoArredondado';
import ThumbQuadrada from '../../atoms/ThumbQuadrada';
import './style.scss';
import { twMerge } from 'tailwind-merge';

const CardResgatePonto = ({ pontos = 0, img, textoPremio = '', onClick, className = '', mostrarBotao = true, clickButton=true }) => {

    return (

        <div className={twMerge(
            `card-resgate-pontos`,
            "w-full max-w-[190px] h-[220px] min-h-[220px] justify-between border border-[#ED636E] rounded-[8px] px-[12px] m-0 pb-[12px] mx-auto",
            "md:w-[190px] md:m-[8px] md:h-[240px]",
            className
        )}>
            <div className={twMerge(
                "w-[120px] flex justify-center items-center py-[4px] rounded-b-[8px]",
                "bg-gradient-to-r to-[#E13441] from-[#FAAE2E] text-center text-[10px] font-semibold"
            )}>
                <span>{pontos} Pontos</span>
            </div>

            <ThumbQuadrada src={img} className="h-[60px] cursor-default"></ThumbQuadrada>
            <span className='text-[#80848C] text-[14px] font-light text-center'>
                {textoPremio}
            </span>
            {/* <div className="card-resgate-pontos-body">
                <div className="card-thumb-custom-resgate">
                </div>
                <div className="card-resgate-premio">
                </div>
            </div> */}

            <button 
                className={twMerge(
                    'w-full pb-[2px] md:py-[6px] rounded-[16px] bg-[#8A8A8A] cursor-not-allowed',
                    (mostrarBotao && clickButton) && "bg-[#FAAE2E] cursor-pointer hover:opacity-80"
                )}
                onClick={() => {
                    (mostrarBotao && clickButton) && onClick()
                }}
                disabled={!mostrarBotao}
            >
                <span className='text-[10px] font-semibold'>{clickButton ? "RESGATAR" : "RESGATANDO..."}</span>
            </button>

            {/* {clickButton ? 
                <BotaoArredondado ativo={mostrarBotao} value="RESGATAR" disabledValue="RESGATAR" disabledTitle ="Você ainda não tem os pontos suficientes para trocar por este item. Continue navegando na plataforma e pontuando. É fácil! É rápido!" className="botao-resgate-custom " onClick={onClick} />
            : 
                <BotaoArredondado ativo={false} value="asdsadasd" disabledValue="RESGATANDO..." className="botao-resgate-custom"  />
            } */}


        </div>

    )
};

export default CardResgatePonto;