import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getFavoritos, obterClienteResumo, obterExtrato, obterProdutos, obterFiltros, obterGiftsResgatados, apagarUsuario, getPlano, contratarPlano, consultarCpfServidor, getProfissoes } from '../../actions/assistamais/api';
import CardPopup from '../../componentes/molecules/CardPopup';
import FiltroVoucher from '../../componentes/molecules/FiltroVoucher';
import ContaPerfil from '../../componentes/organisms/ContaPerfil';
import ContaSaldo from '../../componentes/organisms/ContaSaldo';
import ExtratoDePontos from '../../componentes/organisms/ExtratoDePontos';
import ListaResgatePontosHorizontal from '../../componentes/organisms/ListaResgatePontosHorizontal';
import Menu from '../../componentes/organisms/Menu';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import './style.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import BotaoBordaArredondado from '../../componentes/atoms/BotaoBordaArredondado';
import LabelBordaArredondado from '../../componentes/atoms/LabelBordaArredondado';
import ListaFilmesHorizontalFavoritos from '../../componentes/organisms/ListaFilmesHorizontalFavoritos';
import { SessionContext } from '../../contexts/SessionContext';
import { ContaPerfilUsemais } from '../../componentes/organisms/ContaPerfilUsemais';
import { Autocomplete, Box, Card, Checkbox, Divider, Modal, useMediaQuery } from '@mui/material';
import { ContaSaldoUsemais } from '../../componentes/organisms/ContaSaldoUsemais';
import BotaoArredondadoUsemais from '../../componentes/atoms/BotaoArredondadoUsemais';
import { ListaGiftsResgatadosHorizontalUsemais } from '../../componentes/organisms/ListaGiftsResgatadosHorizontalUsemais';
import { ListaPlanosContratadosHorizontalUsemais } from '../../componentes/organisms/ListaPlanosContratadosHorizontalUsemais';
import { RodapeUsemais } from '../../componentes/organisms/RodapeUsemais';
import { twMerge } from 'tailwind-merge';

import bgPerfilFundo from '../../assets/img/usemais/bg-perfil-fundo-mobile.png';
import { logout } from '../../utils/Session';
import { toast } from 'react-toastify';
import { useMessage } from '../../contexts/MessageContext';
import Input from '../../componentes/atoms/InputUsemais';
import { Select } from '../../componentes/atoms/Select';
import { useQuery } from '../../utils';
import { formatCurrencyBRL, shuffleArray, validaEmail } from '../../utils/functions';
import InputPassword from '../../componentes/atoms/InputPassword';
import { useLoader } from '../../hooks/useLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { SelectSearch } from '../../componentes/atoms/SelectSearch';
import { nacionalidadesMock } from '../../utils/nacionalidadesMock';
import { getAuthenticatedUser } from '../../actions/services/userService';


export default function Planos({ location }) {
    const [favoritos, setFavoritos] = useState([]);
    const [perfil, setPerfil] = useState([]);
    const [filtros, setFiltros] = useState({categorias: [], pontos: []});
    const [produtos, setProdutos] = useState([]);
    const [giftsResgatados, setGiftsResgatados] = useState([]);
    const [extrato, setExtrato] = useState();
    const [filter, setFilter] = useState('');
    const [totalResgatado, setTotalResgatado] = useState(0);

    const extratoDePontosRef = useRef();
    const cardPopup = useRef(null);

    const isMobile = useMediaQuery('(max-width:768px)');

    const [showConfirm, setShowConfirm] = useState(false);
    const [showRequestSend, setShowRequestSend] = useState(false);
    
    const [dadosCliente, setDadosCliente] = useState(null);
    
    const [step, setStep] = useState(1);
    const [plano, setPlano] = useState(null);

    const [profissoesList, setProfissoesList] = useState([]);
    const [profissao, setProfissao] = useState(null);

    const [nacionalidade, setNacionalidade] = useState(null);

    const teclado = shuffleArray([0,1,2,3,4,5,6,7,8,9]);
    
    const loader = useLoader();
    
    const [margemIndisponivel, setMargemIndisponivel] = useState(false);
    const [margemDisponível, setMargemDisponivel] = useState(false);

    const [formatoPagamento, setFormatoPagamento] = useState(0);
    
    const [showSuccess, setShowSuccess] = useState(false);
    const [showPendente, setShowPendente] = useState(false);

    const [conveniosList, setConveniosList] = useState([])
    const [matriculasList, setMatriculasList] = useState([])

    const [dadosConvenios, setDadosConvenios] = useState(null);

    const [convenio, setConvenio] = useState(null);
    const [matricula, setMatricula] = useState(null);

    const [averbaToken, setAverbaToken] = useState(false);

    const [habilitaContratar, setHabilitaContratar] = useState(false);

    let history = useHistory();

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        getSessionData();
        getUserData();
        fetchData()
    },[]);

    useEffect(() => {
        if (userData) {
            fetchProfissoes()
        }
    }, [userData]);

    useEffect(() => {
        if (convenio) {
            let matriculas = dadosConvenios[Number(convenio.value)].matriculas.map(mat => {
                return {
                    label: mat,
                    value: mat
                }
            });

            setAverbaToken(dadosConvenios[Number(convenio.value)].params.token_averbacao)

            setMatriculasList(matriculas ?? [])
        } else {
            setMatriculasList([])
            setMatricula(null)
        }
    },[convenio]);

    async function fetchData() { 
        const query = useQuery(location.search);

        const params = {
            id: query.get('id'),
        }

        if (params.id) {
            try {
                const plano = await getPlano(params.id);

                if (plano.payload?.status != 200 || !plano.payload?.data?.plano) {
                    throw new Error("Plano não localizado!")
                }

                setPlano(plano.payload?.data?.plano)
            } catch (err) {
                console.error(err?.message ?? "Plano não localizado!")
                history.replace('/');
            }
        } else {
            history.replace('/')
        }
    }

    async function fetchProfissoes() { 
        const query = useQuery(location.search);

        const params = {
            id: query.get('id'),
        }

        if (params.id) {
            try {
                const profissoesResponse = await getProfissoes(params.id);

                if (profissoesResponse.payload?.status != 200 || !profissoesResponse.payload?.data?.profissoes) {
                    throw new Error("Plano não localizado!")
                }

                const camposPersonalizados = profissoesResponse.payload?.data?.profissoes;

                const profissoes = camposPersonalizados.map(prof => {
                    return {
                        label: prof.profissao.profissao,
                        value: prof.profissao.id,
                    }
                })

                setProfissoesList(profissoes);
            } catch (err) {
                console.error(err?.message ?? "Profissões não localizadas!")
                history.replace('/');
            }
        } else {
            history.replace('/')
        }
    }

    const getUserData = async () => {
        const userDataResponse = await getAuthenticatedUser();

        if (userDataResponse?.code && userDataResponse?.code == 401) {
            setUserData(null)
        } else {
            setUserData(userDataResponse)
        }
    }

    const MultiStep = ({ currentStep }) => {
        return (
            <div className='w-full grid grid-cols-3'>
                <span className={twMerge(
                        'border text-center p-2 rounded-l-lg border-cinza text-cinza',
                        currentStep == 1 && 'bg-primaria-1 border-primaria-1 text-white'
                    )}
                >
                    Detalhes do plano
                </span>
                <span className={twMerge(
                        'border text-center p-2 border-cinza text-cinza',
                        currentStep == 2 && 'bg-primaria-1 border-primaria-1 text-white'
                    )}
                >
                    Confirme seus dados
                </span>
                <span className={twMerge(
                        'border text-center p-2 rounded-r-lg border-cinza text-cinza',
                        currentStep == 3 && 'bg-primaria-1 border-primaria-1 text-white'
                    )}
                >
                    Pagamento
                </span>
            </div>
        )
    }

    const nextStep = () => {
        setStep(oldValue => oldValue + 1)
    }

    const DetalhesPlanoStep = () => {
        return (
            <>
                <Box>
                    <h2 className='text-[32px] flex gap-2 font-light'>
                        Por apenas 
                        <span className="bg-gradient-to-r from-red-500 via-red-500 to-yellow-400 inline-block text-transparent bg-clip-text font-semibold">
                            R$ {formatCurrencyBRL(plano.valor)}
                        </span>
                        /mês
                    </h2>
                    <h2 className='text-[32px] font-light'>
                        você tem acesso a:
                    </h2>
                </Box>

                <Box className='grid grid-cols-2 gap-6'>
                    {plano?.itens && plano.itens.map((item, index) => (
                        <div key={index} className='flex gap-4 items-center w-full'>
                            <img src="/usemais/star_plan.png" alt="" className='w-[40px]' />
                            <span className='leading-[18px]'>
                                <h5 className='font-semibold'>
                                    {item.nome}
                                </h5>
                                <p className='font-light'>
                                    {item.descricao}
                                </p>
                            </span>
                        </div>
                    ))}
                </Box>

                <button
                    className='bg-gradient-to-r from-red-500 from-40% to-yellow-400 w-full text-white p-2 rounded-md hover:opacity-90'
                    onClick={() => {
                        if (userData) {
                            nextStep()
                        } else {
                            history.push('/login');
                        }
                    }}
                >
                    Avançar
                </button>
            </>
        )
    }

    const ConfirmacaoDadosStep = () => {
        const [email, setEmail] = useState(userData?.email ?? "");
        const [aceiteTermo, setAceiteTermo] = useState(false);
        
        const next = () => {

            const errors = []

            if (!nacionalidade) {
                errors.push("Selecione sua nacionalidade")
            }

            if (!profissao) {
                errors.push("Selecione sua profissão")
            }

            if (!email) {
                errors.push("Informe seu e-mail")
            }

            if (!validaEmail(email)) {
                errors.push("Informe um e-mail válido")
            }            

            if (!aceiteTermo) {
                errors.push("Aceite o termo de adesão")
            }

            if (errors.length > 0) {
                errors.forEach(message => {
                    toast.warning(message, { autoClose: 3000 })
                })
            } else {

                setDadosCliente({
                    nacionalidade: nacionalidade.label.toUpperCase(),
                    profissao: profissao.value,
                    email,
                    aceiteTermo
                })

                nextStep()
            }

        }

        return (
            <>
                <Box className="flex flex-col gap-4">

                    <SelectSearch
                        label="Nacionalidade"
                        placeholder="Selecione uma nacionalidade"
                        options={nacionalidadesMock}
                        className="max-w-[340px]"
                        value={nacionalidade}
                        onChange={(e) => {
                            setNacionalidade(e)
                        }}
                    />

                    <SelectSearch
                        label="Profissão"
                        placeholder="Selecione uma profissão"
                        options={profissoesList}
                        className="max-w-[340px]"
                        value={profissao}
                        onChange={(e) => {
                            setProfissao(e)
                        }}
                    />

                    <p className='border-l-[10px] px-2 w-full max-w-[720px] border-l-primaria-1'>
                        Você vai receber todas as informações e instruções de utilização dos benefícios através do seu e-mail. Por isso, garanta que o seu e-mail esteja correto.
                    </p>

                    <Input 
                        label="E-mail"
                        placeholder="Ex.: ana123@gmail.com"
                        className="max-w-[340px]"
                        value={email}
                        onChange={e => {
                            setEmail(e.target.value)
                        }}
                    />

                    <div className='flex gap-2 items-center'>
                        <Checkbox 
                            value={aceiteTermo}
                            onChange={() => setAceiteTermo(!aceiteTermo)}
                            sx={{
                                color: '#ED636E',
                                '&.Mui-checked': {
                                color: '#ED636E',
                                },
                                marginLeft: '-10px'
                            }}
                        />
                        <span className='flex gap-2'>
                            Li e estou de acordo com o 
                            <a href={plano?.termoUrl ?? '/termos/termo_de_uso.pdf'} className='underline hover:text-primaria-1' target='_blank'>
                                Termo de adesão
                            </a>
                        </span>
                    </div>

                    <button
                        className='bg-gradient-to-r from-red-500 from-40% to-yellow-400 w-full text-white p-2 rounded-md hover:opacity-90 mt-10'
                        onClick={next}
                    >
                        Avançar
                    </button>
                </Box>
            </>

            
        )
    }

    


    const PagamentoStep = () => {

        const [senha, setSenha] = useState("");

        const handleFetchPaymentTypes = async () => {
    
            if (plano.formaPagamentoId.includes('1')) {
                loader.show(["Buscando dados"])
    
                try {
                    const consultaResponse = await consultarCpfServidor(userData?.extra?.cpf ?? "");
    
                    // TODO: Após integração OK, remover essa linha
                    // setFormatoPagamento(1)
    
                    if (consultaResponse.payload?.status != 200) {
                        toast.warn("Formato de pagamento não disponível!", { autoClose: 3000 })
                        throw new Error(consultaResponse.payload?.data?.message)
                    }
    
                    const dados = consultaResponse.payload?.data?.dados;
    
                    if (dados && dados?.length == 0) {
                        toast.warn("Pagamento consignado disponível apenas para servidores públicos!", { autoClose: 3000 })
                        throw new Error(consultaResponse.payload?.data?.message)
                    }

                    setDadosConvenios(dados)
    
                    let convenios = [];
    
                    for (const key in dados) {
                        const convenio = {
                            label: dados[key].convenio_nome,
                            value: dados[key].convenio_codigo.toString()
                        }
                        convenios.push(convenio)
                    }

                    setConveniosList(convenios)

                    setHabilitaContratar(true)
    
                    setFormatoPagamento(1)
                    // TODO: Após integração OK, descomentar essa linha
                } catch (err) {
                    console.error(err?.message ?? "Dados não localizados!")
                    // history.replace('/');
                }
    
                loader.hide()
            }
        }

        const handleSenha = (button) => {
            if (button == 'backspace') {
                if (senha.length > 0) {
                    setSenha(oldValue => (oldValue.slice(0, -1)))
                }
            } else if (senha.length < 5) {
                setSenha(oldValue => (oldValue + button.toString()))
            }
        }
        
        const handleContratar = async () => {
            if (validData()) {

                const hashSenha = window.btoa(senha);

                const data = {
                    ...dadosCliente,
                    planoId: plano.id,
                    convenio: convenio.value,
                    nomeConvenio: convenio.label,
                    matricula: matricula.value,
                    senha: hashSenha,
                    formatoPagamento
                };

                try {
                    loader.show(["Aguarde..."])

                    const response = await contratarPlano(plano.id, data);
    
                    if (![200,201,204].includes(response.payload?.status)) {
                        if (response.payload?.status == 412) {
                            toast.warn(response.payload?.data?.message || "Não foi possível realizar a contratação do plano!")
                            setMargemIndisponivel(false)
                            setMargemDisponivel(false)
                        } else {
                            throw new Error("Não foi possível realizar a contratação do plano!")
                        }
                    } else {
                        setMargemIndisponivel(false)
                        setMargemDisponivel(true)

                        if (response.payload?.status == 201) {
                            setShowPendente(true)
                        } else {
                            setShowSuccess(true)
                        }
                    }
                } catch (err) {
                    console.error(err?.message)
                    toast.warn("Não foi possível realizar a contratação do plano!")
                    setMargemIndisponivel(true)
                    setMargemDisponivel(false)
                } finally {
                    loader.hide()
                }

            }
        }

        const validData = () => {
            const errors = []

            if (!convenio) {
                errors.push("Selecione um convênio")
            }

            if (!matricula) {
                errors.push("Selecione uma matrícula / Nº de ordem")
            }

            if (!senha) {
                errors.push(averbaToken ? "Informe seu token de averbação" : "Informe sua senha de averbação")
            }

            if (senha.length !== 5 && !averbaToken) {
                errors.push("A senha de averbação de conter 5 caracteres")
            }

            if (errors.length > 0) {
                errors.forEach(message => {
                    toast.warning(message, { autoClose: 3000 })
                })
                return false
            } else {
                return true
            }
        }

        return (
            <>
                <Box className="flex flex-col items-start gap-4">

                    {plano.formaPagamentoId.includes('1') && (
                        <button 
                            className='bg-cinza text-white text-[14px] p-6 flex gap-2 items-center rounded-md'
                            onClick={handleFetchPaymentTypes}
                        >
                            <img src="/usemais/card-consignado.svg" alt="" className='w-[60px]' />
                            <span>Consignado</span>
                        </button>
                    )}

                    {/* {plano.formaPagamentoId.includes('2') && (
                        <button 
                            className='bg-cinza text-white text-[14px] p-6 flex gap-2 items-center rounded-md'
                            onClick={() => toast.info("Não implementado")}
                        >
                            <img src="/usemais/card-consignado.svg" alt="" className='w-[60px]' />
                            <span>Cartão de crédito</span>
                        </button>
                    )} */}

                    {plano.formaPagamentoId.includes('1') && formatoPagamento == 1 && (
                        <>
                            <SelectSearch
                                label="Selecione um convênio"
                                placeholder="Selecione..."
                                options={conveniosList}
                                className="max-w-[340px] w-full"
                                value={convenio}
                                onChange={(e) => {
                                    setConvenio(e)
                                }}
                            />

                            <SelectSearch
                                label="Matrícula ou Nº de ordem"
                                placeholder="Selecione..."
                                options={matriculasList}
                                className="max-w-[340px] w-full"
                                value={matricula}
                                disabled={!convenio}
                                placeholderDisabled='Selecione um convênio'
                                onChange={(e) => {
                                    setMatricula(e)
                                }}
                            />

                            {/* <Input 
                                label="Matrícula ou Nº de ordem"
                                placeholder="Digite a matrícula ou o Nº de ordem"
                                className="w-full max-w-[340px]"
                                value={matricula}
                                onChange={e => {
                                    setMatricula(e.target.value)
                                }}
                            /> */}

                            {!margemDisponível && !margemIndisponivel && (
                                <div className='px-6 py-2 bg-[#D9D9D9] text-[14px] font-light rounded-[4px] flex gap-4 items-center'>
                                    <img src="/usemais/warning-icon.svg" alt="" className='w-[20px]' />
                                    <p>
                                        Verifique se sua margem está disponível para a realização da compra!
                                    </p>
                                </div>
                            )}

                            {margemIndisponivel && (
                                <div className='px-6 py-2 bg-primaria-1 text-[14px] font-semibold rounded-[4px] flex gap-4 items-center'>
                                    <img src="/usemais/close-icon.svg" alt="" className='w-[16px]' />
                                    <p className='text-azul-padrao'>
                                        Margem insdisponível para compra
                                    </p>
                                </div>
                            )}

                            {margemDisponível && (
                                <div className='px-6 py-2 bg-[#0EB70E] text-[14px] font-semibold rounded-[4px] flex gap-4 items-center'>
                                    <img src="/usemais/check-icon.svg" alt="" className='w-[16px]' />
                                    <p className='text-azul-padrao'>
                                        Margem disponível para compra
                                    </p>
                                </div>
                            )}
                            

                            <Input 
                                label="Condição de pagamento"
                                placeholder="Ex.: ana123@gmail.com"
                                className="w-full max-w-[500px]"
                                value={`R$ ${formatCurrencyBRL(plano.valor)} (Recorrente)`}
                                readOnly
                                description="* O valor do plano será debitado automaticamente enquanto o plano estiver ativo."
                            />

                            {(convenio && matricula) && (
                                <>
                                    {averbaToken ? (
                                        <>
                                            <Input 
                                                label="Token de averbação"
                                                placeholder="Ex.: AA1A1C31"
                                                className="w-full max-w-[340px]"
                                                value={senha}
                                                onChange={e => {
                                                    setSenha(e.target.value)
                                                }}
                                                description="* Caso não saiba onde gerar seu token, entre em contato com o suporte da sua processadora para orientações."
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <InputPassword 
                                                label="Senha de averbação"
                                                className="w-full max-w-[340px]"
                                                value={senha}
                                                onChange={e => {
                                                    setSenha(e.target.value)
                                                }}
                                                disabled
                                                description="* Se você esqueceu sua senha, entre em contato com o suporte da sua processadora para orientações sobre como recuperá-la."
                                            />

                                            <div className='grid grid-cols-3 gap-2'>
                                                {teclado.map((item, key) => (
                                                    <button
                                                        key={key}
                                                        className='border border-cinza text-cinza shadow-sm rounded-md w-[60px] h-[32px] hover:bg-slate-100'
                                                        onClick={() => handleSenha(item)}
                                                    >
                                                        {item}
                                                    </button>
                                                ))}
                                                <button
                                                    className='border border-cinza text-cinza shadow-sm rounded-md w-full h-[32px] hover:bg-slate-100 col-span-2 flex items-center justify-center'
                                                    onClick={() => handleSenha("backspace")}
                                                >
                                                    <img 
                                                        src="/usemais/backspace-icon.svg" 
                                                        alt="backspace"
                                                        className='w-[26px]'
                                                    />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                        </>
                    )}

                    <button
                        className={twMerge(
                            ' w-full text-white p-2 rounded-md mt-10',
                            habilitaContratar && "bg-gradient-to-r from-red-500 from-40% to-yellow-400 hover:opacity-90",
                            !habilitaContratar && "bg-azul-padrao opacity-60 cursor-not-allowed",
                        )}
                        onClick={handleContratar}
                        type='button'
                        disabled={!habilitaContratar}
                    >
                        Contratar
                    </button>
                </Box>
            </>
        )
    }

    return (
        <>
            <div className="w-full md:w-10/12 mx-auto md:my-20 md:mt-32 lg:mt-40">
                {plano && (
                    <Card 
                        className='px-40 py-10 flex flex-col gap-20'
                        sx={{
                            borderRadius: '16px'
                        }}
                    >
                        <MultiStep currentStep={step} />

                        {step === 1 && (
                            <DetalhesPlanoStep />
                        )}

                        {step === 2 && (
                            <ConfirmacaoDadosStep />
                        )}

                        {step === 3 && (
                            <PagamentoStep />
                        )}

                    </Card>
                )}
            </div>

            {showSuccess &&
                <Modal
                    open={showSuccess}
                    onClose={() => {
                        setShowSuccess(false)
                        history.replace('/minha-conta')
                    }}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] p-[40px] md:p-[60px] w-full md:max-w-[500px] flex flex-col gap-[40px] rounded-[36px] md:rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <img 
                                src="/usemais/medal.png" 
                                alt="medalha" 
                                className='w-full max-w-[120px] -mt-[64px] md:max-w-[200px] md:-mt-[160px] md:mb-6' 
                            />
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold'>
                                Sucesso!
                            </h2>
                            <span className='text-[16px] text-[#151A25] font-light text-center'>
                                Você receberá no seu e-mail mais detalhes sobre o plano e instruções para usar os benefícios.
                            </span>
                            <span className='text-[16px] text-[#151A25] font-light text-center'>
                                Caso tenha alguma dúvida ligue para: (41) 99191-5240 ou fale conosco via WhatsApp
                            </span>
                        </div>
                        <div className='w-full flex flex-col gap-[10px]'>
                            <button 
                                onClick={() => {
                                    setShowSuccess(false)
                                    history.replace('/minha-conta')
                                }} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441] to-[#FAAE2E] 
                                    text-white text-[16px] py-[8px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Entendido</span>
                            </button>
                            <button 
                                onClick={() => {
                                    setShowSuccess(false)
                                    window.open("https://wa.me/5541991915240", "blank")
                                    history.replace('/')
                                }} 
                                className='
                                    w-full bg-[#0EB70E]
                                    text-white text-[16px] py-[8px] rounded-[8px]
                                    hover:opacity-90 flex px-4 items-center relative
                                '
                            >
                                <img 
                                    src="/usemais/whatsapp-icon.svg" 
                                    alt="whatsapp" 
                                    className='w-[24px] absolute' 
                                />
                                <span className='w-full'>
                                    Quero falar com alguém
                                </span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }

            {showPendente &&
                <Modal
                    open={showPendente}
                    onClose={() => {
                        setShowPendente(false)
                        history.replace('/editarperfil')
                    }}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] p-[40px] md:p-[60px] w-full md:max-w-[500px] flex flex-col gap-[40px] rounded-[36px] md:rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <img 
                                src="/usemais/medal.png" 
                                alt="medalha" 
                                className='w-full max-w-[120px] -mt-[64px] md:max-w-[200px] md:-mt-[160px] md:mb-6' 
                            />
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold'>
                                Confirmação de dados!
                            </h2>
                            <span className='text-[16px] text-[#151A25] font-light text-center'>
                                Para finalizar a contratação e ativar seus benefícios, realize a finalização do seu cadastro na plataforma.
                            </span>
                            <span className='text-[16px] text-[#151A25] font-light text-center'>
                                Para isso, clique no botão abaixo!
                            </span>
                        </div>
                        <div className='w-full flex flex-col gap-[10px]'>
                            <button 
                                onClick={() => {
                                    setShowPendente(false)
                                    history.replace('/editarperfil')
                                }} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441] to-[#FAAE2E] 
                                    text-white text-[16px] py-[8px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Finalizar cadastro</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }

            <RodapeUsemais 
                className="md:pb-8 mt-[32px] md:mt-0"
            />
        </>
    );
}



