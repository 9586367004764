import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import logoutIcon from '../../../assets/img/logout-ico.png';
import { logout } from '../../../utils/Session';
import BotaoBordaArredondado from '../../atoms/BotaoBordaArredondado';
import FotoPerfilEdit from '../../molecules/FotoPerfilEdit';
import './style.scss';
import BotaoBordaArredondadoSemTransparencia from '../../atoms/BotaoBordaArredondadoSemTranparencia';
import ListaAvatarHorizontal from '../ListaAvatarHorizontal';
import { getFavoritos } from '../../../actions/assistamais/api';
import { FotoPerfilEditUsemais } from '../../molecules/FotoPerfilEditUsemais';


export const ContaPerfilUsemais = ({ perfil, editar = false, className = '' }) => {
    const history = useHistory();
    const cardPopup = useRef(null);
    const [favoritos, setFavoritos] = useState([]);

    const handleEditarPerfil = () => {
        //resetAnuncios();
        history.push('/editarperfil');
    }

    const handleSairClick = () => {
        logout();
        window.location.href = "/"
    }

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        let x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        let y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;

        if (x < 0) x = 0;
        if (y < 0) y = 0;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }


    


    useEffect(() => {
        async function fetchData() {

            //            const { genres } = (await getGenresMovieList()).payload.data;

            getFavoritos().then(result => {
                // TODO criar função para usar em todos

                if (!result?.payload?.status || Math.trunc(result?.payload?.status / 100) !== 2) return;


                let { data } = result?.payload;

                setFavoritos(data.map(f => {
                    let pathname = '/filme';
                    if (f.tipo.toLowerCase() === 'tv') {
                        pathname = 'serie';
                    }
                    const linkDetalhe = `${pathname}/?id=${f.tituloId}&type=${f.tipo}`;
                    const { genres, backdrop_path, poster_path, title, overview } = f.data

                    return {
                        ...f,
                        genres,
                        backdrop_path,
                        poster_path,
                        title,
                        overview,
                        linkDetalhe: linkDetalhe
                    }
                }).reverse());

            });

        }
        fetchData()
    }, []);


    return (
        <>
            <div className='flex flex-col items-center md:items-start gap-4 px-10'>
                <FotoPerfilEditUsemais />
                <div className='flex flex-col items-center md:items-start gap-0 md:gap-4'>
                    <span className="text-black font-semibold text-[18px] md:text-2xl">{perfil?.nome ?? ""}</span>
                    <span className="text-black font-light text-[18px] md:text-3xl">{perfil?.pontos?.disponivel ?? 0} pontos</span>
                </div>
            </div>

            {/* <div className={`conta-perfil ${className}`}>
                <div className='perfilGrupo hideMobile'>
                    <div className='perfilDados'>
                        <div className='perfiFoto'>
                            <FotoPerfilEdit />
                            <div className="text-black">{perfil.nome}</div>
                            <div className="text-black">{perfil.email}</div>
                        </div>
                        
                        <div className="dados-perfil">
                            <div className="dados-perfil-nome">{perfil.nome}</div>
                            <div className="dados-perfil-email">{perfil.email}</div>
                            <div className='contaBotoes'>
                                <BotaoBordaArredondadoSemTransparencia value="EDITAR PERFIL" className="conta-perfil-botao-editar" classNameText="conta-perfil-botao-editar-text" onClick={handleEditarPerfil} />
                                <BotaoBordaArredondadoSemTransparencia value="SAIR" className="conta-perfil-botao-sair" classNameText="conta-perfil-botao-sair-text" onClick={handleSairClick}/>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className='divAvatar'>
                        <ListaAvatarHorizontal cardPopup={cardPopup} titulo={"Escolha sua imagem de usuario:"} onMouseOver={handleMouseOver}  />
                    </div>
                </div>

                <div className='perfilGrupo showMobile'>
                    <div className='perfilDados'>
                        <div className='perfiFoto'>
                            <FotoPerfilEdit />
                        </div>
                        
                        <div className="dados-perfil">
                            <div className="dados-perfil-nome">{perfil.nome}</div>
                            <div className="dados-perfil-email">{perfil.email}</div>

                            <div className='divAvatar'>
                                <ListaAvatarHorizontal cardPopup={cardPopup} titulo={"Escolha sua imagem de usuario:"} onMouseOver={handleMouseOver}   />
                            </div>
                            <div className='contaBotoes'>
                                <BotaoBordaArredondadoSemTransparencia value="EDITAR PERFIL" className="conta-perfil-botao-editar" classNameText="conta-perfil-botao-editar-text" onClick={handleEditarPerfil} />
                                
                                <BotaoBordaArredondadoSemTransparencia value="SAIR" className="conta-perfil-botao-sair" classNameText="conta-perfil-botao-sair-text" onClick={handleSairClick}/>
                            </div>
                            
                        </div>
                    </div>
                    
                    
                </div>
                
            </div>
             */}
            
        </>
    )
};