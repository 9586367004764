import React, { useContext, useState } from "react";
import { TextField, Button, InputAdornment, MenuItem, ClickAwayListener } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faArrowRight, faArrowDown, faChevronCircleDown, faArrowAltCircleDown, faArrowCircleDown, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../../contexts/SessionContext";


const SearchBarUsemais = () => {

    const history = useHistory();

    const [, , , systemData] = useContext(SessionContext);

    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({
        provider: '',
        genrer: '',
    });

    const [mostrarFiltro, setMostrarFiltro] = useState(false);

    const handlePesquisa = () => {
        const provider = filter.provider * 1 > 0 ? `&plataforma=${filter.provider}` : '';
        const genrer = filter.genrer * 1 > 0 ? `&genero=${filter.genrer}` : '';
        history.push({
            pathname: '/pesquisa',
            search: `?q=${search}${provider}${genrer}`
        }, {
            some: new Date()
        });
    }

    const handleProviderChange = (e) => setFilter({ ...filter, provider: e.target.value });
    const handleGenrerChange = (e) => setFilter({ ...filter, genrer: e.target.value });

    const havigateTo = (path) => {
        history.push({
            pathname: path,
        })
    }

    return (
        <div className="hidden md:flex flex-col gap-4 lg:flex-row items-center justify-center bg-black py-6 w-full px-[20px] relative">
            {/* Campo de Busca */}
            <div className="flex items-center bg-white rounded-full shadow-lg overflow-hidden h-[44px] w-full max-w-[700px]">
                <div className="relative w-full">
                    <button 
                        className="absolute right-[12px] top-[6px] h-[36px] w-[36px] rounded-full group"
                        onClick={handlePesquisa}
                    >
                        <FontAwesomeIcon 
                            icon={faSearch} 
                            className={twMerge(
                                "text-gray-500 text-[20px]",
                                "group-hover:text-orange-500"
                            )}
                        />
                    </button>
                    <input 
                        type="text" 
                        name="search" 
                        id="search" 
                        placeholder="Busque por filmes, séries, categorias e mais."
                        className="border-none h-[44px] w-full text-[#151A25] text-center"
                        onChange={e => setSearch(e.target.value)}
                        onKeyUp={e => {
                            if (e.key == 'Enter') {
                                handlePesquisa();
                            }
                        }}
                    />
                </div>
                <Button
                    variant="contained"
                    color="warning"
                    className="rounded-r-full rounded-none text-white font-bold h-full w-full max-w-[180px] gap-4"
                    onClick={() => setMostrarFiltro(!mostrarFiltro)}
                >
                    <span className="capitalize text-[16px]">Mais Filtros</span>
                    <FontAwesomeIcon icon={faArrowCircleDown} className="text-[24px]" />
                </Button>
            </div>

            {/* Botões Adicionais */}
            <div className="flex gap-4 w-full max-w-[500px]">
                <div className="overflow-hidden rounded-full h-[44px] w-full max-w-[240px]">
                    <Button
                        variant="contained"
                        color="warning"
                        className="px-6 text-white font-bold flex items-center gap-8 h-full w-full"
                        onClick={() => havigateTo('listas')}
                    >
                        <span className="capitalize text-[16px]" >Listas</span>
                        <FontAwesomeIcon icon={faArrowCircleRight} className="text-[24px]" />
                    </Button>
                </div>
                <div className="overflow-hidden rounded-full h-[44px] w-full max-w-[240px]">
                    <Button
                        variant="contained"
                        color="warning"
                        className="px-6 text-white font-bold flex items-center gap-8 h-full w-full"
                        onClick={() => havigateTo('noticias')}
                    >
                        
                        <span className="capitalize text-[16px] ">Notícias</span>
                        <FontAwesomeIcon icon={faArrowCircleRight} className=" text-[24px]" />
                    </Button>
                </div>
            </div>


            {mostrarFiltro &&
                <ClickAwayListener 
                    onClickAway={() => setMostrarFiltro(!mostrarFiltro)}
                >
                    <div className="absolute top-[68px] z-20 rounded-[8px] bg-white flex flex-col p-8 gap-4">
                        <div className="flex flex-col w-full">
                            <span className="text-[#151A25] font-semibold">
                                Plataformas
                            </span>
                            <TextField
                                select
                                onChange={handleProviderChange}
                                size="small"
                                className="w-[360px] bg-white"
                                SelectProps={{
                                    MenuProps: { disablePortal: true }, // Garante que o menu fique dentro do ClickAwayListener
                                }}
                            >
                                <MenuItem value="0" disabled>Todas as plataformas</MenuItem>
                                {systemData.providers.map(p =>
                                    <MenuItem key={`provider-option-${p.provider_id}`} value={p.provider_id}>{p.provider_name}</MenuItem>
                                )}
                            </TextField>
                        </div>
                        <div className="flex flex-col w-full">
                            <span className="text-[#151A25] font-semibold">
                                Generos
                            </span>
                            <TextField
                                select
                                onChange={handleGenrerChange}
                                size="small"
                                className="w-[360px] bg-white"
                                SelectProps={{
                                    MenuProps: { disablePortal: true }, // Garante que o menu fique dentro do ClickAwayListener
                                }}
                            >
                                <MenuItem value="0" disabled>Todos os gêneros</MenuItem>
                                {systemData.genres.map(g =>
                                    <MenuItem key={`genrer-option-${g.id}`} value={g.id}>{g.name}</MenuItem>
                                )}
                            </TextField>
                        </div>
                    </div>
                </ClickAwayListener>
            }
            
        </div>
    );
};

export default SearchBarUsemais;
