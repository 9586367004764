import "./style.scss";
import { useHistory } from "react-router-dom";
import useSession from "../../../../hooks/useSession";
import { useMessage } from "../../../../contexts/MessageContext";
import { CarrosselParceirosUsemais } from "../../../organisms/CarrosselParceirosUsemais";
import Hashtag from "./Hashtag";

export default function Index() {

    const [session, setSession, logado] = useSession();
    const [showMessage, showError, showMessageFromResult, showWarning] = useMessage();

    const gotToElement = (path: string) => {
        const element = document.getElementById(path);
        element?.scrollIntoView({ behavior: 'smooth' });
    };

    const history = useHistory();
    
    const goCadastro = () => {
        history.push({
            pathname: 'cadastro'
        });
    };

    function handleGoToGifts() {
        if (logado) {
            history.push('/gifts')
        } else {
            showWarning("Realize o login/cadastro para acessar", 3000)
        }
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col md:flex-row md:min-h-[930px] w-full midiaFrame ">
                <div className="flex flex-col bg-transparent md:w-full lg:w-11/12 mx-auto p-2 md:p-4">
                    <div className="midiaBg lg:w-[36%] lg:h-[700px] md:mt-[80px]">
                        <div className="flex flex-col mt-2 md:mt-8 max-md:max-w-full">
                            <img
                                loading="lazy"
                                srcSet="usemais/logo_use_mais.png"
                                className="mt-0 max-w-full w-[110px] max-md:ml-[8px] md:w-[250px] md:h-[145px] lg:mx-auto md:mt-2 lg:mx-0"
                            />
                        </div>
                        <div className="flex flex-wrap gap-[8px] md:px-4 max-md:mt-[180px] max-md:justify-center mt-4">
                            <Hashtag text="Educação" />
                            <Hashtag text="Entretenimento" />
                            <Hashtag text="Finanças" />
                            <Hashtag text="Saúde" />
                        </div>
                        <div className="mt-8 text-[20px] lg:text-[1.5rem] max-lg:text-center font-medium text-gray-900 max-md:mt-6 max-md:max-w-full px-4">
                            Bem-vindo à sua plataforma de benefícios exclusivos! Economize, proteja-se e aproveite vantagens que fazem a diferença no seu dia a dia.
                        </div>
                        <div className="mt-4 text-[20px] lg:text-[1.5rem] max-lg:text-center font-medium text-gray-900 max-md:mt-6 max-md:max-w-full px-4">
                            Desde economia no mercado até cuidados com a saúde e soluções jurídicas, tudo em um só lugar!
                        </div>
                        <div className="flex flex-col md:flex-row mt-10 md:mt-16 items-center justify-start max-lg:justify-center space-y-5 md:space-y-0 md:space-x-12 w-full px-4">
                            {!logado && (
                                <div onClick={goCadastro} className="cursor-pointer max-h-[54px] py-2 px-5 rounded-full text-white bg-red-400 hover:bg-red-500 w-full md:w-[40%] lg:w-[242px] shadow-lg flex justify-between items-center">
                                    <div className="font-semibold text-lg">Cadastre-se</div>
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b9d3b28bb7a2c0bb785cdce68cb90df56052661f47cea248b1ea4b8b3960b64?apiKey=aa73524611a84c2fad6615e339c3a83d&"
                                        className="shrink-0 aspect-square w-[34px]"
                                    />
                                </div>
                            )}
                            <div onClick={() => gotToElement('howWorks')} className="cursor-pointer max-h-[54px] py-2 px-5 rounded-full text-red-400 border border-red-400 bg-white shadow-lg w-full md:w-[40%] lg:w-[299px] flex justify-between items-center">
                                <button className="font-semibold text-lg">Veja como funciona</button>
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4b417b046bf002c2f3c26cfc3e3a156be8b968610a73b88aa685184c623db10?apiKey=aa73524611a84c2fad6615e339c3a83d&"
                                    className="shrink-0 aspect-square w-[34px]"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex md:justify-center items-center z-10 mt-10 lg:mt-auto mb-0">
                        <div className="flex items-center text-[16px] md:text-2xl justify-center text-gray-900 text-center p-2 w-full md:w-[936px] h-[60px] font-bold bg-white rounded-full md:shadow-md">
                            Você pode 
                            <span 
                                className="text-red-400 ml-1 md:ml-2 cursor-pointer hover:underline"
                                onClick={handleGoToGifts}
                            >
                                utilizar os seus pontos aqui!
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center mb-4 mt-[20px] carousel-lista-parceiros z-10 w-full">
                <CarrosselParceirosUsemais />
            </div>
        </div>
    );
}
