import { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface TitleProps extends HTMLAttributes<HTMLDivElement> {
    text: string
}

export function Title({ text, ...rest }: TitleProps) {
    return (
        <div {...rest} className={twMerge("w-full text-4xl font-bold text-neutral-800", rest.className)}>
            {text}
        </div>
    );
}