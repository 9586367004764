import * as React from "react";

interface HashtagProps {
    text: string;
}

function Hashtag({ text }: HashtagProps) {

    return (
        <div className="flex gap-[4px] text-azul-padrao text-[14px] md:text-[18px] 2xl:text-[22px] items-center">
            <span className="font-bold text-[12px] md:text-[20px] 2xl:text-[26px]">#</span>
            <span className="font-semibold">
                {text}
            </span>
        </div>
    );
}

export default Hashtag;
