import axios, { AxiosInstance } from 'axios'
import { toast } from 'react-toastify'
import { apiAssistaMaisRequest } from '../../utils/ApiRequest'
// import { User } from '@/app/Models/User'

interface ApiResponse {
    exists: boolean
}

const api: AxiosInstance = axios.create({
    baseURL: process.env.apiBaseURL + '/users/',
    timeout: 10000,
})

export const checkExistingUser = async (data: any): Promise<ApiResponse> => {
    const backendToken = process.env.backendToken

    const response = await api.post<ApiResponse>('exists', data,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                token: backendToken
            }
        })

    return response.data
}

export const signUp = async (data: any): Promise<{ data: { key: '', message: '' } }> => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': process.env.backendToken
        }
    }

    const response = await api.post('sign-up', data, config)

    return response
}

export const getAuthenticatedUser = async (): Promise<any> => {
    const token = window.localStorage.getItem('token')

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': process.env.backendToken,
            'Authorization': `Bearer ${token}`
        }
    }

    try {
        // const response = await api.get<any>('/', config)
        const response = await apiAssistaMaisRequest().get<any>('/clientes/perfil')
        // const user = User.fromJson(response.data.user)

        // const user = JSON.parse(response.data)

        return response.data
    } catch (error: any) {
        toast.error(error.response, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        })
        throw error
    }
}

export const changePassword = async (data: any): Promise<{ status: boolean, message: string }> => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': process.env.backendToken
        }
    }

    let change = { status: false, message: '' }

    try {
        const response = await api.post<any>('/change-password', data, config)

        change = response.data
    } catch (error: any) {
        change = { status: false, message: error.response.data.message }
    } finally {
        return change
    }
}