import moment from 'moment';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { obterAnuncios } from '../../actions/assistamais/api';
import { discoverMovie, getGenresMovieList } from '../../actions/tmdb';
import fundo from '../../assets/img/what-is-netflix-5-en.png';
import ListaTituloPorGenero from '../../componentes/templates/ListaTitulosPorGenero';
import ListaTituloPorPlataformaGrid from '../../componentes/templates/ListaTitulosPorPlataformaGrid';
import { SessionContext } from '../../contexts/SessionContext';
import { popularTipo } from '../../utils/titulos';
import './style.scss';
import { useQuery } from '../../utils';

export default function ListaTituloPorPlataforma({ location }) {

    const [populares, setPopulares] = useState([]);

    const [lancamentos, setLancamentos] = useState([]);
    const [emAlta, setEmAlta] = useState([]);

    const [listaGeneros, setListaGeneros] = useState([]);
    const [nomePlataforma, setNomePlataforma] = useState([]);
    const [anuncios, setAnuncios] = useState([]);

    const cardPopup = useRef(null);
    let history = useHistory();

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const popularGeneros = (results, genres) => {

        for (let r of results) {

            r.genres = [];
            r.genre_ids && r.genre_ids.forEach(id => {
                r.genres.push(genres.find(g => g.id === id))
            });
        }
    }

    const popularLinkDetalhe = (results, mediaType) => {

        for (let r of results) {
            let pathname = '/filme';
            if (r.tipo.toLowerCase() === 'tv') {
                pathname = '/serie';
            }
            r.linkDetalhe = `${pathname}/?id=${r.id}&type=${r.tipo}`;
        }
    }

    const carregarAnuncios = () => {
        try {
            obterAnuncios('PLATAFORMA').then(result => {
                setAnuncios(result?.payload?.data?.results[0].linha);
            });
        } catch (err) {
            console.error(err);
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        carregarAnuncios();
        async function fetchData() {

            const query = useQuery(location.search);

            const params = {
                id: query.get('id'),
                name: query.get('name'),
            }

            setNomePlataforma(params.name);

            const { genres } = (await getGenresMovieList()).payload.data;

            discoverMovie(`&vote_count.gte=10000&vote_average.gte=7&with_watch_providers=${params.id}&watch_region=BR`).then(result => {
                let { results } = result?.payload?.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results, 'movie');
                setPopulares(results)

            });

            const dataLancamentos = moment().subtract(90, 'days').format('YYYY-MM-DD');
            discoverMovie(`&primary_release_date.gte=${dataLancamentos}&with_watch_providers=${params.id}&watch_region=BR&`).then(result => {
                let { results } = result?.payload?.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results, 'movie');
                setLancamentos(results)
            });

            const dataEmAlta = moment().subtract(90, 'days').format('YYYY-MM-DD');
            discoverMovie(`&primary_release_date.gte=${dataEmAlta}&vote_count.gte=10&vote_average.gte=4&with_watch_providers=${params.id}&watch_region=BR&`).then(result => {
                let { results } = result?.payload?.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results, 'movie');
                setEmAlta(results)
            });



            const resultadoGeneros = [];

            for (let i = 0; i < genres?.length; i++) {

                const g = genres[i];
                const result = await discoverMovie(`&with_watch_providers=${params.id}&with_genres=${g.id}|&watch_region=BR&`);

                let { results } = result?.payload?.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results, 'movie');

                let genero = { id: g.id, name: g.name, filmes: results };

                resultadoGeneros.push(genero);

            }
            setListaGeneros(resultadoGeneros);


        }
        fetchData()

    }, [location]);

    const handleClick = (e, data) => {

        const { innerWidth: width, innerHeight: height } = window;

        const x = e.pageX > width / 2 ? e.pageX - 432 + 20 : e.pageX;
        const y = e.clientY > height / 2 ? e.pageY - 481 + 20 : e.pageY;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        const x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        const y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleClickVerMais = (id) => {

        const query = useQuery(location.search);

        const params = {
            id: query.get('id'),
        }

        history.push({
            pathname: '/titulos-plataforma-genero/',
            search: `?genero=${id}&plataforma=${params.id}`
        }, {
            some: new Date()
        });

    }


    return <ListaTituloPorPlataformaGrid
        emAlta={emAlta}
        listaGeneros={listaGeneros}
        onClickVerMais={handleClickVerMais}
        populares={populares}
        lancamentos={lancamentos}
        titulo={nomePlataforma}
        fundo={fundo}
        subtitulo="O melhor conteúdo em"
        subtituloDestaque={nomePlataforma}
        providers={systemData.providers}
        tipoAnuncio="tira"
        protegida={true}
        anuncios={anuncios}
        onCheckMonetizado={() => carregarAnuncios()}
    />

}


ListaTituloPorPlataforma.propTypes = {

};

