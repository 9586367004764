import { InputHTMLAttributes, ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  showPassword?: boolean;
  validation?: string;
  showValidation?: any;
  classNameValidation?: string;
  endIcon?: ReactNode;
  validationComponent?: ReactNode;
}

export function Input({ classNameValidation, endIcon, validationComponent, ...rest }: InputProps) {
    const [dirt, setDirt] = useState(false);

  return (
    <div className="relative w-full">
      <input
        {...rest}
        onBlur={()=> setDirt(true)}
        autoComplete={rest.autoComplete}
        className={twMerge(
          "w-full justify-center items-start px-4 py-2.5 mt-6 text-justify whitespace-nowrap bg-white rounded-lg border border-solid border-zinc-500 text-zinc-500 max-md:pr-5 max-md:mt-10 max-md:max-w-full",
          dirt && rest.showValidation && rest.showValidation() ? " border-red-500  placeholder:text-red-500 focus:border-zinc-500 focus:placeholder:text-zinc-500" : "",
          rest.className
        )}
      />
      <span
        className={twMerge(
          "absolute -bottom-5 left-0 text-base  w-full",
          dirt && rest.showValidation && rest.showValidation() ? "text-red-500 focus:text-zinc-500" : "hidden",
          classNameValidation
        )}
      >
        {rest.validation}
      </span>

      {endIcon}

      {validationComponent}
    </div>
  );
}
