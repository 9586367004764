"use client";
import { useEffect, useState } from "react";
import { Password } from "../Password";
import { SignUp } from "../SignUp";
import { SignIn } from "../SignIn";
import { validateCode } from "../../../../actions/services/authService";
import { toast } from "react-toastify";
import { recuperarSenha } from "../../../../actions/assistamais/api";
import { twMerge } from "tailwind-merge";
import { useMediaQuery } from "@mui/material";
interface passwordProps {
  passwordKey: any;
  changePassword: any;
  codeValidate: any;
  saveCode: any;
  savePassword: any;
  handleRedirectLogin: any;
}

export default function Index({
  passwordKey,
  changePassword,
  codeValidate,
  saveCode,
  savePassword,
  handleRedirectLogin,
}: passwordProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [code, setCode] = useState([]);
  const [step, setStep] = useState(1);
  const [codeValidated, setCodeValidated] = useState(false);
  const [recoverInfo, setRecoverInfo] = useState("");
  const [validation, setValidation] = useState({
    isLengthValid: false,
    isUpperCaseValid: false,
    isLowerCaseValid: false,
    isNumberValid: false,
    isSpecialCharValid: false,
    isEqualPassword: false,
  });

  const isMobile = useMediaQuery('(max-width:768px)');

  const formValid = (password: string) => {
    const lengthValid = password.length >= 8;
    const upperCaseValid = /[A-Z]/.test(password);
    const lowerCaseValid = /[a-z]/.test(password);
    const numberValid = /\d/.test(password);
    const specialCharValid = /[!@#$%^&*]/.test(password);
    const equalPassword = password.length > 0 && password == confirmPassword;

    setValidation({
      isLengthValid: lengthValid,
      isUpperCaseValid: upperCaseValid,
      isLowerCaseValid: lowerCaseValid,
      isNumberValid: numberValid,
      isSpecialCharValid: specialCharValid,
      isEqualPassword: equalPassword,
    });

    const isFormInvalid = [
      lengthValid,
      upperCaseValid,
      lowerCaseValid,
      numberValid,
      specialCharValid,
      equalPassword,
    ].every(Boolean);

    setIsFormValid(isFormInvalid);
  };

  const validations = [
    {
      id: "validation-length",
      text: "8 caracteres",
      validation: validation.isLengthValid,
    },
    {
      id: "validation-upper",
      text: "1 letra maiúscula",
      validation: validation.isUpperCaseValid,
    },
    {
      id: "validation-lower",
      text: "1 letra minúscula",
      validation: validation.isLowerCaseValid,
    },
    {
      id: "validation-number",
      text: "1 número",
      validation: validation.isNumberValid,
    },
    {
      id: "validation-caracter",
      text: "1 caractere especial",
      validation: validation.isSpecialCharValid,
    },
    // { id: "validation-equal", text: "Senhas iguais", validation: validation.isEqualPassword }
  ];

  useEffect(() => {
    formValid(password);
    savePassword(password);
  }, [password, confirmPassword]);

  useEffect(() => {
    if (code.length === 0) {
      setCodeValidated(false);
    }

    const allFieldsFilled = code.every(
      (value) => value !== "" && value !== undefined
    );

    if (!allFieldsFilled) {
      setCodeValidated(false);
    }

    if (allFieldsFilled && code.length == 6) {
      setCodeValidated(true);
      handleValidation();
    }
  }, [code]);

  const sendEmail = async () => {
    console.log(password);
    if (password?.replace(/\D/g, "").length >= 11 || password?.indexOf('@') >= 0) {
      try {
        setIsLoading(true);
        let response: any = await recuperarSenha(password);
        if (response.payload.status == 204) {
          setStep(2);
        } else {
          toast.error(response.payload?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        // router.push('/');
      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const backHome = () => {
    handleRedirectLogin();
  };

  const handleValidation = async () => {
    const formatedCode = code.join("");
    const validation = await validateCode({
      key: passwordKey,
      code: code.join(""),
    });
    const validated = validation.validate;

    saveCode(formatedCode);
    setStep(2);

    if (!validated) {
      toast.error(validation.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div className="flex flex-col h-full md:h-auto md:mx-auto md:w-full md:ml-0 w-full md:flex-row md:justify-center md:p-[40px] lg:p-[80px]">
      <div className="md:border border-[#151A25] md:px-5 py-[40px] md:my-auto h-full md:h-full md:max-h-[860px] w-full text-xl bg-white md:shadow-lg md:rounded-[48px] max-md:max-w-full xl:max-w-[496px] 2xl:max-w-[596px]">
        {step == 1 ? (
          <Password.Root
            className="flex flex-col items-center justify-between py-[40px] h-full max-md:mt-0 max-md:px-[20px] max-lg:px-2"
          >

            {isMobile && (
              <img 
                src="/usemais/logo_use_mais.png" 
                alt="Logo"
                className=" w-[160px] cursor-pointer mb-0 flex"
              />
            )}

            <div className="self-start flex flex-col w-full">
              <SignUp.Title 
                text={"Redefinir senha"} 
                className="md:mt-3 max-md:max-w-full text-[28px] md:text-[32px] lg:text-[36px] "
              />
              <SignUp.Subtitle
                text={
                  "Digite seu e-mail ou celular, enviaremos o link para redefinição da sua senha."
                }
                className="text-[18px] md:text-[20px] lg:text-[24px] max-lg:text-start"
              />
              <SignIn.Input
                className=" max-md:mt-8 md:mt-12 h-[44px] max-lg:text-[16px] lg:text-[18px] 2xl:[20px]"
                value={password}
                type="text"
                name="recoverInfo"
                placeholder="E-mail ou celular"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {/* <Password.InputCode type="text"
                        digits={6}
                        setCode={setCode}
                        className="w-12 px-3 py-2 text-center text-lg font-bold text-gray-700 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500" /> */}
            <div className="self-end flex flex-col w-full">
              <SignIn.Action
                id="checkUser"
                onClick={sendEmail}
                text={isLoading ? "Enviando..." : "Enviar"}
                className={twMerge(
                  `self-end mt-10 items-center text-center p-2.5 w-full text-white rounded-full shadow-sm ${
                    !isLoading
                      ? "bg-[linear-gradient(90deg,#E13441_0%,#FAAE2E_100%)]"
                      : "bg-gray-400"
                  }`,
                  "h-[44px] max-md:text-[16px] p-0 mt-0 md:text-base xl:text-lg"
                )}
                disabled={isLoading}
              />
              <SignIn.Action
                id="back"
                onClick={backHome}
                text="Voltar"
                className={twMerge(
                  "self-end items-center p-2.5 mt-4 text-center w-full text-red-400 rounded-full border border-red-400 border-solid",
                  "h-[44px] max-md:text-[16px] p-0 md:text-base xl:text-lg"
                )}
                disabled={isLoading}
              />
            </div>
          </Password.Root>
        ) : (
          ""
        )}

        {step == 2 ? (
          <Password.Root
            className="flex flex-col items-center justify-between h-full max-md:mt-0 max-md:px-[20px] max-lg:px-2"
          >
            
            {isMobile && (
              <img 
                src="/usemais/logo_use_mais.png" 
                alt="Logo"
                className=" w-[160px] cursor-pointer mb-0 flex"
              />
            )}

            <div className="self-start flex flex-col w-full max-md:gap-[40px]">
              <div className="flex flex-inline text-4xl font-medium justify-center items-center text-left text-white md:mt-14 w-full">
                <img
                  loading="lazy"
                  src="usemais/link_enviado.png"
                  className="w-full aspect-[0.96] fill-white max-w-[68px] md:max-w-[98px]"
                />
              </div>
              <SignIn.Title
                text={"Link enviado!"}
                className="text-center md:mt-16 text-[28px] md:text-[32px] lg:text-[36px]"
              />
              <SignIn.Subtitle
                // className="text-center mt-10 font-medium"
                className="text-[16px] leading-[20px] md:text-[20px] lg:text-[24px] text-center mt-[20xpx] md:mt-10 font-normal max-md:px-4 max-md:max-w-[400px] max-md:mx-auto"
                text={
                  "Enviamos um link no e-mail e/ou celular informado para redefinir a sua senha. Por favor, verifique :)"
                }
              />
            </div>
            <div className="self-end flex flex-col w-full">
              <SignIn.Action
                id="checkUser"
                onClick={backHome}
                text="Login"
                className={twMerge(
                  `self-end mt-10 items-center text-center p-2.5 w-full text-white rounded-full shadow-sm bg-[linear-gradient(90deg,#E13441_0%,#FAAE2E_100%)]`,
                  "h-[44px] max-md:text-[16px] p-0 mt-0 md:text-base lg:text-xl xl:text-2xl"
                )}
                disabled={isLoading}
              />
            </div>
            {/* <SignUp.Actions>
                        <SignUp.Action id="showPassword" onClick={changePassword} text="Alterar"
                            className={`justify-center mt-10 items-center text-center p-2.5 w-full text-white rounded-lg shadow-sm ${isFormValid && !isLoading ? 'bg-[linear-gradient(90deg,#E13441_0%,#FAAE2E_100%)]' : 'bg-gray-400'}`}
                            disabled={!isFormValid || isLoading} />
                    </SignUp.Actions> */}
          </Password.Root>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
