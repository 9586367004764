import { useEffect, useState } from "react"
import "./style.scss"
import { consultarPlanosPortal } from "../../../../actions/assistamais/api";
import { useHistory } from "react-router-dom";

export default function Index() {

  const [planos, setPlanos] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetchPlanos()
  }, []);

  const fetchPlanos = async () => {
    const planosAtivos = await consultarPlanosPortal();

    if (planosAtivos.payload?.status === 200 && planosAtivos.payload?.data?.planos?.length > 0) {
      setPlanos(planosAtivos.payload?.data?.planos)
    }
  }

  return (
    <>
      {planos.length > 0 ? (
        <div className="max-md:hidden w-full my-8 px-8 md:px-20 md:flex justify-center relative">
          <div className="absolute -top-[16px] text-azul-padrao bg-white px-4 py-1 rounded-full font-semibold text-[14px] md:text-[20px]">
            <p className="text-center">
              Escolha o plano de um parceiro e comece a usar <span className="text-[#1ab3ee]">agora mesmo!</span>
            </p>
          </div>
          <div className="w-full max-w-[1720px] rounded-[24px] overflow-hidden">
            {planos.map((plano, index) => (
              <div 
                key={index} 
                onClick={() => { history.push(`/planos/?id=${plano.id}`) }}
                className="cursor-pointer"
              >
                <img src={plano.imagemUrl} alt="" className="w-full" />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}