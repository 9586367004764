import * as React from "react"
import HomeFrame from "./HomeFrame/index"
import FacaJusFrame from "./FacaJusFrame/index"
import PlansFrame from "./PlansFrame/index"
import ProductsFrame from "./ProductsFrame/index"
import AnunciosFrame from "./AnunciosFrame/index"
import FaqFrame from "./FaqFrame/index"
import ServicesFrame from "./ServicesFrame/index"

export default function Index() {
    return (
        <div className="w-full">
            <HomeFrame />
            <ServicesFrame />
            <PlansFrame />
            <ProductsFrame />
            <AnunciosFrame />
            <FaqFrame />
        </div>
    )
}