import React, { useImperativeHandle, useState } from 'react';
import Popup from '../../molecules/Popup';
import './style.scss';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';


export const ExtratoDePontos = ({ pontos , saldo }, ref) => {
    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
        open: () => setShow(true),
        close: () => close(),
        toggle: () => {
            return setShow(!show);
        }
    }));

    const close = () => {
        setShow(false);
    }

    const data = [
        { data: '11/09/2024 (16:00)', evento: 'Ganhou 10 pontos', origem: 'Vídeos parceiros - Use Mais', validade: '11/09/2025 (16:00)', saldo: 120 },
        { data: '11/09/2024 (15:00)', evento: 'Ganhou 50 pontos', origem: 'Meu Consignado', validade: '11/09/2025 (15:00)', saldo: 110 },
        { data: '09/09/2024 (13:00)', evento: 'Utilizou 100 pontos', origem: 'Doe R$ 2,00 e transforme vidas - acompanhe seu e-mail', validade: '-', saldo: 60 },
        { data: '05/09/2024 (12:00)', evento: 'Ganhou 100 pontos', origem: 'Meu Consignado', validade: '05/09/2025 (12:00)', saldo: 160 },
        { data: '04/09/2024 (12:08)', evento: 'Ganhou 10 pontos', origem: 'Vídeos parceiros - Use Mais', validade: '04/09/2025 (12:08)', saldo: 30 },
        { data: '04/09/2024 (12:05)', evento: 'Ganhou 10 pontos', origem: 'Vídeos parceiros - Use Mais', validade: '04/09/2025 (12:05)', saldo: 20 },
        { data: '04/09/2024 (12:00)', evento: 'Ganhou 10 pontos', origem: 'Vídeos parceiros - Use Mais', validade: '04/09/2025 (12:00)', saldo: 10 },
    ];
      

    return show && (
        <>
            <Modal
                onClose={() => setShow(false)}
                open={show}
                className='flex items-center justify-center px-2'
            >
                <Box className="bg-white rounded-3xl flex flex-col px-2 py-8 md:p-10 gap-4 md:gap-8 max-h-[90%] md:h-auto">

                    <div className='w-full flex justify-between px-2 md:px-0'>
                        <span className='text-black text-[20px] md:text-[28px] font-semibold' >Extrato de pontos</span>
                        <FontAwesomeIcon 
                            icon={faClose} 
                            className='text-black text-[20px] md:text-[28px] ml-auto cursor-pointer' 
                            onClick={() => setShow(false)}
                        />
                    </div>

                    <TableContainer component={Paper} className='hidden md:flex'>
                        <Table size="small" aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='min-w-[180px]' sx={{ fontSize: 18, fontWeight: 400 }}>Data/hora</TableCell>
                                    <TableCell className='min-w-[180px]' sx={{ fontSize: 18, fontWeight: 400 }}>Evento</TableCell>
                                    <TableCell className='min-w-[180px]' sx={{ fontSize: 18, fontWeight: 400 }}>Origem</TableCell>
                                    <TableCell className='min-w-[180px]' sx={{ fontSize: 18, fontWeight: 400 }}>Validade</TableCell>
                                    <TableCell className='min-w-[110px]' sx={{ fontSize: 18, fontWeight: 400 }}>Saldo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(pontos && pontos?.length > 0) ? pontos.map((p, index) => (
                                    <TableRow key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                        <TableCell>{p.data}</TableCell>
                                        <TableCell>{p.evento}</TableCell>
                                        <TableCell>{p.origem}</TableCell>
                                        <TableCell>{p.validade ?? '-'}</TableCell>
                                        <TableCell>{p.saldo}</TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow className='h-[50px]'>
                                        <TableCell colSpan={5} align='center'>Não há dados de extrato disponíveis</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className='flex flex-col md:hidden overflow-y-auto gap-2 p-2'>
                        {(pontos && pontos?.length > 0) ? pontos.map((p, index) => (
                            <Box
                                key={index}
                                className={twMerge(
                                    "flex flex-col w-full border p-1 rounded-[8px] shadow-md"
                                )}
                                
                            >
                                <div className='flex gap-2'>
                                    <span className='text-[#151A25] text-[14px] font-medium underline'>
                                        Evento:
                                    </span>
                                    <p className='text-[#151A25] text-[14px]'>
                                        {p.evento}
                                    </p>
                                </div>
                                <div className='flex gap-2'>
                                    <span className='text-[#151A25] text-[14px] font-medium underline'>
                                        Origem:
                                    </span>
                                    <p className='text-[#151A25] text-[14px]'>
                                        {p.origem}
                                    </p>
                                </div>
                                <div className='flex gap-2'>
                                    <span className='text-[#151A25] text-[14px] font-medium underline'>
                                        Data/hora:
                                    </span>
                                    <p className='text-[#151A25] text-[14px]'>
                                        {p.data}
                                    </p>
                                </div>
                                <div className='flex gap-2 justify-between'>
                                    <div className='flex gap-2'>
                                        <span className='text-[#151A25] text-[14px] font-medium underline'>
                                            Validade:
                                        </span>
                                        <p className='text-[#151A25] text-[14px]'>
                                            {p.validade ?? '-'}
                                        </p>
                                    </div>
                                    <div className='flex gap-2'>
                                        <span className='text-[#151A25] text-[14px] font-medium underline'>
                                            Saldo:
                                        </span>
                                        <p className='text-[#151A25] text-[14px]'>
                                            {p.saldo}
                                        </p>
                                    </div>
                                </div>
                            </Box>
                        )) : (
                            <Box>
                                Não há dados de extrato disponíveis
                            </Box>
                        )}
                    </div>

                    
                </Box>
            </Modal>
        </>
    )
};

export default React.forwardRef(ExtratoDePontos);