import React, { createContext, useEffect, useState } from 'react';
import { obterSaldo, obterProviders, isLogged } from '../actions/assistamais/api';
import { getGenresMovieList } from '../actions/tmdb';
import { gravarDados, isLogado, logout, obterDados } from '../utils/Session';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

export const SessionContext = createContext();

export const ApiProvider = (props) => {
    const [session, setSession] = useState(null);
    const [logado, setLogado] = useState(null);
    const [providers, setProviders] = useState([]);
    const [genres, setGenres] = useState([]);

    useEffect(() => {
        getSessionData()
    }, [])

    async function getSessionData() {
        const dados = obterDados();
        console.log("Atualiza saldo!")

        if (dados) {
            if (dados && dados?.user && dados?.user?.id) {
                const isUserLogged = await isLogged(dados.user.id)

                if (!isUserLogged.payload.data.isLogged) {
                    logout();
                    setSession(null)
                    window.location.href = '/';
                    toast.warning("Você foi deslogado", { autoClose: 3000, theme: 'colored' })
                    return
                }
            }

            let retornoSaldo = await obterSaldo();
            let newDados = {
                ...dados
            };
            
            if (retornoSaldo?.payload?.status == 200) {
                newDados.perfil = {
                    ...dados.perfil,
                    pontos: retornoSaldo.payload.data.saldo
                };
            }

            setSession(newDados);
            gravarDados(newDados);
            console.log("Saldo atualizado!")
        } else {
            setSession(dados);
            gravarDados(dados);
        }

        setLogado(isLogado());

        inicializar();
    }

    const inicializar = async () => {
        getGenresMovieList().then(result => {

            if (result?.payload) {
                const { status, data } = result?.payload;

                if (status === 200) {
                    setGenres(data.genres);
                }
            }
            
        })

        obterProviders().then(result => {
            if (result?.payload) {
                const { status, data } = result?.payload;
                if (status === 200) {
                    setProviders(data.results);
                }
            }
        })

    }

    return (
        <SessionContext.Provider value={[session, setSession, logado, { providers, genres }, getSessionData]}>
            {props.children}
        </SessionContext.Provider>
    );
}