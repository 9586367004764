import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';


export default function InputPassword({ name, error, label, description, ...rest }) {

    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className={twMerge("flex flex-col", rest.className)}>
            {label && (
                <label htmlFor={name} className='text-[#151A25] text-[14px] mb-[8px]'>
                    {label}
                </label>
            )}
            <div 
                className={twMerge("flex relative", rest.className)}
            >
                <input
                    name={name}
                    {...rest}
                    type={showPassword ? 'text' : 'password'}
                    className='border border-[#8A8A8A] rounded-[8px] text-[14px] text-[#151A25] p-[10px] w-full'
                />
                <button 
                  className="absolute right-[14px] top-[12px] flex items-center justify-center h-[20px] w-[20px]"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon 
                    icon={showPassword ? faEyeSlash : faEye} 
                    className="text-[#151A25] text-[16px]" 
                  />
                </button>
            </div>
            {description && <span className="text-[#151A25] text-[12px] font-light mt-1">{description}</span>}
            {error ? <span className="text-red-500 text-[14px]">{error}</span> : <span className='h-[16px]'></span>}
        </div>
    );
}