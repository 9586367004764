import React from 'react';
import './style.scss';
import { twMerge } from 'tailwind-merge';


export default function BotaoArredondadoUsemais({ className = '', value, ativo = true, disabledTitle = '', disabledValue = '', classNameText = '', ...rest }) {

    return (
        <button 
            className={twMerge(
                'w-full h-[40px] bg-[#ED636E] hover:bg-[#C4525A] rounded-full', 
                className, 
                !ativo && 'bg-[#E4E4E4] hover:bg-[#E4E4E4] cursor-not-allowed'
            )} 
            {...rest} 
            disabled={!ativo} 
            title={!ativo ? disabledTitle : ''}
        >
            <div className={twMerge('text-lg font-semibold text-white', classNameText, !ativo && 'text-[#8A8A8A]')}>{!ativo && disabledValue ? disabledValue : value}</div>
        </button>
    );
}