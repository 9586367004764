import React, { useImperativeHandle, useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { isSafari, isIOS, isChrome } from 'react-device-detect';
import { anuncioView } from '../../../actions/assistamais/api';
import BotaoBordaArredondado from '../BotaoBordaArredondado';
import DialogPopup from '../DialogPopup';
import './style.scss';

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { Box, Dialog, Modal, Portal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const VideoPopup = ({ className = '', url, controls = false, allowClose = false, onVideoEnded, poster = false, monetizado }, ref) => {
    const [showVideo, setShowVideo] = useState(false);
    const [urlVideo, setUrlVideo] = useState(undefined);
    const [anuncioToken, setAnuncioToken] = useState();
    const [anuncioLink, setAnuncioLink] = useState();
    const [anuncioId, setAnuncioId] = useState();
    const [tempoMinimo, setTempoMinimo] = useState();
    const [duracao, setDuracao] = useState(0);
    const [progresso, setProgresso] = useState(0);
    const [videoIsOver, setVideoIsOver] = useState(false);
    const [videoInteracted, setVideoInteracted] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isPlayed, setIsPlayed] = useState(false);
    const [showCloseVideo, setShowCloseVideo] = useState(false);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 961px)' });

    const dialogRef = useRef();
    const videoRef = useRef();

    useEffect(() => {
        if (isPlayed && videoRef.current) {
            videoRef.current.currentTime = 0;
        }
    },[isPlayed]);

    // Função para abrir o vídeo e garantir que ele comece do início
    const openVideo = () => {
        console.log(url)
        setShowVideo(true);
        setIsPlayed(true);
        setVideoIsOver(false); // Reseta o estado de término do vídeo
    };

    const handleVideoEnded = (e) => {
        setVideoIsOver(true);
        if (anuncioToken) {
            anuncioView({ anuncioId: anuncioId, token: anuncioToken, tempo: tempoMinimo && tempoMinimo < duracao ? tempoMinimo : duracao }).then((res) => {
               
                let data = res.payload.data;
                console.log("anuncioId:", anuncioId)
                console.log("data", data)
                onVideoEnded({...e, pontos: data.pontos});
                // if (onVideoEnded) {
                // }    
            });
        } else {
            onVideoEnded(e);
            // if (onVideoEnded) {
            // }
        }
        setTimeout(() => setShowVideo(false), 1000);
    }

    useImperativeHandle(ref, () => ({
        show: () => openVideo(),
        setUrl: (url) => setUrlVideo(url),
        setAnuncioLink: (link) => setAnuncioLink(link),
        setAnuncioToken: (token) => setAnuncioToken(token),
        setAnuncioId: (id) => setAnuncioId(id),
        setTempoMinimo: (tm) => setTempoMinimo(tm),
    }));

    const handleClickOutside = () => {
        if (allowClose) {
            setShowVideo(false);
        }
    }

    const handleClosePopup = () => {
        // videoRef.current.pause()
        // return
        if(videoIsOver) {
            setShowVideo(false);
            if(progresso === 0){
                // if(anuncioLink){
                //     window.open(anuncioLink, "_blank")
                // }
            }
            
        } else if (showVideo ) {{
            if (anuncioToken && progresso > 0){
                setShowCloseVideo(true)
                videoRef.current.pause()
            } else {
                handleCloseConfirm();
            }
        }
            
        }
    }

    const handleMuted = () => {
        setIsMuted(!isMuted);
    }

    const handleCloseConfirm = () => {
        if (progresso <= 0 && anuncioToken) {
            anuncioView({ anuncioId: anuncioId, token: anuncioToken, tempo: tempoMinimo && tempoMinimo < duracao ? tempoMinimo : duracao }).then((res) => {
               
                let data = res.payload.data;
                console.log("anuncioId:", anuncioId)
                console.log("data", data)
                onVideoEnded({pontos: data.pontos});
                // if (onVideoEnded) {
                // }    
            });
            setTimeout(() => setShowVideo(false), 1000);
        } else {
            setShowVideo(false);
        }
        
        setShowCloseVideo(false)
        setProgresso(0);
    }
    const handleCloseAbort = () => {
        setShowCloseVideo(false);
        videoRef.current.play()
    }

    const handleLinkClick = () => {
        window.open(anuncioLink, "_blank")
    }

    const handleVideoPlay = () => {
        console.warn('video play')
    }

    const handleVideoClick = () => {
        console.warn('video click')
        setVideoInteracted(true);
        setIsPlayed(true);
    }

    useEffect(() => {
        setIsMuted(true); 
    },[]);

    if (!showVideo) return null;

    return (
        <Portal>

            <Modal
                open={showVideo}
                onClose={handleClosePopup}
            >
                <Box className="flex flex-col w-full h-[400px] max-h-[400px] items-center justify-center gap-[20px]">
                    {
                        isIOS && isMuted && <BotaoBordaArredondado value="" className="video-popup-mute" onClick={handleMuted} >
                            <VolumeOffIcon />
                        </BotaoBordaArredondado>
                    }

                    <video
                        ref={videoRef}
                        src={url || urlVideo}
                        muted={ isIOS && isMuted }
                        width={isTabletOrMobile ? '90%' : '60%'}
                        style={{ marginTop:'30%',marginBottom:'auto', maxHeight: isTabletOrMobile ? '60%' : '600px', objectFit: 'contain' }}
                        playsInline
                        autoPlay={isPlayed}
                        controls={controls}
                        onPlay={handleVideoPlay}
                        onEnded={handleVideoEnded}
                        onTimeUpdate={(e) => setProgresso(Math.trunc((tempoMinimo && tempoMinimo < duracao ? tempoMinimo : duracao) - e.currentTarget.currentTime))}
                        onLoadedMetadata={(e) => setDuracao(e.currentTarget.duration)}
                        
                    />

                    <div
                        className='w-full flex flex-col items-center gap-[10px]'
                    >
                        {
                            anuncioId && progresso > 0 && 
                            <div className='video-popup-progresso'> Restam {progresso} segundos </div>
                        }

                        <button
                            onClick={handleClosePopup}
                            className='rounded-full w-[50px] h-[50px] bg-gray-900 opacity-80 hover:opacity-100'
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>
                </Box>
            </Modal>

            {showCloseVideo &&
                <Modal
                    open={showCloseVideo}
                    onClose={handleCloseConfirm}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] p-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold'>
                                Deseja fechar?
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium'>
                                {(anuncioToken && progresso > 0)  
                                    ? "Se fechar este vídeo antes do contador você não receberá pontos." 
                                    : 'Este vídeo não tem pontuação'
                                }
                            </span>
                        </div>
                        <div className='w-full flex flex-col gap-[10px]'>
                            <button 
                                onClick={handleCloseConfirm} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] py-[10px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Sim</span>
                            </button>
                            <button 
                                onClick={handleCloseAbort}
                                className='
                                    w-full bg-white border border-[#ED636E] 
                                    text-[#ED636E] text-[16px] py-[10px] rounded-[8px]
                                    hover:font-medium
                                '
                            >
                                <span>Não</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }
        </Portal>
    );
}

export default React.forwardRef(VideoPopup);