import React, {useContext, useEffect, useImperativeHandle, useState} from 'react';
import { autenticar, autenticarGoogle, obterClienteResumo } from '../../../actions/assistamais/api';
import config from '../../../config/default';
import { MessageContext } from '../../../contexts/MessageContext';
import { useForm } from '../../../hooks/useForm';
import { gravarDados } from '../../../utils/Session';
import BotaoBordaArredondado from '../../atoms/BotaoBordaArredondado';
import BotaoRetanguloArredondado from '../../atoms/BotaoRetanguloArredondado';
import Input from '../../atoms/Input';
import Popup from '../../molecules/Popup';
import './style.scss';
import BotaoGoogleLogin from '../../atoms/BotaoGoogleLogin';
import {gapi} from "gapi-script";

export const FazerLogin = ({ recuperarSenhaClick, fazerCadastroClick, obrigatorio = false, onCancel }, ref) => {

    const [show, setShow] = useState(false);
    const [showError] = useContext(MessageContext);
    const [isMobile, setIsMobile] = useState(false);

    // useEffect(() => {
    //     const verifyIsMobile = () => {
    //         setIsMobile(window.navigator.userAgentData.mobile)
    //     }
    //
    //     verifyIsMobile()
    // }, []);

    useImperativeHandle(ref, () => ({
        open: () => setShow(true),
        close: () => close(),
        toggle: () => {
            return setShow(!show);
        }
    }));

    const close = () => {
        setShow(false);
    }

    const handleRecuperarSenhaClick = () => {
        recuperarSenhaClick();
        close();
    }

    const handleFazerCadastroClick = () => {
        fazerCadastroClick();
        close();
    }

    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
    } = useForm({ // the hook we are going to create
        validations: { // all our validation rules go here
            email: {
                required: {
                    value: true,
                    message: 'Preencha o e-mail',
                },
            },
            password: {
                required: {
                    value: true,
                    message: 'Preencha a senha',
                },
            },
        },
        onSubmit: async () => {
            const result = await autenticar(data.email, data.password);

            if (result?.payload?.status === 200) {
                gravarDados(result?.payload?.data);
                const urlFoto = `${config.staticURL}avatar/${result?.payload?.data.foto.originalname}`;

                obterClienteResumo().then(result2 => {
                    if (result2?.payload?.status === 200) {
                        const perfil = { ...result2?.payload?.data, foto: urlFoto }
                        const data = { ...result?.payload?.data, perfil: perfil }
                        gravarDados(data);
                    }
                    window.location.reload();
                })
            } else {
                showError(result?.payload?.data.message);
            }
        },
        initialValues: { // used to initialize the data
            email: '',
            telefone: ''
        },
    });

    const handleLoginClick = async () => {





    }

    const handleClose = (e) => {
        if(onCancel) {
            onCancel(e);
        }
        setShow(false);
    }

    const onSuccess = async (res) => {
        console.log('success:', res);

        const result = await autenticarGoogle(res.profileObj);

        if (result?.payload?.status === 200) {
            gravarDados(result?.payload?.data);
            const urlFoto = res.profileObj.imageUrl; //`${config.baseURLAssistaMais}/clientes/perfil/foto/${result?.payload?.data.user.id}`;

            obterClienteResumo().then(result2 => {
                if (result2?.payload?.status === 200) {
                    const perfil = { ...result2?.payload?.data, foto: urlFoto }
                    const data = { ...result?.payload?.data, perfil: perfil }
                    gravarDados(data);
                }
                window.location.reload();
            })
        } else {
            showError(result?.payload?.data?.message);
        }

    };

    const onFailure = (err) => {
        console.log('failed:', err);
    };



    return show && (
        <Popup titulo="Fazer login" onClose={handleClose}>

            <div className="fazer-login-popup">
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <div className="fazer-login-inputs">
                        <Input type="email" className="fazer-login-input" label="E-mail" value={data['email']} onChange={handleChange('email')} error={errors} name="email" />
                        <Input type="password" className="fazer-login-input password" label="Senha" value={data['password']} onChange={handleChange('password')} error={errors} name="password" />

                        <a className="link-popup" href="#123" onClick={handleRecuperarSenhaClick} >Esqueci minha senha</a>                  
                    </div>  
                    <div className="fazer-login-acoes">

                        
                        <BotaoRetanguloArredondado value="Entrar com e-mail" className="botao-arredondado-hack" onClick={handleLoginClick} />
                        {/*{!isMobile &&*/}
                        {/*    <>*/}
                            <span className="break-login hide-on-mobile">ou</span>

                            <BotaoGoogleLogin
                                buttonText={"Entrar com sua conta Google"}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                onError={onFailure}
                                cookiePolicy={'single_host_origin'}
                                className='botao-arrendondado-google hide-on-mobile'
                                render={renderProps => (
                                    <>

                                        <BotaoRetanguloArredondado
                                            icon={<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path fill="none" d="M0 0h18v18H0z"></path></g></svg>}
                                            value={"Entrar com sua conta Google"}
                                            className="botao-arredondado-hack botao-arrendondado-google hide-on-mobile"
                                            onClick={renderProps.onClick} />
                                    </>
                                )} />
                        {/*    </>*/}
                        {/*}*/}

                        
                        <div className="nao-tem-cadastro">Não tem cadastro?</div>
                        <BotaoBordaArredondado value="CADASTRE-SE" className="botao-cadastre-se-hack" type="button" onClick={handleFazerCadastroClick} />
                    </div>
                </form>
            </div>
        </Popup>
    )
};

export default React.forwardRef(FazerLogin);