import * as React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import "./style.scss";
import { Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";

export default function Index() {

    const FAQ = [
        {
            title: "O que é a UseMais?",
            description: `
                Uma plataforma de multisserviços pensada para você! Na Use Mais, 
                selecionamos as melhores vantagens oferecidas por nossos parceiros, 
                trazendo ofertas exclusivas em uma ampla variedade de serviços. 
                Com a gente, você tem acesso único às melhores opções, 
                feitas sob medida para você e sua família.
            `,
        },
        {
            title: "Quais serviços eu encontro na UseMais?",
            description: `
                Através de nossos parceiros, 
                oferecemos benefícios imperdíveis em diversas categorias, 
                como alimentação, entretenimento, moda, viagens, seguros, 
                assistência jurídica e muito mais! Aproveite as vantagens e 
                transforme seu dia a dia com ofertas especiais que só a Use Mais pode proporcionar.
            `,
        },
        {
            title: "Como funciona o programa de pontos?",
            description: `
                Assista nossos anúncios, 
                acumule pontos e troque por gift cards para economizar em diversas categorias. 
                Fácil né? Não perca tempo, aproveite agora mesmo!
            `,
        },
        {
            title: "O que são gift cards?",
            description: `
                São códigos de desconto exclusivos que podem 
                ser utilizados para fazer compras em categorias variadas! 
                Acumule pontos e escolha a loja que deseja aproveitar.
            `,
        },
        {
            title: "O que é o Clube Use Mais?",
            description: `
                Feito para quem gosta de economizar. 
                Com o clube Use Mais você encontra vantagens como descontos e 
                cashback para você aproveitar em lojas, restaurantes, viagens e muito mais!
            `,
        },
        {
            title: "O que é o Assista Mais?",
            description: `
                O Assista Mais é a nossa seção ideal para quem ama filmes e séries! 
                Com nosso guia de streamings, você descobre os melhores lançamentos e onde assisti-los. 
                Agora, você nunca mais ficará em dúvida na hora de escolher aquele filme ou série
            `,
        },
    ];

    return (
        <div className="flex flex-col items-center justify-center md:pt-10 mb-[40px] md:pb-20 px-4 md:px-0">
            <div className="flex flex-col items-center w-full max-w-9/12 md:max-w-full relative z-10">
                <div className="flex justify-center items-center text-[16px] md:text-[20px] lg:text-[24px] font-bold text-gray-900 bg-white rounded-[56px] md:w-[936px] py-4 md:mb-8 max-md:mt-[20px]">
                    Alguma dúvida?
                </div>
                
                {/* Ajuste de espaçamento para maior "respiro" */}
                <div className="w-full md:w-9/12 space-y-6"> {/* Adicionado space-y-6 para espaçamento entre acordeões */}
                    
                    {FAQ.map((item, index) => (
                        <Accordion className="shadow-md rounded-lg overflow-hidden" key={index}>
                            <AccordionSummary
                                expandIcon={<FontAwesomeIcon icon={faPlus} className="text-[20px] md:text-[24px] text-black" />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="text-accordion-title h-[80px] md:h-[100px] px-4" // Adiciona padding para aumentar o respiro
                            >
                                <div className={twMerge(
                                    "my-auto text-[18px] md:text-[20px] lg:text-[22px] 2xl:text-[26px]",
                                    "my-auto leading-[18px] md:leading-[20px] lg:tleadingext-[22px] 2xl:leading-[26px]",
                                )}>
                                    {item.title}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className={twMerge(
                                "px-4 pb-4 text-[14px] md:text-[16px] lg:text-[18px] 2xl:text-[20px]",
                                "leading-[16px] md:leading-[18px] lg:leading-[20px] 2xl:leading-[22px]",
                            )}>
                                {item.description}
                            </AccordionDetails>
                        </Accordion>
                    ))}

                </div>
            </div>
        </div>
    );
}
