import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { verificarEmail } from '../../actions/assistamais/api';
import PopupAviso from '../../componentes/organisms/PopupAviso';
import { MessageContext, useMessage } from '../../contexts/MessageContext';
import Home from '../Home';
import './style.scss';
import { useQuery } from '../../utils';
import { Box, Card, Modal } from '@mui/material';

export default function ConfirmacaoEmail() {

    const [showAviso, setShowAviso] = useState(false);
    
    let history = useHistory();
    
    const query = useQuery();
    const params = {
        token: query.get('token')
    }

    useEffect(() => {
        verifyEmail()
    }, []);

    const verifyEmail = async () => {
        if (params.token) {
            const result = await verificarEmail(params.token);
    
            if (result.payload?.status == 204) {
                setShowAviso(true);
            } else {
                history.push({
                    pathname: '/'
                });
            }
        }
    }

    const handleClose = () => {
        setShowAviso(false);
        history.push({
            pathname: '/'
        });
    }

    return (
        <div className="confirmacao-email">

            <Home />

            {/* {showAviso && <PopupAviso titulo="E-mail confirmado com sucesso!" mensagem="Seu e-mail foi confirmado. Acesse sua conta e aproveite!" onClose={handleClose} />} */}

            {showAviso &&
                <Modal
                    open={showAviso}
                    onClose={handleClose}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] p-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold'>
                                E-mail confirmado com sucesso!
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium text-center'>
                                Seu e-mail foi confirmado. Acesse sua conta e aproveite!
                            </span>
                        </div>
                        <div className='w-full flex flex-col gap-[10px]'>
                            <button 
                                onClick={handleClose} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] py-[10px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Fechar</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }
            

        </div>
    );
}



