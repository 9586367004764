import React, { useContext, useEffect, useRef } from 'react';
import { SessionContext } from '../../../contexts/SessionContext';
import CardPopup from '../../molecules/CardPopup';
import ImagemFundoDetalhe from '../../organisms/ImagemFundoDetalhe';
import ListaAnunciosHorizontal from '../../organisms/ListaAnunciosHorizontal';
import ListaAnunciosHorizontalCustom from '../../organisms/ListaAnunciosHorizontalCustom';
import ListaAnunciosTiraHorizontal from '../../organisms/ListaAnunciosTiraHorizontal';
import ListaFilmesHorizontal from '../../organisms/ListaFilmesHorizontal';
import ListaProvidersHorizontal from '../../organisms/ListaProvidersHorizontal';
import Menu from '../../organisms/Menu';
import Rodape from '../../organisms/Rodape';
import RodapeConta from '../../organisms/RodapeConta';
import './style.scss';
import Footer from '../../usemais/Footer';



export default function ListaTituloPorPlataformaGrid({ lancamentos, emAlta, populares, listaGeneros, titulo, subtitulo, subtituloDestaque, fundo, anuncios = [], providers, tipoAnuncio = "quadrado", onClickVerMais, protegida = false, onCheckMonetizado }) {

    const cardPopup = useRef(null);
    const [session, setSession, logado] = useContext(SessionContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = (e, data) => {

        const { innerWidth: width, innerHeight: height } = window;

        const x = e.pageX > width / 2 ? e.pageX - 432 + 20 : e.pageX;
        const y = e.clientY > height / 2 ? e.pageY - 481 + 20 : e.pageY;


        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    return protegida && !logado ?
        (<div className="lista-titulos-genero bg-black">
            {/* <Menu simples={false} protegida={protegida} /> */}


            {/* <ImagemFundoDetalhe img={fundo} className="lista-titulos-genero-fundo" opacity={false}>
                <div className="titulo-pagina">
                    <div className="titulo-principal">{titulo}</div>
                    <div className="titulo-secundario">{subtitulo} <span>{subtituloDestaque}</span></div>
                </div>
            </ImagemFundoDetalhe> */}

            <div
                style={{
                    backgroundImage: `url(${fundo})`,
                    backgroundRepeat: 'no-repeat',
                }}
                className='w-full min-h-[500px] flex'
            >
                <div className='w-full min-h-full flex flex-col items-center justify-center bg-white bg-opacity-30'>
                    <div className="text-[44px] font-bold">{titulo}</div>
                    <div className="text-[32px] font-semibold">
                        {subtitulo} <span className='text-[#ff6a00]'>{subtituloDestaque}</span>
                    </div>
                </div>
            </div>

            {/* {providers &&
                <ListaProvidersHorizontal providers={providers} titulo={"Plataformas"} />
            } */}
            <Footer />
        </div>
        )
        :
        (
            <div className="lista-titulos-genero bg-black">
                {/* <Menu simples={false} protegida={protegida} /> */}

                <div
                    style={{
                        backgroundImage: `url(${fundo})`,
                        backgroundRepeat: 'no-repeat',
                    }}
                    className='w-full min-h-[500px] flex'
                >
                    <div className='w-full min-h-full flex flex-col items-center justify-center bg-white bg-opacity-30'>
                        <div className="text-[44px] font-bold">{titulo}</div>
                        <div className="text-[32px] font-semibold">
                            {subtitulo} <span className='text-[#ff6a00]'>{subtituloDestaque}</span>
                        </div>
                    </div>
                </div>


                {/* <ImagemFundoDetalhe img={fundo} className="lista-titulos-genero-fundo" opacity={false}>
                    <div className="titulo-pagina">
                        <div className="titulo-principal">{titulo}</div>
                        <div className="titulo-secundario">{subtitulo} <span>{subtituloDestaque}</span></div>
                    </div>
                </ImagemFundoDetalhe> */}

                {/* {providers &&
                    <ListaProvidersHorizontal providers={providers} titulo={"Plataformas"} />
                } */}

                {/* {tipoAnuncio === 'quadrado' &&
                    <ListaAnunciosHorizontal anuncios={anuncios} key="lista-anuncios" onCheckMonetizado={onCheckMonetizado} />
                }

                {tipoAnuncio === 'tira' &&

                    <ListaAnunciosTiraHorizontal anuncios={anuncios} key="lista-anuncios" onCheckMonetizado={onCheckMonetizado} />
                } */}

                {/* <ListaAnunciosHorizontalCustom onCheckMonetizado={onCheckMonetizado} anuncios={anuncios} key="lista-anuncios" /> */}

                <CardPopup ref={cardPopup} />

                <div className='relative'>

                    <ListaFilmesHorizontal cardPopup={cardPopup} filmes={lancamentos} titulo={`Lançamentos em ${titulo}`} onClick={handleClick} style={{ marginBottom: 20 }} />

                    <ListaFilmesHorizontal cardPopup={cardPopup} filmes={emAlta} titulo={`Em alta em ${titulo}`} onClick={handleClick} />
                    <ListaFilmesHorizontal cardPopup={cardPopup} filmes={populares} titulo={`Populares ${titulo}`} onClick={handleClick} />

                    {listaGeneros?.map(genero => {
                        return (
                            <ListaFilmesHorizontal
                                onClickVerMais={() => onClickVerMais(genero.id)}
                                cardPopup={cardPopup}
                                key={`lista-filmes-genero-${genero.id}`}
                                titulo={`${genero.name} ${genero.name !== titulo ? ` & ${titulo}` : ''}`}
                                filmes={genero.filmes} onClick={handleClick} />
                        )
                    })}
                </div>


                <Footer />
            </div>
        );
}


ListaTituloPorPlataformaGrid.propTypes = {

};

