import { Action } from "./Action";
import { Actions } from "./Actions";
import { Icon } from "./Icon";
import { Input } from "./Input";
import { Label } from "./Label";
import { LabelButton } from "./LabelButton";
import { Root } from "./Root";
import { Subtitle } from "./Subtitle";
import { Title } from "./Title";

export const SignIn = {
    Root: Root,
    Action: Action,
    Actions: Actions,
    Icon: Icon,
    Input: Input,
    Label: Label,
    Title: Title,
    Subtitle: Subtitle,
    LabelButton: LabelButton
}