import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

export function ScrollToTopButton() {
    const [showButton, setShowButton] = useState(false);

    // Detectar a rolagem da página
    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    // Adicionar evento de rolagem quando o componente for montado
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Função para rolar para o topo
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        showButton ? (
            <div className="fixed z-20 bottom-5 right-5 max-md:hidden">
                <button 
                    className='bg-[#151A25] w-[50px] h-[50px] rounded-full hover:bg-opacity-90' 
                    onClick={scrollToTop}
                >
                    <FontAwesomeIcon icon={faChevronUp} className='text-[20px]' />
                </button>
            </div>
        ) : null
    );
};