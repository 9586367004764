import React, { useContext, useImperativeHandle, useRef, useState } from 'react';
import { enviarSms, registrar, validarSms } from '../../../actions/assistamais/api';
import { MessageContext } from '../../../contexts/MessageContext';
import { onlyNumbers } from '../../../utils';
import BotaoRetanguloArredondado from '../../atoms/BotaoRetanguloArredondado';
import Popup from '../../molecules/Popup';
import PopupAviso from '../PopupAviso';
import PopupSms from '../PopupSms';
import Contatos from './Contatos';
import DadosPessoais from './DadosPessoais';
import Endereco from './Endereco';
import Senha from './Senha';
import './style.scss';

export const FazerCadastro = (props, ref) => {
    const [show, setShow] = useState(false);
    const [blocoDesabilitado, setblocoDesabilitado] = useState({ dadosPessoais: false, contato: true, endereco: true, senha: true });
    const [blocoValidado, setblocoValidado] = useState({ dadosPessoais: false, contato: false, endereco: false, senha: false });
    const [data, setData] = useState({cep: ''});
    const [showAvisoSucesso, setShowAvisoSucesso] = useState(false);
    const [showSms, setShowSms] = useState(false);
    const [validaSms, setValidaSms] = useState(false);

    const [dataContato, setDataContato] = useState({email: '', telefone: ''});
    
    const blocoDadosPessoaisRef = useRef();
    const blocoContatoRef = useRef();
    const blocoEnderecoRef = useRef();
    const enderecoRef = useRef();
    const blocoSenhaRef = useRef();
    const containerRef = useRef();

    const [showError] = useContext(MessageContext);

    useImperativeHandle(ref, () => ({
        open: () => setShow(true),
        close: () => setShow(false),
        toggle: () => {
            return setShow(!show);
        }
    }));

    const habilitarDadosPessoais = () => setblocoDesabilitado({ dadosPessoais: false, contato: true, endereco: true, senha: true, cadastrar: true });
    const habilitarContato = () => setblocoDesabilitado({ dadosPessoais: true, contato: false, endereco: true, senha: true, cadastrar: true });
    const habilitarEndereco = () => setblocoDesabilitado({ dadosPessoais: true, contato: true, endereco: false, senha: true, cadastrar: true });
    const habilitarSenha = () => setblocoDesabilitado({ dadosPessoais: true, contato: true, endereco: true, senha: false, cadastrar: true });
    const habilitarCadastrar = () => setblocoDesabilitado({ dadosPessoais: true, contato: true, endereco: true, senha: true, cadastrar: false });

    const irParaBloco = (objectRef) => {
        const top = objectRef.current.offsetTop; //Getting Y of target element
        containerRef.current.parentElement.parentElement.scrollTop = top;
    }

    const handleContinuarDadosPessoais = (dados) => {
        setblocoValidado({ ...blocoValidado, dadosPessoais: true });
        setData({ ...data, ...dados });
        irParaContato(true)
    }

    const irParaContato = (bypass = false) => {
        if (blocoValidado.dadosPessoais || bypass) {
            irParaBloco(blocoContatoRef);
            habilitarContato();
        }
    }

    const handleContinuarContato = (dadosContato) => {
        setblocoValidado({ ...blocoValidado, contato: true });
        setData({ ...data, ...dadosContato });
        irParaEndereco(true)
    }

    const irParaEndereco = (bypass = false) => {
        if (blocoValidado.contato || bypass) {
            irParaBloco(blocoEnderecoRef);
            habilitarEndereco();
        }
    }

    const handleContinuarEndereco = (dadosEndereco) => {
        setblocoValidado({ ...blocoValidado, endereco: true });
        irParaBloco(blocoSenhaRef);
        setData({ ...data, ...dadosEndereco });
        habilitarSenha();
    }

    const handleContinuarSenha = (dadosSenha) => {
        setblocoValidado({ ...blocoValidado, senha: true });
        setData({ ...data, ...dadosSenha });
        if(!validaSms){
            enviarSms({cpf: data.cpf, telefone: onlyNumbers(data.telefone) }).then(result => {
                if (result?.payload?.status * 1 < 200 || result?.payload?.status * 1 > 299) {
                    showError(result?.payload?.data.message)
                } else {
                    setShowSms(true);
                }
            }); 
        }else{
            habilitarCadastrar();
        }
    }

    const handleCadastrarClick = async () => {

        if(!blocoValidado.dadosPessoais){
            showError('Existem pendências de preenchimento no bloco Dados Pessoais');
            irParaBloco(blocoDadosPessoaisRef);
            habilitarDadosPessoais();
            return
        }
        
        if(!blocoValidado.contato){
            showError('Existem pendências de preenchimento no bloco Contatos');
            irParaBloco(blocoContatoRef);
            habilitarContato();
            return
        }

        if(!blocoValidado.endereco){
            showError('Existem pendências de preenchimento no bloco Endereço');
            irParaBloco(blocoEnderecoRef);
            habilitarEndereco();
            return
        }

        if(!blocoValidado.senha){
            showError('Existem pendências de preenchimento no bloco Senha');
            irParaBloco(blocoSenhaRef);
            habilitarSenha();
            return
        }


        // if(!validaSms){
        //     enviarSms({cpf: data.cpf, telefone: onlyNumbers(data.telefone) }).then(result => {
        //         if (result?.payload?.status * 1 < 200 || result?.payload?.status * 1 > 299) {
        //             showError(result?.payload?.data.message)
        //         } else {
        //             setShowSms(true);
        //         }
        //     });
        //     return
        // }

        const body = {
            name: data.nome,
            email: data.email,
            password: data.senha,
            role: 'user',
            extra: {
                cpf: `${data.cpf}`,
                genero: data.genero,
                nascimento: data.nascimento || '',
                celular: onlyNumbers(data.telefone),
                endereco: {
                    logradouro: data.endereco,
                    bairro: data.bairro,
                    cidade: data.cidade,
                    cep: data.cep,
                    uf: data.uf || '--',
                },
                autorizaReceberEmail: data.autorizaReceberEmail,
            }
        }

        registrar(body).then(result => {
            if (result?.payload?.status * 1 < 200 || result?.payload?.status * 1 > 299) {
                showError(result?.payload?.data.message)
            } else {
                localStorage.setItem('preCadastro', JSON.stringify({}));

                setShowAvisoSucesso(true);
            }
        });
    }

    const handlePreCadastrar = (dados, callback = null) => {
        console.log(enderecoRef);
        setData({ ...data, ...dados });
        if(callback) { 
            callback(...data)
        }
    }

    const closePupupSms = () => {

        // if(!validaSms){
        //     showError("Por favor realize a validação do token.")
        // }else{
            setShowSms(false)
        // }
        
    }

    const handleValidarClick = (codigo) => {

        validarSms({codigo: codigo, telefone: onlyNumbers(data.telefone) }).then(result => {
            if (result?.payload?.status * 1 < 200 || result?.payload?.status * 1 > 299) {
                showError(result?.payload?.data.message)
            } else {
                setShowSms(false);
                setValidaSms(true);
            }
        }); 
    }

    return ( 
        show && (
                    <Popup onClose={(e) => setShow(false)}>

                        {showAvisoSucesso &&
                            <PopupAviso titulo="Cadastro finalizado!" mensagem="Enviamos um link de validação para o seu e-mail. Verifique sua caixa de mensagem e ative sua conta." buttomOk={true} onClose={() => setShow(false)} />
                        }

                        {showSms &&
                            <PopupSms titulo="CÓDIGO" mensagem={`Para validar seu cadastro digíte o código Assista Mais que foi enviado por sms para o número ${data.telefone}.`} onClose={closePupupSms} validarClick={handleValidarClick} />
                        }

                        <div ref={containerRef} className="fazer-cadastro-container">
                            <div ref={blocoDadosPessoaisRef}>Faça seu cadastro</div>

                            <DadosPessoais disabled={blocoDesabilitado.dadosPessoais} continuarClick={handleContinuarDadosPessoais} onBlockedClick={habilitarDadosPessoais} preCadastrar={handlePreCadastrar} />

                            <div ref={blocoContatoRef} className="divisor-bloco"></div>
                            <Contatos data={dataContato} disabled={blocoDesabilitado.contato} continuarClick={handleContinuarContato} onBlockedClick={irParaContato} />

                            <div ref={blocoEnderecoRef} className="divisor-bloco"></div>
                            <Endereco data={data} disabled={blocoDesabilitado.endereco} continuarClick={handleContinuarEndereco} onBlockedClick={irParaEndereco} />

                            <div ref={blocoSenhaRef} className="divisor-bloco"></div>
                            <Senha disabled={blocoDesabilitado.senha} continuarClick={handleContinuarSenha} onBlockedClick={handleContinuarEndereco} />

                            <div className="divisor-bloco"></div>

                            <div className="fazer-cadastro-acoes">
                                <BotaoRetanguloArredondado value="Cadastrar" className="botao-arredondado-hack" disabled={false} onClick={handleCadastrarClick} />
                            </div>

                        </div>
                    </Popup>
                )

        
    )
};

export default React.forwardRef(FazerCadastro);