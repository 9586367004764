export const mask = (value, pattern) => {

    const _tamanho = () => {
        let tamanhoComplementar = 0;
        for (let i = 0; i < value.length + tamanhoComplementar; i++) {
            if (pattern[i] && pattern[i] !== '#') tamanhoComplementar++;
        }
        if (tamanhoComplementar + value.length > pattern.length) return pattern.length;
        else return tamanhoComplementar + value.length;
    }

    if (!value) {
        return '';
    }

    let i = 0;
    const v = value.toString();
    return pattern.substring(0, _tamanho()).replace(/#/g, () => v[i++] || '')
}

export const onlyNumbers = (value = '') => {
    const numberPattern = /\d+/g;
    const resultado = value.match(numberPattern) || []

    return resultado.length > 0 ? resultado.join('') : '';

}

export const formatName = (name) => {
    if (name) {
        const n = name.split(' ');
        const lastIndex = n.length - 1;
        const newName = `${n[0]} ${n[lastIndex]}`;
        return newName;
    } else {
        return '';
    }
}

export const formatNumberWithDot = (number) => {
    if (number) {
        return number.toLocaleString();   
    } else {
        return 0;
    }
}

export const useQuery = (location = null) => {
    return new URLSearchParams(location ?? window.location.search);
};

export function formatCurrencyBRL(value) {
    let newValue = "";
    if (value != undefined && value != null) {
        value = value.toString().replace(',', '');
    }
    newValue = Number(value).toFixed(2).toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return newValue;
}