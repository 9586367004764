import closedIconBlack from "../../../assets/img/closed-icon-black.png";
import closedIcon from "../../../assets/img/closed-icon.png";
import BannerCard from "../../atoms/BannerCard";
import MolduraPopup from "../../atoms/MolduraPopup";
import "./style.scss";

const OnBoardingPainel = ({}) => {
  return (
    <div className="flex flex-col w-full h-full bg-no-repeat bg-center bg-cover bg-[url('assets/img/usemais/banner_login_background.png')]">
      <div className="flex overflow-hidden relative flex-col grow justify-center">
        <div className="xl:-ml-6 flex relative justify-center items-center px-8 py-10 mix-blend-hard-light max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center mt-2  max-w-full">
            <img 
              loading="lazy" 
              src="usemais/logo_use_mais_white.png"
              className="w-[267px]"
            />
            <div className="mt-32 mb-24 sm:text-[2rem] md:text-[2rem] 2xl:text-5xl font-bold text-center text-white w-full max-w-[481px] h-[122px] max-md:mt-10 max-md:max-w-full max-md:text-4xl">
              <span className="2xl:text-5xl">Acesse a Use Mais e aproveite!</span>
            </div>
            <div className="w-full flex justify-center">
              <div className="grid grid-cols-2 grid-rows-2 md:gap-4 xl:gap-8 w-11/12 max-w-[680px]">
                <div className="">
                  <BannerCard 
                    img='usemais/login_planos_unicos.png' 
                    text='Planos únicos'
                  />
                </div>
                <div className="md:pl-[20px] xl:pl-[40px]">
                  <BannerCard 
                    img='usemais/login_prod_exclusivos.png' 
                    text='Produtos exclusivos' 
                  />
                </div>
                <div className="">
                  <BannerCard 
                    img='usemais/login_gift_cards.png' 
                    text={<span>Gift<br/>cards</span>} 
                  />
                </div>
                <div className="md:pl-[20px] xl:pl-[40px]">
                  <BannerCard 
                    img='usemais/login_beneficios.png' 
                    text='Benefícios e muito mais!' 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingPainel;
