import React, {useState} from 'react';
import BotaoArredondado from '../../atoms/BotaoArredondado';
import Popup from '../../molecules/Popup';
import PopupAviso from '../PopupAviso';
import './style.scss';

export const PopupSms = ({ titulo, mensagem, validarClick, ...rest }) => {


    const [codigo, setCodigo] = useState('');

    const [popupContato, setPopupContato] = useState(false)

    const onChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
           setCodigo(e.target.value)
        }
    }

    const link = () => {
       return ( <a style={{color: 'blue'}} href="mailto:contato@assistamais.com.br">contato@assistamais.com.br</a>)
    }

    return (
        <>
        <Popup {...rest} >
            <div className="popup-sms">
                <div className='popup-sms-titulo'>{titulo}</div>
                <div className='popup-sms-mensagem'>{mensagem}</div>
                <input maxLength={6} onChange={(res) => {onChange(res)}} value={codigo} className='popup-input' type="text"/>
                <span className='link-mail' onClick={() => setPopupContato(true)}><strong><u>Não recebi o SMS</u></strong></span>
                <BotaoArredondado value="VALIDAR" className='popup-resgate-custom-button' onClick={() => validarClick(codigo)} />
                
            </div>
        </Popup>
        {popupContato &&

            <Popup {...rest}>
                <div className="popup-aviso">
                    <div className='popup-aviso-titulo'>Não recebeu o seu código?</div>
                    <div className='popup-aviso-mensagem'> 
                        Envie-nos um e-mail para <a href="mailto:contato@assistamais.net.br" className='popup-link-contato'>contato@assistamais.net.br</a> e entraremos em contato para solucionar seu problema.
                    </div>
                </div>
            </Popup>

        }
        </>
        
    )
};

export default PopupSms;