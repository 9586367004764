import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface PasswordProps {
    children: ReactNode,
    className?: string
}


export function PasswordRoot({ children, className }: PasswordProps) {
    return (
        <div className={twMerge(
            "flex flex-wrap justify-center self-stretch px-20 py-10 my-auto h-full w-full text-xl bg-white rounded-[48px] max-md:px-5 max-md:mt-10 max-md:max-w-full",
            className
        )}>
            {children}
        </div>
    )
}