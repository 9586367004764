import { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Avatar, Box, Modal } from '@mui/material';
import BotaoArredondadoUsemais from '../../atoms/BotaoArredondadoUsemais';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { enviarSms, resgatarDePontos, validarSms } from '../../../actions/assistamais/api';
import { obterDados } from '../../../utils/Session';


export const ListaGiftsResgatadosHorizontalUsemais = ({ resgates = [], notMouseOver = false, onClick, onMouseOver, onMouseLeave, cardPopup, pontos, ...rest }) => {

    const [listaGiftCards, setListaGiftCards] = useState([]);
    const [produtoSelecionado, setProdutoSelecinado] = useState({});

    const [showAviso, setShowAviso] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showSms, setShowSms] = useState(false);
    const [validaSms, setValidaSms] = useState(false);
    const [codigo, setCodigo] = useState("");

    const dados = obterDados();

    useEffect(() => {
        setListaGiftCards(resgates);
    }, [resgates]);

    const carouselRef = useRef(null); // Referência ao container do carrossel
    const [isDragging, setIsDragging] = useState(false); // Estado para controlar o arraste
    const [startX, setStartX] = useState(0); // Posição inicial do mouse/touch
    const [scrollLeft, setScrollLeft] = useState(0); // Posição inicial do scroll

    // Inicia o arraste
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX || e.touches[0].pageX); // Posição inicial do mouse ou toque
        setScrollLeft(carouselRef.current.scrollLeft); // Posição inicial do scroll
    };

    // Finaliza o arraste
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Movimenta o carrossel
    const handleMouseMove = (e) => {
        if (!isDragging) return; // Apenas movimenta se estiver arrastando
        e.preventDefault(); // Previne seleção de texto/imagens
        const x = e.pageX || e.touches[0].pageX; // Posição atual do mouse ou toque
        const walk = (x - startX) * 1.5; // Multiplica o deslocamento para maior fluidez
        carouselRef.current.scrollLeft = scrollLeft - walk; // Atualiza o scroll
    };

    const handleNext = () => carouselRef.current.scrollBy({ left: 300, behavior: "smooth" })

    const handlePrev = () => carouselRef.current.scrollBy({ left: -300, behavior: "smooth" })

    const handlePressResgatarDenovo = async (produto) => {
        setProdutoSelecinado(produto);
        setShowConfirm(true);
    }

    const handleResgateConfirm = async() => {
        if(!validaSms){
            enviarSms({cpf: dados.cpf, telefone: dados.celular }).then(result => {
                setShowConfirm(false);
                setShowSms(true);
            });
        }else{
            resgatarDePontos(produtoSelecionado.codigo, produtoSelecionado.categoria).then(resultado => {
                if (Math.trunc(resultado?.payload?.status / 100) === 2) { // 2xx => Resultado ok
                    setShowAviso(true);
                } else {
                    showError(resultado?.payload?.data?.message);
                }
            })
        }
    }

    const handleValidarClick = (codigo) => {
        validarSms({codigo: codigo, telefone: dados.celular }).then(result => {
            if (result?.payload?.status * 1 < 200 || result?.payload?.status * 1 > 299) {
                showError(result?.payload?.data.message)
            } else {
                setShowSms(false);
                setValidaSms(true);
                resgatarDePontos(produtoSelecionado.id ?? produtoSelecionado.codigo, produtoSelecionado.categoria).then(resultado => {
                    if (Math.trunc(resultado?.payload?.status / 100) === 2) { // 2xx => Resultado ok
                        setShowAviso(true); 
                    } else {
                        showError(resultado?.payload?.data?.message);
                    }
                })
            }
        }); 
    }

    return (
        <div className='flex flex-col w-full gap-6'>
            <span className='text-[#151A25] font-semibold text-[18px] md:text-2xl mt-[36px] md:mt-0 text-center md:text-left'>
                Gifts resgatados
            </span>

            <div className='w-full bg-white border border-[#BFBFBF] rounded-3xl py-5 min-h-[150px]'>

                {listaGiftCards && listaGiftCards.length > 0 ? (
                    <Box className="relative flex flex-col items-center w-full bg-white overflow-hidden">
                        <Box
                            ref={carouselRef}
                            className="flex overflow-x-scroll gap-2 w-full scrollbar-hide"
                            style={{
                                cursor: isDragging ? "grabbing" : "grab", // Troca o cursor durante o arraste
                                userSelect: "none", // Desativa seleção de texto
                            }}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseUp}
                            onMouseMove={handleMouseMove}
                            onTouchStart={handleMouseDown}
                            onTouchEnd={handleMouseUp}
                            onTouchMove={handleMouseMove}
                        >
                            
                            {/* Itens do Carrossel */}
                          
                            {listaGiftCards.map((gift, index) => (
                                <Box
                                    key={`gift-${index}`}
                                    className="flex flex-col items-center gap-4 w-[240px] md:w-[300px] bg-white rounded-lg px-[20px] shrink-0"
                                >
                                    <div className='w-full flex items-center gap-4'>
                                        {/* Imagem em formato de avatar */}
                                        <Avatar
                                            src={gift.objectData.imagemCapa}
                                            alt={gift.objectData.descricao}
                                            className="w-[64px] h-[64px] mb-4 md:ml-8"
                                        />
                                        {/* Descrição do gift */}
                                        <span className="text-center text-sm font-light text-[#151A25] mb-2 max-w-[160px]">
                                            {gift.objectData.descricao}
                                        </span>
                                    </div>
                                    <BotaoArredondadoUsemais  
                                        value={"Resgatar novamente"} 
                                        className='w-[200px] h-[36px] md:w-[240px] md:h-[40px]'
                                        classNameText='text-[14px] md:text-sm' 
                                        onClick={() => {
                                            gift.objectData.pontos <= pontos?.disponivel && handlePressResgatarDenovo(gift.objectData)
                                        }}
                                        ativo={gift.objectData.pontos <= pontos?.disponivel}
                                    />
                                </Box>
                            ))}

                            {/* Controles do Carrossel */}

                            <Box className="hidden md:flex absolute left-[4px] top-1/2 transform -translate-y-1/2">
                                <button 
                                    onClick={handlePrev} 
                                    className="bg-black bg-opacity-30 shadow-lg rounded-full w-[40px] h-[40px] flex items-center justify-center text-white hover:bg-slate-700"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                            </Box>
                            <Box className="hidden md:flex absolute right-[4px] top-1/2 transform -translate-y-1/2">
                                <button 
                                    onClick={handleNext} 
                                    className="bg-black bg-opacity-30 shadow-lg rounded-full w-[40px] h-[40px] flex items-center justify-center text-white hover:bg-slate-700"
                                >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </Box>

                        </Box>
                        
                    </Box>
                ) : (
                    <Box className="w-full h-full flex items-center justify-center">
                        <span className='text-[#151A25]'>Não há dados disponíveis</span>
                    </Box>
                )}

            </div>
            
            {showConfirm &&
                <Modal
                    open={showConfirm}
                    onClose={() => setShowConfirm(false)}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] p-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold'>
                                Muito bem!
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium text-center'>
                                Deseja resgatar este gift novamente?
                            </span>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium text-center'>
                                Serão utilizados {produtoSelecionado.pontos} pontos.
                            </span>
                        </div>
                        <div className='w-full flex flex-col gap-[10px]'>
                            <button 
                                onClick={handleResgateConfirm} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] py-[10px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Sim</span>
                            </button>
                            <button 
                                onClick={() => setShowConfirm(false)}
                                className='
                                    w-full bg-white border border-[#ED636E] 
                                    text-[#ED636E] text-[16px] py-[10px] rounded-[8px]
                                    hover:font-medium
                                '
                            >
                                <span>Voltar</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }

            {showSms &&
                <>
                    <Modal
                        open={showSms}
                        onClose={() => setShowSms(false)}
                        className='flex items-center justify-center px-2'
                    >
                        <Box className="bg-[#FFF] p-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                            <div className='w-full flex flex-col items-center gap-[10px]'>
                                <h2 className='text-[28px] md:text-[32px] text-[#151A25] text-center font-bold'>
                                    Validação Código!
                                </h2>
                                <span className='text-[16px] md:text-[20px] text-[#151A25]  font-medium text-center'>
                                    Para realizar o resgate enviamos um código via SMS para o telefone que esta cadastrado na sua conta do Use mais.
                                </span>
                            </div>
                            <div className='w-full flex flex-col items-start gap-[4px]'>
                                <input 
                                    type="text"
                                    className='
                                        w-full bg-white border border-[#ED636E]
                                        text-[16px] p-[10px] rounded-[8px] text-[#3D3D3D]
                                    '
                                    placeholder='Digite o código SMS'
                                    onChange={e => setCodigo(e.target.value)}
                                    value={codigo}
                                />
                                <button 
                                    className='text-[#ED636E] text-[14px] underline font-semibold hover:font-bold'
                                    onClick={() => {
                                        handleResgateConfirm()
                                        toast.success("Código reenviado via SMS!")
                                    }}
                                >
                                    Não recebi o SMS
                                </button>
                            </div>
                            <div className='w-full flex flex-col gap-[10px]'>
                                <button 
                                    onClick={() => handleValidarClick(codigo)}
                                    className='
                                        w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                        text-white text-[16px] py-[10px] rounded-[8px]
                                        hover:opacity-90
                                    '
                                >
                                    <span>Validar</span>
                                </button>
                                <button 
                                    onClick={() => setShowSms(false)}
                                    className='
                                        w-full bg-white border border-[#ED636E] 
                                        text-[#ED636E] text-[16px] py-[10px] rounded-[8px]
                                        hover:font-medium
                                    '
                                >
                                    <span>Cancelar</span>
                                </button>
                            </div>
                        </Box>
                    </Modal>
                </>
            }

            {showAviso &&
                <Modal
                    open={showAviso}
                    onClose={() => {
                        setShowAviso(false)
                        window.location.reload()
                    }}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] p-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold'>
                                Sucesso!
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium text-center'>
                                Em até 10 dias úteis seu gift será enviado no seu e-mail para utilização.
                            </span>
                        </div>
                        <div className='w-full flex flex-col gap-[10px]'>
                            <button 
                                onClick={() => {
                                    setShowAviso(false)
                                    window.location.reload()
                                }} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] py-[10px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Ok</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }


        </div>
    )
};