import { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Avatar, Box, Modal } from '@mui/material';
import BotaoArredondadoUsemais from '../../atoms/BotaoArredondadoUsemais';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { enviarSms, resgatarDePontos, validarSms } from '../../../actions/assistamais/api';
import { obterDados } from '../../../utils/Session';
import { useHistory } from 'react-router-dom';


export const ListaPlanosContratadosHorizontalUsemais = ({ planosContratados = [], notMouseOver = false, onClick, onMouseOver, onMouseLeave, cardPopup, pontos, ...rest }) => {

    const [listaPlanosCards, setListaPlanosCards] = useState([]);
    const history = useHistory();

    const dados = obterDados();

    useEffect(() => {
        setListaPlanosCards(planosContratados);
    }, [planosContratados]);

    const carouselRef = useRef(null); // Referência ao container do carrossel
    const [isDragging, setIsDragging] = useState(false); // Estado para controlar o arraste
    const [startX, setStartX] = useState(0); // Posição inicial do mouse/touch
    const [scrollLeft, setScrollLeft] = useState(0); // Posição inicial do scroll

    // Inicia o arraste
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX || e.touches[0].pageX); // Posição inicial do mouse ou toque
        setScrollLeft(carouselRef.current.scrollLeft); // Posição inicial do scroll
    };

    // Finaliza o arraste
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Movimenta o carrossel
    const handleMouseMove = (e) => {
        if (!isDragging) return; // Apenas movimenta se estiver arrastando
        e.preventDefault(); // Previne seleção de texto/imagens
        const x = e.pageX || e.touches[0].pageX; // Posição atual do mouse ou toque
        const walk = (x - startX) * 1.5; // Multiplica o deslocamento para maior fluidez
        carouselRef.current.scrollLeft = scrollLeft - walk; // Atualiza o scroll
    };

    const handleNext = () => carouselRef.current.scrollBy({ left: 300, behavior: "smooth" })

    const handlePrev = () => carouselRef.current.scrollBy({ left: -300, behavior: "smooth" })

    const handlePressDetalhesContratacao = async (contratacaoId) => {
        history.push(`/detalhes-plano/?id=${contratacaoId}`)
    }

    return (
        <div className='flex flex-col w-full gap-6'>
            <span className='text-[#151A25] font-semibold text-[18px] md:text-2xl mt-[36px] md:mt-0 text-center md:text-left'>
                Meu Planos
            </span>

            <div className='w-full bg-white border border-[#BFBFBF] rounded-3xl py-5 min-h-[150px]'>

                {listaPlanosCards && listaPlanosCards.length > 0 ? (
                    <Box className="relative flex flex-col items-center w-full bg-white overflow-hidden">
                        <Box
                            ref={carouselRef}
                            className="flex overflow-x-scroll gap-2 w-full scrollbar-hide"
                            style={{
                                cursor: isDragging ? "grabbing" : "grab", // Troca o cursor durante o arraste
                                userSelect: "none", // Desativa seleção de texto
                            }}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseUp}
                            onMouseMove={handleMouseMove}
                            onTouchStart={handleMouseDown}
                            onTouchEnd={handleMouseUp}
                            onTouchMove={handleMouseMove}
                        >
                            
                            {/* Itens do Carrossel */}
                          
                            {listaPlanosCards.map((contratacao, index) => (
                                <Box
                                    key={`gift-${index}`}
                                    className="flex flex-col items-center gap-4 w-[240px] md:w-[300px] bg-white rounded-lg px-[20px] shrink-0"
                                >
                                    <div className='w-full flex items-center justify-center flex-col gap-2 min-h-[90px]'>
                                        {/* Imagem em formato de avatar */}
                                        <Avatar
                                            src={contratacao.plano.imagemLogoUrl}
                                            alt="Logo do Plano"
                                            className="w-[64px] h-[64px] mb-4 rounded-full"
                                        />
                                        
                                        <span className="text-center text-md font-light text-[#151A25] mb-2 max-w-[160px]">
                                            {contratacao.plano.nome}
                                        </span>

                                    </div>
                                    <BotaoArredondadoUsemais  
                                        value={contratacao.status == 'ATIVA' ? "Dados do plano" : "Pendente confirmação de dados"} 
                                        className='w-[200px] h-[36px] md:w-[240px] md:h-[40px]'
                                        classNameText='text-[14px] md:text-sm' 
                                        onClick={() => handlePressDetalhesContratacao(contratacao.id)}
                                        ativo={contratacao.status == 'ATIVA'}
                                    />
                                </Box>
                            ))}

                            {/* Controles do Carrossel */}

                            <Box className="hidden md:flex absolute left-[4px] top-1/2 transform -translate-y-1/2">
                                <button 
                                    onClick={handlePrev} 
                                    className="bg-black bg-opacity-30 shadow-lg rounded-full w-[40px] h-[40px] flex items-center justify-center text-white hover:bg-slate-700"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                            </Box>
                            <Box className="hidden md:flex absolute right-[4px] top-1/2 transform -translate-y-1/2">
                                <button 
                                    onClick={handleNext} 
                                    className="bg-black bg-opacity-30 shadow-lg rounded-full w-[40px] h-[40px] flex items-center justify-center text-white hover:bg-slate-700"
                                >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </Box>

                        </Box>
                        
                    </Box>
                ) : (
                    <Box className="w-full h-full flex items-center justify-center">
                        <span className='text-[#151A25]'>Não há dados disponíveis</span>
                    </Box>
                )}

            </div>

        </div>
    )
};