import { ButtonHTMLAttributes, ElementType } from "react";
import { twMerge } from 'tailwind-merge'

interface SignUpActionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: ElementType,
    text?: string
}

export function SignUpAction({ icon: Icon, text, ...rest }: SignUpActionProps) {
    return (
        <button
            {...rest}
            className={twMerge("", rest.className)}
        >
            {text}
            {Icon && <Icon />} 
        </button>
    )
}