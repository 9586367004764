import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import './style.css';


export default function BotaoMenuHamburger({ className, onClick, ...rest }) {

    return (
        <div className={`${className} botao-menu-hamburger-icon`} onClick={onClick}>
            <MenuIcon />
        </div>
    );
}