import { useMediaQuery } from "@mui/material";
import { ButtonHTMLAttributes, ElementType } from "react";
import { twMerge } from "tailwind-merge";

interface SignUpPassValidationProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon: ElementType,
    text: string,
    validation: boolean
}

export function SignUpPassValidation({ icon: Icon, text, validation }: SignUpPassValidationProps) {

    const size = {
        sm: useMediaQuery('(max-width:768px)'),
        md: useMediaQuery('(min-width:768px)'),
        lg: useMediaQuery('(min-width:1024px)')
    }

    return (
        <div className="flex flex-row gap-[8px] items-center">
            <div className={twMerge(
                "w-[36px]",
                size.sm && "w-[20px]",
                size.md && "w-[24px]",
                size.lg && "w-[28px]",
            )}>
                {validation && (
                    <Icon 
                        style={{ 
                            color: validation ? '#ED636E' : '', 
                            // display: validation ? 'block' : 'none',
                            width: size.sm ? '18px' : size.md ? '20px' : size.lg ? '24px' : '32px' ,
                        }}
                    />
                )}
            </div>
            
            <span 
                className={validation 
                    ? 'leading-[14px] md:leading-[16px] lg:leading-[18px] text-[12px] md:text-[14px] lg:text-[16px] line-through' 
                    : 'leading-[14px] md:leading-[16px] lg:leading-[18px] text-[12px] md:text-[14px] lg:text-[16px]'
                }
            >
                {text}
            </span>
        </div >
    )
}