import { twMerge } from 'tailwind-merge';


export default function Input({ name, error, label, description, ...rest }) {

    return (
        <div className={twMerge("flex flex-col", rest.className)}>
            {label && (
                <label htmlFor={name} className='text-[#151A25] text-[14px] mb-[8px]'>
                    {label}
                </label>
            )}
            <input
                name={name}
                {...rest}
                className='border border-[#8A8A8A] rounded-[8px] text-[14px] text-[#151A25] p-[10px]'
            />
            {description && <span className="text-[#151A25] text-[12px] font-light mt-1">{description}</span>}
            {error ? <span className="text-red-500 text-[14px]">{error}</span> : <span className='h-[16px]'></span>}
        </div>
    );
}