import React from 'react';
import config from '../../../config/default';
import BotaoBordaArredondado from '../../atoms/BotaoBordaArredondado';
import Card from '../../atoms/Card';
import Classificacao from '../../atoms/Classificacao';
import './style.scss';

const SobreFilmeSerie = ({ filme, staff, poster, backdropPath, videoPopupRef, trailer }) => {

    const obterClassificacao = (filme) => {
        try {
            if(filme.content_ratings) {
                return filme.content_ratings.results.find(r => r.iso_3166_1 === 'BR').rating;
            } else {
                return filme.release_dates.results.find(r => r.iso_3166_1 === 'BR').release_dates[0].certification;

            }
        } catch (err) {
            return "";
        }
    }

    const obterLabelClassificacao = (filme) => {
        try {
            const classificacao = obterClassificacao(filme);

            if (isNaN(classificacao)) {
                if (classificacao?.length > 0) {
                    return "Classificação Livre"
                }
            } else {
                return `Não recomendado para menores de ${classificacao} anos.`
            }
        } catch (err) {
            return "";
        }
    }

    const handleClickTrailer = () => {
        try {
            videoPopupRef.current.show();

        } catch (err) {

        }
    }

    return (
        <div className="sobre-filme-serie" style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.85), rgba(0,0,0,0.85)),url(${config.bigImageURL}${backdropPath})`,
            backgroundRepeat: 'no-repeat'
        }}>

            <div className="card">
                <Card posterPath={filme.poster_path || poster} name={filme.title} className="poster" />
            </div>
            <div className="dados-filme-serie">
                <div className="dados-filme-serie-titulo">Sobre {filme.title} </div>
                <div className="dados-filme-serie-staff">
                    {staff &&
                        <div className="staff">
                            <div><span className="Text-Style-8">Direção:</span> <span className="Text-Style-9">{staff.diretor.map(d => d.name).join(", ")}</span></div>
                            <div><span className="Text-Style-8">Roteiro:</span> <span className="Text-Style-9">{staff.roteiro.map(r => r.name).join(", ")}</span></div>
                            <div><span className="Text-Style-8">Elenco:</span> <span className="nomes Text-Style-9">{staff.estrelas.slice(0, 12).map(e => e.name).join(", ")}</span></div>
                        </div>
                    }
                    <div className="dados-filme-serie-classificacao" >
                        <Classificacao codigo={obterClassificacao(filme)} /> <span className="dados-filme-serie-label-classificacao">{obterLabelClassificacao(filme)}</span>
                    </div>
                    {trailer &&
                        <BotaoBordaArredondado className="dados-filme-serie-botao" value="ASSISTA AO TRAILER" onClick={handleClickTrailer} />
                    }
                </div>

            </div>

        </div>
    )
};

export default SobreFilmeSerie;