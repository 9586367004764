import React, { useContext, useEffect, useRef, useState } from 'react';
import { enviarFotoPerfil, obterFotoPerfil } from '../../../actions/assistamais/api';
import photoIcon from '../../../assets/img/edit-icon.png';
import { MessageContext } from '../../../contexts/MessageContext';
import { SessionContext } from '../../../contexts/SessionContext';
import './style.scss';
import { twMerge } from 'tailwind-merge';
import { gravarDados, obterDados } from '../../../utils/Session';
import { toast } from 'react-toastify';

const avatarDefaultImg = `${process.env.PUBLIC_URL}/img/avatar/img4.jpg`;

export const FotoPerfilEditUsemais = ({ fot, className }) => {

    const inputFileRef = useRef();

    const [foto, setFoto] = useState();
    const [, , showMessageFromResultRequest] = useContext(MessageContext);
    const [session, setSession, logado] = useContext(SessionContext);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            //reader.readAsText(file)
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleChangeImage = async (e) => {

        if (e.target.files[0]) {

            let formData = new FormData();
            formData.append('image', e.target.files[0]);
            let resp = await enviarFotoPerfil(formData).then(async resultado => {
                if (Math.trunc(resultado?.payload?.status / 100) === 2) { // 2xx => Resultado ok
                    toast.success('Foto atualizada com sucesso');
                    setFoto(await getBase64(e.target.files[0]));
                    let session = obterDados();
                    gravarDados({ ...session, foto: resultado?.payload?.data?.avatar, status: 'OK' });
                    document.location.reload(true);
                } else {
                    toast.error(resultado.payload.data.message);
                }
            });
            // if (showMessageFromResultRequest(resp, "Foto atualizada com sucesso")) {
                

            // }
        }


    }

    useEffect(() => {
        setFoto(session?.foto);
    }, [session])

    return (
        <div className={twMerge(`foto-perfil-edit relative`, className)}>
            <img src={foto ?? avatarDefaultImg} className="w-[134px] h-[134px] md:w-[150px] md:h-[150px] rounded-full" alt="" />
            <input type="file" name="file"
                className="upload-file"
                id="file"
                style={{ display: 'none' }}
                onChange={handleChangeImage}
                encType="multipart/form-data"
                ref={inputFileRef} >

            </input> 
            <img 
                src={photoIcon} 
                height="35" 
                width="35" 
                className="cursor-pointer absolute right-0 bottom-[4px] camera-icon border-[3px] border-white rounded-3xl" 
                alt='Câmera Icon' 
                onClick={() => inputFileRef.current.click()} 
            />
        </div>
    )
};