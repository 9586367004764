import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { twMerge } from 'tailwind-merge';

const BannerFundo = ({ carrossel = [], interval = 5000 }) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (carrossel && carrossel.length > 0) {
            const intervalRepeat = setInterval(() => {

                setCurrentIndex(prevValue => {
                    if (prevValue + 1 > carrossel.length -1 ) {
                        return 0
                    } else {
                        return prevValue + 1
                    }
                })
            }, interval)
            return () => clearInterval(intervalRepeat)
        }
    },[carrossel])


    const handleClick = (url) => {
        window.location.href = url;
    }

    const handleNext = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex + 1 > carrossel.length - 1) {
                return 0
            } else {
                return prevIndex + 1
            }
        });
    };
    
    const handlePrev = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + carrossel.length) % carrossel.length
        );
    };

    return (
        <Box className="relative w-full overflow-hidden">
            <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {carrossel && carrossel.map((anuncio, index) => (
                    <div 
                        key={index}
                        className={`min-w-full cursor-pointer`} 
                        // onClick={() => handleClick(anuncio.url)} 
                    >
                        <img src={anuncio.imagemCapa} alt="" className='w-full' />
                    </div>
                ))}
            </div>

            {/* Indicadores em forma de traços */}
            <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-[10px] p-[10px]">
                {carrossel.map((_, index) => (
                    <div
                        key={index}
                        // onClick={() => setCurrentIndex(index)}
                        className={twMerge(
                            "w-[50px] h-[6px] cursor-pointer",
                            index === currentIndex ? "bg-[#FF6A00]" : "bg-[#757575]"
                        )}
                    />
                ))}
            </div>
        </Box>
    )
};

export default BannerFundo;