import { useEffect } from 'react'
import Header from '../../componentes/usemais/Header/index'
import Body from '../../componentes/usemais/Body/index'
import Footer from "../../componentes/usemais/Footer/index"
import "./style.scss"
import useSession from '../../hooks/useSession'
import { useHistory } from 'react-router-dom'
import { RodapeUsemais } from '../../componentes/organisms/RodapeUsemais'
import { ScrollToTopButton } from '../../componentes/usemais/components/ScrollToTopButton'


export default function Home() {

    const [session, , logado, , getSessionData] = useSession();
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        getSessionData()
    }, []);

    // useEffect(() => {
    //     if (logado) {
    //         history.replace('/home')
    //     }
    // }, [logado]);

    return (
        <div className='' id="home-start">
            {/* <Header /> */}

            <Body />

            <RodapeUsemais />

            <ScrollToTopButton />
        </div>
    )
}