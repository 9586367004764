"use client";
import { useEffect, useState } from "react";
import { SignIn } from "../SignIn";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { toast } from "react-toastify";
import { SignUp } from "../SignUp";
import { resetarSenha } from "../../../../actions/assistamais/api";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

interface passwordProps {
  token: string;
}

export default function Index({ token }: passwordProps) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [code, setCode] = useState([]);
  const [codeValidated, setCodeValidated] = useState(false);
  const [recoverInfo, setRecoverInfo] = useState("");
  const [validation, setValidation] = useState({
    isLengthValid: false,
    isUpperCaseValid: false,
    isLowerCaseValid: false,
    isNumberValid: false,
    isSpecialCharValid: false,
    isEqualPassword: false,
  });

  const isMobile = useMediaQuery('(max-width:768px)');

  const sizeHeight = {
    xs: useMediaQuery('(max-height:760px)')
  };

  const [showValidation, setShowValidation] = useState(false);

  const formValid = (password: string) => {
    const lengthValid = password.length >= 8;
    const upperCaseValid = /[A-Z]/.test(password);
    const lowerCaseValid = /[a-z]/.test(password);
    const numberValid = /\d/.test(password);
    const specialCharValid = /[!@#$%^&*]/.test(password);
    const equalPassword = password.length > 0 && password == confirmPassword;

    setValidation({
      isLengthValid: lengthValid,
      isUpperCaseValid: upperCaseValid,
      isLowerCaseValid: lowerCaseValid,
      isNumberValid: numberValid,
      isSpecialCharValid: specialCharValid,
      isEqualPassword: equalPassword,
    });

    const isFormInvalid = [
      lengthValid,
      upperCaseValid,
      lowerCaseValid,
      numberValid,
      specialCharValid,
      equalPassword,
    ].every(Boolean);

    setIsFormValid(isFormInvalid);
  };

  async function resetSenha() {
    try {
      setIsLoading(true);

      let response: any = await resetarSenha(password, token);

      if (response.payload.status == 204) {
        toast.success('Senha alterada com sucesso');
        setStep(2);
      } else {
        toast.error(response.payload?.data?.message ?? "Erro ao redefinir senha");
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    
  }

  const validations = [
    {
      id: "validation-length",
      text: "8 caracteres",
      validation: validation.isLengthValid,
    },
    {
      id: "validation-upper",
      text: "1 letra maiúscula",
      validation: validation.isUpperCaseValid,
    },
    {
      id: "validation-lower",
      text: "1 letra minúscula",
      validation: validation.isLowerCaseValid,
    },
    {
      id: "validation-number",
      text: "1 número",
      validation: validation.isNumberValid,
    },
    {
      id: "validation-caracter",
      text: "1 caractere especial",
      validation: validation.isSpecialCharValid,
    },
    // { id: "validation-equal", text: "Senhas iguais", validation: validation.isEqualPassword }
  ];

  useEffect(() => {
    formValid(password);
    // savePassword(password);
  }, [password, confirmPassword]);

  useEffect(() => {
    if (code.length === 0) {
      setCodeValidated(false);
    }

    const allFieldsFilled = code.every(
      (value) => value !== "" && value !== undefined
    );

    if (!allFieldsFilled) {
      setCodeValidated(false);
    }

    if (allFieldsFilled && code.length == 6) {
      setCodeValidated(true);
      //   handleValidation();
    }
  }, [code]);

  const handleStep = () => {
    if (step == 1){
      resetSenha();
    } else 
      history.replace('/login');
  };

  const backHome = () => {
    history.replace('/login');
  };

  const getButtonText = () => {
    if (step == 1) return "Confirmar";
    else return "Login";
  };

  return (
    <div className="flex flex-col h-full md:h-auto md:mx-auto md:w-full md:ml-0 w-full md:flex-row md:justify-center md:p-[40px] lg:p-[80px]">
      <div className="md:border border-[#151A25] md:px-5 py-[40px] md:my-auto h-full md:h-full md:max-h-[860px] w-full text-xl bg-white md:shadow-lg md:rounded-[48px] max-md:max-w-full xl:max-w-[496px] 2xl:max-w-[596px]">
        <SignIn.Root
          className="flex flex-col items-center justify-between py-[40px] h-full max-md:mt-0 max-md:px-[20px] max-lg:px-2 flex-nowrap gap-4"
        >

          {isMobile && (
            <img 
              src="/usemais/logo_use_mais.png" 
              alt="Logo"
              className={twMerge(
                "w-full max-w-[160px] cursor-pointer mb-0 flex",
                sizeHeight.xs && "max-w-[120px]"
              )}
            />
          )}

          <div className="self-start flex flex-col w-full">
            {step == 1 && (
              <>
                <SignIn.Title 
                  className={twMerge(
                    "lg:mt-3 max-md:max-w-full text-[28px] md:text-[32px] lg:text-[36px]",
                    sizeHeight.xs && "text-[24px]"
                  )}
                  text={"Redefinir senha"} 
                />
                <SignIn.Subtitle
                  className={twMerge(
                    "text-[16px] md:text-[18px] lg:text-[20px] max-lg:text-start font-normal mt-[8px] md:mt-[32px]",
                    sizeHeight.xs && "text-[14px]"
                  )}
                  text={"Defina sua senha para acessar a Use Mais."}
                />
                <SignIn.Input
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  placeholder="Digite sua nova senha"
                  autoComplete="off"
                  className="text-[16px] h-[44px] max-md:mt-[20px] gap-0 md:mt-[28px] max-lg:text-[16px] lg:text-lg"
                  onChange={(e) => setPassword(e.target.value)}
                  onFocusCapture={(e) => {
                    setShowValidation(true)
                  }}
                  onBlurCapture={(e) => {
                    setShowValidation(false)
                  }}
                  endIcon={
                    <button 
                      className="absolute right-[14px] max-md:top-[32px] top-[40px] flex items-center justify-center h-[20px] w-[20px]"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <FontAwesomeIcon 
                        icon={showPassword ? faEyeSlash : faEye} 
                        className="text-[#8A8A8A] text-[16px]" 
                      />
                    </button>
                  }
                  validationComponent={
                    <>
                      {showValidation && (
                        <SignUp.Validations
                          className="absolute z-10 bg-white shadow-lg p-[10px] min-w-[240px]"
                        >
                          <div className="flex flex-col mt-4 self-start text-justify password-text-tip">
                            <span className="text-[14px] md:text-[16px]">Sua senha deve ter:</span>
                            <div className="flex flex-col mt-[10px] gap-[4px] md:gap-2 self-start ml-2 text-justify password-text-tip">
                              {validations.map((validation) => (
                                <SignUp.Validation
                                  key={validation.id}
                                  id={validation.id}
                                  icon={CheckBoxIcon}
                                  text={validation.text}
                                  validation={validation.validation}
                                />
                              ))}
                            </div>
                          </div>
                        </SignUp.Validations>
                      )}
                    </>
                  }
                />
                <SignIn.Input
                  type={showPassword ? "text" : "password"}
                  name="newPasswordConfirm"
                  placeholder="Repita sua nova senha"
                  autoComplete="off"
                  className="text-[16px] h-[44px] max-md:mt-[14px] gap-0 md:mt-[18px] max-lg:text-[16px] lg:text-lg"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endIcon={
                    <button 
                      className="absolute right-[14px] max-md:top-[26px] top-[30px] flex items-center justify-center h-[20px] w-[20px]"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <FontAwesomeIcon 
                        icon={showPassword ? faEyeSlash : faEye} 
                        className="text-[#8A8A8A] text-[16px]" 
                      />
                    </button>
                  }
                />

                <SignUp.Validations>
                  <div className="flex flex-col mt-4 self-start text-justify password-text-tip">
                    <span className="text-[14px] md:text-[16px]">Sua senha deve ter:</span>
                    <div className="flex flex-col mt-[10px] gap-[4px] md:gap-2 self-start ml-2 text-justify password-text-tip">
                      {validations.map((validation) => (
                        <SignUp.Validation
                          key={validation.id}
                          id={validation.id}
                          icon={CheckBoxIcon}
                          text={validation.text}
                          validation={validation.validation}
                        />
                      ))}
                    </div>
                  </div>
                </SignUp.Validations>
              </>
            )}

            {step == 2 && (
              <div className="flex flex-col max-md:-mt-[100px]">
                <SignIn.Title 
                  text={"Senha redefinida com sucesso!"} 
                  className="text-left md:mt-16 text-[28px] md:text-[28px] 2xl:text-[36px]"
                />
                <SignIn.Subtitle
                  className="text-[16px] leading-[20px] md:text-[18px] 2xl:text-[24px] text-left mt-[16px] md:mt-10 font-normal max-md:pr-[8px]"
                  text={
                    "Faça login e utilize todos os seus benefícios agora mesmo!"
                  }
                />
              </div>
            )}
          </div>
          <div className="flex flex-col w-full">
            <SignIn.Action
              id="checkUser"
              onClick={handleStep}
              text={getButtonText()}
              className={twMerge(
                `self-end mt-10 items-center text-center p-2.5 w-full text-white rounded-full shadow-sm ${
                  !isLoading
                    ? "bg-[linear-gradient(90deg,#E13441_0%,#FAAE2E_100%)]"
                    : "bg-gray-400"
                }`,
                "h-[44px] max-md:text-[16px] p-0 mt-0 md:text-base xl:text-lg"
              )}
              disabled={isLoading}
            />
            {step < 2 && (
              <SignIn.Action
                id="back"
                onClick={backHome}
                text="Voltar"
                className={twMerge(
                  "self-end items-center p-2.5 max-md:mt-[14px] md:mt-4 text-center w-full text-red-400 rounded-full border border-red-400 border-solid",
                  "h-[44px] max-md:text-[16px] p-0 md:text-base xl:text-lg"
                )}
                disabled={isLoading}
              />
            )}
          </div>
        </SignIn.Root>
      </div>
    </div>
  );
}
