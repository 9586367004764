"use client";
import { ClipboardEvent, useEffect, useRef, useState } from "react";
import { Password } from "../Password";
import { SignUp } from ".";
import { SignIn } from "../SignIn";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { validateCode } from "../../../../actions/services/authService";
import { toast } from "react-toastify";
import VerificationInput from "react-verification-input";
import { useHistory } from "react-router-dom";
import {
  registrar,
  validarSmsRegistro,
  createPassword,
  verifyUserMigrated,
  validarSmsMigracao,
} from "../../../../actions/assistamais/api";
import { gravarDados, logout } from "../../../../utils/Session";
import { Box, Checkbox, IconButton, useMediaQuery } from "@mui/material";
import { twMerge } from "tailwind-merge";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { validaCelular, validaEmail, validarCPF } from "../../../../utils/functions";
import { useMessage } from "../../../../contexts/MessageContext";

interface MigrateAccountScreenProps {}

export default function MigrateAccountScreen({}: MigrateAccountScreenProps) {
  const history = useHistory();

  const [showMessage, showError] = useMessage();
  const [sendCodeType, setSendCodeType] = useState<'phone' | 'email'>();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  // const [code, setCode] = useState<string>("");
  const [codeValidated, setCodeValidated] = useState(false);
  const [recoverInfo, setRecoverInfo] = useState("");
  const [validation, setValidation] = useState({
    isLengthValid: false,
    isUpperCaseValid: false,
    isLowerCaseValid: false,
    isNumberValid: false,
    isSpecialCharValid: false,
    isEqualPassword: false,
  });

  
  const [showValidation, setShowValidation] = useState(false);

  const [acceptNotifications, setAcceptNotification] = useState(false);
  const [acceptTermo, setAcceptTermo] = useState(false);

  const [code, setCode] = useState(["", "", "", ""]); // Estado para armazenar os dígitos
  const inputs = useRef<HTMLInputElement[]>([]); // Referência para os inputs

  const [timeLeft, setTimeLeft] = useState(180); // Tempo restante em segundos (3 minutos)
  const [canResend, setCanResend] = useState(false); // Controla se o botão de reenviar está visível

  // Controla o temporizador
  useEffect(() => {
    if (step == 2) {
      if (timeLeft > 0) {
          const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
          return () => clearTimeout(timer);
      } else {
          setCanResend(true); // Exibe o botão de reenviar quando o tempo acabar
      }
    }
  }, [timeLeft, step]);

  // Formata o tempo restante (mm:ss)
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  const isMobile = useMediaQuery('(max-width:1024px)');

  const sizeHeight = {
    xs: useMediaQuery('(max-height:760px)')
  };

  const formValid = (password: string) => {
    const lengthValid = password.length >= 8;
    const upperCaseValid = /[A-Z]/.test(password);
    const lowerCaseValid = /[a-z]/.test(password);
    const numberValid = /\d/.test(password);
    const specialCharValid = /[!@#$%^&*]/.test(password);
    const equalPassword = password.length > 0 && password == confirmPassword;

    const allValidations = {
      isLengthValid: lengthValid,
      isUpperCaseValid: upperCaseValid,
      isLowerCaseValid: lowerCaseValid,
      isNumberValid: numberValid,
      isSpecialCharValid: specialCharValid,
      isEqualPassword: equalPassword,
    }

    setValidation(allValidations);

    const isFormInvalid = [
      lengthValid,
      upperCaseValid,
      lowerCaseValid,
      numberValid,
      specialCharValid,
      equalPassword,
    ].every(Boolean);

    setIsFormValid(isFormInvalid);

    return allValidations
  };

  async function createUser() {

    const validCpf = submitValidation("cpf", cpf);
    const validEmail = submitValidation("email", email);
    const validPhone = submitValidation("phone", phone);

    if (
      validCpf &&
      validEmail &&
      validPhone
    ) {
      setIsLoading(true);
      try {
        let response: any = await registrar({
          cpf: cpf.replace(/\D/g, ""),
          email,
          celular: phone.replace(/\D/g, ""),
        });
        if (response.payload.status == 201) {
          setCanResend(false);
          setTimeLeft(180); // 3 minutos
          setStep(2);
        } else {
          showError(response.payload?.data?.message, 3000)
        }
      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleVerifyUserMigrated(type: 'email' | 'phone' = 'phone') {

    const validCpf = submitValidation("cpf", cpf);

    if (validCpf) {
      try {
        setIsLoading(true);

        let response: any = await verifyUserMigrated({
          cpf: cpf.replace(/\D/g, ""),
          type
        });

        if (response.payload.status == 200) {
          setCanResend(false);
          setTimeLeft(180); // 3 minutos
          setStep(2);

          setEmail(response.payload?.data.contatos.email);
          setPhone(response.payload?.data.contatos.celular);

        } else {
          showError(response.payload?.data?.message, 3000)
        }
      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function resendCode(type: 'email' | 'phone' = 'phone') {
    await handleVerifyUserMigrated(type)
    showMessage('success', "Código reenviado", 3000)
    setCode(["", "", "", ""]);
  }

  async function verifyCode() {

    const fullCode = code.join("");

    if (fullCode && fullCode.length == 4) {
      setIsLoading(true);

      try {

        let response: any = await validarSmsMigracao({
          codigo: fullCode,
          cpf: cpf.replace(/\D/g, ""),
          type: sendCodeType ?? 'phone'
        });
        if (response.payload.status == 200) {
          //esse token e temporario
          gravarDados({ tokens: response?.payload?.data?.tokens });

          setStep(3);
        } else {
          showError(response.payload?.data?.message, 3000)
        }
      } catch (error: any) {
        console.log(error);
        showError(error?.payload?.data?.message, 3000)
      } finally {
        setIsLoading(false);
      }
    }
  }

  // Lida com o evento de colar
  const handlePaste = (e: ClipboardEvent) => {
    e.preventDefault(); // Evita o comportamento padrão

    const pasteData = e.clipboardData.getData("text"); // Obtém os dados colados
    if (!/^\d+$/.test(pasteData)) return; // Permite apenas números

    const newCode = [...code];
    pasteData.split("").slice(0, 4).forEach((char, i) => {
        newCode[i] = char; // Distribui os primeiros 4 caracteres nos campos
        if (inputs.current[i]) {
            inputs.current[i].value = char; // Atualiza visualmente o campo
        }
    });

    setCode(newCode);

    // Foca no próximo campo vazio
    const nextEmptyField = pasteData.length < 4 ? pasteData.length : 3;
    inputs.current[nextEmptyField]?.focus();
};

  async function criarPassword() {
    if (isFormValid) {

      if (!acceptTermo) {
        showError("É obrigatório aceitar o termo de uso", 3000)
        return
      }

      setIsLoading(true);

      try {
        const response: any = await createPassword(password, acceptTermo, acceptNotifications);

        if (response.payload.status == 201) {
          logout();
          setStep(4);
        } else {
          showError(response.payload?.data?.message ?? "Não foi possível criar a senha", 3000)
        }

      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      const validations = formValid(password);

      if (password.length == 0) {
        showError("Digite uma senha", 3000)
      } else if (!validations.isLengthValid) {
        showError("A senha deve ter no mínimo 8 caracteres", 3000)
      } else if (!validations.isUpperCaseValid) {
        showError("A senha deve ter no mínimo 1 letra maiúscula", 3000)
      } else if (!validations.isLowerCaseValid) {
        showError("A senha deve ter no mínimo 1 letra minúscula", 3000)
      } else if (!validations.isNumberValid) {
        showError("A senha deve ter no mínimo 1 número", 3000)
      } else if (!validations.isSpecialCharValid) {
        showError("A senha deve ter no mínimo 1 caractere especial", 3000)
      } else if (!validations.isEqualPassword) {
        showError("As senhas não são iguais", 3000)
      }
    }
  }

  const handleSocialId = (event: any) => {
    let value = event.target.value.replace(/\D/g, "");

    if (value.length >= 11 && value.length <= 14) {
      const masked = value.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );
      setCpf(masked);
    }

    if (value.length < 11) {
      setCpf(value);
    }
  };

  const handleEmail = (event: any) => {
    let value = event.target.value;
    setEmail(value);
  };

  const handlePhone = (event: any) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length >= 11 && value.length <= 14) {
      const masked = value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
      setPhone(masked);
    } else setPhone(value);
  };

  const fieldValidation = (field: string, value: string) => {
    if (!value) return true;
  };

  const submitValidation = (field: string, value: string) => {
    switch (field) {
      case 'cpf':
        if (value.length === 0) {
          showError("Informe o CPF", 3000)
          return false
        } else if (!validarCPF(value)) {
          showError("Informe um CPF válido", 3000)
          return false
        }
        return true;
      case 'email':
        if (value.length === 0) {
          showError("Informe o E-mail", 3000)
          return false
        } else if (!validaEmail(value)) {
          showError("Informe um E-mail válido", 3000)
          return false
        }
        return true;
      case 'phone':
        if (value.length === 0) {
          showError("Informe o Telefone", 3000)
          return false
        } else if (!validaCelular(value)) {
          showError("Informe um Telefone válido", 3000)
          return false
        }
        return true;
      default:
        return false;
    }
  };

  const validations = [
    {
      id: "validation-length",
      text: "8 caracteres",
      validation: validation.isLengthValid,
    },
    {
      id: "validation-upper",
      text: "1 letra maiúscula",
      validation: validation.isUpperCaseValid,
    },
    {
      id: "validation-lower",
      text: "1 letra minúscula",
      validation: validation.isLowerCaseValid,
    },
    {
      id: "validation-number",
      text: "1 número",
      validation: validation.isNumberValid,
    },
    {
      id: "validation-caracter",
      text: "1 caractere especial",
      validation: validation.isSpecialCharValid,
    },
    // { id: "validation-equal", text: "Senhas iguais", validation: validation.isEqualPassword }
  ];

  useEffect(() => {
    formValid(password);
    // savePassword(password);
  }, [password, confirmPassword]);

  

  // Atualiza o valor do código
  const handleChange = (value: string, index: number) => {
      if (!/^\d*$/.test(value)) return; // Permite apenas números

      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Move para o próximo input automaticamente
      if (value && index < inputs.current.length - 1) {
          inputs.current[index + 1].focus();
      }
  };

  // Move para o campo anterior ao apagar
  const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      if (e.key === "Backspace" && !code[index] && index > 0) {
          inputs.current[index - 1].focus();
      }
  };

  const handleStep = async () => {
    if (step == 1) {
      await handleVerifyUserMigrated('phone');
    } else if (step == 2) {
      await verifyCode();
    } else if (step == 3) {
      await criarPassword();
    } else if (step == 5) {
      await resendCode(sendCodeType ?? 'phone')
    } else
      history.replace({
        pathname: "/login",
      });
  };

  const backHome = () => {
    if (step == 1) {
      history.goBack()
    } else {
      if (step == 5) {
        setStep(2)
      } else {
        setStep(step - 1);
        setCode(["", "", "", ""])
        setTimeLeft(0)
      }
    }
  };

  const getButtonText = () => {
    if ([1,2].includes(step)) return "Confirmar";
    else if (step == 3) return "Finalizar Cadastro";
    else if (step == 5) return "Enviar código";
    else return "Acessar";
  };

  return (
    <div className="flex flex-col h-full lg:h-auto lg:mx-auto lg:w-full lg:ml-0 w-full lg:flex-row lg:justify-center  lg:p-[80px]">
      <div className="lg:border border-[#151A25] lg:px-5 py-[40px] lg:my-auto h-full lg:h-full lg:max-h-[860px] w-full text-xl bg-white lg:shadow-lg lg:rounded-[48px] max-lg:max-w-full xl:max-w-[496px] 2xl:max-w-[596px]">
        <SignIn.Root
          className={twMerge(
            "flex flex-col items-center justify-between py-[40px] h-full max-lg:mt-0 max-md:px-[20px] flex-nowrap gap-2",
            sizeHeight.xs && "py-0"
          )}
        >
          
          {isMobile && (
            <img 
              src="/usemais/logo_use_mais.png" 
              alt="Logo"
              className={twMerge(
                " w-[160px] cursor-pointer mb-0 flex",
                sizeHeight.xs && "max-w-[120px]"
              )}
            />
          )}

          <div className="self-start flex flex-col w-full">
            {step == 1 && (
              <>
                <SignIn.Title
                  text={"Recupere sua conta"}
                  className={twMerge(
                    "md:mt-3 max-lg:max-w-full text-[28px] md:text-[32px] lg:text-[2.2rem] ",
                    sizeHeight.xs && "text-[24px]"
                  )}
                />
                <SignUp.Subtitle
                  text={
                    "Precisamos do CPF para localizar a sua antiga conta."
                  }
                  className={twMerge(
                    "text-[18px] md:text-[20px] lg:text-[1.4rem] text-left md:mt-[80px] ",
                    sizeHeight.xs && "text-[16px] mt-[10px]"
                  )}
                />
                <SignIn.Input
                  type="text"
                  name="socialId"
                  value={cpf}
                  maxLength={14}
                  onChange={handleSocialId}
                  disabled={isLoading}
                  placeholder="CPF"
                  validation="Campo obrigatório"
                  showValidation={() => fieldValidation("cpf", cpf)}
                  className="text-[16px] h-[44px] mt-2 gap-0 md:mt-[20px] max-lg:text-[16px] lg:text-[18px] 2xl:[20px]"
                  classNameValidation="flex relative bottom-0 max-md:text-[14px] md:text-[16px] 2xl:text-[18px] lg:mt-[4px]"
                />

                <Box className="w-full flex gap-2 items-center mt-4">
                  <img 
                    src="/usemais/whatsapp-green-icon.png" 
                    alt="whatsapp"
                    className="w-[40px] cursor-pointer"
                    onClick={() => window.open("https://wa.me/5541998842718", "blank")}
                  />
                  <div className="flex flex-col gap-1 mt-1">
                    <span className="underline font-semibold text-cinza text-[14px] leading-[16px]">
                      Precisa de ajuda?
                    </span>
                    <span className="text-cinza text-[14px] leading-[16px]">
                      Fale pelo WhatsApp ou ligue para 41 99884-2718
                    </span>
                  </div>
                </Box>
                {/* <SignIn.Input
                  type="text"
                  name="email"
                  value={email}
                  onChange={handleEmail}
                  disabled={isLoading}
                  placeholder="E-mail"
                  validation="Campo obrigatório"
                  showValidation={() => fieldValidation("email", email)}
                  className="text-[16px] h-[44px] max-md:mt-[4px] gap-0 md:mt-[4px] max-lg:text-[16px] lg:text-[18px] 2xl:[20px]"
                  classNameValidation="flex relative bottom-0 max-md:text-[14px] md:text-[16px] 2xl:text-[18px] lg:mt-[4px]"
                />
                <SignIn.Input
                  type="text"
                  name="socialId"
                  value={phone}
                  maxLength={14}
                  onChange={handlePhone}
                  disabled={isLoading}
                  placeholder="Telefone"
                  validation="Campo obrigatório"
                  showValidation={() => fieldValidation("phone", phone)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      createUser;
                    }
                  }}
                  className="text-[16px] h-[44px] max-md:mt-[4px] gap-0 md:mt-[4px] max-lg:text-[16px] lg:text-[16px] 2xl:text-[18px]"
                  classNameValidation="flex relative bottom-0 max-md:text-[14px] max-md:text-[14px] md:text-[16px] 2xl:text-[18px] lg:mt-[4px]"
                />
                <p className="text-neutral-400 text-justify mt-[4px] leading-[18px] text-[14px] md:text-[16px] md:leading-[20px] 2xl:text-[18px] 2xl:leading-[22px] ">
                  Enviaremos um SMS com código para validar seu celular.
                </p> */}
              </>
            )}

            {step == 2 && (
              <>
                <SignIn.Title
                  text={"Valide sua conta"}
                  className={twMerge(
                    "md:mt-3 max-md:max-w-full text-[28px] md:text-[32px] lg:text-[2.2rem] ",
                    sizeHeight.xs && "text-[24px]"
                  )}
                />
                <SignUp.Subtitle
                  text={
                    `Enviamos um código para recuperar a sua conta no contato 
                    ${!sendCodeType || sendCodeType == 'phone' ? phone : email}, digite abaixo o código recebido:`
                  }
                  className={twMerge(
                    "text-[18px] md:text-[20px] lg:text-[1.4rem] text-left",
                    sizeHeight.xs && "text-[16px] mt-[10px]"
                  )}
                />

                <div className="grid grid-cols-4 gap-4 mt-[20px]">
                  {code.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleBackspace(e, index)}
                        onPaste={index === 0 ? handlePaste : undefined} // Habilita colar apenas no primeiro campo
                        ref={(el) => (inputs.current[index] = el!)} // Adiciona a referência do input
                        className={twMerge(
                          "w-full h-[100px] max-w-[80px] mx-auto text-center border border-neutral-400 rounded-lg text-[32px] font-semibold focus:outline-none focus:ring-2 text-red-400 focus:ring-red-400 ",
                          sizeHeight.xs && "h-[80px]"
                        )}
                    />
                  ))}
                </div>
                <div className={twMerge(
                  "mt-10 shadow-md rounded-md py-5 px-3",
                  sizeHeight.xs && "mt-[20px]"
                )}>
                  <div className="flex items-center justify-between gap-[20px]">
                    {timeLeft > 0 ? (
                      <>
                        <div className=" text-neutral-500">
                          <p className="text-[16px] xl:text-[18px] leading-[20px] text-neutral-500">
                            Tempo de validade do código
                          </p>
                        </div>
                        <div className="text-right min-w-[80px]">
                          <p className="text-neutral-500 font-bold text-[24px] xl:text-[28px]">
                            {formatTime()}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=" text-neutral-500">
                          <p className="text-[16px] xl:text-[18px] leading-[20px] text-neutral-500">
                            Código expirado
                          </p>
                        </div>
                        <div className="text-right">
                          <span 
                            className="text-blue-800 hover:text-blue-600 underline underline-offset-[4px] text-[16px] xl:text-[18px] cursor-pointer"
                            onClick={() => resendCode(sendCodeType ?? 'phone')}
                          >
                            Reenviar código
                          </span>
                        </div>
                      </>
                    )}
                    
                  </div>
                </div>
                <div className="mt-[20px]">
                  <span 
                    className="underline text-red-400 hover:text-red-500 cursor-pointer"
                    onClick={() => setStep(5)}
                  >
                    Tente de outra forma
                  </span>
                </div>
              </>
            )}

            {step == 5 && (
              <>
                <SignIn.Title
                  text={"Valide sua conta"}
                  className={twMerge(
                    "md:mt-3 max-md:max-w-full text-[28px] md:text-[32px] lg:text-[2.2rem] ",
                    sizeHeight.xs && "text-[24px]"
                  )}
                />
                <SignUp.Subtitle
                  text={
                    `Vamos enviar um código para recuperar a sua conta.${'\n'}
                    Escolha como receber o código:`
                  }
                  className={twMerge(
                    "text-[18px] md:text-[20px] lg:text-[1.4rem] text-left",
                    sizeHeight.xs && "text-[16px] mt-[10px]"
                  )}
                />

                <Box className="flex flex-col gap-2 mt-[20px]">
                  <button 
                    className={twMerge(
                      "flex flex-col w-full hover:bg-slate-100 rounded-md",
                      sendCodeType == 'phone' && "bg-slate-100"
                    )}
                    
                    onClick={() => setSendCodeType('phone')}
                  >
                    <span className="text-azul-padrao font-medium">
                      {phone}
                    </span>
                    <span className="text-cinza text-[14px]">
                      Verificação por SMS
                    </span>
                  </button>
                  <button 
                    className={twMerge(
                      "flex flex-col w-full hover:bg-slate-100 rounded-md",
                      sendCodeType == 'email' && "bg-slate-100"
                    )}
                    onClick={() => setSendCodeType('email')}
                  >
                    <span className="text-azul-padrao font-medium">
                      {email}
                    </span>
                    <span className="text-cinza text-[14px]">
                      Verificação por e-mail
                    </span>
                  </button>
                </Box>
                
                <Box className="w-full flex gap-2 items-center mt-4">
                  <img 
                    src="/usemais/whatsapp-green-icon.png" 
                    alt="whatsapp"
                    className="w-[40px] cursor-pointer"
                    onClick={() => window.open("https://wa.me/5541998842718", "blank")}
                  />
                  <div className="flex flex-col gap-1 mt-1">
                    <span className="underline font-semibold text-cinza text-[14px] leading-[16px]">
                      Precisa de ajuda?
                    </span>
                    <span className="text-cinza text-[14px] leading-[16px]">
                      Fale pelo WhatsApp ou ligue para 41 99884-2718
                    </span>
                  </div>
                </Box>
                
              </>
            )}

            {step == 3 && (
              <>
                <SignUp.Title 
                  text={"Crie sua senha"}
                  className={twMerge(
                    "md:mt-3 max-md:mt-0 max-md:max-w-full text-[28px] md:text-[32px] lg:text-[2.2rem] ",
                    sizeHeight.xs && "text-[24px]"
                  )}
                />
                <SignUp.Subtitle
                  text={"Crie sua senha para acessar a Use Mais."}
                  className="text-[14px] md:text-[20px] lg:text-[1.4rem] text-left max-md:mt-0"
                />
                <SignIn.Input
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  placeholder="Digite sua nova Senha"
                  autoComplete="off"
                  className="text-[16px] h-[44px] max-md:mt-[40px] gap-0 md:mt-[28px] max-lg:text-[16px] lg:text-[18px] 2xl:[20px]"
                  onChange={(e) => setPassword(e.target.value)}
                  endIcon={
                    <button 
                      className="absolute right-[14px] max-md:top-[52px] top-[40px] flex items-center justify-center h-[20px] w-[20px]"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <FontAwesomeIcon 
                        icon={showPassword ? faEyeSlash : faEye} 
                        className="text-[#8A8A8A] text-[16px]" 
                      />
                    </button>
                  }
                  onFocusCapture={(e) => {
                    setShowValidation(true)
                  }}
                  onBlurCapture={(e) => {
                    setShowValidation(false)
                  }}
                  validationComponent={
                    <>
                      {showValidation && (
                        <SignUp.Validations
                          className="absolute z-10 bg-white shadow-lg p-[10px] min-w-[240px]"
                        >
                          <div className="flex flex-col mt-4 self-start text-justify password-text-tip">
                            <span className="text-[14px] md:text-[16px]">Sua senha deve ter:</span>
                            <div className="flex flex-col mt-[10px] gap-[4px] md:gap-2 self-start ml-2 text-justify password-text-tip">
                              {validations.map((validation) => (
                                <SignUp.Validation
                                  key={validation.id}
                                  id={validation.id}
                                  icon={CheckBoxIcon}
                                  text={validation.text}
                                  validation={validation.validation}
                                />
                              ))}
                            </div>
                          </div>
                        </SignUp.Validations>
                      )}
                    </>
                  }
                />
                <SignIn.Input
                  type={showPassword ? "text" : "password"}
                  name="newPasswordConfirm"
                  placeholder="Repita sua nova Senha"
                  autoComplete="off"
                  className="text-[16px] h-[44px] max-md:mt-[14px] gap-0 md:mt-[18px] max-lg:text-[16px] lg:text-[18px] 2xl:[20px]"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endIcon={
                    <button 
                      className="absolute right-[14px] max-md:top-[28px] top-[32px] flex items-center justify-center h-[20px] w-[20px]"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <FontAwesomeIcon 
                        icon={showPassword ? faEyeSlash : faEye} 
                        className="text-[#8A8A8A] text-[16px]" 
                      />
                    </button>
                  }
                />

              </>
            )}

            {step == 4 && (
              <>
                <SignUp.Title 
                  text={"Sua conta foi migrada com sucesso!"} 
                  className={twMerge(
                    "md:mt-3 max-md:max-w-full text-[28px] md:text-[32px] md:leading-[34px] lg:text-[2.2rem] lg:leading-[38px]",
                    sizeHeight.xs && "text-[24px]"
                  )}
                />
                <SignUp.Subtitle
                  text={
                    "Seu saldo de pontos e dados de cadastro já estão disponíveis."
                  }
                  className="text-[18px] leading-[20px] md:text-[20px] md:leading-[22px] lg:text-[1.4rem] lg:leading-[26px] text-left lg:mt-[40px] max-md:mt-[10px]"
                />
                <SignUp.Subtitle
                  text={
                    "Agora você pode utilizar todos os benefícios e vantagens que a Use Mais oferece!"
                  }
                  className="text-[18px] leading-[20px] md:text-[20px] md:leading-[22px] lg:text-[1.4rem] lg:leading-[26px] text-left lg:mt-[40px] max-md:mt-[10px]"
                />
              </>
            )}
          </div>

          <div className="self-end flex flex-col w-full">

            {step == 3 && (
              <div className="flex flex-col gap-[16px]">
                <div className="flex items-center gap-[8px]">
                  <IconButton 
                    onClick={() => setAcceptNotification(!acceptNotifications)}
                    style={{
                      padding: 0
                    }}
                  >
                    {acceptNotifications ? (
                      <CheckBox 
                        color="error"  
                        sx={{
                          fontSize: '20px'
                        }}
                      />
                    ) : (
                      <CheckBoxOutlineBlank 
                        color="error"
                        sx={{
                          fontSize: '20px'
                        }}
                      />
                    )}
                  </IconButton>
                  <span className="text-[#8A8A8A] text-[12px] sm:text-[14px] xl:text-[16px] leading-[16px] xl:leading-[18px]">
                    Quero receber notificações de ofertas da Use Mais Store e parceiros.
                  </span>
                </div>
                <div className="flex items-center gap-[8px]">
                  <IconButton 
                    onClick={() => setAcceptTermo(!acceptTermo)}
                    style={{
                      padding: 0
                    }}
                  >
                    {acceptTermo ? (
                      <CheckBox 
                        color="error"  
                        sx={{
                          fontSize: '20px'
                        }}
                      />
                    ) : (
                      <CheckBoxOutlineBlank 
                        color="error"
                        sx={{
                          fontSize: '20px'
                        }}
                      />
                    )}
                  </IconButton>
                  <span className="text-[#8A8A8A] text-[12px] sm:text-[14px] xl:text-[16px] leading-[16px] xl:leading-[18px]">
                    Declaro que li e concordo com os <a href="/termos/termo_de_uso.pdf" target="_blank" className="underline text-blue-800">Termos de Uso</a>. 
                  </span>
                </div>
              </div>
            )}

            <SignIn.Action
              id="checkUser"
              onClick={handleStep}
              text={getButtonText()}
              className={twMerge(
                `self-end mt-10 items-center text-center p-2.5 w-full text-white rounded-full shadow-sm ${
                  !isLoading
                    ? "bg-[linear-gradient(90deg,#E13441_0%,#FAAE2E_100%)]"
                    : "bg-gray-400"
                }`,
                "h-[44px] max-lg:text-[16px] p-0 lg:text-[18px] 2xl:text-[18px]",
                (sizeHeight.xs || step == 3) && "mt-[10px]",
              )}
              disabled={isLoading}
            />
            {(step < 4 || step == 5) && (
              <SignIn.Action
                id="back"
                onClick={backHome}
                text="Voltar"
                className={twMerge(
                  "self-end items-center p-2.5 mt-4 text-center w-full text-red-400 rounded-full border border-red-400 border-solid",
                  "h-[44px] max-lg:text-[16px] p-0 lg:text-[18px] 2xl:text-[18px]"
                )}
                disabled={isLoading}
              />
            )}
          </div>
        </SignIn.Root>
      </div>
    </div>
  );
}
