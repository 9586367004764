import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getFavoritos, obterClienteResumo, obterExtrato, obterProdutos, obterFiltros, obterGiftsResgatados, apagarUsuario, consultarPlanosContratados } from '../../actions/assistamais/api';
import CardPopup from '../../componentes/molecules/CardPopup';
import FiltroVoucher from '../../componentes/molecules/FiltroVoucher';
import ContaPerfil from '../../componentes/organisms/ContaPerfil';
import ContaSaldo from '../../componentes/organisms/ContaSaldo';
import ExtratoDePontos from '../../componentes/organisms/ExtratoDePontos';
import ListaResgatePontosHorizontal from '../../componentes/organisms/ListaResgatePontosHorizontal';
import Menu from '../../componentes/organisms/Menu';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import './style.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import BotaoBordaArredondado from '../../componentes/atoms/BotaoBordaArredondado';
import LabelBordaArredondado from '../../componentes/atoms/LabelBordaArredondado';
import ListaFilmesHorizontalFavoritos from '../../componentes/organisms/ListaFilmesHorizontalFavoritos';
import { SessionContext } from '../../contexts/SessionContext';
import { ContaPerfilUsemais } from '../../componentes/organisms/ContaPerfilUsemais';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { ContaSaldoUsemais } from '../../componentes/organisms/ContaSaldoUsemais';
import BotaoArredondadoUsemais from '../../componentes/atoms/BotaoArredondadoUsemais';
import { ListaGiftsResgatadosHorizontalUsemais } from '../../componentes/organisms/ListaGiftsResgatadosHorizontalUsemais';
import { ListaPlanosContratadosHorizontalUsemais } from '../../componentes/organisms/ListaPlanosContratadosHorizontalUsemais';
import { RodapeUsemais } from '../../componentes/organisms/RodapeUsemais';
import { twMerge } from 'tailwind-merge';

import bgPerfilFundo from '../../assets/img/usemais/bg-perfil-fundo-mobile.png';
import { logout } from '../../utils/Session';
import { toast } from 'react-toastify';
import { useMessage } from '../../contexts/MessageContext';


export default function ContaUsuarioUsemais() {
    const [favoritos, setFavoritos] = useState([]);
    const [perfil, setPerfil] = useState([]);
    const [filtros, setFiltros] = useState({categorias: [], pontos: []});
    const [produtos, setProdutos] = useState([]);
    const [giftsResgatados, setGiftsResgatados] = useState([]);
    const [planosContratados, setPlanosContratados] = useState([]);
    const [extrato, setExtrato] = useState();
    const [filter, setFilter] = useState('');
    const [totalResgatado, setTotalResgatado] = useState(0);

    const extratoDePontosRef = useRef();
    const cardPopup = useRef(null);

    const isMobile = useMediaQuery('(max-width:768px)');

    const [showConfirm, setShowConfirm] = useState(false);
    const [showRequestSend, setShowRequestSend] = useState(false);

    let history = useHistory();

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    const [ showMessage, showError ] = useMessage();

    useEffect(() => {
        getSessionData();
    },[]);

    useEffect(() => {
        async function fetchData() {

            //            const { genres } = (await getGenresMovieList()).payload.data;

            getFavoritos().then(result => {
                // TODO criar função para usar em todos

                if (!result?.payload?.status || Math.trunc(result?.payload?.status / 100) !== 2) return;


                let { data } = result?.payload;

                setFavoritos(data.map(f => {
                    let pathname = '/filme';
                    if (f.tipo.toLowerCase() === 'tv') {
                        pathname = 'serie';
                    }
                    const linkDetalhe = `${pathname}/?id=${f.tituloId}&type=${f.tipo}`;
                    const { genres, backdrop_path, poster_path, title, overview } = f.data

                    return {
                        ...f,
                        genres,
                        backdrop_path,
                        poster_path,
                        title,
                        overview,
                        linkDetalhe: linkDetalhe
                    }
                }).reverse());

            });

            obterClienteResumo().then(result => {
                setPerfil(result?.payload?.data);
            })

            consultarPlanosContratados(true).then(result => { 
                if (result?.payload?.data?.contratacoes) {
                    const contratacoes = result?.payload?.data?.contratacoes;
                    setPlanosContratados(contratacoes)
                }
            });

            obterGiftsResgatados().then(result => { 
                if (result?.payload?.data?.resgates) {
                    setGiftsResgatados(result?.payload?.data?.resgates);

                    const totalResgatadoCount = result?.payload?.data?.resgates.reduce((acc, resgates) => {
                        return acc += Number(resgates.objectData.bonificacao ?? 0);
                    }, 0);
                    setTotalResgatado(totalResgatadoCount ?? 0);
                }
            })

            obterProdutos().then(result => {
                setProdutos(result?.payload?.data?.results);
            });

            obterFiltros().then(result => {
                setFiltros({
                    pontos: result?.payload?.data.pontos, 
                    categorias: result?.payload?.data.categorias
                });
            });           

            obterExtrato().then(result => {
                setExtrato(result?.payload?.data);
            });
        }
        fetchData()
    }, []);

    const handleExtratoPontoClick = () => {
        extratoDePontosRef.current.open();
    }

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        let x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        let y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;

        if (x < 0) x = 0;
        if (y < 0) y = 0;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleClickFavoritos = () => {

        history.push({
            pathname: '/favoritos/'
        }, {
            some: new Date()
        });

    }

    const atualizaVoucher = async (c, p) => {
        let prod = await obterProdutos(c, p);
        setProdutos(prod.payload.data.results);
    }

    const handleChange = (event) => {
        event.persist()
        setFilter(event.target.value);
    }

    const filtroGlobal = item =>
        Object.keys(item).some(
            (key) => { 
                const text = item[key] + '';
                return text
                    .toLowerCase()
                    .indexOf(filter.toLowerCase()) !== -1
            },
        );

    const handleCancelarConta = async () => {
        setShowConfirm(true)
    }

    const cancelarConta = async () => {
        try {
            const retorno = await apagarUsuario();

            if (retorno?.payload?.status !== 201) {
                throw new Error(retorno?.payload?.data?.message ?? "Não foi possível realizar o cancelamento da conta.")
            }

            setShowConfirm(false)
            setShowRequestSend(true)
            
        } catch (e) {
            showError(e.message)
        }
    }

    const handleSairClick = () => {
        // googleLogout()
        logout();
        window.location.href = '/';
    }

    return (
        <>
            <div className="w-full md:w-10/12 mx-auto md:my-20 md:mt-32 lg:mt-60">
                
                <div className={twMerge(
                    "gap-10",
                    "md:grid md:grid-cols-8 md:grid-rows-2"
                )}>
                    <div
                        style={{
                            // backgroundImage: `url(${bgPerfilFundo})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPositionX: 'center',
                        }}
                        className='w-full h-[226px] flex md:hidden'
                    >
                    </div>

                    <div className='-mt-[80px] md:mt-0 md:row-span-2 md:col-span-3 flex flex-col gap-4 md:gap-8 w-full md:max-w-[440px]'>
                        
                        <ContaPerfilUsemais perfil={perfil} />

                        <div className='w-full flex justify-center md:justify-start px-8 md:px-0'>
                            <Divider 
                                sx={{ bgcolor: "#ED636E" }}
                                className='w-full'
                            />
                        </div>
                        
                        <ContaSaldoUsemais 
                            pontos={perfil?.pontos}
                            extratoPontoClick={handleExtratoPontoClick} 
                            cancelarConta={handleCancelarConta}
                            totalResgatado={totalResgatado}
                        />

                        <ExtratoDePontos ref={extratoDePontosRef} saldo={perfil?.pontos} pontos={extrato} />
                    </div>

                    <div className='px-4 md:col-span-5'>
                        <ListaGiftsResgatadosHorizontalUsemais 
                            resgates={giftsResgatados} 
                            pontos={perfil?.pontos}
                        />
                    </div>

                    <div className='px-4 md:col-span-5'>
                        <ListaPlanosContratadosHorizontalUsemais 
                            planosContratados={planosContratados}
                        />
                    </div>

                    {/* <div className='col-span-5'>
                        <ListaPlanosContratadosHorizontalUsemais 
                            planos={[
                                { id: 1, imagem: "", descricao: "Plano Faça Jus"},
                                { id: 2, imagem: "", descricao: "Plano Bem Mais"},
                            ]} 
                        />
                    </div> */}

                    {isMobile && (
                        <div className='w-full flex justify-center mt-8'>
                            <BotaoArredondadoUsemais 
                                className="w-4/12 md:w-[214px] h-[32px] md:h-[40px] bg-[#151A25] hover:bg-[#151A25] hover:bg-opacity-90" 
                                value={"Sair"} 
                                classNameText="text-[14px] md:text-sm font-semibold text-white" 
                                onClick={handleSairClick} 
                            />
                        </div>
                    )}
                </div>

            </div>

            {showConfirm &&
                <Modal
                    open={showConfirm}
                    onClose={() => setShowConfirm(false)}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] px-[20px] md:px-[60px] py-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold text-center'>
                                Deseja cancelar sua conta? {":("}
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium text-center'>
                                Tem certeza que deseja solicitar o cancelamento da sua conta Use Mais?
                            </span>
                        </div>
                        <div className='w-full flex gap-[10px]'>
                            <button 
                                onClick={() => {
                                    setShowConfirm(false)
                                }}
                                className='
                                    w-full bg-white border border-[#ED636E] 
                                    text-[#ED636E] text-[16px] h-[40px] rounded-[8px]
                                    hover:font-medium
                                '
                            >
                                <span>Voltar</span>
                            </button>
                            <button 
                                onClick={cancelarConta} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] h-[40px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Sim</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }

            {showRequestSend &&
                <Modal
                    open={showRequestSend}
                    onClose={() => {
                        setShowRequestSend(false)
                        history.replace('/minha-conta')
                    }}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] px-[20px] md:px-[60px] py-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold text-center'>
                                Solicitação de cancelamento enviada
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium text-center'>
                                Sua solicitação de cancelamento da conta foi enviada para aprovação Use Mais
                            </span>
                        </div>
                        <div className='w-full flex'>
                            <button 
                                onClick={() => {
                                    setShowRequestSend(false)
                                    history.replace('/minha-conta')
                                }} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] h-[40px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>OK</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }

            <RodapeUsemais 
                className="md:pb-8 mt-[32px] md:mt-0"
            />
        </>
    );
}



