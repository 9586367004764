
import { useContext } from "react";

import { useForm } from "../../../hooks/useForm";
import BotaoSomenteTexto from "../../atoms/BotaoSomenteTexto";
import Input from "../../atoms/Input";
import InputSelect from "../../atoms/InputSelect";
import { preCadastro } from "../../../actions/assistamais/api";

import { MessageContext } from "../../../contexts/MessageContext";

export default function DadosPessoais({ disabled, continuarClick, onBlockedClick, preCadastrar, cpf = '' }) {

    const {        
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
        updateData,
    } = useForm({ // the hook we are going to create
        validations: { // all our validation rules go here
            nome: {
                required: {
                    value: true,
                    message: 'Preencha o nome completo',
                },
                custom: {
                    isValid: (value) => {
                        const newName = value.split(' ');

                        if(newName.length >= 2){
                            return newName[1].length > 0 && newName[0].length > 0
                        } else {
                            return false
                        }
                    },
                    message: 'Preencha o nome completo',
                },
            },
            cpf: {
                required: {
                    value: true,
                    message: 'Preencha o CPF',
                },
                custom: {
                    isValid: (value) => {
                        return testaCPF(value)
                    },
                    message: 'Preencha com um CPF válido',
                },
            },
            genero: {
                required: {
                    value: false,
                    message: 'Preencha o gênero',
                },
            },
            nascimento: {
                required: {
                    value: false,
                    message: 'Preencha a data de nascimento',
                },
            },
        },
        onSubmit: () => {
            continuarClick(data)
        },
        initialValues: { // used to initialize the data
            nome: '',
            // sobrenome: '',
        },
    });

    const [ showError ] = useContext(MessageContext);

    const handleBlurCpf = (e) => {
        const cpf = e.target.value;

        preCadastro(cpf).then(async result => {
            if (result?.payload?.status === 200) {
                const { nome = '', email = '', genero = '', nascimento = '', telefone = '', cep = '', logradouro: endereco = '', bairro = '', cidade = '', uf = '' } = result?.payload?.data;                
                const body = { nome, email, genero, nascimento, telefone, cep, endereco, bairro,cidade,uf };
                if(nome !== '' && data['nome'] === ''){
                    updateData(body)
                    preCadastrar(body)
                    localStorage.setItem('preCadastro', JSON.stringify(body));
                } else if ( nome === '' ){
                    localStorage.setItem('preCadastro', JSON.stringify(body));
                }

            } else {
                showError(result?.payload?.data.message)
            }

        });
    }

    const testaCPF = (cpf) => {
        
        if (cpf == undefined)
            return false;	
        if(cpf == '') return false;	
        cpf = cpf.replace(/[^\d]+/g,'');
        // Elimina CPFs invalidos conhecidos	
        if (cpf.length != 11 || 
            cpf == "00000000000" || 
            cpf == "11111111111" || 
            cpf == "22222222222" || 
            cpf == "33333333333" || 
            cpf == "44444444444" || 
            cpf == "55555555555" || 
            cpf == "66666666666" || 
            cpf == "77777777777" || 
            cpf == "88888888888" || 
            cpf == "99999999999")
            return false;		
            // Valida 1o digito	
            let add = 0;	
            for (let i=0; i < 9; i ++)		
            add += parseInt(cpf.charAt(i)) * (10 - i);	
            let rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)		
            rev = 0;	
            if (rev != parseInt(cpf.charAt(9)))		
            return false;		
            // Valida 2o digito	
            add = 0;	
            for (let i = 0; i < 10; i ++)		
            add += parseInt(cpf.charAt(i)) * (11 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)	
            rev = 0;	
            if (rev != parseInt(cpf.charAt(10)))
            return false;		
            return true;  
            
            





            
    }

    return (

        <div className="dados-pessoais">
            {disabled && <div className="camuflar" onClick={onBlockedClick} />}
            <div className="titulo-bloco">Dados pessoais</div>
            <form onSubmit={handleSubmit}>
                <div className="fazer-cadastro-inputs">
                <div className="row">
                        <Input type="text" className="w100" maxLength="11" label="Digite seu CPF sem traços ou pontos *" value={data['cpf']} onBlur={handleBlurCpf} onChange={handleChange('cpf')} error={errors} name="cpf" />
                    </div>
                    <div className="row">
                        <Input type="text" className="w100" label="Nome completo *" value={data['nome']} onChange={handleChange('nome')} error={errors} name="nome" />
                    </div>
                   
                    <div className="row">

                        <Input type="date" className="w50" label="Data de nascimento" value={data['nascimento']} onChange={handleChange('nascimento')} error={errors} name="nascimento" />
                        <InputSelect type="select" className="w50" placeholder="" label="Selecione seu gênero" value={data['genero']} onChange={handleChange('genero')} error={errors} name="genero" >
                            <option></option>
                            <option value="M">Masculino</option>
                            <option value="F">Feminino</option>
                            <option value="NA">Prefiro não Informar</option>
                        </InputSelect>
                    </div>
                    <BotaoSomenteTexto className="botao" value="Continuar" type="submit" />
                </div>
            </form>
        </div>
    )
};

