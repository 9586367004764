import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getDetalhesPlanoContratado } from '../../actions/assistamais/api';
import CardPopup from '../../componentes/molecules/CardPopup';
import FiltroVoucher from '../../componentes/molecules/FiltroVoucher';
import ContaPerfil from '../../componentes/organisms/ContaPerfil';
import ContaSaldo from '../../componentes/organisms/ContaSaldo';
import ExtratoDePontos from '../../componentes/organisms/ExtratoDePontos';
import ListaResgatePontosHorizontal from '../../componentes/organisms/ListaResgatePontosHorizontal';
import Menu from '../../componentes/organisms/Menu';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import './style.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import BotaoBordaArredondado from '../../componentes/atoms/BotaoBordaArredondado';
import LabelBordaArredondado from '../../componentes/atoms/LabelBordaArredondado';
import ListaFilmesHorizontalFavoritos from '../../componentes/organisms/ListaFilmesHorizontalFavoritos';
import { SessionContext } from '../../contexts/SessionContext';
import { ContaPerfilUsemais } from '../../componentes/organisms/ContaPerfilUsemais';
import { Autocomplete, Box, Card, Checkbox, Divider, Modal, useMediaQuery } from '@mui/material';
import { ContaSaldoUsemais } from '../../componentes/organisms/ContaSaldoUsemais';
import BotaoArredondadoUsemais from '../../componentes/atoms/BotaoArredondadoUsemais';
import { ListaGiftsResgatadosHorizontalUsemais } from '../../componentes/organisms/ListaGiftsResgatadosHorizontalUsemais';
import { ListaPlanosContratadosHorizontalUsemais } from '../../componentes/organisms/ListaPlanosContratadosHorizontalUsemais';
import { RodapeUsemais } from '../../componentes/organisms/RodapeUsemais';
import { twMerge } from 'tailwind-merge';

import bgPerfilFundo from '../../assets/img/usemais/bg-perfil-fundo-mobile.png';
import { logout } from '../../utils/Session';
import { toast } from 'react-toastify';
import { useMessage } from '../../contexts/MessageContext';
import Input from '../../componentes/atoms/InputUsemais';
import { Select } from '../../componentes/atoms/Select';
import { useQuery } from '../../utils';
import { formatCurrencyBRL, shuffleArray, validaEmail } from '../../utils/functions';
import InputPassword from '../../componentes/atoms/InputPassword';
import { useLoader } from '../../hooks/useLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { SelectSearch } from '../../componentes/atoms/SelectSearch';
import { nacionalidadesMock } from '../../utils/nacionalidadesMock';
import { getAuthenticatedUser } from '../../actions/services/userService';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/pt-br'


export default function DetalhesDoPlano({ location }) {
    const [favoritos, setFavoritos] = useState([]);
    const [perfil, setPerfil] = useState([]);
    const [filtros, setFiltros] = useState({categorias: [], pontos: []});
    const [produtos, setProdutos] = useState([]);
    const [giftsResgatados, setGiftsResgatados] = useState([]);
    const [extrato, setExtrato] = useState();
    const [filter, setFilter] = useState('');
    const [totalResgatado, setTotalResgatado] = useState(0);

    const extratoDePontosRef = useRef();
    const cardPopup = useRef(null);

    const isMobile = useMediaQuery('(max-width:768px)');

    const [showConfirm, setShowConfirm] = useState(false);
    const [showRequestSend, setShowRequestSend] = useState(false);
    
    const [dadosCliente, setDadosCliente] = useState(null);
    
    const [step, setStep] = useState(1);
    const [plano, setPlano] = useState(null);
    const [contratacao, setContratacao] = useState(null);

    const [profissoesList, setProfissoesList] = useState([]);
    const [profissao, setProfissao] = useState(null);

    const [nacionalidade, setNacionalidade] = useState(null);

    const teclado = shuffleArray([0,1,2,3,4,5,6,7,8,9]);
    
    const loader = useLoader();
    
    const [margemIndisponivel, setMargemIndisponivel] = useState(false);
    const [margemDisponível, setMargemDisponivel] = useState(false);

    const [formatoPagamento, setFormatoPagamento] = useState(0);
    
    const [showSuccess, setShowSuccess] = useState(false);
    const [showCancelamento, setShowCancelamento] = useState(false);
    const [showPendente, setShowPendente] = useState(false);

    const [conveniosList, setConveniosList] = useState([])
    const [matriculasList, setMatriculasList] = useState([])

    const [dadosConvenios, setDadosConvenios] = useState(null);

    const [convenio, setConvenio] = useState(null);
    const [matricula, setMatricula] = useState(null);

    let history = useHistory();

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        fetchData()
    }, []);

    async function fetchData() { 
        const query = useQuery(location.search);

        const params = {
            id: query.get('id'),
        }

        if (params.id) {
            try {
                const planoContratado = await getDetalhesPlanoContratado(params.id); // ID da Contratação

                if (planoContratado.payload?.status != 200 || !planoContratado.payload?.data?.contratacao) {
                    throw new Error("Plano não localizado!")
                }

                setContratacao(planoContratado.payload?.data?.contratacao);
                setPlano(planoContratado.payload?.data?.contratacao.plano);
            } catch (err) {
                console.error(err?.message ?? "Plano não localizado!")
                // history.replace('/');
            }
        } else {
            history.replace('/')
        }
    }


    const getUserData = async () => {
        const userDataResponse = await getAuthenticatedUser();
        setUserData(userDataResponse)
    }

    const getFormaPagamento = (formaPagamentoId) => {
        switch(formaPagamentoId) {
            case '1':
                return 'Consignado'
            case '2':
                return 'Cartão de crédito'
            default:
                return ''
        }
    }

    const getStatusContratacao = (status) => {
        switch(status) {
            case 'ATIVA':
                return 'Ativo'
            case 'PENDENTE':
                return 'Pendente'
            default:
                return ''
        }
    }

    return (
        <>
            <div className="w-full md:w-10/12 mx-auto md:my-20 md:mt-32 lg:mt-40">
                {contratacao && (
                    <Box className="w-full text-azul-padrao flex flex-col gap-8">
                        <div className='w-full flex justify-between items-center '>
                            <h2 className='font-bold text-[32px]'>
                                Assinante desde {moment(contratacao.createdAt).locale('pr-br').format('MMMM')} de {moment(contratacao.createdAt).format('YYYY')}
                            </h2>
                            <div className='flex flex-col items-center gap-[4px]'>
                                <button 
                                    className='bg-[#151A25] rounded-full w-[50px] h-[50px] hover:opacity-80'
                                    onClick={() => history.goBack()}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} className='text-white' />
                                </button>
                                <span className='text-[14px] text-[#151A25] font-semibold'>
                                    Voltar
                                </span>
                            </div>
                        </div>
                        <div className='w-full rounded-[32px] flex flex-col gap-2 bg-slate-50 p-8'>
                            <h5 className='text-[18px] font-bold'>
                                {plano.nome}
                            </h5>
                            <div className='flex gap-1'>
                                <span className='font-semibold'>
                                    Data da contratação:
                                </span>
                                <span>{moment(contratacao.createdAt).format('DD/MM/YYYY')}</span>
                            </div>
                            <div className='flex gap-1'>
                                <span className='font-semibold'>
                                    Forma de pagamento:
                                </span>
                                <span>{getFormaPagamento(contratacao.formaPagamento)}</span>
                            </div>
                            <div className='flex gap-2 items-center'>
                                <div className={twMerge(
                                    "w-[14px] h-[14px] rounded-full",
                                    contratacao.status == 'ATIVA' && "bg-[#48C579]",
                                    contratacao.status == 'PENDENTE' && "bg-[#F74C2F]",
                                )}>
                                </div>
                                <span className='font-semibold'>
                                    Plano {getStatusContratacao(contratacao.status)}
                                </span>
                            </div>
                        </div>
                    </Box>
                )}

                {plano && (
                    <Box className="w-full text-azul-padrao flex flex-col gap-8 mt-10 md:mt-20">
                        <div className='w-full flex justify-between items-center '>
                            <h2 className='font-bold text-[32px]'>
                                Meus benefícios
                            </h2>
                        </div>
                        <div className='w-full grid grid-cols-3 gap-x-6 gap-y-12'>
                            {plano?.itens?.length > 0 && plano.itens.map((item, index) => (
                                <div key={index} className='border rounded-[8px] bg-slate-50 p-4 flex flex-col gap-4'>
                                    <h4 className='font-semibold text-[20px]'>
                                        {item.nome}
                                    </h4>
                                    <span>
                                        {item.descricao}
                                    </span>
                                    <BotaoArredondadoUsemais  
                                        value={'Acessar'} 
                                        className='w-[120px] h-[36px] md:w-[120px] md:h-[40px]'
                                        classNameText='text-[14px] md:text-sm' 
                                        onClick={() => {
                                            window.open(item.link, 'blank');
                                        }}
                                        ativo={item.link}
                                    />
                                </div>
                            ))}
                        </div>
                    </Box>
                )}
                <div className='w-full flex justify-end'>
                    <BotaoArredondadoUsemais  
                        value={'Cancelar plano'} 
                        className={twMerge(
                            "w-full lg:w-[220px] 2xl:w-[240px] bg-azul-padrao hover:bg-azul-padrao hover:bg-opacity-70",
                        )}
                        classNameText='text-[14px] md:text-sm' 
                        onClick={() => {
                            setShowCancelamento(true)
                        }}
                        ativo={true}
                    />
                </div>
            </div>

            {showCancelamento &&
                <Modal
                    open={showCancelamento}
                    onClose={() => setShowCancelamento(false)}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] p-[40px] md:p-[60px] w-full md:max-w-[500px] flex flex-col gap-[40px] rounded-[36px] md:rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold'>
                                Cancelar plano
                            </h2>
                            <span className='text-[16px] text-[#151A25] font-light text-center'>
                                Para efetuar o cancelamento, você pode ligar para (41) 99191-5240 ou se preferir solicite via WhatsApp.
                            </span>
                        </div>
                        <div className='w-full flex flex-col gap-[10px]'>
                            <button 
                                onClick={() => {
                                    setShowCancelamento(false)
                                    window.open("https://wa.me/5541991915240", "blank")
                                }} 
                                className='
                                    w-full bg-[#0EB70E]
                                    text-white text-[16px] py-[8px] rounded-[8px]
                                    hover:opacity-90 flex px-4 items-center relative
                                '
                            >
                                <img 
                                    src="/usemais/whatsapp-icon.svg" 
                                    alt="whatsapp" 
                                    className='w-[24px] absolute' 
                                />
                                <span className='w-full'>
                                    Quero falar com alguém
                                </span>
                            </button>
                            {/* <button 
                                onClick={() => {
                                    setShowSuccess(false)
                                    history.replace('/')
                                }} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441] to-[#FAAE2E] 
                                    text-white text-[16px] py-[8px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Entendido</span>
                            </button> */}
                            
                        </div>
                    </div>
                </Modal>
            }

            <RodapeUsemais 
                className="md:pb-8 mt-[32px] md:mt-0"
            />
        </>
    );
}



