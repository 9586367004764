import { ReactNode } from "react"

interface SignUpActionsProps {
    children: ReactNode
}

export function SignUpActions({ children }: SignUpActionsProps) {
    return (
        <div className="mt-2 pr-4 w-full text-right text-2xl font-medium text-gray-900">
            {children}
        </div>
    )
}