import React, { useContext, useEffect, useState } from 'react';
import { desfavoritar, favoritar, getFavorito } from '../../../actions/assistamais/api';
import favorite from '../../../assets/img/add-favorite.png';
import { MessageContext } from '../../../contexts/MessageContext';
import { SessionContext } from '../../../contexts/SessionContext';
import { doLogin } from '../../../utils/Session';
import './style.scss';



export default function Favoritar({ className = '', filme, id, tipo = 'movie', ...rest }) {
    const [favorito, setFavorito] = useState(false);
    const [session, setSession, logado] = useContext(SessionContext);
    const [showMessage, showError, showMessageFromResult] = useContext(MessageContext);

    useEffect(() => {

        if (logado) {

            getFavorito({ tituloId: filme.id || id, tipo }).then(result => {
                if (result && result?.payload && result?.payload?.data) {
                    setFavorito(result?.payload?.data.tituloId !== undefined);
                }
            });

        }
    }, [id]);

    const handleFavoritarClick = async (e) => {
        if (!logado) {
            doLogin();
        } else {

            if (!favorito) {
                const { title, genres, overview, backdrop_path, poster_path, original_name } = filme;
                const data = {
                    title: title || original_name, genres, overview, backdrop_path, poster_path,
                };

                favoritar({ tituloId: filme.id || id, tipo: tipo, data: data }).then(result => {
                    if (result.payload.status && Math.trunc(result.payload.status / 100) === 2) {
                        showMessage('success', "Adicionado aos favoritos", 3000);
                        setFavorito(true);
                    } else {
                        const error = result.payload.data && result.payload.data.message 
                            ? result.payload.data.message
                            : 'Algo inexperado aconteceu, tente novamente.';
                        throw new Error(error);
                    }
                }).catch(err => {
                    console.error(err);
                    showMessage('error', err.message, 3000);
                });

                // if (await showMessageFromResult(favoritar({ tituloId: filme.id || id, tipo: tipo, data: data }), "Adicionado aos favoritos",)) {
                //     setFavorito(true);
                // }

            } else {

                desfavoritar({ tituloId: filme.id || id, tipo: tipo }).then(result => {
                    if (result.payload.status && Math.trunc(result.payload.status / 100) === 2) {
                        showMessage('success', "Removido da lista de favoritos", 3000);
                        setFavorito(false);
                    } else {
                        const error = result.payload.data && result.payload.data.message 
                            ? result.payload.data.message
                            : 'Algo inexperado aconteceu, tente novamente.';
                        throw new Error(error);
                    }
                }).catch(err => {
                    console.error(err);
                    showMessage('error', err.message, 3000);
                });


                // if (await showMessageFromResult(desfavoritar({ tituloId: filme.id || id, tipo: tipo }), "Removido da lista de favoritos",)) {
                //     setFavorito(false);
                // }
            }
        }
    }

    return (
        <div className={`favoritar ${className} `} {...rest} inativo={favorito ? 0 : 1} onClick={handleFavoritarClick} >
            <img src={favorite} alt="favoritar" />
        </div>
    );
}