"use client";

import "./style.scss";
import LoginForm from "../../componentes/usemais/components/SignIn/loginScreen";
import SetPasswordForm from "../../componentes/usemais/components/SignUp/setPasswordScreen";
import PasswordForm from "../../componentes/usemais/components/SignIn/passwordScreen";
import { useEffect, useState } from "react";
import { changePassword, signUp } from "../../actions/services/userService";
import { signIn } from "../../actions/services/authService";
import { ToastContainer, toast } from "react-toastify";
import { AnimatePresence } from "framer-motion";
// import { useSearchParams, useRouter } from "next/navigation";
import PasswordScreen from "../../componentes/usemais/components/ChangePassword/changePassword";
import { SignUp } from "../../componentes/usemais/components/SignUp";
import ForgotScreen from "../../componentes/usemais/components/SignIn/forgotScreen";
import OnBoardingPainel from "../../componentes/molecules/OnboardingPainel";
import { autenticar } from "../../actions/assistamais/api";
import { gravarDados } from "../../utils/Session";
import { useHistory } from "react-router-dom";
import { validarCPF } from "../../utils/functions";

export default function Login() {
  const history = useHistory();
  
  const [socialId, setSocialId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkedUser, setCheckedUser] = useState(false);
  const [existUser, setExistUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [statusLoginForm, setStatusLoginForm] = useState(true);
  const [statusForgot, setStatusForgot] = useState(false);
  const [statusSetPassword, setStatusSetPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordChangedMessage, setPasswordChangedMessage] = useState("");

  // const searchParams = useSearchParams()
  // const router = useRouter();
  // const passwordResetKey = searchParams?.get('password-reset-key');
  const passwordResetKey = "";

  const handleRedirectSenha = () => {
    setStatusLoginForm(false);
    setStatusForgot(true);
    // router.push('/requestPassword')
  };

  const handleRedirectLogin = () => {
    setStatusLoginForm(true);
    setStatusForgot(false);
    // router.push('/requestPassword')
  };

  const handleSignIn = async (socialId: string, password: string) => {

    if (!socialId || !password) {
      return;
    }

    if (!validarCPF(socialId)) {
      toast.error("Informe um CPF válido", { autoClose: 3000 })
      return;
    }

    try {
      setIsLoading(true);

      let response: any = await autenticar(socialId.replace(/\D/g, ""), password);
      if (response.payload.status == 200) {
        gravarDados(response?.payload?.data);
        window.location.replace('/minha-conta')
      } else if (response.payload.status == 401) {
        toast.error(response.payload?.data?.message)
      } else {
        toast.error("Não foi possível realizar o login")
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Não foi possível realizar o login")
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    const response = await changePassword({
      key: passwordResetKey,
      code: code,
      password: password,
    });
    const changed = response.status;
    setPasswordChangedMessage(response.message);
    setPasswordChanged(changed);
    setIsLoading(false);

    if (!changed) {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleCodeValidate = async () => {
    try {
      setIsLoading(true);
      await signIn({ social_id: socialId, password: password });

      // router.push('/');
    } catch (error: any) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const statusPass = checkedUser && existUser;
    const statusSetPass = checkedUser && !existUser;
    setStatusLoginForm(!checkedUser && !passwordResetKey);
    // setStatusPassword(statusPass)
    setStatusSetPassword(statusSetPass);
  }, [checkedUser, passwordResetKey, existUser]);

  // const handleRedirectLogin = () => {
  //   window.location.href = "/login";
  // };

  return (
    <section>
      {/* <ToastContainer /> */}
      <div className="max-md:flex max-md:flex-col md:grid grid-cols-2 h-screen">

          <div className="hidden md:block w-full">
            <OnBoardingPainel />
          </div>
          
          {passwordChanged ? (
            <div className="flex flex-col mx-auto w-[36%] max-md:ml-0 max-md:w-full">
              <div className="flex border-neutral-800 border-2 flex-col self-stretch p-20 my-auto h-[860px] w-full text-xl shadow bg-white rounded-[48px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <SignUp.Root>
                  <SignUp.Title text={passwordChangedMessage} />
                  <SignUp.Actions>
                    <SignUp.Action
                      id="login"
                      onClick={() => handleRedirectLogin()}
                      text="Login"
                      className="justify-center mt-10 items-center text-center p-2.5 w-full text-white rounded-lg shadow-sm bg-[linear-gradient(90deg,#E13441_0%,#FAAE2E_100%)]"
                    />
                  </SignUp.Actions>
                </SignUp.Root>
              </div>
            </div>
          ) : (
            <>
              {statusLoginForm ? (
                <LoginForm
                  isLoading={isLoading}
                  type="login"
                  handleRedirectSenha={handleRedirectSenha}
                  setExistUser={setExistUser}
                  setCheckedUser={setCheckedUser}
                  signIn={handleSignIn}
                />
              ) : (
                ""
              )}
              {statusForgot ? (
                <ForgotScreen
                  passwordKey={passwordResetKey}
                  changePassword={handleChangePassword}
                  handleRedirectLogin={handleRedirectLogin }
                  saveCode={setCode}
                  codeValidate={handleCodeValidate}
                  savePassword={setPassword}
                />
              ) : (
                ""
              )}
              {/* {statusSetPassword ? <SetPasswordForm setExistUser={setExistUser} setCheckedUser={setCheckedUser} setPassword={setPassword}
                              signUp={handleSignUp} setName={setName} setEmail={setEmail} isLoadingSignUp={isLoading} /> : ''} */}
              {/* {passwordResetKey ?
                              <PasswordScreen passwordKey={passwordResetKey} changePassword={handleChangePassword} isLoading={isLoading}
                                  saveCode={setCode} codeValidate={handleCodeValidate} savePassword={setPassword} /> : ''} */}
              {/* {passwordResetKey ?
                              <ForgotScreen passwordKey={passwordResetKey} changePassword={handleChangePassword} isLoading={isLoading}
                                  saveCode={setCode} codeValidate={handleCodeValidate} savePassword={setPassword} /> : ''}        */}
            </>
          )}
      </div>

      <ToastContainer 
        position="top-right"
        theme="colored"
      />
    </section>
  );
}
