import { twMerge } from "tailwind-merge";

interface SignUpSubtitleProps {
    text: string
    className?: string
}

export function SignUpSubtitle({ text, className }: SignUpSubtitleProps) {
    return (
        <div className={twMerge(
            "mt-8 w-full text-2xl font-medium text-justify text-gray-900 max-w-[436px]",
            className
        )}>
            {text}
        </div>
    );
}