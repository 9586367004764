// import { User } from "@/app/Models/User"
import axios, { AxiosInstance } from "axios"

const api: AxiosInstance = axios.create({
    baseURL: process.env.apiBaseURL,
    timeout: 10000,
    timeoutErrorMessage: "Time out!",
})

export const signIn = async (data: any): Promise<any | null> => {
    data.social_id = data.social_id.replace(/[^a-zA-Z0-9_ ]/g, '')
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': process.env.backendToken
        }
    }

    let userData = null

    await api.post<any>('/user/sign-in', data, config)
        .then(function (response) {
            userData = JSON.parse(response.data.user)

            window.localStorage.setItem('token', response.data.token)
        })

        .catch(function (error) {
            throw error
        })

    return userData
}

export const requestPasswordChange = async (socialId: string) => {
    socialId = socialId.replace(/[^a-zA-Z0-9_ ]/g, '')
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': process.env.backendToken
        }
    }

    try {
        const response = await api.post<any>('/user/request-change', { socialId }, config)
        return response.data
    } catch (error) {
        return error
    }
}

export const validateCode = async (data: any): Promise<{ validate: boolean, message: string }> => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': process.env.backendToken
        }
    }

    let validation = { validate: false, message: '' }

    try {
        const response = await api.post<any>('/multifactor/validate', data, config)
        validation = response.data
    } catch (error: any) {
        validation = { validate: false, message: error.response.data.message }
    } finally {
        return validation
    }
}

export const validateUserToken = async (token: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': process.env.backendToken,
            Authorization: `Bearer ${token}`,
        }
    }

    const response = await api.get<any>('/validate-token', config)

    return response.data.validate
}

export const logout = async () => {
    localStorage.removeItem("token")
}

export const validateEmail = async (data: any): Promise<{ validate: boolean, message: string }> => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': process.env.backendToken
        }
    }

    let validation = { validate: false, message: '' }

    try {
        const response = await api.post<any>('/multifactor/validate-email', data, config)
        validation = response.data
    } catch (error: any) {
        validation = { validate: false, message: error.response.data.message }
    } finally {
        return validation
    }
}