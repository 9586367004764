import React, { useContext, useEffect, useState } from 'react';
import { getFavoritos, obterAnuncios } from '../../actions/assistamais/api';
import fundo from '../../assets/img/pexels-august-de-richelieu-4260642.png';
import ListaTitulosGrid from '../../componentes/templates/ListaTitulosGrid';
import ListaTitulosGridFavoritos from '../../componentes/templates/ListaTitulosGridFavoritos';
import { SessionContext } from '../../contexts/SessionContext';
import './style.scss';

export default function ListaTitulosFavoritos() {

    const [favoritos, setFavoritos] = useState([]);
    const [anuncios, setAnuncios] = useState([]);

    const [session, , , , getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const carregarAnuncios = () => {
        try {
            obterAnuncios('FAVORITO').then(result => {
                setAnuncios(result?.payload?.data?.results[0].linha);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        carregarAnuncios();
        async function fetchData() {

            getFavoritos().then(result => {
                // TODO criar função para usar em todos

                if (!result?.payload?.status || Math.trunc(result?.payload?.status / 100) !== 2) return;


                let { data } = result?.payload;

                setFavoritos(data.map(f => {
                    let pathname = '/filme';
                    if (f.tipo.toLowerCase() === 'tv') {
                        pathname = 'serie';
                    }
                    const linkDetalhe = `${pathname}/?id=${f.tituloId}&type=${f.tipo}`;
                    const { genres, backdrop_path, poster_path, title, overview } = f.data

                    return {
                        ...f,
                        genres,
                        backdrop_path,
                        poster_path,
                        title,
                        overview,
                        linkDetalhe: linkDetalhe
                    }
                }).reverse());

            });

        }
        fetchData()

    }, []);

    return <ListaTitulosGridFavoritos
        fundo={fundo}
        titulos={favoritos}
        tituloPagina="Minha lista de favoritos"
        anuncios={anuncios}
        onCheckMonetizado={() => carregarAnuncios()}
    />
}


ListaTitulosFavoritos.propTypes = {

};

