import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import config from '../../../config/default';
import BotaoBordaArredondado from '../../atoms/BotaoBordaArredondado';
import ThumbWide from '../../atoms/ThumbWide';
import './style.scss';

// `url(${config.bigImageURL}${backdropPath})`
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
        slidesToSlide: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

let carouselRef;
const GaleriaImagens = ({ titulo, imagens = [] }) => {

    const [showPreview, setShowPreview] = useState(false);


    const setCarouselRef = (el) => {
        carouselRef = el;
    }

    const handleImagePreviewClick = (idx) => {

        setShowPreview(true);
        setTimeout(() => {
            // soma existe para o carrossel ir para a imagem correta. Ele tem um funcionamento estranho.

            const soma = imagens.length === 1 ? 1 : 2;
            carouselRef.goToSlide(idx + soma, true)
        }, 100)

    }

    return (
        <div className="galeria-imagens">
            <div className="galeria-imagens-titulo">{titulo}</div>
            <div className="galeria-imagens-lista" >
                {imagens.map((i, idx) =>
                    <ThumbWide key={idx} className="galeria-imagens-item" src={`${config.bigImageURL}${i.file_path}`} onClick={() => handleImagePreviewClick(idx)} />
                )}
            </div>

            {showPreview &&
                <div className="galeria-imagens-visualizacao" >

                    <Carousel responsive={responsive}
                        ref={el => setCarouselRef(el)}
                        swipeable={true}
                        draggable={true}
                        infinite={true}
                        showDots={false}
                        arrows={true}


                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                    //deviceType={this.props.deviceType||''}
                    >
                        {imagens.map((i, key) =>
                            <div key={key} className="galeria-imagens-preview-item">
                                <img alt="" src={`${config.bigImageURL}${i.file_path}`} />
                                <BotaoBordaArredondado value="X" className="galeria-preview-close" onClick={() => setShowPreview(false)} />
                            </div>
                        )}

                    </Carousel>


                </div>
            }
        </div>
    )
};

export default GaleriaImagens;