import { useRef } from "react";
import { Route } from "react-router-dom";
import VideoPopup from "../componentes/atoms/VideoPopup";
import Header from '../componentes/usemais/Header/index'

export function RotaTemaPadrao ({ component: Component, titulo, completo, headerShow = true, headerFixed = false, ...rest }) {

    const videoRef = useRef()

    return (
        <Route
            {...rest}
            render={props => (
                <>
                    {headerShow && <Header fixed={headerFixed} />}
                    <VideoPopup ref={videoRef} />
                    <Component {...props} videoRef={videoRef} />

                </>
            )}
        />
    );
}