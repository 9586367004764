import { useContext, useEffect, useRef, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { getFavoritos, obterClienteResumo, obterExtrato, obterProdutos, obterFiltros, obterSaldo, buscarCodigoPontos } from '../../actions/assistamais/api';
import CardPopup from '../../componentes/molecules/CardPopup';
import ListaResgatePontosHorizontal from '../../componentes/organisms/ListaResgatePontosHorizontal';
import './style.scss';
import 'react-loading-skeleton/dist/skeleton.css'
import ExtratoDePontos from '../../componentes/organisms/ExtratoDePontos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faClose } from '@fortawesome/free-solid-svg-icons';
import { SessionContext } from '../../contexts/SessionContext';
import { gravarDados } from '../../utils/Session';
import { twMerge } from 'tailwind-merge';
import { Box, Modal } from '@mui/material';
import { RodapeUsemais } from '../../componentes/organisms/RodapeUsemais';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { formatLettersAndNumbers } from '../../utils/functions';
import { useMessage } from '../../contexts/MessageContext';




export default function Gifts({saldo = 0, className, extratoPontoClick }) {
    
    const [session, setSession, , , getSessionData] = useContext(SessionContext);
    const [ showMessage ] = useMessage();

    const [favoritos, setFavoritos] = useState([]);
    const [perfil, setPerfil] = useState([]);
    const [filtros, setFiltros] = useState({categorias: [], pontos: []});
    const [produtos, setProdutos] = useState([]);
    const [filtredProdutos, setFiltredProdutos] = useState([]);
    const [extrato, setExtrato] = useState();
    const [filter, setFilter] = useState('');
    const [filterCategory, setFilterCategory] = useState('todos');
    const [filterOrder, setFilterOrder] = useState('menor-ponto');
    const [filterOrderLabel, setFilterOrderLabel] = useState('Menor ponto');
    const [filteredCategoria, setFilteredCategoria] = useState('');
    const [showDrop, setShowDrop] = useState(false);
    const [showSucesso, setShowSucesso] = useState(false);

    const [showOrdened, setShowOrdened] = useState(false);

    const [codigoPontos, setCodigoPontos] = useState('');

    const extratoDePontosRef = useRef();
    const cardPopup = useRef(null);

    const refDropDownCategory = useDetectClickOutside({ onTriggered: handleCloseDropDown });

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        if (produtos.length > 0) {
            filtrarDados(filterCategory, filterOrder)
        }
    },[produtos]);

    useEffect(() => {
        async function fetchData() {

            //            const { genres } = (await getGenresMovieList()).payload.data;

            getFavoritos().then(result => {
                // TODO criar função para usar em todos

                if (!result?.payload?.status || Math.trunc(result?.payload?.status / 100) !== 2) return;


                let { data } = result?.payload;

                setFavoritos(data.map(f => {
                    let pathname = '/filme';
                    if (f.tipo.toLowerCase() === 'tv') {
                        pathname = 'serie';
                    }
                    const linkDetalhe = `${pathname}/?id=${f.tituloId}&type=${f.tipo}`;
                    const { genres, backdrop_path, poster_path, title, overview } = f.data

                    return {
                        ...f,
                        genres,
                        backdrop_path,
                        poster_path,
                        title,
                        overview,
                        linkDetalhe: linkDetalhe
                    }
                }).reverse());

            });

            obterClienteResumo().then(result => {
                setPerfil(result?.payload?.data);
                getSessionData()
            })

            obterProdutos().then(result => {
                setProdutos(result?.payload?.data?.results);
                // setFiltredProdutos(result?.payload?.data?.results)
            });

            obterFiltros().then(result => {
                setFiltros({
                    pontos: result?.payload?.data.pontos, 
                    categorias: result?.payload?.data.categorias
                });
            });           

            obterExtrato().then(result => {
                setExtrato(result?.payload?.data);
            });
        }
        fetchData()
    }, []);

    const handleChange = (event) => {
        event.persist()
        setFilter(event.target.value);
    }

    const handleClickCategoria = (e, categoria) =>{

        setFilteredCategoria(e.target.textContent.toLowerCase())
        setFilterCategory(categoria);

        filtrarDados(categoria, filterOrder);

        setShowDrop(false);
    }

    const handleExtratoPontoClick = () => {
        
        extratoDePontosRef.current.open();
    }

    const handleShowMenu = () =>{
        setShowDrop(!showDrop);
    }

    function atualizarDados() {
        obterProdutos().then(result => {                  
            setProdutos(result?.payload?.data?.results);
        });
        obterClienteResumo().then(result => {
            setPerfil(result?.payload?.data);
        })
        obterExtrato().then(result => {
            setExtrato(result?.payload?.data);
        });
        obterSaldo().then(result => {
            const newSession = {
                ...session,
                perfil: {
                    ...session.perfil,
                    pontos: result?.payload?.data.saldo
                }
            };
            gravarDados(newSession)
            setSession(newSession);
        });
    }

    function handleSelectOrder(type, label) {

        setFilterOrder(type);
        setFilterOrderLabel(label)

        filtrarDados(filterCategory, type);

    }

    function handleCloseDropDown() {
        setShowDrop(false);
    }

    const filtrarDados = (category = null, order = null) => {

        let dadosFiltrados = [
            ...produtos
        ];

        // Filtragem
        if (category !== 'todos') {
            dadosFiltrados = dadosFiltrados.filter((giftCard) =>
                giftCard.categoria.toLowerCase().includes(category.toLowerCase())
            );
        }

        // Ordenação
        switch (order) {
            case 'a-z':
                dadosFiltrados.sort((a, b) => a.descricao > b.descricao ? 1 : a.descricao < b.descricao ? -1 : 0);
                break;
            case 'z-a':
                dadosFiltrados.sort((a, b) => a.descricao > b.descricao ? -1 : a.descricao < b.descricao ? 1 : 0);
                break;
            case 'maior-ponto':
                dadosFiltrados.sort((a, b) => Number(b.pontos) - Number(a.pontos));
                break;
            case 'menor-ponto':
                dadosFiltrados.sort((a, b) => Number(a.pontos) - Number(b.pontos));
                break;
            case 'maior-valor':
                dadosFiltrados.sort((a, b) => Number(b.bonificacao) - Number(a.bonificacao));
                break;
            case 'menor-valor':
                dadosFiltrados.sort((a, b) => Number(a.bonificacao) - Number(b.bonificacao));
                break;
            case 'fornecedor':
                dadosFiltrados.sort((a, b) => a.fornecedorNome > b.fornecedorNome ? 1 : a.fornecedorNome < b.fornecedorNome ? -1 : 0);
                break;
            default:
                dadosFiltrados.sort((a, b) => Number(a.pontos) - Number(b.pontos));
                break;
        }

        setFiltredProdutos(dadosFiltrados);
    };

    const ListFilters = (mobile) => {

        const categorias = [
            { label: 'Todas', value: 'todos' },
            { label: 'Infantil', value: 'brinquedos e crian' },
            { label: 'Construção', value: 'casa e constru' },
            { label: 'Decoração', value: 'casa e decora' },
            { label: 'Combustível e serviços', value: 'combust' },
            { label: 'Cultura e entretenimento', value: 'cultura e entretenimento' },
            { label: 'Cupons exclusivos', value: 'cupom exclusivo' },
            { label: 'Cursos', value: 'cursos' },
            { label: 'Delivery', value: 'delivery' },
            { label: 'Doação', value: 'doa' },
            { label: 'Eletrônicos', value: 'eletr' },
            { label: 'Esporte e lazer', value: 'esporte e lazer' },
            { label: 'Experiências', value: 'experi' },
            { label: 'Games e software', value: 'games e software' },
            { label: 'Hipermercado', value: 'hipermercado e varejo' },
            { label: 'Informática e papelaria', value: 'informati' },
            { label: 'Mobilidade', value: 'mobilidade' },
            { label: 'Moda e acessórios', value: 'moda e acess' },
            { label: 'Óculos e acessórios', value: 'culos e acess' },
            { label: 'Petshop', value: 'petshop' },
            { label: 'Presentes e flores', value: 'presentes e flores' },
            { label: 'Programa de pontos', value: 'programa de pontos' },
            { label: 'Restaurante e cafés', value: 'restaurantes e caf' },
            { label: 'Saúde e beleza', value: 'e beleza' },
            { label: 'Serviços', value: 'servi' },
            { label: 'Varejo virtual', value: 'varejo virtual' },
            { label: 'Viagens e turismo', value: 'viagens e turismo' }
        ];
          
        
        return (
            <ul 
                className="
                    w-[250px] bg-white border border-[#BFBFBF] 
                    rounded-xl text-[#3D3D3D] font-light text-[14px]
                    md:flex flex-col gap-8 pb-10
                    hidden
                "
            >                                  
                {!mobile.mobile &&
                    <li className='w-full flex justify-center'>
                        <span className='bg-[#ED636E] w-[200px] p-2 text-center text-white font-medium rounded-b-lg mb-4 -mt-2'>
                            Categorias
                        </span>
                    </li>
                }
                {categorias.map((categoria) => (
                    <li key={categoria.value} onClick={(e) => handleClickCategoria(e, categoria.value)} className='w-full flex justify-center'>
                        <span className={twMerge(
                            'flex w-[200px] hover:text-[#ED636E] hover:font-medium cursor-pointer',
                            filterCategory == categoria.value && "text-[#ED636E] font-medium underline"
                        )}>
                            {categoria.label}
                        </span>
                    </li>
                ))}
            </ul>
        )
    }

    const ordenacaoOpcoes = [
        { label: 'A - Z', value: 'a-z' },
        { label: 'Z - A', value: 'z-a' },
        { label: 'Maior ponto', value: 'maior-ponto' },
        { label: 'Menor ponto', value: 'menor-ponto' },
        { label: 'Maior valor', value: 'maior-valor' },
        { label: 'Menor valor', value: 'menor-valor' },
        { label: 'Parceiros', value: 'fornecedor' }
    ];
      
    function handleOrder() {
        setShowOrdened(!showOrdened);
    }

    const handleKeyPressCodigo = async (event) => {
        if (event.key === "Enter") {
            
            if (codigoPontos.length == 0) {
                showMessage('error', "Informe o código para resgate.", 3000)
                return
            }
            
            try {
                const response = await buscarCodigoPontos({ codigo: codigoPontos });

                if (response?.payload?.status === 200) {
                    setShowSucesso(true)
                } else if (response?.payload?.status === 412) {
                    showMessage('error', response?.payload?.data?.message ?? response?.payload?.statusText, 3000)
                } else {
                    throw new Error(response?.payload?.data?.message ?? response?.payload?.statusText)
                }

            } catch (error) {
                console.error("Erro ao buscar código de pontos:", error);
                showMessage('error', "Cupom não localizado!", 3000)
            }
        }
    };

    function handleCloseModalSucesso() {
        setShowSucesso(false)
        window.location.reload()
    }

    return (
        <>
        <div className="contaMeusPontos pb-[20px]">
            <div className='divTitulo'>
            </div>

            <div className={twMerge(
                'md:w-full lg:w-10/12 md:mx-auto md:grid md:grid-cols-3 xl:grid-cols-4 md:gap-10 2xl:mt-20 md:flex-row',
                'flex flex-col'
            )}>
                <div className='flex flex-col gap-8 p-4 md:p-0'>
                    <Box className="flex w-full md:w-[250px] relative">
                        <div className='h-[40px] md:h-[50px] border border-[#ED636E] rounded-l-lg w-6/12 bg-white flex items-center justify-center gap-2'>
                            {filterOrder && (
                                <>
                                    <span className='text-[#3D3D3D] text-[14px] md:text-[12px] font-medium'>
                                        {filterOrderLabel}
                                    </span>
                                    <FontAwesomeIcon 
                                        icon={faClose} 
                                        className='text-[#3D3D3D] cursor-pointer'
                                        onClick={() => handleSelectOrder("", "")}
                                    />
                                </>
                            )}
                        </div>
                        <button 
                            className='bg-[#ED636E] h-[40px] md:h-[50px] px-4 rounded-r-lg flex items-center justify-between w-6/12 text-[14px] md:text-[12px]'
                            onClick={handleOrder}
                        >
                            <span className='w-full md:w-auto'>Ordenar por:</span>
                            <FontAwesomeIcon icon={!showOrdened ? faChevronDown : faChevronUp} color='#FFF' />
                            {showOrdened && (
                                <Box className="absolute top-[50px] right-0 md:left-0 w-[250px] bg-[#1c1717] opacity-90 p-4">
                                    <ul className="flex flex-col gap-4 text-[14px]">
                                        {ordenacaoOpcoes.map((opcao) => (
                                            <li 
                                                key={opcao.value}
                                                onClick={() => handleSelectOrder(opcao.value, opcao.label)}
                                                className='hover:font-semibold'
                                            >
                                                {opcao.label}
                                            </li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                        </button>
                    </Box>
                    <ListFilters mobile={false} />
                </div>
                
                <div className='md:col-span-2 xl:col-span-3 flex flex-col 2xl:w-10/12 px-4 md:px-0'>
                    <Box className="flex flex-col w-full md:mt-[56px]">
                        <div className='flex md:justify-end items-end gap-4'>
                            <div className='flex flex-col gap-2 w-full md:w-auto'>
                                <label htmlFor="codigo-cupom-pontos" 
                                        className='text-[#151A25] font-medium text-[14px] md:text-base'
                                        
                                >
                                    Código de pontos:
                                </label>
                                <input 
                                    type="text"
                                    name='codigo-cupom-pontos'
                                    id='codigo-cupom-pontos'
                                    className='w-full md:w-[250px] 2xl:w-[300px] h-[40px] md:h-[50px] border border-[#8A8A8A] rounded-lg bg-white px-2 md:px-4 text-[#151A25] text-[12px]'
                                    placeholder='Informe seu código de pontos'
                                    onChange={(e) => {
                                        const text = formatLettersAndNumbers(e.target.value.toUpperCase())
                                        setCodigoPontos(text)
                                    }}
                                    value={codigoPontos}
                                    onKeyDown={handleKeyPressCodigo}
                                />
                            </div>
                            <button 
                                className='
                                    w-[200px] h-[50px] md:flex justify-center items-center 
                                    gap-2 text-[16px] text-[#151A25] font-light bg-white 
                                    border border-[#ED636E] rounded-lg hidden
                                '
                                onClick={handleExtratoPontoClick}
                            >
                                <span>Saldo de pontos:</span>
                                <span className='text-[#ED636E] font-semibold'>
                                    {perfil?.pontos?.disponivel ?? 0}
                                </span>
                            </button>

                            <div className='flex flex-col items-center gap-2 md:hidden min-w-[120px]'>
                                <label 
                                    className='text-[#151A25] font-medium text-[14px] md:text-base'   
                                >
                                    Saldo de pontos:
                                </label>
                                <button 
                                    className='
                                        h-[40px] md:h-[50px] flex justify-center items-center 
                                        gap-2 text-[16px] text-[#151A25] font-light bg-white 
                                        border border-[#ED636E] rounded-lg w-full
                                    '
                                    onClick={handleExtratoPontoClick}
                                >
                                    <span className='text-[#ED636E] font-semibold'>
                                        {perfil?.pontos?.disponivel ?? 0}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className='w-full'>
                            <ListaResgatePontosHorizontal 
                                pontos={perfil?.pontos?.disponivel}
                                handleChange={handleChange} 
                                listaResgatePontos={filtredProdutos} 
                                onResgate={atualizarDados} 
                                className={twMerge(
                                    'grid grid-cols-2 gap-2 justify-center',
                                    'sm:grid-cols-2',
                                    'lg:grid-cols-3',
                                    'xl:grid-cols-4 gap-y-4',
                                    // 'md:gap-0 md:flex md:justify-start'
                                )}
                            />
                        </div>
                    </Box>                     
                </div>

            </div>
        
            <ExtratoDePontos 
                ref={extratoDePontosRef} 
                saldo={perfil?.pontos} 
                pontos={extrato} 
            />

            <CardPopup ref={cardPopup} />
        
        </div>

        {showSucesso &&
            <Modal
                open={showSucesso}
                onClose={handleCloseModalSucesso}
                className='flex items-center justify-center'
            >
                <div className="bg-[#FFF] p-[60px] w-[450px] flex flex-col gap-[60px] rounded-[48px]">
                    <div className='w-full flex flex-col items-center gap-[20px]'>
                        <h2 className='text-[32px] text-[#151A25] font-bold'>
                            Sucesso!
                        </h2>
                        <span className='text-[20px] text-[#151A25] font-medium text-center'>
                            Cupom resgatado com sucesso.
                        </span>
                    </div>
                    <div className='w-full flex flex-col gap-[10px]'>
                        <button 
                            onClick={handleCloseModalSucesso} 
                            className="
                                w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                text-white text-[16px] py-[10px] rounded-[8px]
                                hover:opacity-90
                            "
                        >
                            <span>Ok</span>
                        </button>
                    </div>
                </div>
            </Modal>
        }


        <RodapeUsemais />
        </>
    );
}