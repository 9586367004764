import { createSlice } from '@reduxjs/toolkit';

interface User {
    email: string;
    cpf: string;
}

interface UserState {
    user: User;
    authToken: string;
}

const initialState: UserState = {
    authToken: '',
    user: {
        email: '',
        cpf: ''
    },
}

interface LoginAction {
    type: string;
    payload: {
        token: string;
        user: User;
    }
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action: LoginAction) {
            state.authToken = action.payload.token
            state.user = {
                ...action.payload.user
            }
        },
        logout(state) {
            state = {
                ...initialState
            }
        },
        // setMargens(state, action: SetMargensAction) {
        //     if (action.payload.margemConsignavel) {
        //         state.margemConsignavel = action.payload.margemConsignavel
        //     }

        //     if (action.payload.margemDisponivel) {
        //         state.margemDisponivel = action.payload.margemDisponivel
        //     }
        // },
        // aceite(state, action: AceiteAction) {
        //     state.tratamentoDados = action.payload.tratamentoDados
        // },
    }
})

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
