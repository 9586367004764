import { useContext, useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from 'react-responsive';
import { resgatarDePontos, enviarSms, validarSms } from '../../../actions/assistamais/api';
import verified from '../../../assets/img/verified.png';
import { MessageContext, useMessage } from '../../../contexts/MessageContext';
import { obterDados } from '../../../utils/Session';
import BotaoArredondado from '../../atoms/BotaoArredondado';
import DialogPopup from '../../atoms/DialogPopup';
import CardResgatePonto from '../../molecules/CardResgatePonto';
import Popup from '../../molecules/Popup';
import PopupSms from '../PopupSms';
import './style.scss';
import { twMerge } from 'tailwind-merge';
import { Box, Dialog, Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { formatOnlyNumbers } from '../../../utils/functions';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1367 },
        items: 4,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1366, min: 1024 },
        items: 4,
        slidesToSlide: 4
    },
    tablet: {
        breakpoint: { max: 991, min: 464 },
        items: 2,
        slidesToSlide: 2,
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        slidesToSlide: 1,
        items: 1,
        partialVisibilityGutter: 30
    }
};

const ListaResgatePontosHorizontal = ({ onClick, listaResgatePontos = [], className = '', onResgate, pontos, handleChange }) => {
    const dialogRef = useRef();
    const history = useHistory();

    // const [produtos, setProdutos] = useState(listaResgatePontos);
    const [produtoSelecionado, setProdutoSelecinado] = useState({});
    const [showMessage, showError] = useMessage();
    const [button, setButton] = useState(true);
    const [showSms, setShowSms] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [validaSms, setValidaSms] = useState(false);
    const [codigo, setCodigo] = useState("");
    const [logado, setLogado] = useState(false);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const dados = obterDados();

    const [showAviso, setShowAviso] = useState(false);

    // useEffect(() => {

    //     let newArray = listaResgatePontos
    //     setProdutos(newArray)
    // }, [listaResgatePontos]);

    useEffect(() => {
        setLogado(obterDados()?.celular);
    }, [])

    const handleClick = (produto) => {  
        if (logado){
            setButton(false);
            setProdutoSelecinado(produto);
            // dialogRef.current.show()
            setShowConfirm(true);
        } else {
            window.location.href = '/login'
        }
        
    }

    const handleCancel = () => {
        setButton(true);
    }

    const handleResgateConfirm = async () => {

        if (dados.status == 'INCOMPLETO') {
            setShowConfirm(false);
            toast.error("Para resgatar o cupom, finalize seu cadastro.", {
                autoClose: 3000,
                theme: 'colored',
                onClick: () => history.push('/editarperfil'),
                onClose: () => history.push('/editarperfil'),
            })
            return
        }

        setShowConfirm(false);
        setShowSms(true);

        enviarSms({cpf: null, telefone: null });
    }

    const ordenarProdutos = (a, b) => {
        return a.pontos > b.pontos ? 1 : a.pontos < b.pontos ? -1 : 0;
    }

    const handleFecharAvisoClick = () => {
        setShowAviso(false);
        window.location.reload();
    }

    const closePupupSms = () => {

        // if(!validaSms){
        //     showError("Por favor realize a validação do token.")
        // }else{
            setShowSms(false)
        // }

        handleCancel()
        
    }

    const handleValidarClick = (codigo) => {

        if (codigo.length === 0) {
            showError("Informe o código enviado por SMS", 3000)
            return
        }

        validarSms({codigo: codigo }).then(result => {
            if (result?.payload?.status * 1 < 200 || result?.payload?.status * 1 > 299) {
                showError(result?.payload?.data.message, 3000)
            } else {
                setShowSms(false);
                setValidaSms(true);
                resgatarDePontos(produtoSelecionado.id ?? produtoSelecionado.codigo, produtoSelecionado.categoria).then(resultado => {
                    if (Math.trunc(resultado?.payload?.status / 100) === 2) { // 2xx => Resultado ok
                        setShowAviso(true);
                        if (onResgate) {
                            onResgate();
                        } 
                    } else {
                        showError(resultado?.payload?.data?.message, 3000);
                        handleCancel()
                    }
                })
            }
        }); 
    }
    
    return (
        <>
            {showConfirm &&
                <Modal
                    open={showConfirm}
                    onClose={() => {
                        setShowConfirm(false)
                        handleCancel()
                    }}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] p-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold'>
                                Muito bem!
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium'>
                                Deseja resgatar seu gift?
                            </span>
                        </div>
                        <div className='w-full flex flex-col gap-[10px]'>
                            <button 
                                onClick={handleResgateConfirm} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] py-[10px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Sim</span>
                            </button>
                            <button 
                                onClick={() => {
                                    setShowConfirm(false)
                                    handleCancel()
                                }}
                                className='
                                    w-full bg-white border border-[#ED636E] 
                                    text-[#ED636E] text-[16px] py-[10px] rounded-[8px]
                                    hover:font-medium
                                '
                            >
                                <span>Voltar</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }
            
            {showSms &&
                <>
                    <Modal
                        open={showSms}
                        onClose={closePupupSms}
                        className='flex items-center justify-center px-2'
                    >
                        <Box className="bg-[#FFF] p-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                            <div className='w-full flex flex-col items-center gap-[10px]'>
                                <h2 className='text-[28px] md:text-[32px] text-[#151A25] text-center font-bold'>
                                    Validação Código!
                                </h2>
                                <span className='text-[16px] md:text-[20px] text-[#151A25]  font-medium text-center'>
                                    Para realizar o resgate enviamos um código via SMS para o telefone {logado} que esta cadastrado na sua conta do Use mais.
                                </span>
                            </div>
                            <div className='w-full flex flex-col items-start gap-[4px]'>
                                <input 
                                    type="text"
                                    className='
                                        w-full bg-white border border-[#ED636E]
                                        text-[16px] p-[10px] rounded-[8px] text-[#3D3D3D]
                                    '
                                    placeholder='Digite o código SMS'
                                    onChange={e => {
                                        const text = formatOnlyNumbers(e.target.value)
                                        setCodigo(text)
                                    }}
                                    onKeyUp={e => {
                                        if (e.key == 'Enter') {
                                            handleValidarClick(codigo)
                                        }
                                    }}
                                    value={codigo}
                                />
                                <button 
                                    className='text-[#ED636E] text-[14px] underline font-semibold hover:font-bold'
                                    onClick={() => {
                                        showMessage('success', "Código reenviado via SMS!", 3000)
                                        handleResgateConfirm()
                                        // toast.success("Código reenviado via SMS!")
                                    }}
                                >
                                    Não recebi o SMS
                                </button>
                            </div>
                            <div className='w-full flex flex-col gap-[10px]'>
                                <button 
                                    onClick={() => handleValidarClick(codigo)}
                                    className='
                                        w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                        text-white text-[16px] py-[10px] rounded-[8px]
                                        hover:opacity-90
                                    '
                                >
                                    <span>Validar</span>
                                </button>
                                <button 
                                    onClick={closePupupSms}
                                    className='
                                        w-full bg-white border border-[#ED636E] 
                                        text-[#ED636E] text-[16px] py-[10px] rounded-[8px]
                                        hover:font-medium
                                    '
                                >
                                    <span>Cancelar</span>
                                </button>
                            </div>
                        </Box>
                    </Modal>
                </>
            }

            {showAviso &&
                <Modal
                    open={showAviso}
                    onClose={() => {
                        setShowAviso(false)
                        handleFecharAvisoClick()
                    }}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] p-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold'>
                                Sucesso!
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium text-center'>
                                Em até 10 dias úteis seu gift será enviado no seu e-mail para utilização.
                            </span>
                        </div>
                        <div className='w-full flex flex-col gap-[10px]'>
                            <button 
                                onClick={handleFecharAvisoClick} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] py-[10px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Ok</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }

            <div className={twMerge(
                `container-lista-resgate-pontos-horizontal`,
                className
            )}>
                <DialogPopup 
                    ref={dialogRef} 
                    allowClose={true} 
                    title="Muito bem!" 
                    subtitle="Deseja resgatar seus pontos agora?" 
                    onConfirm={handleResgateConfirm} 
                    onCancel={handleCancel} 
                    popupPontos={true} 
                    className="popup-resgate-agora-custom" 
                    confirmLabel='SIM'
                />

                {listaResgatePontos?.length > 0 ?
                    (listaResgatePontos.map((produto, index) =>
                        <CardResgatePonto 
                            key={`item-resgate-${index}`} 
                            img={produto.imagemCapa} 
                            className="custom-card-resgate" 
                            textoPremio={produto.descricao ?? `${produto.apelido} - R$ ${produto.valor}`} 
                            pontos={produto.pontos} 
                            onClick={() => handleClick(produto)} 
                            mostrarBotao={pontos >= produto.pontos || !logado} 
                            clickButton={button} 
                        />
                    ))
                :
                    (<p className='text text-azul-padrao'>Nenhum produto encontrado para essa categoria ou filtro.</p>)
                }

            </div>

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
};

export default ListaResgatePontosHorizontal;