"use client";

import "./style.scss";
import LoginForm from "../../componentes/usemais/components/SignIn/loginScreen";
import SetPasswordForm from "../../componentes/usemais/components/SignUp/setPasswordScreen";
import PasswordForm from "../../componentes/usemais/components/SignIn/passwordScreen";
import { useEffect, useState } from "react";
import { changePassword, signUp } from "../../actions/services/userService";
import { signIn } from "../../actions/services/authService";
import { ToastContainer, toast } from "react-toastify";
import { AnimatePresence } from "framer-motion";
// import { useSearchParams, useRouter } from "next/navigation";
import PasswordScreen from "../../componentes/usemais/components/ChangePassword/changePassword";
import { SignUp } from "../../componentes/usemais/components/SignUp";
import ForgotScreen from "../../componentes/usemais/components/SignIn/forgotScreen";
import OnboardingPainel from "../../componentes/molecules/OnboardingPainel";
import SignUpScreen from "../../componentes/usemais/components/SignUp/signUpScreen";
import OnBoardingPainel from "../../componentes/molecules/OnboardingPainel";

export default function Login() {
  const [socialId, setSocialId] = useState("");
  const [form, setForm] = useState<any>({});
  const [step, setStep] = useState(1);
  const [password, setPassword] = useState("");
  const [checkedUser, setCheckedUser] = useState(false);
  const [existUser, setExistUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [statusLoginForm, setStatusLoginForm] = useState(false);
  const [statusPassword, setStatusPassword] = useState(false);
  const [statusSetPassword, setStatusSetPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordChangedMessage, setPasswordChangedMessage] = useState("");
  // const searchParams = useSearchParams()
  // const router = useRouter();
  // const passwordResetKey = searchParams?.get('password-reset-key');
  const passwordResetKey = "true";

  const handleRedirectSenha = () => {
    // router.push('/requestPassword')
  };

  const handleSignUp = async () => {
    try {
      setIsLoading(true);
      const backendTokenSliced = process.env.backendToken?.slice(0, 15);

      if (!backendTokenSliced) {
        throw new Error("backendToken não está definido");
      }

      const cyptedSocialId = btoa(
        encodeURIComponent(socialId.trim() + backendTokenSliced.trim())
      );
      const cyptedPassoword = btoa(password.trim() + backendTokenSliced.trim());
      const sign = await signUp({
        name: form.name,
        email: form.email,
        social_id: cyptedSocialId,
        password: cyptedPassoword,
      });

      toast.success(sign.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        onClose: () => {
          // router.push('/')
        },
      });
    } catch (error: any) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsLoading(false);
    }
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    const response = await changePassword({
      key: passwordResetKey,
      code: code,
      password: password,
    });
    const changed = response.status;
    setPasswordChangedMessage(response.message);
    setPasswordChanged(changed);
    setIsLoading(false);

    if (!changed) {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleCodeValidate = async () => {
    try {
      setIsLoading(true);
      await signIn({ social_id: socialId, password: password });

      // router.push('/');
    } catch (error: any) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const statusPass = checkedUser && existUser;
    const statusSetPass = checkedUser && !existUser;
    // setStatusLoginForm(!checkedUser && !passwordResetKey)
    setStatusPassword(statusPass);
    setStatusSetPassword(statusSetPass);
  }, [checkedUser, passwordResetKey, existUser]);

  const handleRedirectLogin = () => {
    window.location.href = "/login";
  };

  return (
    <section>
      <ToastContainer 
        theme="colored"
        position="top-right"
      />
      <div className="max-lg:flex max-lg:flex-col lg:grid grid-cols-2 h-screen">

        <div className="hidden lg:block w-full">
          <OnBoardingPainel />
        </div>
        
        {/* <AnimatePresence> */}
          <SignUpScreen />
        {/* </AnimatePresence> */}
      </div>
    </section>
  );
}
