import React, { useEffect, useState } from 'react'
import Link from "./Link/index"
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from "react-toastify"
import useSession from '../../../hooks/useSession'
import { useHistory } from 'react-router-dom'
import { Box, useMediaQuery } from '@mui/material'
import { twMerge } from 'tailwind-merge'
import { faBagShopping, faGifts, faHome, faLightbulb, faUser } from '@fortawesome/free-solid-svg-icons'

interface HeaderProps {
    fixed?: boolean;
}

export default function Index({ fixed }: HeaderProps) {
    const [menuOpen, setMenuOpen] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [session, , logado] = useSession();

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        window.onscroll = () => {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                setIsVisible(true)
            } else {
                setIsVisible(false)
            }
        }
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <>
            <ToastContainer position="top-right" theme="colored" />
            <div className={twMerge(
                "flex justify-center w-full px-4 sm:px-10 md:px-0", 
                fixed && isMobile && "fixed z-50",
                "transition-transform duration-300 ease-in-out bottom-[10px] md:top-0"
            )}>
                

                <div className={twMerge(
                    "flex gap-5 justify-between items-center mt-6 max-h-[80px] max-md:items-start",
                    fixed && !isMobile && "fixed z-50",
                    `px-6 py-3 lg:px-10 text-lg font-semibold text-gray-900 bg-white drop-shadow-2xl z-30 w-full md:w-11/12 h-auto md:h-[50px] rounded-[32px] lg:flex`
                )}>
                    <img 
                        src="/usemais/logo_use_mais.png" 
                        alt="Logo"
                        className="h-[40px] cursor-pointer mb-0 hidden md:flex"
                        onClick={() => { 
                            // window.scrollTo(0, 0);
                            history.replace('/');
                            var element = document.getElementById('home-start')
                            element?.scrollIntoView({ behavior: 'smooth' })
                        }} 
                    />
                    <Link isLoading={false} label="Início" path="home-start" onClick={() => {}} icon={faHome} />
                    <Link isLoading={false} label="Gifts" path="gifts" onClick={() => {}} icon={faGifts} />
                    <Link isLoading={false} label="Serviços" path="services" onClick={() => {}} icon={faLightbulb} />
                    {/* <Link isLoading={false} label="Planos" path="plans" onClick={() => {}} icon={faBagShopping} /> */}
                    {(logado && session) ? (
                        <>
                            <Box className="hidden md:flex items-center gap-6">
                                {/* <div className="w-[40px] h-[40px] flex items-center justify-center rounded-xl cursor-pointer shadow-lg hover:shadow-inner">
                                    <img src="/usemais/icon_notifications.svg" alt="Notifications" />
                                </div> */}
                                <img 
                                    src={session?.foto} 
                                    alt="Perfil"
                                    className="w-[40px] h-[40px] rounded-full cursor-pointer hover:shadow-lg hover:ring-[1px] hover:ring-[#ED636E]" 
                                    onClick={() => { 
                                        window.scrollTo(0, 0);
                                        history.push('/minha-conta') 
                                    }} 
                                />
                            </Box>
                            <Link 
                                isLoading={false} 
                                label="Perfil" 
                                path="minha-conta" 
                                className="justify-center px-8 py-2 text-white bg-gray-900 rounded-3xl hover:font-medium hover:shadow-lg md:hidden"
                                onClick={() => window.scrollTo(0, 0)}
                                icon={faUser}
                                isPerfil={true}
                            />
                        </>
                    ) : (
                        <Link 
                            isLoading={false} 
                            label="Login" 
                            path="login" 
                            className="justify-center px-[32px] xl:px-[60px] py-[10px] md:py-0 lg:py-[6px] text-white bg-gray-900 rounded-3xl hover:font-medium hover:shadow-lg"
                            onClick={() => window.scrollTo(0, 0)}
                            icon={faUser}
                        />
                    )}
                </div>
            </div>
        </>
    )
}
