import React, { useContext, useRef, useState } from 'react';
import { anuncioClick } from '../../../actions/assistamais/api';
import moneyOn from '../../../assets/img/money-on.png';
import { SessionContext } from '../../../contexts/SessionContext';
import { doLogin, gravarDados, obterDados } from '../../../utils/Session';
import VideoPopup from '../VideoPopup';
import './style.scss';
import BotaoArredondado from "../../atoms/BotaoArredondado";
import Popup from "../../molecules/Popup";
import verified from '../../../assets/img/verified.png';
import { Box, Dialog, IconButton, Modal } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

export default function Anuncio({ anuncio = {}, className = '', children, imagemCapa, alt, onCheckMonetizado, ...rest }) {

    const [session, setSession, logado] = useContext(SessionContext);
    const videoPopupRef = useRef()
    const avisoPontosPopupRef = useRef();

    const [showAvisoPontos, setShowAvisoPontos] = useState(false);
    const [receivedPoints, setReceivedPoints] = useState(0);

    const checkMonetizado = () => {

        if (anuncio.isMonetizado && onCheckMonetizado) {
            onCheckMonetizado();
        }
    }

    const handleClick = () => {
        if (!logado && anuncio.isMonetizado) {
            doLogin()
            return;
        }
        if (anuncio.modelo === 'LINK_EXTERNO') {
            if (anuncio.isMonetizado) {
                anuncioClick({ anuncioId: anuncio.id, token: anuncio.token }).then(() => {
                    checkMonetizado();
                });
            }
            window.open(anuncio.url, "_blank");
            setTimeout(() => {
                window.location.reload();
            },500);
        } else if (anuncio.modelo === 'LINK_INTERNO') {
            if (anuncio.isMonetizado) {
                anuncioClick({ anuncioId: anuncio.id, token: anuncio.token }).then(() => {
                    checkMonetizado();
                });
            }
            window.location.href = anuncio.url;
        }

        if (anuncio.modelo === 'VIDEO') {
            // setShowVideo(true);
            videoPopupRef.current.setAnuncioToken(anuncio.isMonetizado ? anuncio.token : null)
            videoPopupRef.current.setAnuncioLink(anuncio.link)
            videoPopupRef.current.setAnuncioId(anuncio.id)
            videoPopupRef.current.setTempoMinimo(anuncio.tempoMinimo)
            videoPopupRef.current.show();
        } else {
            if (anuncio.isMonetizado) {
                anuncioClick({ anuncioId: anuncio.id, token: anuncio.token }).then((response) => {
                    checkMonetizado();
                    setReceivedPoints(response.payload.data.pontos)
                    setShowAvisoPontos(true);

                    const dadosLocalStorage = obterDados();

                    const newDadosLocalStorage = {
                        ...dadosLocalStorage,
                        perfil: {
                            ...dadosLocalStorage.perfil,
                            pontos: session.perfil.pontos + response.payload.data.pontos
                        }
                    };

                    gravarDados(newDadosLocalStorage);

                    setSession({
                        ...session,
                        perfil: {
                            ...session.perfil,
                            pontos: session.perfil.pontos + response.payload.data.pontos,
                        }
                    })
                });
            }
            
            window.open(anuncio.link, "_blank");
        }
    }

    const handleVideoEnded = (e) => {
        console.log(e)
        if(e != undefined) {
            setReceivedPoints(e.pontos);
            setShowAvisoPontos(true);

            const dadosLocalStorage = obterDados();

            const newDadosLocalStorage = {
                ...dadosLocalStorage,
                perfil: {
                    ...dadosLocalStorage.perfil,
                    pontos: session.perfil.pontos + e.pontos
                }
            };

            gravarDados(newDadosLocalStorage);

            setSession({
                ...session,
                perfil: {
                    ...session.perfil,
                    pontos: session.perfil.pontos + e.pontos,
                }
            })
        }
        checkMonetizado()
    }

    function handleFecharAvisoPontosClick() {
        anuncio.isMonetizado = false;
        setShowAvisoPontos(false);
    }
    
    return (
        <>
            {showAvisoPontos && (
                <Modal
                    open={showAvisoPontos}
                    onClose={handleFecharAvisoPontosClick}
                    className='flex items-center justify-center px-2'
                >
                    <Box className="bg-[#FFF] p-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='flex flex-col w-full gap-[8px]'>
                            <img src={verified} alt="" className='w-[64px]' />
                            <div className='text-[#b2c108] text-[28px] font-medium'>Parabéns!</div>
                        </div>
                        <div className='text-[#3D3D3D] text-[20px] font-medium'>
                            Você ganhou {receivedPoints ?? 0} pontos.
                        </div>
                        <button 
                            onClick={handleFecharAvisoPontosClick}
                            className='
                                w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                text-white text-[16px] py-[10px] rounded-[8px]
                                hover:opacity-90
                            '
                        >
                            <span>Ok</span>
                        </button>
                    </Box>
                </Modal>
            )}

            <VideoPopup url={anuncio.url} ref={videoPopupRef} onVideoEnded={handleVideoEnded} />
            
            <div className={`anuncio bg-transparent ${className} ${anuncio.isMonetizado ? 'anuncio-monetizado' : ''}`} {...rest} onClick={handleClick}>
                {anuncio.isMonetizado ? (
                    <div className="barra-titulo">
                        <img className="imagem-barra-titulo" src={moneyOn} alt='money-on' />
                        <div className="barra-titulo-label">Clique e ganhe pontos</div>
                    </div>
                ) : (
                    <div className="barra-titulo" style={{ backgroundColor: 'transparent' }}></div>
                )}
                <img className="imagemCapa" src={anuncio.imagemCapa} alt={anuncio.texto} title={anuncio.texto} />
            </div>
        </>
    );
}