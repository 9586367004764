import * as React from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useHistory } from "react-router-dom";

function Footer() {
    const history = useHistory();
    
    const goCentrais = () => {
        history.push({
            pathname: 'central-atendimento'
        });
    };

    return (
        <div style={{ backgroundColor: '#F5F5F5' }} className="flex justify-center items-center px-16 py-[24px] shadow-lg max-md:px-5">
            <div className="w-full max-w-[1692px] max-md:max-w-full">
                {/* Grid modificado para responsividade */}
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-[#151A25]">
                    {/* Coluna 1 */}
                    <div className="flex flex-col w-full max-md:w-full max-md:ml-0">
                        <div className="flex flex-col max-md:mt-10">
                            <div className="flex gap-5 justify-between max-w-full">
                                <img
                                    loading="lazy"
                                    src="/usemais/logo_use_mais.png"
                                    className="w-[120px]"
                                />
                            </div>
                            <div className="mt-7 text-[16px] flex flex-col">
                                <span>Copyright 2023 Todos os direitos</span>
                                <span>reservados - Versão 1.0.9</span>
                                <span>USE MAIS : CNPJ 12.751.349/0001-44</span>
                            </div>
                            <div className="mt-[40px] text-[16px] font-semibold text-red-400 flex flex-col">
                                <a href="/termos/termo_de_uso.pdf">Termos de Uso</a>
                                <a href="/termos/politica_de_privacidade.pdf">Política de Privacidade e Cookie</a>
                            </div>
                        </div>
                    </div>

                    {/* Coluna 2 */}
                    <div className="flex flex-col w-full max-md:w-full">
                        <div className="flex flex-col mt-[36px] max-md:mt-10">
                            <div className="text-[20px] font-semibold text-red-400">Público em geral</div>
                            <div className="flex flex-col gap-[4px] mt-[24px]">
                                <div className="text-[16px] flex gap-[4px]">
                                    <span>E-mail:</span>
                                    <span className="font-semibold text-red-400">
                                        <a href="mailto:contato@usemaisstore.com.br">contato@usemaisstore.com.br</a>
                                    </span>
                                </div>
                                <div className="text-[16px] flex gap-[4px]">
                                    <span>WhatsApp:</span>
                                    <span className="font-semibold text-red-400">
                                        <a href="https://wa.me/554130729343?text=Ol%C3%A1" target="_blank">(41) 3072-9343</a>
                                    </span>
                                </div>
                                <div className="text-[16px]">
                                    Horário de atendimento: 8h às 18h
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Coluna 3 */}
                    <div className="flex flex-col w-full max-md:w-full">
                        <div className="flex flex-col mt-[36px] max-md:mt-10">
                            <div className="text-[20px] font-semibold text-red-400">Servidores públicos</div>
                            <div className="mt-[24px] text-[16px]">MeuConsignado e RioConsig:</div>
                            <button onClick={goCentrais} className="w-full md:w-[240px] h-[40px] flex justify-center items-center rounded-[20px] bg-gradient-to-r from-[#E13441] to-[#FAAE2E] mt-[20px] hover:opacity-90">
                                <span className="text-[16px] font-medium text-white">
                                    Central de atendimento
                                </span>
                            </button>
                        </div>
                    </div>

                    {/* Coluna 4 */}
                    <div className="flex flex-col w-full max-md:w-full">
                        <div className="flex flex-col mt-[36px] max-md:mt-10">
                            <div className="text-[20px] font-semibold text-red-400">
                                Redes Sociais
                            </div>
                            <div className="mt-[24px] text-[16px]">
                                Siga a Use Mais nas redes sociais
                            </div>
                            <div className="flex gap-[16px] mt-[20px]">
                                <a
                                    href="https://www.facebook.com/use.mais.multisservico"
                                    className="w-[40px] h-[40px] rounded-full bg-gray-900 flex justify-center items-center"
                                >
                                    <FontAwesomeIcon
                                        icon={faFacebook}
                                        className="text-[24px] text-red-400"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/use.mais.multi"
                                    className="w-[40px] h-[40px] rounded-full bg-red-400 flex justify-center items-center"
                                >
                                    <FontAwesomeIcon
                                        icon={faInstagram}
                                        className="text-[24px] text-gray-900"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
