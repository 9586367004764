import { twMerge } from "tailwind-merge";

interface SignUpTitleProps {
    text: string,
    className?: string,
}

export function SignUpTitle({ text, className }: SignUpTitleProps) {
    return (
        <div className={twMerge(
            "w-full text-4xl font-bold text-neutral-800 ",
            className
        )}>
            {text}
        </div>
    );
}