import React from 'react';
import './style.scss';
import { twMerge } from 'tailwind-merge';


export default function BotaoArredondado({ className = '', classNameText = '', value, ativo = true, disabledTitle = '', disabledValue = '', ...rest }) {

    return (
        <button className={twMerge(`botao-arredondado`, className, !ativo && 'botao-inativo')} {...rest} disabled={!ativo} title={!ativo ? disabledTitle : ''}>
            <div className={twMerge("text", classNameText)}>{!ativo && disabledValue ? disabledValue : value}</div>
        </button>
    );
}