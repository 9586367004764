export const nacionalidadesMock = [
    { value: 0, label: "Afegão" },
    { value: 1, label: "Alemão" },
    { value: 2, label: "Albanês" },
    { value: 3, label: "Andorrano" },
    { value: 4, label: "Angolano" },
    { value: 5, label: "Antiguano e Barbudense" },
    { value: 6, label: "Argelino" },
    { value: 7, label: "Argentino" },
    { value: 8, label: "Armeno" },
    { value: 9, label: "Australiano" },
    { value: 10, label: "Austríaco" },
    { value: 11, label: "Azeri" },
    { value: 12, label: "Bahamense" },
    { value: 13, label: "Bahreinita" },
    { value: 14, label: "Bangladês" },
    { value: 15, label: "Barbadense" },
    { value: 16, label: "Belga" },
    { value: 17, label: "Belizenho" },
    { value: 18, label: "Beninense" },
    { value: 19, label: "Bielorrusso" },
    { value: 20, label: "Birmanês" },
    { value: 21, label: "Boliviano" },
    { value: 22, label: "Botsuano" },
    { value: 23, label: "Brasileiro" },
    { value: 24, label: "Bruneano" },
    { value: 25, label: "Búlgaro" },
    { value: 26, label: "Burquinense" },
    { value: 27, label: "Burundês" },
    { value: 28, label: "Butanês" },
    { value: 29, label: "Cabo-Verdiano" },
    { value: 30, label: "Camaronês" },
    { value: 31, label: "Cambojano" },
    { value: 32, label: "Canadense" },
    { value: 33, label: "Cazaque" },
    { value: 34, label: "Chadiano" },
    { value: 35, label: "Chileno" },
    { value: 36, label: "Chinês" },
    { value: 37, label: "Cipriota" },
    { value: 38, label: "Colombiano" },
    { value: 39, label: "Comorense" },
    { value: 40, label: "Congolês (República do Congo)" },
    { value: 41, label: "Congolês (República Democrática do Congo)" },
    { value: 42, label: "Costa-Marfinense" },
    { value: 43, label: "Costarriquenho" },
    { value: 44, label: "Croata" },
    { value: 45, label: "Cubano" },
    { value: 46, label: "Dinamarquês" },
    { value: 47, label: "Djiboutiano" },
    { value: 48, label: "Dominicano" },
    { value: 49, label: "Egípcio" },
    { value: 50, label: "Emiradense" },
    { value: 51, label: "Equatoriano" },
    { value: 52, label: "Eritreu" },
    { value: 53, label: "Escocês" },
    { value: 54, label: "Eslovaco" },
    { value: 55, label: "Esloveno" },
    { value: 56, label: "Espanhol" },
    { value: 57, label: "Estadunidense" },
    { value: 58, label: "Estoniano" },
    { value: 59, label: "Etíope" },
    { value: 60, label: "Fijiano" },
    { value: 61, label: "Filipino" },
    { value: 62, label: "Finlandês" },
    { value: 63, label: "Francês" },
    { value: 64, label: "Gabonense" },
    { value: 65, label: "Gambiano" },
    { value: 66, label: "Ganês" },
    { value: 67, label: "Georgiano" },
    { value: 68, label: "Granadino" },
    { value: 69, label: "Grego" },
    { value: 70, label: "Guatemalteco" },
    { value: 71, label: "Guianense" },
    { value: 72, label: "Guiné-Bissauense" },
    { value: 73, label: "Guineense" },
    { value: 74, label: "Guinéu Equatoriano" },
    { value: 75, label: "Haitiano" },
    { value: 76, label: "Holandês" },
    { value: 77, label: "Hondurenho" },
    { value: 78, label: "Húngaro" },
    { value: 79, label: "Iemenita" },
    { value: 80, label: "Indiano" },
    { value: 81, label: "Indonésio" },
    { value: 82, label: "Inglês" },
    { value: 83, label: "Iraquiano" },
    { value: 84, label: "Iraniano" },
    { value: 85, label: "Irlandês" },
    { value: 86, label: "Islandês" },
    { value: 87, label: "Israelense" },
    { value: 88, label: "Italiano" },
    { value: 89, label: "Jamaicano" },
    { value: 90, label: "Japonês" },
    { value: 91, label: "Jordaniano" },
    { value: 92, label: "Kiribatiano" },
    { value: 93, label: "Kuwaitiano" },
    { value: 94, label: "Laosiano" },
    { value: 95, label: "Lesotiano" },
    { value: 96, label: "Letão" },
    { value: 97, label: "Libanês" },
    { value: 98, label: "Liberiano" },
    { value: 99, label: "Líbio" },
    { value: 100, label: "Liechtensteinense" },
    { value: 101, label: "Lituano" },
    { value: 102, label: "Luxemburguês" },
    { value: 103, label: "Macedônio" },
    { value: 104, label: "Madagascarense" },
    { value: 105, label: "Malgaxe" },
    { value: 106, label: "Malaio" },
    { value: 107, label: "Maldivo" },
    { value: 108, label: "Maliano" },
    { value: 109, label: "Maltes" },
    { value: 110, label: "Marfinense" },
    { value: 111, label: "Marroquino" },
    { value: 112, label: "Marshallino" },
    { value: 113, label: "Mauriciano" },
    { value: 114, label: "Mauritano" },
    { value: 115, label: "Mexicano" },
    { value: 116, label: "Micronésio" },
    { value: 117, label: "Moçambicano" },
    { value: 118, label: "Moldávio" },
    { value: 119, label: "Monegasco" },
    { value: 120, label: "Mongol" },
    { value: 121, label: "Montenegrino" },
    { value: 122, label: "Namibiano" },
    { value: 123, label: "Nauruano" },
    { value: 124, label: "Nepalês" },
    { value: 125, label: "Neozelandês" },
    { value: 126, label: "Nicaraguense" },
    { value: 127, label: "Nigeriano" },
    { value: 128, label: "Nigerino" },
    { value: 129, label: "Norueguês" },
    { value: 130, label: "Omâni" },
    { value: 131, label: "Paquistanês" },
    { value: 132, label: "Palauense" },
    { value: 133, label: "Palestino" },
    { value: 134, label: "Panamenho" },
    { value: 135, label: "Papua-Nova Guineense" },
    { value: 136, label: "Paraguaio" },
    { value: 137, label: "Peruano" },
    { value: 138, label: "Polonês" },
    { value: 139, label: "Português" },
    { value: 140, label: "Qatarense" },
    { value: 141, label: "Queniano" },
    { value: 142, label: "Quirguiz" },
    { value: 143, label: "Romeno" },
    { value: 144, label: "Ruandês" },
    { value: 145, label: "Russo" },
    { value: 146, label: "Salomônico" },
    { value: 147, label: "Samoano" },
    { value: 148, label: "Santomense" },
    { value: 149, label: "Saudita" },
    { value: 150, label: "Senegalês" },
    { value: 151, label: "Sérvio" },
    { value: 152, label: "Seychellense" },
    { value: 153, label: "Singapurense" },
    { value: 154, label: "Sírio" },
    { value: 155, label: "Somaliano" },
    { value: 156, label: "Sri-Lankês" },
    { value: 157, label: "Sul-Africano" },
    { value: 158, label: "Sudanês" },
    { value: 159, label: "Sudanês do Sul" },
    { value: 160, label: "Sueco" },
    { value: 161, label: "Suíço" },
    { value: 162, label: "Surinamês" },
    { value: 163, label: "Tailandês" },
    { value: 164, label: "Tanzaniano" },
    { value: 165, label: "Timorense" },
    { value: 166, label: "Togolês" },
    { value: 167, label: "Tonguês" },
    { value: 168, label: "Trinidadiano" },
    { value: 169, label: "Tunisiano" },
    { value: 170, label: "Turcomeno" },
    { value: 171, label: "Turco" },
    { value: 172, label: "Tuvaluano" },
    { value: 173, label: "Ucraniano" },
    { value: 174, label: "Ugandense" },
    { value: 175, label: "Uruguaio" },
    { value: 176, label: "Uzbeque" },
    { value: 177, label: "Vanuatuense" },
    { value: 178, label: "Venezuelano" },
    { value: 179, label: "Vietnamita" },
    { value: 180, label: "Zambiano" },
    { value: 181, label: "Zimbabuano" }
  ];
  