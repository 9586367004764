import React, { useState } from 'react';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TelegramIcon, TelegramShareButton, TumblrIcon, TumblrShareButton, TwitterIcon, TwitterShareButton, VKIcon, VKShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import share from '../../../assets/img/share-small.png';
import { doLogin } from '../../../utils/Session';
import './style.scss';

const iconSize = 40;
export default function ShareButton({ className = '', logado, ...rest }) {
    const [showOptions, setShowOptions] = useState(false);


    const handleClick = () => {
        if (!logado) {
            doLogin();
        } else {

            setShowOptions(!showOptions)
        }

    }

    const message = 'Confira esse título, acesse o Assista Mais e confira esse mais milhares de filmes e séries disponíveis em cada plataforma. Veja lá!'
    return (
        <div className={`share-button ${className}`} >
            <img src={share} alt="share" onClick={handleClick} />
            {showOptions &&
                <div className='share-button-options'>
                    <FacebookShareButton
                        url={window.location.href} 
                        quote={message} > 
                        <FacebookIcon 
                            round 
                            size={40}   /> 
                    </FacebookShareButton>
                    <TwitterShareButton 
                        url={window.location.href}
                        title={message}>
                        <TwitterIcon 
                            round 
                            size={iconSize}/>
                    </TwitterShareButton>
                    <TelegramShareButton 
                        url={window.location.href}
                        title={message}>
                        <TelegramIcon 
                            round 
                            size={iconSize}/>
                    </TelegramShareButton>
                    <WhatsappShareButton 
                        url={window.location.href}
                        title={message}>
                        <WhatsappIcon 
                            round 
                            size={iconSize}/>
                    </WhatsappShareButton>
                    <LinkedinShareButton 
                        url={window.location.href}
                        title={message}>
                        <LinkedinIcon 
                            round 
                            size={iconSize}/></LinkedinShareButton>
                    <VKShareButton 
                        url={window.location.href}
                        title={message}>
                        <VKIcon 
                            round 
                            size={iconSize}/>
                    </VKShareButton>
                    <RedditShareButton 
                        url={window.location.href}
                        title={message}>
                        <RedditIcon 
                            round 
                            size={iconSize} 
                        />
                    </RedditShareButton>
                    <TumblrShareButton 
                        url={window.location.href}
                        title={message}>
                        <TumblrIcon 
                            round 
                            size={iconSize}/>
                    </TumblrShareButton>
                    <EmailShareButton 
                        url={window.location.href}
                        subject={message}>
                        <EmailIcon 
                            round 
                            size={iconSize}/>
                    </EmailShareButton>
                </div>
            }
        </div>
    );
}