import { ReactNode } from "react";

interface SignUpProps {
    children: ReactNode
}


export function SignUpRoot({ children }: SignUpProps) {
    return (
        <div className="flex flex-col self-stretch px-20 my-auto h-[860px] w-full text-xl bg-white rounded-[48px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
            {children}
        </div>
    )
}