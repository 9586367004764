import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getFavoritos, obterClienteResumo, obterExtrato, obterProdutos, obterFiltros, editarFotoPerfil, enviarSms, editarPerfil, obterPerfl, validarSms, apagarUsuario, obterGiftsResgatados, validaDadosEditarPerfil, enviarEmail, validarEmail } from '../../actions/assistamais/api';
import ExtratoDePontos from '../../componentes/organisms/ExtratoDePontos';
import './style.scss';
import 'react-loading-skeleton/dist/skeleton.css'
import { SessionContext } from '../../contexts/SessionContext';
import { ContaPerfilUsemais } from '../../componentes/organisms/ContaPerfilUsemais';
import { Box, Dialog, Divider, MenuItem, Modal, useMediaQuery } from '@mui/material';
import { ContaSaldoUsemais } from '../../componentes/organisms/ContaSaldoUsemais';
import { ListaGiftsResgatadosHorizontalUsemais } from '../../componentes/organisms/ListaGiftsResgatadosHorizontalUsemais';
import { ListaPlanosContratadosHorizontalUsemais } from '../../componentes/organisms/ListaPlanosContratadosHorizontalUsemais';
import { RodapeUsemais } from '../../componentes/organisms/RodapeUsemais';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowLeft, faCaretLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { logout, obterDados } from "../../utils/Session";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Typography, Avatar } from '@mui/material';

import { useSwipeable } from 'react-swipeable';
import ListaAvatarHorizontal from '../../componentes/organisms/ListaAvatarHorizontal';
import config from '../../config/default';
// import { useForm } from '../../hooks/useForm';
import { onlyNumbers } from '../../utils';
import { gravarDados } from '../../utils/Session';
import Input from '../../componentes/atoms/InputUsemais';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup"
import { Field, Form, Formik } from 'formik';
import { Select } from '../../componentes/atoms/Select';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';

import bgPerfilFundo from '../../assets/img/usemais/bg-perfil-fundo-mobile.png';
import BotaoArredondadoUsemais from '../../componentes/atoms/BotaoArredondadoUsemais';
import { useMessage } from '../../contexts/MessageContext';
import { maskCelular } from '../../utils/functions';

const AVATARS = [
    { src: '/usemais/avatar/avatar_1.png', alt: 'Avatar 1' },
    { src: '/usemais/avatar/avatar_2.png', alt: 'Avatar 2' },
    { src: '/usemais/avatar/avatar_3.png', alt: 'Avatar 3' },
    { src: '/usemais/avatar/avatar_4.png', alt: 'Avatar 4' },
    { src: '/usemais/avatar/avatar_5.png', alt: 'Avatar 5' },
    // { src: '/usemais/avatar/avatar_6.png', alt: 'Avatar 6' },
    { src: '/usemais/avatar/avatar_7.png', alt: 'Avatar 7' },
    { src: '/usemais/avatar/avatar_8.png', alt: 'Avatar 8' },
    { src: '/usemais/avatar/avatar_9.png', alt: 'Avatar 9' },
    { src: '/usemais/avatar/avatar_10.png', alt: 'Avatar 10' },
    { src: '/usemais/avatar/avatar_11.png', alt: 'Avatar 11' },
    { src: '/usemais/avatar/avatar_12.png', alt: 'Avatar 12' },
    { src: '/usemais/avatar/avatar_13.png', alt: 'Avatar 13' },
    { src: '/usemais/avatar/avatar_14.png', alt: 'Avatar 14' },
    // { src: '/usemais/avatar/avatar_15.png', alt: 'Avatar 15' },
    { src: '/usemais/avatar/avatar_16.png', alt: 'Avatar 16' },
    { src: '/usemais/avatar/avatar_17.png', alt: 'Avatar 17' },
    { src: '/usemais/avatar/avatar_18.png', alt: 'Avatar 18' },
    { src: '/usemais/avatar/avatar_19.png', alt: 'Avatar 19' },
    // { src: '/usemais/avatar/avatar_20.png', alt: 'Avatar 20' },
    { src: '/usemais/avatar/avatar_21.png', alt: 'Avatar 21' },
    { src: '/usemais/avatar/avatar_22.png', alt: 'Avatar 22' },
    { src: '/usemais/avatar/avatar_23.png', alt: 'Avatar 23' },
    { src: '/usemais/avatar/avatar_24.png', alt: 'Avatar 24' },
    { src: '/usemais/avatar/avatar_25.png', alt: 'Avatar 25' },
    { src: '/usemais/avatar/avatar_26.png', alt: 'Avatar 26' },
    { src: '/usemais/avatar/avatar_27.png', alt: 'Avatar 27' },
    // { src: '/usemais/avatar/avatar_28.png', alt: 'Avatar 28' },
    { src: '/usemais/avatar/avatar_29.png', alt: 'Avatar 29' },
    { src: '/usemais/avatar/avatar_30.png', alt: 'Avatar 30' },
    { src: '/usemais/avatar/avatar_31.png', alt: 'Avatar 31' },
    { src: '/usemais/avatar/avatar_32.png', alt: 'Avatar 32' },
    { src: '/usemais/avatar/avatar_33.png', alt: 'Avatar 33' },
    { src: '/usemais/avatar/avatar_34.png', alt: 'Avatar 34' },
    { src: '/usemais/avatar/avatar_35.png', alt: 'Avatar 35' },
    { src: '/usemais/avatar/avatar_36.png', alt: 'Avatar 36' },
    { src: '/usemais/avatar/avatar_37.png', alt: 'Avatar 37' },
    { src: '/usemais/avatar/avatar_38.png', alt: 'Avatar 38' },
    { src: '/usemais/avatar/avatar_39.png', alt: 'Avatar 39' },
    { src: '/usemais/avatar/avatar_40.png', alt: 'Avatar 40' },
    { src: '/usemais/avatar/avatar_41.png', alt: 'Avatar 41' },
    { src: '/usemais/avatar/avatar_42.png', alt: 'Avatar 42' },
    { src: '/usemais/avatar/avatar_43.png', alt: 'Avatar 43' },
    { src: '/usemais/avatar/avatar_44.png', alt: 'Avatar 44' },
    { src: '/usemais/avatar/avatar_45.png', alt: 'Avatar 45' },
    { src: '/usemais/avatar/avatar_46.png', alt: 'Avatar 46' },
];

export default function EditarPerfilUsemais() {
    const [perfil, setPerfil] = useState([]);
    const [extrato, setExtrato] = useState();
    const [avatarP, setAvatarP] = useState();
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);

    const isMobile = useMediaQuery('(max-width:768px)');

    const [dadosPerfil, setDadosPerfil] = useState({
        name: '',
        cpf: '',
        email: '',
        celular: '',
        nascimento: '',
        genero: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        cep: '',
        uf: '',
    });

    const [showSms, setShowSms] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [validaSms, setValidaSms] = useState(false);
    const [validaEmail, setValidaEmail] = useState(false);
    const [validaDados, setValidaDados] = useState(false);
    const [celularValidacao, setCelularValidacao] = useState("");
    const [emailValidacao, setEmailValidacao] = useState("");
    const [codigo, setCodigo] = useState("");
    const [dataToSubmit, setDataToSubmit] = useState(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const extratoDePontosRef = useRef();

    const [showConfirm, setShowConfirm] = useState(false);
    const [showRequestSend, setShowRequestSend] = useState(false);

    const [totalResgatado, setTotalResgatado] = useState(0);

    let history = useHistory();

    const [session, setSession, logado, systemData, getSessionData] = useContext(SessionContext);

    const [ showMessage, showError ] = useMessage();

    useEffect(() => {
        getSessionData();
    },[]);

    useEffect(() => {
        async function fetchData() {

            //            const { genres } = (await getGenresMovieList()).payload.data;

            // getFavoritos().then(result => {
            //     // TODO criar função para usar em todos

            //     if (!result?.payload?.status || Math.trunc(result?.payload?.status / 100) !== 2) return;


            //     let { data } = result?.payload;

            //     setFavoritos(data.map(f => {
            //         let pathname = '/filme';
            //         if (f.tipo.toLowerCase() === 'tv') {
            //             pathname = 'serie';
            //         }
            //         const linkDetalhe = `${pathname}/?id=${f.tituloId}&type=${f.tipo}`;
            //         const { genres, backdrop_path, poster_path, title, overview } = f.data

            //         return {
            //             ...f,
            //             genres,
            //             backdrop_path,
            //             poster_path,
            //             title,
            //             overview,
            //             linkDetalhe: linkDetalhe
            //         }
            //     }).reverse());

            // });

            fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
                .then((res) => res.json())
                .then((data) => {
                    const estadosOptions = data.map((estado) => ({
                        label: estado.nome,
                        value: estado.sigla,
                    }));
                    setEstados(estadosOptions);
                })
                .catch((error) => console.error("Erro ao carregar estados:", error));

            obterClienteResumo().then(result => {
                setPerfil(result?.payload?.data);
            })

            obterGiftsResgatados().then(result => {
                if (result?.payload?.data?.resgates) {
                    const totalResgatadoCount = result?.payload?.data?.resgates.reduce((acc, resgates) => {
                        return acc += Number(resgates.objectData.bonificacao ?? 0);
                    }, 0);
                    setTotalResgatado(totalResgatadoCount ?? 0);
                }
            })

            obterPerfl().then(result => {
                if (result?.payload?.data) {
                    if (result?.payload?.data?.extra?.endereco?.uf){
                        fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${result?.payload?.data?.extra?.endereco?.uf}/municipios`)
                        .then((res) => res.json())
                        .then((data) => {
                            const cidadesOptions = data.map((cidade) => ({
                                label: cidade.nome,
                                value: cidade.nome,
                            }));
                            setCidades(cidadesOptions);
                            setFieldValue('cidade', ''); // Limpa o campo cidade ao mudar o estado
                        })
                        .catch((error) => console.error("Erro ao carregar cidades:", error));
                    }
                    setDadosPerfil({
                        name: result?.payload?.data?.name ?? "",
                        cpf: result?.payload?.data?.extra?.cpf ?? "",
                        celular: result?.payload?.data?.extra?.celular ?? "",
                        email: result?.payload?.data?.email ?? "",
                        nascimento: result?.payload?.data?.extra?.nascimento ? moment(result?.payload?.data?.extra?.nascimento).format("YYYY-MM-DD") : "",
                        genero: result?.payload?.data?.extra?.genero ?? "",
                        logradouro: result?.payload?.data?.extra?.endereco?.logradouro ?? "",
                        numero: result?.payload?.data?.extra?.endereco?.numero ?? "",
                        bairro: result?.payload?.data?.extra?.endereco?.bairro ?? "",
                        cidade: result?.payload?.data?.extra?.endereco?.cidade ?? "",
                        uf: result?.payload?.data?.extra?.endereco?.uf ?? "",
                        cep: result?.payload?.data?.extra?.endereco?.cep ?? "",
                    })
                    if (result?.payload?.data?.extra?.avatar) {
                        setAvatarP(result?.payload?.data?.extra?.avatar);
                    }
                    
                }
            })

            // obterProdutos().then(result => {
            //     setProdutos(result?.payload?.data?.results);
            // });

            // obterFiltros().then(result => {
            //     setFiltros({
            //         pontos: result?.payload?.data.pontos, 
            //         categorias: result?.payload?.data.categorias
            //     });
            // });           

            obterExtrato().then(result => {
                setExtrato(result?.payload?.data);
            });
        }
        fetchData()
    }, []);

    const handleExtratoPontoClick = () => {
        extratoDePontosRef.current.open();
    }

    const sizeScreen = {
        xs: useMediaQuery('(max-width: 400px)'),
        sm: useMediaQuery('(max-width: 600px)'),
        md: useMediaQuery('(max-width: 960px)'),
        lg: useMediaQuery('(max-width: 1280px)'),
        xl: useMediaQuery('(max-width: 1320px)'),
        '2xl': useMediaQuery('(max-width: 1400px)'),
        '3xl': useMediaQuery('(max-width: 1700px)'),
    }

    const [currentIndex, setCurrentIndex] = useState(0);

    // Número de avatares visíveis por vez
    const itemsToShow = 
        sizeScreen.md ? 4 : 
        sizeScreen.lg ? 6 : 
        sizeScreen['2xl'] ? 7 : 
        sizeScreen['3xl'] ? 8 : 
        10;

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? 0 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + itemsToShow >= AVATARS.length - itemsToShow ? prevIndex : prevIndex + 1
        );
    };

    // Configuração do swipe com react-swipeable
    const handlers = useSwipeable({
        onSwipedLeft: handleNext,    // Deslizar para a esquerda exibe o próximo
        onSwipedRight: handlePrev,   // Deslizar para a direita exibe o anterior
        preventDefaultTouchmoveEvent: true,
        trackMouse: true // Permite arrastar com o mouse também
    });

    const validateCPF = (cpf) => {
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf.length !== 11 || /^(\d)\1*$/.test(cpf)) return false;

        let sum = 0;
        let remainder;
        for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf.substring(9, 10))) return false;

        sum = 0;
        for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        return remainder === parseInt(cpf.substring(10, 11));
    };

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required('Preencha o nome')
            .test('nome-completo', 'Preencha o nome completo', (value) => {
                const names = value?.trim().split(' ') || [];
                return names.length >= 2 && names[0].length > 0 && names[1].length > 0;
            }),
        cpf: yup
            .string()
            .required('Preencha o CPF')
            .test('valid-cpf', 'Preencha com um CPF válido', validateCPF),
        email: yup
            .string()
            .email('Preencha com um e-mail válido')
            .required('Preencha o e-mail'),
        celular: yup
            .string()
            .required('Preencha o celular')
            .test('valid-celular', 'Preencha com um telefone válido', (value) => value && value.replace(/\D/g, '').length === 11),
        nascimento: yup
            .date()
            .required('Preencha a data de nascimento'),
        genero: yup
            .string()
            .required('Selecione o genero'),
        logradouro: yup
            .string()
            .required('Preencha o logradouro'),
        numero: yup
            .string()
            .required('Preencha o número'),
        bairro: yup
            .string()
            .required('Preencha o bairro'),
        cidade: yup
            .string()
            .required('Selecione a cidade'),
        cep: yup
            .string()
            .required('Preencha o cep')
            .matches(/^\d{5}-?\d{3}$/, 'CEP inválido'),
        uf: yup
            .string()
            .required('Selecione o estado')
            .length(2, 'UF deve ter 2 letras'),
    });

    const onSubmit = async (data) => {
        setIsSubmitting(true);

        const body = data ? {
            name: data.name,
            email: data.email,
            extra: {
                cpf: data.cpf,
                genero: data.genero,
                nascimento: data.nascimento,
                celular: onlyNumbers(data.celular),
                autorizaReceberEmail: data.autorizaReceberEmail,
                avatar: avatarP,
                endereco: {
                    logradouro: data.logradouro,
                    bairro: data.bairro,
                    cidade: data.cidade,
                    cep: onlyNumbers(data.cep),
                    uf: data.uf,
                    numero: data.numero
                },
                status: "COMPLETO"
            }
        }: dataToSubmit;
        let isChangedEmail = dadosPerfil.email != (data? data.email: body.email);
        let isChangedCelular = dadosPerfil.celular != onlyNumbers(data? data.celular: body.extra.celular);
        console.log(isChangedEmail, isChangedCelular)
        if ((!validaSms && isChangedCelular) || (!validaEmail && isChangedEmail)) {

            setDataToSubmit(body);

            const response = await validaDadosEditarPerfil({
                email: body.email,
                celular: body.extra.celular,
                cpf: body.extra.cpf,
            });

            if (response.payload.status === 200) {
                if (!validaSms && isChangedCelular) {
                    setCelularValidacao(body.extra.celular)
                    setShowSms(true); 
    
                    enviarSms({
                        telefone: onlyNumbers(body.extra.celular),
                        cpf: body.extra.cpf
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    setEmailValidacao(body.email)
                    setShowEmail(true); 
    
                    enviarEmail({
                        email: body.email
                    }).catch(err => {
                        console.log(err);
                    });
                }
                

                 
                // await enviarSms({cpf: data.cpf, telefone: onlyNumbers(data.celular) }).then(result => {
                //     if (result?.payload?.status * 1 < 200 || result?.payload?.status * 1 > 299) {
                //         setCelularValidacao(data.celular)
                //         setShowSms(true);                     
                //     } else {
                //         setCelularValidacao(data.celular)
                //         setShowSms(true);
                //     }
                // });
            } else if (response.payload.status === 412) {
                showMessage('error', response.payload?.data?.message ?? "Dados inválidos", 3000)
            } else {
                showMessage('error', "Erro ao realizar a validação dos dados", 3000)
            }

        } else {
            await editarPerfil(dataToSubmit || body).then(resultado => {
                if (Math.trunc(resultado?.payload?.status / 100) === 2) { // 2xx => Resultado ok
                    showMessage('success', "Perfil atualizado com sucesso", 3000)
                    
                    let session = obterDados();
                    gravarDados({ 
                        ...session, 
                        foto: resultado?.payload?.data?.cliente?.avatar, 
                        celular: hideCelNumber(resultado?.payload?.data?.cliente?.celular), 
                        cpf: hideCpf(resultado?.payload?.data?.cliente?.cpf), 
                        status: resultado?.payload?.data?.cliente?.status
                    });

                    setTimeout(() => window.location.href = '/minha-conta', 2000);
                } else {
                    showMessage('error', resultado.payload.data.message, 3000)
                    setValidaSms(false);
                }
            });
        }

        setIsSubmitting(false);
    };

    const hideCelNumber = (cel) => {
        return '(xx) xxxxx-xx' + cel.slice(-2);
      }
      
      const hideCpf = (cel) => {
        return 'xxx.xxx.xx' + cel.charAt(cel.length - 3) + '-' + cel.slice(-2);
      }

    // Função para buscar os dados do CEP
    const fetchAddressByCep = async (cep, setFieldValue) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();
            
            if (data.erro) {
                showMessage('warn', "CEP não encontrado", 3000)
            } else {
                // Define os valores dos campos de endereço com os dados da API
                if (setFieldValue){
                    setFieldValue('logradouro', data.logradouro);
                    setFieldValue('bairro', data.bairro);
    
                }
                
                // Encontra o estado correspondente no select
                const estadoCorrespondente = estados.find(estado => estado.value === data.uf);
                if (estadoCorrespondente) {
                    if (setFieldValue)setFieldValue('uf', estadoCorrespondente.value);
                    
                    // Carrega as cidades para o estado
                    const cidadesResponse = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data.uf}/municipios`);
                    const cidadesData = await cidadesResponse.json();
                    const cidadesOptions = cidadesData.map((cidade) => ({
                        label: cidade.nome,
                        value: cidade.nome,
                    }));
                    setCidades(cidadesOptions);

                    // Encontra a cidade correspondente no select
                    const cidadeCorrespondente = cidadesOptions.find(cidade => cidade.value === data.localidade);
                    if (cidadeCorrespondente) {
                        if (setFieldValue) setFieldValue('cidade', cidadeCorrespondente.value);
                    }
                }
            }
        } catch (error) {
            console.error("Erro ao buscar o CEP:", error);
            showMessage('error', "Erro ao buscar o CEP", 3000)
        }
    };

    const handleEstadoChange = (e, setFieldValue) => {
        const estadoSelecionado = e.target.value;
        setFieldValue('uf', estadoSelecionado);
        
        // Carregar cidades para o estado selecionado
        fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoSelecionado}/municipios`)
            .then((res) => res.json())
            .then((data) => {
                const cidadesOptions = data.map((cidade) => ({
                    label: cidade.nome,
                    value: cidade.nome,
                }));
                setCidades(cidadesOptions);
                setFieldValue('cidade', ''); // Limpa o campo cidade ao mudar o estado
            })
            .catch((error) => console.error("Erro ao carregar cidades:", error));
    };

    const onAvatarSelect = async (e) => {
        if (e) {
            console.log(e);
            setAvatarP(e.src);
            // try {
                // const response = await editarFotoPerfil(e)

                // if (response.payload.status == 200) {

                    // const urlFoto = `${config.staticURL}avatar/${e.name}`;
                    // const dadosLocalStorage = obterDados();
        
                    // const newDadosLocalStorage = {
                    //     ...dadosLocalStorage,
                    //     foto: {
                    //         mimetype: e.type,
                    //         originalname: e.name
                    //     },
                    //     perfil: {
                    //         ...dadosLocalStorage.perfil,
                    //         foto: urlFoto
                    //     }
                    // };
        
                    // gravarDados(newDadosLocalStorage);
        
                    // setSession({
                    //     ...session,
                    //     perfil: {
                    //         ...session.perfil,
                    //         foto: urlFoto,
                    //     }
                    // })
                // }

            // } catch (e) {
            //     console.error(e);
            // }
            

        }
    }

    useEffect(() => {
        if (validaSms || validaEmail) {
            onSubmit(false)
        }
    }, [validaSms, validaEmail]);

    const handleValidarClick = (codigo) => {
        if (!codigo) {
            showMessage('warn', 'Código inválido', 3000);
            return;
        }
        validarSms({codigo: codigo, telefone: celularValidacao }).then(result => {
            if (result?.payload?.status * 1 < 200 || result?.payload?.status * 1 > 299) {
                showMessage('warn', result?.payload?.data.message, 3000)
            } else {
                setShowSms(false);
                setValidaSms(true);
                setCodigo("")
            }
        }); 
    }

    const handleValidarEmail = (codigo) => {
        if (!codigo) {
            showMessage('warn', 'Código inválido', 3000);
            return;
        }
        validarEmail({codigo: codigo, email: emailValidacao }).then(result => {
            if (result?.payload?.status * 1 < 200 || result?.payload?.status * 1 > 299) {
                showMessage('warn', result?.payload?.data.message, 3000)
            } else {
                setShowEmail(false);
                setValidaEmail(true);
                setCodigo("")
            }
        }); 
    }

    const PERFIL_VALUES = [
        {
            label: 'Nome',
            name: 'name',
            type: 'text',
        },
        {
            label: 'CPF',
            name: 'cpf',
            type: 'text',
        },
        {
            label: 'E-mail',
            name: 'email',
            type: 'email',
        },
        {
            label: 'Celular',
            name: 'celular',
            type: 'text',
        },
        {
            label: 'Data de nascimento',
            name: 'nascimento',
            type: 'date',
        },
        {
            label: 'Gênero',
            name: 'genero',
            type: 'select',
            options: [
                { value: "M", label: "Masculino" },
                { value: "F", label: "Feminino" },
                { value: "NA", label: "Prefiro não informar" },
            ]
        },
    ];

    const ENDERECO_VALUES = [
        {
            label: 'CEP',
            name: 'cep',
            type: 'text',
        },
        {
            label: 'Rua',
            name: 'logradouro',
            type: 'text',
        },
        {
            label: 'Número',
            name: 'numero',
            type: 'text',
        },
        {
            label: 'Bairro',
            name: 'bairro',
            type: 'text',
        },
        {
            label: 'Cidade',
            name: 'cidade',
            type: 'select',
            options: cidades
        },
        {
            label: 'Estado',
            name: 'uf',
            type: 'select',
            options: estados
        },
    ];

    const handleCancelarConta = async () => {
        setShowConfirm(true)
    }

    const cancelarConta = async () => {
        try {
            const retorno = await apagarUsuario();

            if (retorno?.payload?.status !== 201) {
                throw new Error(retorno?.payload?.data?.message ?? "Não foi possível realizar o cancelamento da conta.")
            }

            setShowConfirm(false)
            setShowRequestSend(true)

        } catch (e) {
            showError(e.message)
        }
    }

    return (
        <>
            <div className="w-full md:w-10/12 mx-auto md:my-20 md:mt-32 lg:mt-60">

                <div className={twMerge(
                    "gap-10",
                    "md:grid md:grid-cols-8 md:grid-rows-1"
                )}>

                    <div
                        style={{
                            // backgroundImage: `url(${bgPerfilFundo})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPositionX: 'center',
                        }}
                        className='w-full h-[226px] flex md:hidden'
                    >
                    </div>

                    <div className='-mt-[80px] md:mt-0 md:row-span-2 md:col-span-3 flex flex-col gap-4 md:gap-8 w-full md:max-w-[440px]'>
                        <ContaPerfilUsemais 
                            perfil={perfil}
                            editar={true}
                        />
                        
                        <div className='w-full flex justify-center md:justify-start px-8 md:px-0'>
                            <Divider 
                                sx={{ bgcolor: "#ED636E" }}
                                className='w-full'
                            />
                        </div>

                        <ContaSaldoUsemais 
                            pontos={perfil?.pontos} 
                            extratoPontoClick={handleExtratoPontoClick}
                            editar={true}
                            totalResgatado={totalResgatado}
                            cancelarConta={handleCancelarConta}
                        />

                        <ExtratoDePontos 
                            ref={extratoDePontosRef} 
                            saldo={perfil?.pontos}
                            pontos={extrato} 
                        />
                    </div>

                    <Box className='px-4 md:col-span-5 flex flex-col gap-[40px]'>

                        {!isMobile && (
                            <div className='flex justify-between items-center'>
                                <h2 className='text-[#151A25] text-[24px] font-medium'>
                                    Avatar
                                </h2>
                                <div className='flex flex-col items-center gap-[4px]'>
                                    <button 
                                        className='bg-[#151A25] rounded-full w-[50px] h-[50px] hover:opacity-80'
                                        onClick={() => history.goBack()}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                                    <span className='text-[14px] text-[#151A25] font-semibold'>
                                        Voltar
                                    </span>
                                </div>
                            </div>
                        )}

                        <div className='w-full bg-white md:border border-[#BFBFBF] rounded-[24px] flex flex-col p-[10px] gap-[10px]'>
                            <span className='w-full text-center text-[#151A25] text-[12px] md:text-[16px] font-medium'>
                                Escolha uma foto de perfil ou selecione um avatar.
                            </span>
                            <div className='relative'>
                                <Box className="flex items-center justify-center gap-[6px]"  {...handlers}>

                                    <button 
                                        className='bg-black rounded-full min-w-[24px] min-h-[24px] md:min-w-[50px] md:min-h-[50px] opacity-40 hover:opacity-20 absolute left-0 md:relative'
                                        onClick={handlePrev}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} className='text-[12px] md:text-[16px]' />
                                    </button>
                                    
                                    <Box className="flex gap-[6px] overflow-hidden p-2">
                                        {AVATARS.slice(currentIndex, currentIndex + itemsToShow).map((avatar, index) => (
                                            <img 
                                                key={index}
                                                src={avatar.src} 
                                                alt={avatar.alt} 
                                                className={
                                                    twMerge("w-[70px] h-[70px] cursor-pointer hover:ring-2 hover:ring-blue-500 rounded-full",
                                                        avatar.src == avatarP ? 'ring-2 ring-blue-500' : ''
                                                )}
                                                onClick={() => onAvatarSelect(avatar)}
                                            />
                                        ))}
                                    </Box>

                                    <button 
                                        className='bg-black rounded-full min-w-[24px] min-h-[24px] md:min-w-[50px] md:min-h-[50px] opacity-40 hover:opacity-20 absolute right-0 md:relative'
                                        onClick={handleNext}
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} className='text-[12px] md:text-[16px]' />
                                    </button>
                                </Box>
                            </div>
                        </div>

                        <Formik
                            initialValues={dadosPerfil}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize
                        >
                            {({ 
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setValues,
                                setFieldValue,
                                 
                            }) => (
                                <Form>
                                    <div className='flex flex-col md:flex-row justify-between gap-[40px] md:gap-[100px]'>
                                        <div className='flex flex-col w-full'>
                                            <h2 className='text-[#151A25] text-[24px] mb-[20px]'>
                                                Perfil
                                            </h2>
                                            <div className='flex flex-col w-full'>
                                                {PERFIL_VALUES.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {item.type === 'select' ? (
                                                            <Select
                                                                label={item.label}
                                                                name={item.name}
                                                                value={values[item.name]}
                                                                onChange={handleChange}
                                                                options={item.options}
                                                                error={errors[item.name] && touched[item.name] && errors[item.name]}
                                                            />
                                                        ) : (
                                                            <Input
                                                                label={item.label}
                                                                name={item.name}
                                                                error={errors[item.name] && touched[item.name] && errors[item.name]}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values[item.name]}
                                                                type={item.type}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <h2 className='text-[#151A25] text-[24px] mb-[20px]'>
                                                Endereço
                                            </h2>
                                            <div className='flex flex-col w-full'>
                                                {ENDERECO_VALUES.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {item.type === 'select' ? (
                                                            <Select
                                                                label={item.label}
                                                                name={item.name}
                                                                value={values[item.name]}
                                                                onChange={(e) => {
                                                                    if (item.name == 'uf') {
                                                                        handleEstadoChange(e, setFieldValue)
                                                                    } else {
                                                                        handleChange(e)
                                                                    }
                                                                }}
                                                                options={item.options}
                                                                error={errors[item.name] && touched[item.name] && errors[item.name]}
                                                            />
                                                        ) : (
                                                            <Input
                                                                label={item.label}
                                                                name={item.name}
                                                                error={errors[item.name] && touched[item.name] && errors[item.name]}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                    if (item.name == 'cep') {
                                                                        const cep = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
                                                                        if (cep.length === 8) {
                                                                            fetchAddressByCep(cep, setFieldValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values[item.name]}
                                                                type={item.type}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className='flex justify-center mt-[20px]'>
                                        <button 
                                            type='submit' 
                                            className='
                                                w-7/12 md:w-full max-w-[350px] bg-gradient-to-r from-[#E13441] to-[#FAAE2E] 
                                                text-white text-[16px] h-[32px] md:h-[40px] rounded-full
                                                hover:opacity-90 disabled:bg-slate-400
                                            '
                                            disabled={isSubmitting}
                                        >
                                            <span>{isSubmitting ? "Salvando..." : "Salvar"}</span>
                                        </button>
                                    </div>

                                </Form>
                            )}

                        </Formik>

                        {isMobile && (
                            <div className='w-full flex justify-center'>
                                <BotaoArredondadoUsemais 
                                    className="w-7/12 md:w-full max-w-[350px] h-[32px] md:h-[40px] bg-[#8A8A8A] hover:bg-[#5e5e5e]" 
                                    value={"Cancelar minha conta"} 
                                    classNameText="text-[14px] md:text-sm" 
                                    onClick={handleCancelarConta} 
                                />
                            </div>
                        )}

                    </Box>

                    
                </div>
            </div>

            {showSms &&
                <>
                    <Modal
                        open={showSms}
                        onClose={() => {
                            setShowSms(false)
                            setIsSubmitting(false)
                        }}
                        className='flex justify-center items-center'
                    >
                        <Box className="bg-[#FFF] py-[40px] px-[30px]  md:p-[60px] mx-4 min-w-10/12 md:max-w-[600px] flex flex-col gap-[40px] rounded-[60px]">
                            <div className='w-full flex flex-col items-center gap-[10px] text-[#151A25]'>
                                <h2 className='text-[32px] font-bold text-center'>
                                    Validação Código!
                                </h2>
                                <span className='md:text-[20px] font-medium text-center'>
                                    Para atualizar o perfil digíte o código Use Mais que foi enviado por sms para o número {maskCelular(celularValidacao)}.
                                </span>
                            </div>
                            <div className='w-full flex flex-col items-start gap-[4px]'>
                                <input 
                                    type="text"
                                    className='
                                        w-full bg-white border border-[#ED636E]
                                        text-[16px] p-[10px] rounded-[8px] text-[#3D3D3D]
                                    '
                                    placeholder='Digite o código SMS'
                                    onChange={e => {
                                        setCodigo(e.target.value)
                                    }}
                                    value={codigo}
                                />
                                {/* <button 
                                    className='text-[#ED636E] text-[14px] underline font-semibold hover:font-bold'
                                    onClick={() => {
                                        handleResgateConfirm()
                                        toast.success("Código reenviado via SMS!")
                                    }}
                                >
                                    Não recebi o SMS
                                </button> */}
                            </div>
                            <div className='w-full flex flex-col gap-[10px]'>
                                <button 
                                    onClick={() => handleValidarClick(codigo)}
                                    className='
                                        w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                        text-white text-[16px] py-[10px] rounded-[8px]
                                        hover:opacity-90
                                    '
                                >
                                    <span>Validar</span>
                                </button>
                                <button 
                                    onClick={() => {
                                        setShowSms(false)
                                        setIsSubmitting(false)
                                        setCodigo("")
                                    }}
                                    className='
                                        w-full bg-white border border-[#ED636E] 
                                        text-[#ED636E] text-[16px] py-[10px] rounded-[8px]
                                        hover:font-medium
                                    '
                                >
                                    <span>Cancelar</span>
                                </button>
                            </div>
                        </Box>
                    </Modal>
                </>
            }

            {showEmail &&
                <>
                    <Modal
                        open={showEmail}
                        onClose={() => {
                            setShowEmail(false)
                            setIsSubmitting(false)
                        }}
                        className='flex justify-center items-center'
                    >
                        <Box className="bg-[#FFF] py-[40px] px-[30px]  md:p-[60px] mx-4 min-w-10/12 md:max-w-[600px] flex flex-col gap-[40px] rounded-[60px]">
                            <div className='w-full flex flex-col items-center gap-[10px] text-[#151A25]'>
                                <h2 className='text-[32px] font-bold text-center'>
                                    Validação Código!
                                </h2>
                                <span className='md:text-[20px] font-medium text-center'>
                                    Para atualizar o perfil digíte o código Use Mais que foi enviado por email para o {emailValidacao}.
                                </span>
                            </div>
                            <div className='w-full flex flex-col items-start gap-[4px]'>
                                <input 
                                    type="text"
                                    className='
                                        w-full bg-white border border-[#ED636E]
                                        text-[16px] p-[10px] rounded-[8px] text-[#3D3D3D]
                                    '
                                    placeholder='Digite o código SMS'
                                    onChange={e => {
                                        setCodigo(e.target.value)
                                    }}
                                    value={codigo}
                                />
                                {/* <button 
                                    className='text-[#ED636E] text-[14px] underline font-semibold hover:font-bold'
                                    onClick={() => {
                                        handleResgateConfirm()
                                        toast.success("Código reenviado via SMS!")
                                    }}
                                >
                                    Não recebi o SMS
                                </button> */}
                            </div>
                            <div className='w-full flex flex-col gap-[10px]'>
                                <button 
                                    onClick={() => handleValidarEmail(codigo)}
                                    className='
                                        w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                        text-white text-[16px] py-[10px] rounded-[8px]
                                        hover:opacity-90
                                    '
                                >
                                    <span>Validar</span>
                                </button>
                                <button 
                                    onClick={() => {
                                        setShowEmail(false)
                                        setIsSubmitting(false)
                                        setCodigo("")
                                    }}
                                    className='
                                        w-full bg-white border border-[#ED636E] 
                                        text-[#ED636E] text-[16px] py-[10px] rounded-[8px]
                                        hover:font-medium
                                    '
                                >
                                    <span>Cancelar</span>
                                </button>
                            </div>
                        </Box>
                    </Modal>
                </>
            }

            {showConfirm &&
                <Modal
                    open={showConfirm}
                    onClose={() => setShowConfirm(false)}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] px-[20px] md:px-[60px] py-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold text-center'>
                                Deseja cancelar sua conta? {":("}
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium text-center'>
                                Tem certeza que deseja solicitar o cancelamento da sua conta Use Mais?
                            </span>
                        </div>
                        <div className='w-full flex gap-[10px]'>
                            <button 
                                onClick={() => {
                                    setShowConfirm(false)
                                }}
                                className='
                                    w-full bg-white border border-[#ED636E] 
                                    text-[#ED636E] text-[16px] h-[40px] rounded-[8px]
                                    hover:font-medium
                                '
                            >
                                <span>Voltar</span>
                            </button>
                            <button 
                                onClick={cancelarConta} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] h-[40px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>Sim</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }

            {showRequestSend &&
                <Modal
                    open={showRequestSend}
                    onClose={() => {
                        setShowRequestSend(false)
                        history.replace('/minha-conta')
                    }}
                    className='flex items-center justify-center px-2'
                >
                    <div className="bg-[#FFF] px-[20px] md:px-[60px] py-[60px] w-full md:max-w-[450px] flex flex-col gap-[40px] rounded-[48px]">
                        <div className='w-full flex flex-col items-center gap-[10px]'>
                            <h2 className='text-[28px] md:text-[32px] text-[#151A25] font-bold text-center'>
                                Solicitação de cancelamento enviada
                            </h2>
                            <span className='text-[16px] md:text-[20px] text-[#151A25] font-medium text-center'>
                                Sua solicitação de cancelamento da conta foi enviada para aprovação Use Mais
                            </span>
                        </div>
                        <div className='w-full flex'>
                            <button 
                                onClick={() => {
                                    setShowRequestSend(false)
                                    history.replace('/minha-conta')
                                }} 
                                className='
                                    w-full bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                    text-white text-[16px] h-[40px] rounded-[8px]
                                    hover:opacity-90
                                '
                            >
                                <span>OK</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            }

            <RodapeUsemais 
                className="md:pb-8 mt-[32px] md:mt-0"
            />
        </>
    );
}
