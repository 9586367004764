import React, { useState } from "react";
import "./style.scss";
import "react-toastify/dist/ReactToastify.css";
import { checkExistingUser } from "../../../../actions/services/userService";
import { toast } from "react-toastify";
// import { useRouter } from "next/navigation"
import { SignIn } from ".";
import { useHistory } from "react-router-dom";
import { Modal, TextField, useMediaQuery } from "@mui/material";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

interface userProps {
  setCheckedUser: any;
  setExistUser: any;
  signIn: any;
  isLoading: boolean;
  handleRedirectSenha: any;
  type: string;
}

export default function Index({
  setExistUser,
  setCheckedUser,
  signIn,
  handleRedirectSenha,
  isLoading,
  type = "login",
}: userProps) {
  const history = useHistory();
  
  const [loginPassword, setLoginPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [cpf, setCpf] = useState("");
  
  const [showPopupMigracao, setShowPopupMigracao] = useState(false);

  const isMobile = useMediaQuery('(max-width:768px)');

  const handleSocialId = (event: any) => {
    let value = event.target.value.replace(/\D/g, "");

    if (value.length >= 11 && value.length <= 14) {
      const masked = value.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );
      setCpf(masked);
    }

    if (value.length < 11) {
      setCpf(value);
    }
  };

  const goToCadastro = () => {
    history.push('/cadastro');
  };

  const goToHome = () => {
    history.goBack()
  };

  const fieldValidation = (field: string, value: string) => {
    if (!value) return true;
  };

  return (
    <div className="flex flex-col h-full md:h-auto md:mx-auto md:w-full md:ml-0 w-full md:flex-row md:justify-center md:p-[40px] lg:p-[80px]">
      <div className="md:border border-[#151A25] md:px-5 py-[40px] md:my-auto h-full md:h-full md:max-h-[860px] w-full text-xl bg-white md:shadow-lg md:rounded-[48px] max-md:max-w-full xl:max-w-[496px] 2xl:max-w-[596px]">
        <SignIn.Root
          className="flex flex-col items-center justify-between py-[40px] h-full max-md:mt-0 max-md:px-[20px] max-lg:px-2 flex-nowrap"
        >
          {isMobile && (
            <img 
              src="/usemais/logo_use_mais.png" 
              alt="Logo"
              className=" w-[160px] cursor-pointer mb-0 flex"
            />
          )}

          <div className="self-start flex flex-col w-full">
            {type == "login" ? (
              <SignIn.Title
                text={"Login"}
                className="md:mt-3 max-md:max-w-full text-[28px] md:text-[32px] lg:text-[36px] "
              />
            ) : (
              <SignIn.Title
                text={"Cadastro Use Mais"}
                className="md:mt-3 max-md:max-w-full text-[28px] md:text-[32px] lg:text-[36px]"
              />
            )}

            <SignIn.Input
              type="text"
              name="socialId"
              value={cpf}
              maxLength={14}
              onChange={handleSocialId}
              disabled={isLoading}
              validation="Campo obrigatório"
              showValidation={() => fieldValidation("cpf", cpf)}
              placeholder="CPF"
              className="text-[16px] h-[44px] mt-2 gap-0 md:mt-[100px] max-lg:text-[16px] lg:text-[18px] 2xl:[20px]"
              classNameValidation="flex relative bottom-0 max-md:text-[14px]"
            />
            <SignIn.Input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Senha"
              className="text-[16px] h-[44px] mt-0 max-md:mt-0 gap-0  max-lg:text-[16px] lg:text-[18px] 2xl:[20px]"
              classNameValidation="flex relative bottom-0 max-md:text-[14px]"
              value={loginPassword}
              validation="Campo obrigatório"
              showValidation={() => fieldValidation("loginPassword", loginPassword)}
              onChange={(e) => setLoginPassword(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  signIn(cpf, loginPassword);
                }
              }}
              disabled={isLoading}
              endIcon={
                <button 
                  className="absolute right-[14px] top-[12px] flex items-center justify-center h-[20px] w-[20px]"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon 
                    icon={showPassword ? faEyeSlash : faEye} 
                    className="text-[#8A8A8A] text-[16px]" 
                  />
                </button>
              }
            />
            <div className="w-full text-right">
              <SignIn.Action
                className="text-[14px] text-right text-red-400 max-w-full md:text-[16px] lg:text-base"
                text={"Esqueci minha senha"}
                id="forgotPassword"
                onClick={handleRedirectSenha}
              />
            </div>

          </div>

          <div className="self-end flex flex-col w-full">
            <SignIn.Action
              id="checkUser"
              onClick={() => {signIn(cpf, loginPassword)}}
              text={type == "login" ? "Acessar" : "Próximo"}
              className={twMerge(
                `self-end mt-10 items-center text-center p-2.5 w-full text-white rounded-full shadow-sm ${
                  !isLoading
                    ? "bg-[linear-gradient(90deg,#E13441_0%,#FAAE2E_100%)]"
                    : "bg-gray-400"
                }`,
                "h-[44px] max-md:text-[16px] p-0 mt-0 md:text-base xl:text-lg"
              )}
              disabled={isLoading}
            />
            <SignIn.Action
              id="register"
              onClick={goToCadastro}
              text="Criar conta"
              className={twMerge(
                "self-end items-center p-2.5 mt-4 text-center w-full text-red-400 rounded-full border border-red-400 border-solid",
                "h-[44px] max-md:text-[16px] p-0 md:text-base xl:text-lg"
              )}
              disabled={isLoading}
            />
            <SignIn.Action
              id="migrate-account"
              onClick={() => {
                setShowPopupMigracao(true)
              }}
              text="Recuperar conta Assista Mais"
              className={twMerge(
                "self-end items-center p-2.5 mt-4 text-center w-full text-red-400 rounded-full border border-red-400 border-solid",
                "h-[44px] max-md:text-[16px] p-0 md:text-base xl:text-lg"
              )}
              disabled={isLoading}
            />
            {/* <SignIn.Action
              id="back"
              onClick={goToHome}
              text="Voltar"
              className={twMerge(
                "self-end items-center p-2.5 mt-4 text-center w-full text-red-400 rounded-full border border-red-400 border-solid",
                "h-[44px] max-md:text-[16px] p-0 md:text-base xl:text-lg"
              )}
              disabled={isLoading}
            /> */}
          </div>
        </SignIn.Root>
      </div>

      {showPopupMigracao &&
            <Modal
                open={showPopupMigracao}
                onClose={() => setShowPopupMigracao(false)}
                className='flex items-center justify-center'
            >
                <div className="bg-[#FFF] p-[60px] w-full max-w-[680px] flex flex-col gap-[40px] rounded-[48px]">
                    <div className='w-full flex flex-col items-center gap-[20px]'>
                        <h2 className='text-[32px] text-[#151A25] font-bold'>
                            Atenção
                        </h2>
                        <span className='text-[20px] text-[#151A25] font-medium text-center'>
                            O Assista Mais agora faz parte da nova Use Mais! Se você já tem cadastro nessa plataforma, basta fazer a migração da sua conta.
                        </span>
                    </div>
                    <div className='w-full flex flex-col gap-[10px] items-center'>
                        <button 
                            onClick={() => history.push(`/migracao`)} 
                            className="
                                w-full max-w-[440px] bg-gradient-to-r from-[#E13441]  to-[#FAAE2E] 
                                text-white text-[16px] py-[10px] rounded-[8px]
                                hover:opacity-90
                            "
                        >
                            <span>Entendi, migrar conta</span>
                        </button>
                        <button 
                            onClick={() => setShowPopupMigracao(false)} 
                            className="
                                w-full max-w-[440px] bg-white border border-[#E13441] 
                                text-[#E13441] text-[16px] py-[10px] rounded-[8px]
                                hover:opacity-90
                            "
                        >
                            <span>Voltar</span>
                        </button>
                    </div>
                </div>
            </Modal>
        }
    </div>
  );
}
