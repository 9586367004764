import React, { useEffect, useContext, useState } from 'react';
import ProgressoComLegenda from '../../molecules/ProgressoComLegenda';
import { MessageContext } from '../../../contexts/MessageContext';
import { useHistory } from "react-router-dom";
import { useForm } from "../../../hooks/useForm";
import { apagarUsuario, usarCupom } from '../../../actions/assistamais/api';
import BotaoArredondadoUsemais from '../../atoms/BotaoArredondadoUsemais';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faTicket } from '@fortawesome/free-solid-svg-icons';
import PaidIcon from '@mui/icons-material/Paid';
import { twMerge } from 'tailwind-merge';
import { logout } from '../../../utils/Session';
import './style.scss';
import { Box, Modal, useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import { formatCurrencyBRL } from '../../../utils';

export const ContaSaldoUsemais = ({ pontos, editar = false, extratoPontoClick, cancelarConta, totalResgatado }) => {
    const [showMessage, showError] = useContext(MessageContext);
    let history = useHistory();
    const isMobile = useMediaQuery('(max-width:768px)');

    const { handleSubmit, handleChange, data, errors } = useForm({
        validations: {
            codigo: {
                required: {
                    value: true,
                    message: 'Preencha o código do cupom',
                },
            },
        },
        onSubmit: () => {
            const body = { codigo: data.codigo };
            usarCupom(body).then(result => {
                if (result?.payload?.status < 200 || result?.payload?.status > 299) {
                    showError(result?.payload?.data.message)
                } else {
                    showMessage('success', 'Cupom adicionado com sucesso', 3000);
                    setTimeout(() => window.location.href = '/minha-conta', 2000);
                }
            });
        },
        initialValues: { codigo: '' },
    });

    const clickResgatar = () => {
        history.push('/gifts')
    }

    const handleEditarPerfil = () => {
        history.push('/editarperfil');
    }

    const handleSairClick = () => {
        // googleLogout()
        logout();
        window.location.href = '/';
    }

    return (
        <>
            <div className="grid grid-cols-2 md:flex md:flex-wrap gap-4 mt-4 px-8 sm:px-10">
                <div className="flex w-full gap-2 sm:w-auto">
                    <FontAwesomeIcon icon={faTicket} className="text-black -rotate-45 text-[18px] mt-[4px]" />
                    <div className="text-black flex flex-col items-start">
                        <span className="text-[#8A8A8A] text-[14px] md:text-sm">Total de pontos</span>
                        <span className="text-[#8A8A8A] text-[14px] md:text-sm">resgatado</span>
                        <span className="text-black text-[18px] md:text-2xl font-semibold">{pontos?.resgatado ?? 0}</span>
                    </div>
                </div>

                <div className="flex w-full gap-2 sm:w-auto">
                    {/* <PaidIcon className="text-black" /> */}
                    <Box className="flex items-center justify-center bg-black rounded-full h-[20px] w-[20px] mt-[4px]">
                        <FontAwesomeIcon icon={faDollarSign} className="text-white text-[12px]" />
                    </Box>
                    <div className="text-black flex flex-col items-start">
                        <span className="text-[#8A8A8A] text-[14px] md:text-sm">Total</span>
                        <span className="text-[#8A8A8A] text-[14px] md:text-sm">resgatado</span>
                        <span className="text-black text-[18px] md:text-2xl font-semibold">R$ {formatCurrencyBRL(totalResgatado)}</span>
                    </div>
                </div> 
            </div>

            <div className={twMerge(
                "grid grid-cols-3 md:flex md:flex-col gap-2 md:gap-4 mt-6 px-4 md:px-0 md:pl-2",
                editar && isMobile && "grid-cols-2"
            )}>
                {!editar && (
                    <BotaoArredondadoUsemais 
                        className="w-full md:w-[214px] h-[32px] md:h-[40px]" 
                        value={"Editar perfil"} 
                        classNameText="text-[11px] md:text-sm" 
                        onClick={handleEditarPerfil} 
                    />
                )}
                <BotaoArredondadoUsemais 
                    className="w-full md:w-[214px] h-[32px] md:h-[40px]" 
                    value={"Extrato"} 
                    classNameText="text-[11px] md:text-sm" 
                    onClick={extratoPontoClick} 
                />
                <BotaoArredondadoUsemais 
                    className="w-full md:w-[214px] h-[32px] md:h-[40px]" 
                    value={"Resgatar gift"} 
                    classNameText="text-[11px] md:text-sm" 
                    onClick={clickResgatar} 
                />
                {editar && !isMobile && (
                    <BotaoArredondadoUsemais 
                        className="w-full md:w-[214px] h-[32px] md:h-[40px] bg-[#8A8A8A] hover:bg-[#5e5e5e]" 
                        value={"Cancelar minha conta"} 
                        classNameText="text-[11px] md:text-sm" 
                        onClick={cancelarConta} 
                    />
                )}
            </div>
            <div className="flex flex-col px-4 md:px-0 md:pl-2">
                {!editar && !isMobile && (
                    <BotaoArredondadoUsemais 
                        className="w-full md:w-[214px] h-[32px] md:h-[40px] bg-[#151A25] hover:bg-[#151A25] hover:bg-opacity-90" 
                        value={"Sair"} 
                        classNameText="text-[10px] md:text-sm font-semibold text-white" 
                        onClick={handleSairClick} 
                    />
                )}
            </div>
        </>
    )
};
