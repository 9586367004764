import { apiAssistaMaisRequest } from "../../utils/ApiRequest";
import { obterDados } from "../../utils/Session";

const post = async (url: string, body: any = {}, header: {} = {}) => {
  let resultado;

  await apiAssistaMaisRequest(header)
    .post(url, body || {})
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

const deleteMethod = async (url: string) => {
  let resultado;

  await apiAssistaMaisRequest()
    .delete(url)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

const get = async (url: string) => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(url)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const autenticar = async (cpf: String, password: String) => {
  let resultado;
  const data = { cpf, password };

  await apiAssistaMaisRequest()
    .post(`/clientes/auth/login`, data)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const autenticarGoogle = async (profileObj: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/clientes/auth/google/login`, profileObj)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const registrar = async (body: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/clientes/auth/register`, body)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const verifyUserMigrated = async (body: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/clientes/auth/verify-user-migrated`, body)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const loginLecupon = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/auth/login-lecupon`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
}

export const obterClienteResumo = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/resumo`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const obterGiftsResgatados = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/resgates/gifts`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const obterPerfl = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/perfil`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const editarPerfil = async (body: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .put(`/clientes/perfil`, body)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const validaDadosEditarPerfil = async (body: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/clientes/perfil/validate-update`, body)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const obterProviders = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/cache/providers`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const obterAnuncios = async (pagina = "HOME") => {
  const dados = obterDados();
  // if (dados && dados.tokens) {
  //removido cache por enquanto
    return await post(`clientes/anuncios/${pagina}`, {});
  // }
  // return await get(`/cache/anuncios/${pagina}`);
};

export const obterCarrossel = async () => {
  return await get(`/clientes/anuncios/carrossel`);
};

export const obterProdutos = async (categoria = "0", pontos = "0") => {
  let resultado;

  // await apiAssistaMaisRequest().get(`/cache/produtos/${categoria}/${pontos}`).then((response: any) => {
  await apiAssistaMaisRequest()
    .get(`/cache/produtos`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const obterFiltros = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/cache/produtos-filtros`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const obterExtrato = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/extrato`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const resgatarDePontos = async (codigo: any, categoria: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/clientes/resgates/${codigo}/${categoria}`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const obterSaldo = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/saldo`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const buscarCodigoPontos = async (body: any) => {
  let resultado;
  
  await apiAssistaMaisRequest()
    .post(`/clientes/buscarCodigoPontos`, body)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
}

export const favoritar = async (body: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/clientes/favoritos`, body)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const desfavoritar = async ({ tipo, tituloId }: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .delete(`/clientes/favoritos/${tipo}/${tituloId}`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const getFavorito = async ({ tipo, tituloId }: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/favoritos/${tipo}/${tituloId}`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const getFavoritos = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/favoritos/`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const getLinkUseMais = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/url-use-mais`)
    .then((response: any) => {
      resultado = response?.data;
    })
    .catch((error: any) => {
      resultado = error?.response?.data;
    });

  return {
    payload: resultado,
  };
};

export const anuncioClick = async ({ token, anuncioId }: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/clientes/anuncios/click/${token}/${anuncioId}`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const usarCupom = async (body: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .put(`/cupons`, body)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const enviarSms = async ({ cpf, telefone }: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(cpf ? `/clientes/enviar-sms/${cpf}/${telefone}`: `/clientes/enviar-sms/change`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const validarSms = async ({ codigo, telefone }: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(telefone ? `/clientes/validar-sms/${codigo}/${telefone}`: `/clientes/validar-sms/${codigo}`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const enviarEmail = async ({ email }: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/enviar-email/${email}`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const validarEmail = async ({ codigo, email }: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/clientes/validar-email/${codigo}/${email}`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const validarSmsRegistro = async ({ codigo, celular }: any) => {
    let resultado;
  
    await apiAssistaMaisRequest()
      .post(`/clientes/auth/verify-sms`, { codigo: codigo, celular: celular})
      .then((response: any) => {
        resultado = response;
      })
      .catch((error: any) => {
        resultado = error.response;
      });
  
    return {
      payload: resultado,
    };
};

export const validarSmsMigracao = async ({ codigo, cpf, type }: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/clientes/auth/verify-sms-migration`, { codigo, cpf, type })
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const createPassword = async (password: string, aceiteTermo: boolean, autorizaReceberEmail: boolean) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/clientes/auth/create-password`, { password, aceiteTermo, autorizaReceberEmail })
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const editarFotoPerfil = async (body: any) => {
  let resultado;

  resultado = await apiAssistaMaisRequest().post(`/clientes/perfil/foto`, body);

  return {
    payload: resultado,
  };
};

export const classificar = async (body: any) => {
  let resultado;

  resultado = await apiAssistaMaisRequest().post("/clientes/avaliacoes", body);

  return {
    payload: resultado,
  };
};

export const anuncioView = async ({ token, anuncioId, tempo }: any) =>
  await post(`/clientes/anuncios/view/${token}/${anuncioId}`, { tempo });

export const obterClassificao = async ({ tituloId, tipo }: any) =>
  await get(`/clientes/avaliacoes/${tipo}/${tituloId}`);

export const obterInformacoesCEP = async (cep: any) =>
  await get(`/users/cep/${cep}`);

export const preCadastro = async (cpf: any) =>
  await get(`/clientes/auth/pre-cadastro/${cpf}`);

export const recuperarSenha = async (email: string) =>
  await post(`/clientes/auth/forgot-password`, { email });

export const resetarSenha = async (password: string, token: string) =>
  await post(`/clientes/auth/reset-password/?token=${token}`, { password });

export const checkValidityToken = async (token: string, type: string) =>
  await post(`/clientes/auth/check-validity-token/?token=${token}`, { type });

export const trocarSenha = async (
  currentPassword: string,
  newPassword: string
) => await post(`/clientes/change-password`, { currentPassword, newPassword });

export const obterOndeAssistir = async (id: number, titulo: string) =>
  await get(`/cache/onde-assistir/MOVIE/${id}/?query=${titulo}`);

export const obterOndeAssistirTV = async (id: number, titulo: string) =>
  await get(`/cache/onde-assistir/TV/${id}/?query=${titulo}`);

export const verificarEmail = async (token: string) =>
  await post(`/clientes/auth/verify-email/?token=${token}`, {});

export const apagarUsuario = async () => await deleteMethod("/clientes/");

export const enviarFotoPerfil = async (body: any) =>
  await post(`/clientes/perfil/foto`, body, {
    "Content-type": "multipart/form-data",
  });

// export const editarFotoPerfil = async (body: any) =>
//   await apiAssistaMaisRequest().post(`/clientes/perfil/foto`, body);

export const obterFotoPerfil = async () => await get(`/clientes/perfil/foto`);

export const transparentLogin = async (token: string) =>
  await post(`/clientes/auth/transparent-login/?token=${token}`, {});

export const obterListaDeListas = async () => await get(`cache/listas`);

export const obterLista = async (id: string) => await get(`cache/listas/${id}`);

export const obterCapaLista = async (id: string) =>
  await get(`cache/listas/${id}/capa`);

export const loginIncompleto = async (id: string) =>
  await post(`/clientes/auth/incompleto`);

export const obterNoticias = async (id: string) =>
  await get("/cache/noticias?id=123");

export const resetAnuncios = async (id: string) =>
  await get("/clientes/anuncios/reset");

export const obterAvataresPerfil = async () =>
  await post(`/clientes/perfil/fotos`);

export const isLogged = async (userId: string) =>
  await get(`/clientes/auth/isLogged/${userId}`);


export const getCentrais = async (): Promise<any> => 
  await get(`/clientes/centrais`);

export const getPlano = async (id: string) => await get(`/planos/${id}`);

export const getDetalhesPlanoContratado = async (contratacaoId: string) => await get(`/planos/${contratacaoId}/detalhes`);

export const getProfissoes = async (id: string) => await get(`/planos/${id}/get-profissoes`);

export const contratarPlano = async (id: string, data: any) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/planos/${id}/contratar`, data)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const consultarCpfServidor = async (cpf: string) => {
  let resultado;

  await apiAssistaMaisRequest()
    .post(`/planos/consultar-servidor`, { cpf })
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const consultarPlanosPortal = async () => {
  let resultado;

  await apiAssistaMaisRequest()
    .get(`/planos/providers`)
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

export const consultarPlanosContratados = async (pendentes = false) => {
  let resultado;

  let params = {
    pendentes
  };

  await apiAssistaMaisRequest()
    .get(`/planos/consultar-planos-contratados`, { params })
    .then((response: any) => {
      resultado = response;
    })
    .catch((error: any) => {
      resultado = error.response;
    });

  return {
    payload: resultado,
  };
};

// export const gravarAvataresPerfil = async () => await get('/clientes/perfil/gravar-avatar');
