import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { obterAnuncios, obterListaDeListas } from '../../actions/assistamais/api';
import Banner1 from '../../componentes/molecules/Banner1';
import BannerFundo from '../../componentes/organisms/BannerFundo';
import ListaAnunciosMeiaTiraHorizontal from '../../componentes/organisms/ListaAnunciosMeiaTiraHorizontal';
import ListaNoticiasHorizontal from '../../componentes/organisms/ListaNoticiasHorizontal';
import NewListaHorizontal from '../../componentes/organisms/NewListaHorizontal';
import Menu from '../../componentes/organisms/Menu';
import Rodape from '../../componentes/organisms/Rodape';
import config from '../../config/default';
import './style.css';
import ListaAnunciosHorizontalFixo from '../../componentes/organisms/ListaAnunciosHorizontalFixo';
import ListaAnunciosHorizontal from '../../componentes/organisms/ListaAnunciosHorizontal';
import ListaAnunciosHorizontalTresPosicoes from '../../componentes/organisms/ListaAnunciosHorizontalTresPosicoes';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import { SessionContext } from '../../contexts/SessionContext';
import BannerFundoUsemais from '../../componentes/organisms/BannerFundoUsemais';
import Footer from '../../componentes/usemais/Footer';



export default function ListasPersonalizadas({ videoRef }) {

    let history = useHistory();

    const [anuncios, setAnuncios] = useState([]);
    const [carrossel, setCarrossel] = useState([]);

    const [lista, setLista] = useState([]);

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const popularCapa = (lista) => {
        const urlCapa = `${config.baseURLAssistaMais}/cache/listas/${lista.id}/capa`;

        return { ...lista, capa: urlCapa }
        //return lista;
    }

    const carregarAnuncios = () => {
        try {
            obterAnuncios('LISTAS').then(result => {
                setAnuncios(result?.payload?.data?.results[0].linha);
                setCarrossel(result?.payload?.data?.results[0].carrossel);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {

        carregarAnuncios();
        obterListaDeListas().then(result => {
            try {

                if (result?.payload?.status === 200) {
                    const listas = result?.payload?.data?.results;

                    setLista(listas.map(popularCapa));
                }

            } catch (err) {
                console.error(err);
            }
        })
    }, [])

    const handleClickVerMais = (id) => {

        history.push({
            pathname: '/titulos-lista-personalizada/',
            search: `?id=${id}`
        }, {
            some: new Date()
        });

    }
    return (
        <div className='bg-black'>

            <BannerFundoUsemais carrossel={carrossel} />

            <NewListaHorizontal onClick={handleClickVerMais} items={lista.slice(0, 3)} />
            <NewListaHorizontal onClick={handleClickVerMais} items={lista.slice(3, 6)} />
            <NewListaHorizontal onClick={handleClickVerMais} items={lista.slice(6, 9)} />
            <NewListaHorizontal onClick={handleClickVerMais} items={lista.slice(9, 12)} />
            <NewListaHorizontal onClick={handleClickVerMais} items={lista.slice(12, 15)} />
            <NewListaHorizontal onClick={handleClickVerMais} items={lista.slice(15, 18)} />

            {/* <NewListaHorizontal onClick={handleClickVerMais} items={lista.slice(18, 21)} /> */}

            <Footer />
        </div>
    );
}


ListasPersonalizadas.propTypes = {

};

