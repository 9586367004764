import "./style.scss";

const BannerCard = ({img , text}) => {
  return (
    <div className="flex flex-inline  font-medium justify-center items-center text-left text-white max-md:mt-10 w-full">
      <img
        loading="lazy"
        src={img}
        className="w-full aspect-[0.96] fill-white md:max-w-[72px] xl:max-w-[84px] 2xl:max-w-[98px]"
      />
      <div className="pl-4 text-left w-full mt-5 md:text-[22px] xl:text-[28px] 2xl:text-4xl sub-text">{text}</div>
    </div>
  );
};

export default BannerCard;
