import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import config from "../../../config/default";
import Anuncio from '../../atoms/Anuncio';
import './style.scss';

import { Box, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";


const ListaAnunciosHorizontalUsemais = ({ onClick, onClickVerPlataforma, onCheckMonetizado, anuncios, title = "PUBLICIDADE" }) => {
    const [currentIndex, setCurrentIndex] = useState(0); // Índice do grupo de anúncios visíveis
     // Detecta se a tela é menor que 768px
     const isSmallScreen = useMediaQuery('(max-width:1024px)');
     const itemsToShow = isSmallScreen ? 1 : 5; // Define dinamicamente o número de itens visíveis

     const itemsToNext = 1; // Define dinamicamente o número de itens visíveis
 

    const totalAnuncios = anuncios?.length || 0;
    
    useEffect(() => {

        // Atualizar índice a cada intervalo definido
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => 
                prevIndex + itemsToShow >= totalAnuncios ? 0 : prevIndex + itemsToNext // Resetar quando alcançar o final
            );
        }, config.tempoTransicaoAnuncio);

        return () => clearInterval(interval); // Limpar intervalo ao desmontar
    }, [anuncios]);

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + itemsToShow >= totalAnuncios ? 0 : prevIndex + itemsToNext
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - itemsToNext < 0 ? (totalAnuncios - itemsToShow) : prevIndex - itemsToNext
        );
    };

    return (
        <div className="flex flex-col min-h-[200px] gap-6 mt-10 items-center">
            {/* Título */}
            <div className="w-11/12 md:w-full mx-auto text-center text-black text-[16px] md:text-[18px] lg:text-2xl font-semibold">
                <span>Assista os anúncios, acumule pontos e troque por gift cards em</span>
                <span className="text-[#ED636E]"> diversos parceiros.</span>
            </div>
            
            {/* Controles e Lista de Anúncios */}
            <Box className="hidden lg:flex items-center justify-center w-full gap-4">
                <button 
                    onClick={handlePrev} 
                    className="bg-black shadow-lg rounded-full w-[40px] h-[40px] flex items-center justify-center text-white hover:bg-slate-700"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>

                {/* Lista de Anúncios Desktop */}
                <div className="lista-anuncios flex items-end gap-4 min-w-[350px]">
                    {anuncios && anuncios.map((a, index) => (
                            <Anuncio
                                key={`anuncio-lista-${a.id}`}
                                anuncio={a}
                                onCheckMonetizado={onCheckMonetizado}
                                style={{ 
                                    display: index >= currentIndex && index < currentIndex + itemsToShow ? '' : 'none' 
                                }}
                            />
                        ))
                    }
                </div>

                <button 
                    onClick={handleNext} 
                    className="bg-black shadow-lg rounded-full w-[40px] h-[40px] flex items-center justify-center text-white hover:bg-slate-700"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </Box>
            
            {/* Lista de Anúncios Mobile */}
            <div className="flex items-center justify-center mt-4 lg:hidden min-h-[200px] gap-4">
                <button 
                    onClick={handlePrev} 
                    className="bg-black shadow-lg rounded-full w-[40px] h-[40px] flex items-center justify-center text-white hover:bg-slate-700"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                {anuncios && anuncios.map((a, index) => (
                        <Anuncio
                            key={`anuncio-lista-${a.id}`}
                            anuncio={a}
                            onCheckMonetizado={onCheckMonetizado}
                            style={{ 
                                display: index >= currentIndex && index < currentIndex + itemsToShow ? '' : 'none' 
                            }}
                        />
                    ))
                }

                <button 
                    onClick={handleNext} 
                    className="bg-black shadow-lg rounded-full w-[40px] h-[40px] flex items-center justify-center text-white hover:bg-slate-700"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </div>
        </div>
    );
};

export default ListaAnunciosHorizontalUsemais;
