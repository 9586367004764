import { useState, useEffect } from 'react';
import { Autocomplete, TextField, Select as SelectMUI, MenuItem } from '@mui/material';
import { twMerge } from 'tailwind-merge';

// Definindo a interface Option para TypeScript
export interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  value: Option | null;
  name?: string;
  error?: string;
  label?: string;
  placeholder?: string;
  className?: string;
}

export function Select({ options, label, name, error, placeholder, className, ...rest }: SelectProps) {
  
  return (
    <div className={twMerge("flex flex-col", className)}>
      {label && (
          <label htmlFor={name} className='text-[#151A25] text-[14px] mb-[8px]'>
              {label}
          </label>
      )}
      <SelectMUI
          name={name}
          size='small'
          {...rest}
          sx={{
            borderRadius: '8px',
            height: '44px',

            '.MuiSelect-select': {
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            }
          }}
      >
          <MenuItem value="" className=''><em>Selecione</em></MenuItem>
          {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
          ))}
      </SelectMUI>
      {error ? <span className="text-red-500 text-[14px]">{error}</span> : <span className='h-[16px]'></span>}
    </div>
  );
}
