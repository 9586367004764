import { useEffect, useState } from "react";
import { obterAnuncios } from "../../../../actions/assistamais/api";
import "./style.scss";
import ListaAnunciosHorizontal from "../../../organisms/ListaAnunciosHorizontal";
import ListaAnunciosHorizontalUsemais from "../../../organisms/ListaAnunciosHorizontalUsemais";

export default function Index() {

  const [anuncios, setAnuncios] = useState([]);

  useEffect(() => {
    carregarAnuncios();
  },[]);

  const carregarAnuncios = () => {
    try {
        obterAnuncios().then(result => {
            setAnuncios(result?.payload?.data?.results ?? []);
        });
    } catch (err) {
        console.error(err);
    }
  }

  return anuncios.length > 0 ? (
      <ListaAnunciosHorizontalUsemais 
        onCheckMonetizado={() => carregarAnuncios()} 
        anuncios={anuncios} 
        key="lista-anuncios" 
        title="Assista os anúncios, acumule pontos e troque por gift cards em diversos parceiros."
      />
  ) : null
}
