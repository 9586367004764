import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../../contexts/SessionContext';
import BotaoArredondadoUsemais from '../../atoms/BotaoArredondadoUsemais';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faFacebookF, faInstagram, faLinkedin, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { useHistory } from "react-router-dom";

import './style.scss';
import { twMerge } from 'tailwind-merge';
import { useMediaQuery } from '@mui/material';

export const RodapeUsemais = ({ theme = 'default', ...rest }) => {
    const descubra = [
        { id: 'descubra-3', texto: 'Como funciona', link: '/comofunciona' },
        { id: 'descubra-2', texto: 'Notícias', link: '/noticias' },
        { id: 'descubra-1', texto: 'Listas', link: '/listas' },
        { id: 'descubra-5', texto: 'Perguntas Frequentes', link: '/ajuda' },
        { id: 'descubra-4', texto: 'Anuncie', link: '/anuncie' },
    ];

    const [generos, setGeneros] = useState([]);
    const [plataformas, setPlataformas] = useState([]);
    const [, , , systemData] = useContext(SessionContext);

    const isMobile = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        setGeneros(systemData.genres.map(g => ({ id: `genero-${g.id}`, texto: g.name, link: `/titulosPorGenero/?id=${g.id}` })));
        setPlataformas(systemData.providers.map(p => ({ id: `provider-${p.provider_id}`, texto: p.provider_name, link: `/titulosPorPlataforma/?id=${p.provider_id}&name=${p.provider_name}` })));
    }, [systemData])

    const history = useHistory();
    
    const goCentrais = () => {
        history.push({
            pathname: 'central-atendimento'
        });
    };

    return isMobile ? (
        <div className={twMerge(
            "w-full mx-auto py-8 max-md:pb-[100px]",
            rest.className,
            theme == 'default' && "bg-[#F5F5F5] border-t",
            theme == 'dark' && "bg-azul-padrao",
        )}>
            <div className="w-11/12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 md:gap-y-2 mx-auto">

                <div className='sm:col-span-2'>
                    <img src="/usemais/logo_use_mais.png" alt="Logo" className="w-[120px] h-[72px]" />
                </div>
                
                <div className='flex flex-col gap-4'>
                    <div className={twMerge(
                        "flex flex-col ",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-white",
                    )}>
                        <span className="text-sm">Copyright 2023 Todos os direitos</span>
                        <span className="text-sm">reservados - Versão 1.0.9</span>
                        <span className="text-sm">USE MAIS: CNPJ 12.751.349/0001-44</span>
                    </div>
                    <div className={twMerge(
                        "flex flex-col",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-red-400",
                    )}>
                        <a href="/termos/termo_de_uso.pdf" target='_blank' className="text-sm font-semibold hover:underline">Termo de Uso</a>
                        <a href="/termos/politica_de_privacidade.pdf" target='_blank' className="text-sm font-semibold hover:underline">Política de Privacidade e Cookie</a>
                    </div>
                </div>

                <div className='flex flex-col gap-4'>
                    <h4 className={twMerge(
                        "font-semibold",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-red-400",
                    )}>
                        Servidores públicos
                    </h4>
                    <span 
                        className={twMerge(
                            "text-sm",
                            theme == 'default' && "text-azul-padrao",
                            theme == 'dark' && "text-white",
                        )}
                    >
                        MeuConsignado e RioConsig:
                    </span>
                    <BotaoArredondadoUsemais 
                        value="Central de atendimento" 
                        onClick={() => {goCentrais()}} 
                        classNameText="text-sm" 
                        className={twMerge(
                            "w-full lg:w-[220px] 2xl:w-[240px]",
                            theme == 'default' && "bg-azul-padrao hover:bg-azul-padrao hover:bg-opacity-70",
                            theme == 'dark' && "bg-gradient-custom hover:bg-gradient-custom hover:opacity-80",
                        )}
                    />
                </div>

                <div className='flex flex-col gap-4'>
                    <h4 className={twMerge(
                        "font-semibold",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-red-400",
                    )}>
                        Público em geral
                    </h4>
                    <div className={twMerge(
                        "flex flex-col",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-white",
                    )}>
                        <div className="flex gap-1">
                            <span className="text-sm">E-mail:</span>
                            <a href="mailto:contato@usemaisstore.com.br" className={twMerge(
                                "text-sm font-semibold",
                                theme == 'default' && "text-azul-padrao",
                                theme == 'dark' && "text-red-400",
                            )}>
                                contato@usemaisstore.com.br
                            </a>
                        </div>
                        <div className="flex gap-1">
                            <span className="text-sm">WhatsApp:</span>
                            <a href="https://wa.me/554130729341" target='_blank' className={twMerge(
                                "text-sm font-semibold",
                                theme == 'default' && "text-azul-padrao",
                                theme == 'dark' && "text-red-400",
                            )}>
                                (41) 3072-9341
                            </a>
                        </div>
                        <div className="flex gap-1">
                            <span className="text-sm">Horário de atendimento:</span>
                            <span className="text-sm">8h às 18h</span>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col gap-4'>
                    <h4 className={twMerge(
                        "font-semibold",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-red-400",
                    )}>
                        Redes Sociais
                    </h4>
                    <div className="flex flex-col gap-4">
                        <span 
                            className={twMerge(
                                "text-sm",
                                theme == 'default' && "text-azul-padrao",
                                theme == 'dark' && "text-white",
                            )}
                        >
                            Siga a Use Mais nas redes sociais
                        </span>
                        <div className="flex gap-[16px]">
                            <a
                                href="https://www.instagram.com/usemaisbrasil"
                                target='blank'
                                className={twMerge(
                                    "w-[40px] h-[40px] rounded-full flex justify-center items-center hover:opacity-90",
                                    theme == 'default' && "bg-azul-padrao",
                                    theme == 'dark' && "bg-red-400",
                                )}
                            >
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                    className={twMerge(
                                        "text-[28px] ",
                                        theme == 'default' && "text-white",
                                        theme == 'dark' && "text-azul-padrao",
                                    )}
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/use-mais"
                                target='blank'
                                className={twMerge(
                                    "w-[40px] h-[40px] rounded-full flex justify-center items-center hover:opacity-90",
                                    theme == 'default' && "bg-azul-padrao",
                                    theme == 'dark' && "bg-red-400",
                                )}
                            >
                                <FontAwesomeIcon
                                    icon={theme == 'default' ? faLinkedinIn : faLinkedin}
                                    className={twMerge(
                                        "text-[26px] ",
                                        theme == 'default' && "text-white",
                                        theme == 'dark' && "text-azul-padrao",
                                    )}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className={twMerge(
            "w-full mx-auto py-8",
            rest.className,
            theme == 'default' && "bg-[#F5F5F5] border-t",
            theme == 'dark' && "bg-azul-padrao",
        )}>
            <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 gap-y-2 mx-auto">

                <img src="/usemais/logo_use_mais.png" alt="Logo" className="w-[120px] h-[72px]" />
                <h4 
                    className={twMerge(
                        " font-semibold mt-auto mb-2",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-red-400",
                    )}
                >
                    Público em geral
                </h4>
                <h4
                    className={twMerge(
                        " font-semibold mt-auto mb-2",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-red-400",
                    )}
                >
                    Servidores públicos
                </h4>
                <h4
                    className={twMerge(
                        "font-semibold mt-auto mb-2",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-red-400",
                    )}
                >
                    Redes Sociais
                </h4>
                
                <div className="flex flex-col gap-4 items-start">
                    <div className={twMerge(
                        "flex flex-col",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-white",
                    )}>
                        <span className="text-sm">Copyright 2023 Todos os direitos</span>
                        <span className="text-sm">reservados - Versão 1.0.9</span>
                        <span className="text-sm">USE MAIS: CNPJ 12.751.349/0001-44</span>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className={twMerge(
                        "flex flex-col",
                        theme == 'default' && "text-azul-padrao",
                        theme == 'dark' && "text-white",
                    )}>
                        <div className="flex gap-1">
                            <span className="text-sm">E-mail:</span>
                            <a href="mailto:contato@usemaisstore.com.br" className={twMerge(
                                "text-sm font-semibold",
                                theme == 'default' && "text-azul-padrao",
                                theme == 'dark' && "text-red-400",
                            )}>
                                contato@usemaisstore.com.br
                            </a>
                        </div>
                        <div className="flex gap-1">
                            <span className="text-sm">WhatsApp:</span>
                            <a href="https://wa.me/554198842718" target='_blank' className={twMerge(
                                "text-sm font-semibold",
                                theme == 'default' && "text-azul-padrao",
                                theme == 'dark' && "text-red-400",
                            )}>
                                (41) 9884-2718
                            </a>
                        </div>
                        <div className="flex gap-1">
                            <span className="text-sm">Horário de atendimento:</span>
                            <span className="text-sm">8h às 18h</span>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <span 
                        className={twMerge(
                            "text-sm",
                            theme == 'default' && "text-azul-padrao",
                            theme == 'dark' && "text-white",
                        )}
                    >
                        MeuConsignado e RioConsig:
                    </span>
                    <BotaoArredondadoUsemais 
                        value="Central de atendimento" 
                        onClick={() => {goCentrais()}} 
                        classNameText="text-sm" 
                        className={twMerge(
                            "w-full lg:w-[220px] 2xl:w-[240px]",
                            theme == 'default' && "bg-azul-padrao hover:bg-azul-padrao hover:bg-opacity-70",
                            theme == 'dark' && "bg-gradient-custom hover:bg-gradient-custom hover:opacity-80",
                        )}
                    />
                </div>

                <div className="flex flex-col gap-4">
                    <span 
                        className={twMerge(
                            "text-sm",
                            theme == 'default' && "text-azul-padrao",
                            theme == 'dark' && "text-white",
                        )}
                    >
                        Siga a Use Mais nas redes sociais
                    </span>
                    <div className="flex gap-[16px]">
                        <a
                            href="https://www.instagram.com/usemaisbrasil"
                            target='blank'
                            className={twMerge(
                                "w-[40px] h-[40px] rounded-full flex justify-center items-center hover:opacity-90",
                                theme == 'default' && "bg-azul-padrao",
                                theme == 'dark' && "bg-red-400",
                            )}
                        >
                            <FontAwesomeIcon
                                icon={faInstagram}
                                className={twMerge(
                                    "text-[28px] ",
                                    theme == 'default' && "text-white",
                                    theme == 'dark' && "text-azul-padrao",
                                )}
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/use-mais"
                            target='blank'
                            className={twMerge(
                                "w-[40px] h-[40px] rounded-full flex justify-center items-center hover:opacity-90",
                                theme == 'default' && "bg-azul-padrao",
                                theme == 'dark' && "bg-red-400",
                            )}
                        >
                            <FontAwesomeIcon
                                icon={theme == 'default' ? faLinkedinIn : faLinkedin}
                                className={twMerge(
                                    "text-[26px] ",
                                    theme == 'default' && "text-white",
                                    theme == 'dark' && "text-azul-padrao",
                                )}
                            />
                        </a>
                    </div>
                </div>

                <div className={twMerge(
                    "flex flex-col mb-20 md:mb-0",
                    theme == 'default' && "text-azul-padrao",
                    theme == 'dark' && "text-red-400",
                )}>
                    <a href="/termos/termo_de_uso.pdf" target='_blank' className="text-sm font-semibold hover:underline">Termo de Uso</a>
                    <a href="/termos/politica_de_privacidade.pdf" target='_blank' className="text-sm font-semibold hover:underline">Política de Privacidade e Cookie</a>
                </div>
            </div>
        </div>
    )
};
