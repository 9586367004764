import { HTMLAttributes, ReactNode } from "react"

interface SignUpPassValidationsProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
}

export function SignUpPassValidations({ children, ...rest }: SignUpPassValidationsProps) {
    return (
        <div
            {...rest}
        >
            {children}
        </div>
    )
}