
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { obterAnuncios, obterOndeAssistir, obterOndeAssistirTV } from '../../actions/assistamais/api';
import { searchMulti } from '../../actions/tmdb';
import AnuncioTiraEmPeComTitulo from '../../componentes/organisms/AnuncioTiraEmPeComTitulo';
import ListaAnunciosHorizontalFixo from '../../componentes/organisms/ListaAnunciosHorizontalFixo';
import ListaResultadoFilmesBusca from '../../componentes/organisms/ListaResultadoFilmesBusca';
import Menu from '../../componentes/organisms/Menu';
import Rodape from '../../componentes/organisms/Rodape';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import { SessionContext } from '../../contexts/SessionContext';
import './style.scss';
import { useQuery } from '../../utils';
import SearchBarUsemais from '../../componentes/atoms/SearchBarUsemais';
import { twMerge } from 'tailwind-merge';
import Footer from '../../componentes/usemais/Footer';


const QTFILMES = 20;

export default function Pesquisa({ location }) {

    const [resultado, setResultado] = useState([]);
    const [mostrarNumerosPesquisa, setMostrarNumerosPesquisa] = useState(false);
    const [termosDaBusca, setTermosDaBusca] = useState('');
    const [anuncios, setAnuncios] = useState([]);
    const [anuncio, setAnuncio] = useState();

    let history = useHistory();
    let contador = 0;
    // let anuncios = [];

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const carregarAnuncios = () => {
        try {
            obterAnuncios('RESULTADO-DA-BUSCA').then(result => {
                // setAnuncios(result?.payload?.data?.results[0].linha);
                setAnuncios(result?.payload?.data?.results[0].linha);
                setAnuncio(result?.payload?.data?.results[0].linha[0]);
            });
        } catch (err) {
            console.error(err);
        }

    }




    useEffect(() => {
        carregarAnuncios();

        const interval = setInterval(() => {
            if (anuncios?.length > 0) {
                setAnuncio(anuncios[contador++]);
            }
            if (contador >= anuncios?.length) contador = 0;

        }, 5000)

        async function fetchData() {

            const query = useQuery(location.search);

            const params = {
                plataforma: query.get('plataforma'),
                genero: query.get('genero'),
                q: query.get('q'),
            }

            const _filter = (r) => {

                let retorno = true;

                if ( (!r.release_date && !r.first_air_date) || !r.overview) return false;

                if (params.plataforma) {
                    if (!r.providers || r.providers?.length === 0) {
                        retorno = false;
                    } else {
                        retorno = r.providers.some(p => p.provider_id === params.plataforma * 1);
                    }
                }

                if (retorno && params.genero) {
                    retorno = r.genre_ids && r.genre_ids.some(id => id === params.genero * 1);
                }
                return (r => r.media_type.toLowerCase === 'movie' || r.media_type.toLowerCase === 'tv') && retorno;
            }

            setMostrarNumerosPesquisa(false);
            setResultado([]);

            setTermosDaBusca(params.q);

            let totalPages = 1;

            // const { genres } = (await getGenresMovieList()).payload.data;
            const { genres } = systemData;
            var filmes = [];
            for (let i = 1; i <= totalPages; i++) {

                const result = await searchMulti(params.q.replace(" ", "+"), i); 

                if (result?.payload?.status !== 200) {
                    return;
                }

                const { results } = result?.payload?.data;

                totalPages = result?.payload?.data.total_pages < 4 ? result?.payload?.data.total_pages : 4;

                for (let r of results) {

                    if (r.media_type.toLowerCase() !== 'tv' && r.media_type.toLowerCase() !== 'movie') {
                        continue;
                    }

                    r.genres = [];
                    r.genre_ids && r.genre_ids.forEach(id => {
                        r.genres.push(genres.find(g => g.id === id))
                    });

                    let providers;

                    if (r.media_type.toLowerCase() === 'movie') {
                        //providers = await getMovieWatchProviders(r.id);
                        providers = await obterOndeAssistir(r.id, r.title);

                    } else {
                        providers = await obterOndeAssistirTV(r.id, r.title || r.original_name);
                        //providers = await getTVWatchProviders(r.id);
                    }

                    if (providers.payload.data.results) { // if (providers.payload.data.results && providers.payload.data.results.BR) {

                        const novosProvedores = providers.payload.data.results.providers || [];
                        r.providers = novosProvedores;
                    }

                }

                filmes.push(...results.filter(_filter))

                if (filmes?.length >= QTFILMES) {
                    break;
                }
            }

            // filmes = filmes.filter(f => f.release_date || f.first_air_date)

            setResultado(filmes);
            setMostrarNumerosPesquisa(true);

        }

        fetchData();
        return () => {
            clearInterval(interval);
        };

    }, [location]);

    const handleFilmeClick = (filme) => {
        let pathname = '/filme';
        if (filme.media_type.toLowerCase() === 'tv') {
            pathname = 'serie';
        }
        history.push({
            pathname: pathname,
            search: `?id=${filme.id}&type=${filme.media_type}`
        }, {
            some: new Date()
        });

    }

    const filmesOrdenados = (a, b) => {

        let aDate = '';
        let bDate = '';

        if (a.media_type === 'tv') {
            aDate = a.first_air_date;
        } else {
            aDate = a.release_date;
        }

        if (b.media_type === 'tv') {
            bDate = b.first_air_date;
        } else {
            bDate = b.release_date;
        }

        if (aDate < bDate) {
            return 1
        }

        if (aDate > bDate) {
            return -1;
        }

        return 0;

    }

    return (
        <div className='bg-black md:pt-40 pt-20'>
            <div className="titulo-pesquisa-busca">
                <div className="termos-da-busca">{termosDaBusca}</div>
                <div className="informacoes-resultado">
                    {mostrarNumerosPesquisa && <>Foram encontrados <span>{resultado?.length} resultados </span>para {termosDaBusca}</>}
                    {!mostrarNumerosPesquisa && <div className='pesquisa-aguarde-msg'>Aguarde, estamos buscando os melhores resultados para você.</div>}
                </div>
            </div>

            <SearchBarUsemais />
            
            <div className={twMerge(
                "pesquisa-container",
                'mt-20'
            )}>
                <ListaResultadoFilmesBusca filmes={resultado.sort(filmesOrdenados)} onClick={handleFilmeClick} />
            </div>

            <Footer />
        </div>
    );
}
