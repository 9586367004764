import { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { twMerge } from 'tailwind-merge';

// Definindo a interface Option para TypeScript
export interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  value: Option | null;
  name?: string;
  error?: string;
  label?: string;
  placeholder?: string;
  placeholderDisabled?: string;
  className?: string;
  onChange: (value: Option | null) => void;
  disabled: boolean;
}

export function SelectSearch({ options, value, label, name, onChange, error, placeholder, placeholderDisabled, className, disabled = false, ...rest }: SelectProps) {
  
  const [internalValue, setInternalValue] = useState<Option | null>(null);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <div className={twMerge("flex flex-col", className)}>
      {label && (
          <label htmlFor={name} className='text-[#151A25] text-[14px] mb-[8px]'>
              {label}
          </label>
      )}
      <Autocomplete
        disablePortal
        options={options}
        size='small'
        onChange={(event: any, newValue: Option | null) => {
          onChange(newValue);
        }}
        value={internalValue}
        noOptionsText={"Nenhum dado encontrado"}
        disabled={disabled}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
            borderColor: '#8A8A8A'
          },
          '.MuiOutlinedInput-root': {
            height: '44px'
          },
          '.MuiOutlinedInput-input': {
            fontFamily: 'Inter, sans-serif',
            fontSize: '14px',
          },
        }}
        className={twMerge("flex flex-col", className)}
        renderInput={(params) => <TextField {...params} placeholder={disabled && placeholderDisabled ? placeholderDisabled : placeholder} name={name} />}
      />
      {error ? <span className="text-red-500 text-[14px]">{error}</span> : <span className='h-[16px]'></span>}
    </div>
  );
}
