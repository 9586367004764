// import ReactStars from "react-rating-stars-component";
import { Rating } from '@mui/material';
import './style.scss';
import { Star, StarBorder } from '@mui/icons-material';
import { useState } from 'react';
// import { Rating } from "react-simple-star-rating";


const ReactStarsCustom = ({ onChange, value = 0, className = '', max = 5 }) => {

    const [rating, setRating] = useState(value);

    const handleChange = (event, newValue) => {
        setRating(newValue);
        if (onChange) {
            onChange(newValue);
        }
      };

    return (
        <Rating
            name="star-rating"
            onChange={handleChange}
            max={max}
            value={rating}
            size="large"
            // fillColor="#fccd0a"
            // emptyColor="#e4e5e9"
            icon={<Star fontSize="inherit" className="text-[#fccd0a]" />}       // Estrela ativa
            emptyIcon={<StarBorder fontSize="inherit" className="text-[#e4e5e9]" />} // Estrela inativa
        />
    )
};

export default ReactStarsCustom;