import { useState, useRef }from "react";
import { SignIn } from "../../components/SignIn";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { loginLecupon } from "../../../../actions/assistamais/api";
import useSession from "../../../../hooks/useSession";
import { useMessage } from "../../../../contexts/MessageContext";
import { useLoader } from "../../../../hooks/useLoader";
import { toast } from "react-toastify";
import { Box, useMediaQuery } from "@mui/material";

function ServicesFrame() {
  const history = useHistory();
  const [session, _, logado] = useSession();
  const [showMessage, showError, showMessageFromResult, showWarning] = useMessage()
  const loader = useLoader();

  const isMobile = useMediaQuery('(max-width:768px)');

  const carouselRef = useRef(null); // Referência ao container do carrossel
  const [isDragging, setIsDragging] = useState(false); // Estado para controlar o arraste
  const [startX, setStartX] = useState(0); // Posição inicial do mouse/touch
  const [scrollLeft, setScrollLeft] = useState(0); // Posição inicial do scroll

  // Inicia o arraste
  const handleMouseDown = (e) => {
      setIsDragging(true);
      setStartX(e.pageX || e.touches[0].pageX); // Posição inicial do mouse ou toque
      setScrollLeft(carouselRef.current.scrollLeft); // Posição inicial do scroll
  };

  // Finaliza o arraste
  const handleMouseUp = () => {
      setIsDragging(false);
  };

  // Movimenta o carrossel
  const handleMouseMove = (e) => {
      if (!isDragging) return; // Apenas movimenta se estiver arrastando
      e.preventDefault(); // Previne seleção de texto/imagens
      const x = e.pageX || e.touches[0].pageX; // Posição atual do mouse ou toque
      const walk = (x - startX) * 1.5; // Multiplica o deslocamento para maior fluidez
      carouselRef.current.scrollLeft = scrollLeft - walk; // Atualiza o scroll
  };

  // const banners = [
  //   {
  //     bg: "usemais/banner1.png",
  //     logo: "usemais/logo_use_mais.png",
  //     image: "usemais/bt-1.png",
  //     text: "Acumule e troque seus pontos por gifts.",
  //     path: "/gifts",
  //   },
  //   {
  //     bg: "usemais/banner2.png",
  //     logo: "usemais/logo_assista.png",
  //     image: "usemais/bt-2.png",
  //     text: "Encontre seus filmes e séries favoritos.",
  //     path: "/assista-mais",
  //   },
  //   {
  //     bg: "usemais/banner3.png",
  //     logo: "usemais/logo_faca_jus_yellow.png",
  //     image: "usemais/bt-3.png",
  //     text: "Tenha facilidade em consultas jurídicas com pagamento consignado.",
  //     path: "https://www.facajus.com.br/",
  //     target: 'blank'
  //   },
  //   {
  //     bg: "usemais/banner4.png",
  //     logo: "usemais/logo_use_mais.png",
  //     image: "usemais/bt-4.png",
  //     text: "Mais de 3 mil parceiros. Escolha entre farmácias, cinemas e muito mais!",
  //     lecupon: true
  //   },
  // ];

  const banners = [
    {
      alt: "Banner Use Mais Pontos",
      img: isMobile ? "usemais/services/card_pontos_mobile.png" : "usemais/services/card_pontos_web.png",
      handleClick: () => history.push('/gifts')
    },
    {
      alt: "Banner Clube Use Mais",
      img: isMobile ? "usemais/services/card_clube_mobile.png" : "usemais/services/card_clube_web.png",
      handleClick: () => acessoLecupon()
    },
    {
      alt: "Banner Assista Mais",
      img: isMobile ? "usemais/services/card_assista_mobile.png" : "usemais/services/card_assista_web.png",
      handleClick: () => history.push('/assista-mais')
    },
    {
      alt: "Banner Faça jus",
      img: isMobile ? "usemais/services/card_facajus_mobile.png" : "usemais/services/card_facajus_web.png",
      handleClick: () => window.open("https://www.facajus.com.br/", "_blank")
    }
  ];

  async function acessoLecupon() {
    if (logado) {

      try {
        loader.show(["Aguarde..."])

        const response = await loginLecupon();

        if (response?.payload?.status === 200) {
          window.open(response?.payload?.data?.url, "_blank");
        } else if (response?.payload?.status === 412) {
          toast.error(response?.payload?.data?.message, {
            autoClose: 3000,
            theme: 'colored',
            onClick: () => history.push('/editarperfil'),
            onClose: () => history.push('/editarperfil'),
          })
        } else {
          throw new Error(response?.payload?.data?.message ?? "Ocorreu um erro. Tente novamente mais tarde.");
        }

      } catch (e) {
        console.error(e)
        showError(e.message ?? "Ocorreu um erro. Tente novamente mais tarde.", 3000);
      } finally {
        loader.hide()
      }

    } else {
      history.push('/login')
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-[1600px] px-[40px]" id="services">
        {/* Título da seção */}
        <div className="text-center my-8">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900">
            Aproveite nossos serviços <span className="text-red-400">exclusivos</span>
          </h2>
        </div>
        

        <div className="hidden md:grid grid-cols-1 sm:grid-cols-2 md:text-wrap lg:grid-cols-4 gap-8 w-full">
          {banners.map((image, index) => (
            <div 
              className="flex justify-center items-start w-full cursor-pointer" 
              key={index}
              onClick={image.handleClick}
            >
              <img src={image.img} alt={image.alt} />
            </div>
          ))}
        </div>

        <div className="flex w-full md:hidden">
          <Box className="relative flex flex-col items-center w-full bg-white overflow-hidden">
            <Box
                ref={carouselRef}
                className="flex overflow-x-scroll gap-[20px] w-full scrollbar-hide"
                style={{
                    cursor: isDragging ? "grabbing" : "grab", // Troca o cursor durante o arraste
                    userSelect: "none", // Desativa seleção de texto
                }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onMouseMove={handleMouseMove}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onTouchMove={handleMouseMove}
            >
              {banners.map((image, index) => (
                <div 
                  className="flex justify-center items-start cursor-pointer w-[240px] shrink-0" 
                  key={index}
                  onClick={image.handleClick}
                >
                  <img src={image.img} alt={image.alt} />
                </div>
              ))}
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default ServicesFrame;
