import { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Box } from '@mui/material';

export const CarrosselParceirosUsemais = () => {

    const carouselRef = useRef(null); // Referência ao container do carrossel
    const [isDragging, setIsDragging] = useState(false); // Estado para controlar o arraste
    const [startX, setStartX] = useState(0); // Posição inicial do mouse/touch
    const [scrollLeft, setScrollLeft] = useState(0); // Posição inicial do scroll

    useEffect(() => {
        const moveCarousel = () => {
        if (carouselRef.current) {
            const scrollWidth = carouselRef.current.scrollWidth;
            const clientWidth = carouselRef.current.clientWidth;

            // Configura o movimento do carrossel (scrollando da esquerda para a direita)
            carouselRef.current.scrollLeft += 1; // Ajuste a velocidade conforme necessário
            if (carouselRef.current.scrollLeft >= scrollWidth - clientWidth) {
            carouselRef.current.scrollLeft = 0; // Retorna ao início quando chega ao fim
            }
        }
        };

        const interval = setInterval(moveCarousel, 20); // Intervalo de movimento (ajuste conforme necessário)

        return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }, []);

    // Inicia o arraste
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX || e.touches[0].pageX); // Posição inicial do mouse ou toque
        setScrollLeft(carouselRef.current.scrollLeft); // Posição inicial do scroll
    };

    // Finaliza o arraste
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Movimenta o carrossel
    const handleMouseMove = (e) => {
        if (!isDragging) return; // Apenas movimenta se estiver arrastando
        e.preventDefault(); // Previne seleção de texto/imagens
        const x = e.pageX || e.touches[0].pageX; // Posição atual do mouse ou toque
        const walk = (x - startX) * 1.5; // Multiplica o deslocamento para maior fluidez
        carouselRef.current.scrollLeft = scrollLeft - walk; // Atualiza o scroll
    };

    const srcItens = [
        'usemais/logo_uber.png',
        'usemais/logo_americanas.png',
        'usemais/logo_spotify.png',
        'usemais/logo_carrefour.png',
        'usemais/logo_netshoes.png',
        'usemais/logo_renner.png',
        'usemais/logo_rihappy.png',
        'usemais/logo_centauro.png',
        'usemais/logo_riachuelo.png'
    ];

    return (
        <Box className="relative flex flex-col items-center w-screen bg-white overflow-hidden">
            <Box
                ref={carouselRef}
                className="flex overflow-x-scroll gap-4 md:gap-8 w-full scrollbar-hide h-[80px]"
                style={{
                    cursor: isDragging ? "grabbing" : "grab", // Troca o cursor durante o arraste
                    userSelect: "none", // Desativa seleção de texto
                }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onMouseMove={handleMouseMove}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onTouchMove={handleMouseMove}
            >
                {srcItens.map((item, index) => (
                    <Box
                        key={`gift-${index}`}
                        className="flex items-center justify-center w-[150px] md:w-[300px] h-[50px] bg-white rounded-full px-[20px] shrink-0 shadow-lg "
                    >
                        <img src={item} className='h-[24px]' />
                    </Box>
                ))}
            </Box>
        </Box>
    )
};