import { CircularProgress } from "@mui/material";
import { ReactNode, createContext, useContext, useState } from "react";

interface LoaderProviderProps {
    children: ReactNode;
}

interface LoaderContextProps {
    show: (msg: string[]) => void;
    hide: () => void
}

export const LoaderContext = createContext({} as LoaderContextProps);

export function LoaderProvider({ children }: LoaderProviderProps) {

    const [messages, setMessages] = useState<string[]>([]);
    const [isVisible, setIsVisible] = useState(false);

    const show = (msg: string[]) => {
        setMessages(msg);
        setIsVisible(true)
    }

    const hide = () => {
        setIsVisible(false)
    }


    return (
        <LoaderContext.Provider value={{ show, hide }}>
            {children}
            {isVisible && (
                <div className="fixed z-50 inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center px-[20px] gap-[10px]">
                    {messages.map((msg, index) => (
                        <p key={index} className="w-full text-center text-[20px] md:text-[24px] 2xl:text-[28px] font-medium">
                            {msg}
                        </p>
                    ))}
                    <CircularProgress color="inherit" />
                </div>
            )}
        </LoaderContext.Provider>
    )
}

export const useLoader = () => useContext(LoaderContext);