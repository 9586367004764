import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { isLogged } from '../../../actions/assistamais/api';
import { SessionContext } from '../../../contexts/SessionContext';
import { formatName, formatNumberWithDot, useQuery } from '../../../utils';
import { logout, obterDados } from '../../../utils/Session';
import BotaoBuscaMenuMobile from '../../atoms/BotaoBuscaMenuMobile';
import BotaoMenuHamburger from '../../atoms/BotaoMenuHamburger';
import BotaoRetanguloArredondado from '../../atoms/BotaoRetanguloArredondado';
import Logo from '../../atoms/Logo';
import MenuItem from '../../atoms/MenuItem';
import Busca from '../../molecules/Busca';
import BuscaMobile from '../../molecules/BuscaMobile';
import MenuPerfilLogado from '../../molecules/MenuPerfilLogado';
import FazerCadastro from '../FazerCadastro';
import FazerLogin from '../FazerLogin';
import RecuperarSenha from '../RecuperarSenha';
import './style.scss';
import searchIconNew from '../../../assets/img/search-icon-new.png';


const Menu = ({ simples = false, pesquisa = true, classNameBusca = '', className = '', protegida = false, children, bannerGoogleAd = false, isPerfil = false }, ref) => {
    const topnavRef = useRef();
    const popupLoginRef = useRef();
    const recuperarSenhaRef = useRef();
    const fazerCadastroRef = useRef();

    const [showBuscaMobile, setShowBuscaMobile] = useState(false);
    const [cpf, setCpf] = useState('');
    const [entrarIsVisible, setEntrarIsVisible] = useState(true);

    const [session, setSession, logado] = useContext(SessionContext);


    let history = useHistory();

    useImperativeHandle(ref, () => ({
        fazerLogin: () => handleEntrarClick(),
        fazerCadastro: () => handleFazerCadastroClick
    }));

    useEffect(() => {
        const query = useQuery();

        const params = {
            dologin: query.get('dologin'),
            recuperarSenha: query.get('recuperarSenha'),
        }

        if (params.dologin && logado === false) {
            popupLoginRef.current.open();
        }

        // if (params.cadastrar  && logado === false) {

        //     setCpf(params.cpf ? params.cpf : '');
        //     handleFazerCadastroClick();
        // }
        
        if (params.recuperarSenha) {
            handleRecuperarSenhaClick();
        }

        const pathname = history?.location?.pathname || '';
        
        if (session && session?.status === 'INCOMPLETO' && pathname !== '/editarperfil') {
            history.push('/editarperfil');
        }
        if (session && session?.status === 'COMPLETO' && session.empt === true && pathname !== '/editarperfil') {
            history.push('/editarperfil');
        }

    }, [logado])

    useEffect(() => {
        if (protegida && logado === false) {
            popupLoginRef.current.open();
        }
    }, [logado])

    useEffect(() => {
        async function fetchData() {
            const dados = await obterDados();

            if (dados && dados.user && dados.user.id) {
                const isUserLogged = await isLogged(dados.user.id)

                if (!isUserLogged.payload.data.isLogged) {
                    logout();
                    window.location.href = '/';
                }
            }
        }
        fetchData()
    }, []);

    /* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */
    const handleMenuClick = () => {
        if (topnavRef.current.className === "topnav") {
            topnavRef.current.className += " responsive";
            setEntrarIsVisible(false);
        } else {
            topnavRef.current.className = "topnav";
            setEntrarIsVisible(true);
        }
    }

    const handlePesquisa = (valor, filtro) => {

        const provider = filtro.provider * 1 > 0 ? `&plataforma=${filtro.provider}` : '';
        const genrer = filtro.genrer * 1 > 0 ? `&genero=${filtro.genrer}` : '';
        setShowBuscaMobile(false);
        history.push({
            pathname: '/pesquisa',
            search: `?q=${valor}${provider}${genrer}`
        }, {
            some: new Date()
        });

    }

    const handleHomeClick = () => {
        history.push('/');
    }

    const handleComoFuncionaClick = () => {
        history.push('/comofunciona');
    }

    const handleEntrarClick = () => {
        popupLoginRef.current.open();
    }

    const handleRecuperarSenhaClick = () => {
        recuperarSenhaRef.current.open();
    }

    const handleFazerCadastroClick = () => {
        fazerCadastroRef.current.open();
    }

    const handleNoticiaClick = () => {
        history.push('/noticias');
    }

    const handleListasClick = () => {
        history.push('/listas');
    }

    const handleAnuncieClick = () => {
        history.push('/anuncie');
    }

    const handleFacebookClick = () => {
        window.open("https://www.facebook.com/use.mais.multisservico", "_blank");
        // history.push('/listas');
    }

    const handleInstagramClick = () => {

        window.open("https://www.instagram.com/assistamaisoficial/", "_blank");
    }

    const handleSairClick = () => {
        // if (window.confirm("Deseja sair?")) {
            logout();
            window.location.href = '/';
        // }
    }

    const handleClickBuscaMobile = () => {
        setShowBuscaMobile(true);
    }

    const handleCancelLogin = (e) => {
        if(protegida) {
            history.push('/');
        }
    }


    const pathname = history?.location?.pathname || '';



    return (
        <div className={`${simples ? 'menu menu-simples' : 'menu'} ${className}`} >
            {/* <div className="efeito-gradiente"></div> */}
            <div className="efeito-gradiente-topo"></div>
            {pesquisa && showBuscaMobile &&
                <BuscaMobile placeholder="Busque por filmes, séries, categorias e mais" onSubmit={handlePesquisa} onClose={() => setShowBuscaMobile(false)} />
            }
            <nav className="menu-container">
                <span className="menu-logo">
                    <Logo onClick={handleHomeClick} />
                </span>
                <FazerLogin
                    ref={popupLoginRef}
                    recuperarSenhaClick={handleRecuperarSenhaClick}
                    fazerCadastroClick={handleFazerCadastroClick}
                    obrigatorio={true}
                    onCancel={handleCancelLogin}
                />
                <RecuperarSenha ref={recuperarSenhaRef} fazerCadastroClick={handleFazerCadastroClick} />
                <FazerCadastro ref={fazerCadastroRef} />

                <div className="topnav" ref={topnavRef}>
                    <div className="menu-itens">

                        <BotaoMenuHamburger className="botao-menu-hamburger-custom" onClick={handleMenuClick} />
                        <MenuItem className="menu-item-custom-class" onClick={handleHomeClick} ativo={pathname === '/'}>Home</MenuItem>
                        <MenuItem className="menu-item-custom-class showInMobile" onClick={handleClickBuscaMobile} ativo={false}>Pesquisar</MenuItem>
                        <MenuItem className="menu-item-custom-class" onClick={handleComoFuncionaClick} ativo={pathname === '/comofunciona'}>Como funciona</MenuItem>
                        <MenuItem className="menu-item-custom-class" onClick={handleListasClick} ativo={pathname === '/listas'}>Listas</MenuItem>
                        <MenuItem className="menu-item-custom-class" onClick={handleNoticiaClick} ativo={pathname === '/noticias'}>Notícias</MenuItem>
                        <MenuItem className="menu-item-custom-class" onClick={handleAnuncieClick} ativo={pathname === '/anuncie'}>Anuncie</MenuItem>
                        {!logado &&
                            <>
                                <MenuItem className="menu-item-custom-class showInMobile" onClick={handleEntrarClick} >Entrar</MenuItem>
                                <MenuItem className="menu-item-custom-class showInMobile" onClick={handleFazerCadastroClick} >Cadastrar-se</MenuItem>
                            </>
                        }

                        {logado &&
                            <>
                                <MenuItem className="menu-item-custom-class showInMobile" onClick={() => history.push('/minha-conta')} >Conta</MenuItem>
                                <MenuItem className="menu-item-custom-class showInMobile" onClick={() => history.push('/gifts')} >Meus Pontos</MenuItem>
                                <MenuItem className="menu-item-custom-class showInMobile" onClick={() => history.push('/favoritos')} >Lista de favoritos</MenuItem>
                                <MenuItem className="menu-item-custom-class showInMobile" onClick={() => history.push('/ajuda')} >Central de ajuda</MenuItem>
                                <MenuItem className="menu-item-custom-class showInMobile" onClick={handleSairClick} >Sair</MenuItem>
                            </>
                        }
                        <span className="espaco-menu" />
                        <span className="espaco-menu" />
                        {isPerfil ? 
                            (<span className="menu-group-social-login-perfil" >
                                {/* <MenuItem className="menu-item-rede-social" onClick={handleFacebookClick}><FontAwesomeIcon icon={faFacebook} /></MenuItem>
                                <MenuItem className="menu-item-rede-social" onClick={handleInstagramClick}><FontAwesomeIcon icon={faInstagram} /></MenuItem> */}
                                {logado ? <MenuPerfilLogado perfil={session.perfil}  className="menu-item-logado"  /> : <BotaoRetanguloArredondado className="menu-item-entrar" value="ENTRAR" onClick={handleEntrarClick} />}
                            </span>)
                        :
                            (<span className="menu-group-social-login" >
                                <MenuItem className="menu-item-rede-social" onClick={handleFacebookClick}><FontAwesomeIcon icon={faFacebook} /></MenuItem>
                                <MenuItem className="menu-item-rede-social" onClick={handleInstagramClick}><FontAwesomeIcon icon={faInstagram} /></MenuItem>
                                <span className="espaco-menu" />
                                {logado ? <MenuPerfilLogado perfil={session.perfil}/> : <BotaoRetanguloArredondado className="menu-item-entrar" value="ENTRAR" onClick={handleEntrarClick} />}
                            </span>)
                        }

                        {logado ? (
                            <div className='dados-logado-container showInMobile' onClick={() => history.push('/gifts')}>
                                <h6 className='dados-logado-usuario'>Olá {formatName(session?.perfil?.nome)}</h6>
                                <p className='dados-logado-pontos'>{formatNumberWithDot(session?.perfil?.pontos)}</p>
                                <span className='dados-logado-legend'>PONTOS</span>
                            </div>
                        ) : (
                            <>
                                {entrarIsVisible && (
                                    <div className='dados-logado-container showInMobile' onClick={handleEntrarClick}>
                                        <p className='dados-logado-pontos btn-entrar'>ENTRAR</p>
                                    </div>
                                )}
                                
                            </>
                        )}

                        
                        
                        {/* {pesquisa &&
                            <BotaoBuscaMenuMobile className='botao-busca-custom' onClick={handleClickBuscaMobile} />
                        } */}
                    </div>
                </div>
            </nav>

            {children}

            {pesquisa &&

                <div className={`${bannerGoogleAd ? 'menu-container-busca-with-google-ad' : 'menu-container-busca'} hide-on-mobile ${classNameBusca}`}>

                    <Busca placeholder="Busque por filmes, séries, categorias e mais" onSubmit={handlePesquisa} />

                </div>

            }
            {!simples && false && <div className="menu-footer">&nbsp;</div>}
        </div>
    )
};

export default React.forwardRef(Menu);