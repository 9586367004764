
export function maskCpf(cpf: string) {
    if (cpf.length <= 14) {
        let cpfMasked = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
        return cpfMasked;
    } else {
        return cpf.substring(0,14);
    }
}

export function maskTelefone(telefone: string) {
    return telefone.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3');
}

export function maskCelular(celular: string) {
    return celular.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');
}

export function formatCelularOnlyNumbers(celular: string) {
    celular = celular.replace(/[^0-9]+/g, '');
    return celular;
}

export function validaCelular(celular: string) {
    celular = celular.replace(/[^0-9]+/g, '');
    return celular.length === 11;
}

export function validarCPF(cpf: string) {
    // Limpar o CPF (remover pontos, traços e espaços)
    const cpfLimpo = cpf.replace(/\D/g, "");
  
    // Verificar se o CPF tem exatamente 11 dígitos
    if (cpfLimpo.length !== 11 || /^(\d)\1{10}$/.test(cpfLimpo)) {
      return false; // CPF inválido, pois todos os números são iguais (ex: 111.111.111-11)
    }
  
    // // Validar o primeiro dígito verificador
    // let soma = 0;
    // let peso = 10;
    // for (let i = 0; i < 9; i++) {
    //   soma += parseInt(cpfLimpo[i]) * peso;
    //   peso--;
    // }
  
    // let primeiroDigitoVerificador = (soma * 10) % 11;
    // if (primeiroDigitoVerificador === 10 || primeiroDigitoVerificador === 11) {
    //   primeiroDigitoVerificador = 0;
    // }
  
    // // Verificar se o primeiro dígito verificador é válido
    // if (primeiroDigitoVerificador !== parseInt(cpfLimpo[9])) {
    //   return false;
    // }
  
    // // Validar o segundo dígito verificador
    // soma = 0;
    // peso = 11;
    // for (let i = 0; i < 10; i++) {
    //   soma += parseInt(cpfLimpo[i]) * peso;
    //   peso--;
    // }
  
    // let segundoDigitoVerificador = (soma * 10) % 11;
    // if (segundoDigitoVerificador === 10 || segundoDigitoVerificador === 11) {
    //   segundoDigitoVerificador = 0;
    // }
  
    // // Verificar se o segundo dígito verificador é válido
    // if (segundoDigitoVerificador !== parseInt(cpfLimpo[10])) {
    //   return false;
    // }
  
    return true; // CPF válido
}

export function firstName(name: string) {
    let firstName = name.split(" ")[0];
    return firstName;
}

export function formatDateTime(dateTime: string) {

    // 2022-02-07 10:38:29
    let date = dateTime.split(" ")[0];
    date = date.split('-').reverse().join('/');

    let time = dateTime.split(" ")[1];

    return `${date} ${time}`;
}

export function formatDateTimeToDate(dateTime: string) {
    
    // 2022-02-07 10:38:29
    let date = dateTime.split(" ")[0];
    date = date.split('-').reverse().join('/');

    return date;
}

export function formatDateTimeToTime(dateTime: string) {
    
    // 2022-02-07 10:38:29
    let time = dateTime.split(" ")[1];

    return time;
}

export function formatDate(date: string) {
    let formatDate = date.toString().split('-').reverse().join('/');
    return formatDate;
}

export function formatDateDatabase(date: string) {
    let formatDate = date.toString().split('/').reverse().join('-');
    return formatDate;
}

export function formatCurrencyBRL(value: string){
    let newValue = "";
    if (value != undefined && value != null) {
        value = value.toString().replace(',', '');
    }
    newValue = Number(value).toFixed(2).toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return newValue;
}

export function dateNow(){
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: string | number = today.getMonth() + 1; // Months start at 0!
    let dd: string | number = today.getDate();
    let hour: string | number = today.getHours();
    let minutes: string | number = today.getMinutes();
    let seconds: string | number = today.getSeconds(); 

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    if (hour < 10) hour = '0' + hour;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    let formattedToday = `${dd}/${mm}/${yyyy} ${hour}:${minutes}:${seconds}`;

    return formattedToday;
}

export function formatNome(nome: string) {
    return nome.replace(/[^a-zA-ZÀ-ú' ]/g, '').replace(/( )+/g, ' ');
}

export function formatTextAndNumber(str: string) {
    return str.replace(/[^a-zA-ZÀ-ú0-9' ]/g, '').replace(/( )+/g, ' ');
}

export function formatNameGlobal(rua: string) {
    return rua.replace(/[^a-zA-ZÀ-ú0-9-_. ]/g, '').replace(/( )+/g, ' ');
}

export function validaEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function validaNomeCompleto(nome: string) {
    var nomeCompleto = nome.trim().split(' ');

    if (nomeCompleto.length < 2) {
        return false;
    }

    if (nomeCompleto[0].length < 2) {
        return false;
    }

    return true;
}

export function formatCurrency(value: string) {
    value = value.replace(/[^0-9]+/g, '');

    if (value.length >= 3) {
        value = value.substring(0, value.length - 2) + "." + value.substring(value.length - 2, value.length);
    }

    return value;
}

export function formatPercent(value: string | number) {
    value = parseFloat(Number(value).toString());
    return value;
}

export function formatEmail(email: string) {
    return email.replace(/[^a-zA-Z0-9-_.@]/g, '').replace(/( )+/g, ' ').replace(/(@)+/g, '@').replace(/(\.)+/g, '.');
}

export function formatEstadoCivil(estadoCivilCod: number) {
    let estadoCivil = "N/A";

    switch (estadoCivilCod) {
        case 1:
            estadoCivil = "Solteiro(a)";
            break;
        case 2:
            estadoCivil = "Casado(a)";
            break;
        case 3:
            estadoCivil = "Divorciado(a)";
            break;
        case 4:
            estadoCivil = "Viúvo(a)";
            break;
        case 5:
            estadoCivil = "Separado(a)";
            break;
        case 6:
            estadoCivil = "União Estável";
            break;
        default:
            estadoCivil = "N/A";
            break;
    }

    return estadoCivil;
}

// Embaralhar randomicamente um array
export function shuffleArray(array: any[]) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

export function abreviaNome(nome: string) {
    const arrayNome = nome.toUpperCase().split(' ');
    const lestIndex = arrayNome.length - 1;
    const palavrasReservadas = [
        'DA',
        'DE',
        'DO',
        'DAS',
        'DOS',
        'E',
    ]

    const filtredNome = arrayNome.map((name, index) => {
        if (index == 0 || (lestIndex == index) || palavrasReservadas.includes(name)) {
            return name;
        } else {
            return name[0];
        }
    });

    return filtredNome.join(' ');
}

// export function validaNovaSenha(senha: string, parametro: number) {
//     if (senha.length < parametro) return { status: false, msg: 'A senha deve conter ' + parametro + ' dígitos.' };
//     if (senha[0] === '1' || senha[0] === '0') return { status: false, msg: 'A senha não pode iniciar com 0 ou 1.' };
//     if (!!senha.split('').reduce((a, b) => { return (a === b) ? a : null; })) return { status: false, msg: 'A senha não pode conter todos os caracteres repetidos.' };
//     if (isSequencial(senha, parametro)) { return { status: false, msg: 'A senha não pode ser sequencial.' } };
//     return { status: true, msg: 'Senha válida!' };
// }

function isSequencial(senha: string, parametro: number) {
    let arraySequenciais = [];
    if (parametro == 8) {
        arraySequenciais = ['12345678', '23456789', '34567890', '45678901', '98765432', '87654321', '76543210'];
    } else if (parametro == 6) {
        arraySequenciais = ['123456', '234567', '345678', '456789', '987654', '876543', '765432', '654321', '543210'];
    } else {
        // Senha consignação
        arraySequenciais = ['12345', '23456', '34567', '45678', '56789', '98765', '87654', '76543', '65432', '54321', '43210'];
    }

    if (arraySequenciais.indexOf(senha) != -1) return true;
    return false;
}

// Retorna o status da operação
export function operacaoStatus(status: string) {
    switch(status){
        case 'Y':
            return 'Quitação Solicitada';
            break;
        case 'Q':
            return 'Quitado';
            break;
        case 'C':
            return 'Cancelado';
            break;
        case 'S':
            return 'Suspenso';
            break;
        case 'O':
            return 'Suspenso O.J.';
            break;
        case 'I':
            return 'Inconformidade';
            break;
        case 'R':
            return 'Reservado';
            break;
        case 'H': // Reservado sem anuencia do servidor (São Luis sindicatos)
            return 'Reservado';
            break;
        case 'E':
            return 'Efetuado';
            break;
        case 'RJ'://atentar para que essa opção não é usada no banco  sendo a condição status 'E' e valor judicial not null
            return 'Redução Judicial';
            break;
        case 'J':
            return 'Redução Judicial';
            break;
        case 'K':
            return 'LEI 19.562';
            break;
        case 'L':
            return 'LEI 19.574';
            break;

        //Operação Solicitação
        case 'A':
            return 'Solicitação';
            break;

        case 'B':
            return 'Pré Aprovado';
            break;

        case 'D':
            return 'Contra Proposta';
            break;

        case 'F':
            return 'Não Autorizado';
            break;

        //Operação Potabilidade
        case 'V':
            return 'Venda';
            break;

        case 'G':
            return 'Aguardando Portabilidade';
            break;

        case 'U':
            return 'Portabilidade Solicitada';
            break;

        case 'Z':
            return 'Comprovante Enviado';
            break;

        case 'P':
            return 'Portado';
            break;

        //Refin
        case 'N':
            return 'Refin Cadastrado';
            break;

        case 'M':
            return 'Refinanciado';
            break;

        case 'W':
            return 'Aumento de Averbação';
            break;

        default:
            return '';
            break;
    }
}

export function parcelaStatus(status: string)
    {

        //Parcela Status
        switch(status){
            case 'A':
                return 'Agendada';
                break;

            case 'Q':
                return 'Quitada';
                break;

            case 'C':
                return 'Cancelado';
                break;

            case 'S':
                return 'Suspensa';
                break;

            case 'P':
                return 'Pendente';
                break;

            case 'T':
                return 'Transferido';
                break;

            case 'M':
                return 'Refinanciado';
                break;

            case 'D':
                return 'Em processamento';
                break;

            case 'X':
                return 'Desconto Parcial';
                break;

            case 'R':
                return 'Repactuada';
                break;

            default:
                return '';
                break;
        }
    }

export function formatLettersAndNumbers(str: string) {
    return str.replace(/[^a-zA-Z0-9]/g, '');
}

export function formatOnlyNumbers(str: string) {
    return str.replace(/[^0-9]/g, '');
}

export function formatOnlyLetters(str: string) {
    return str.replace(/[^a-zA-Z]/g, '');
}

export function validaDataNascimento(data: string) {
    const day = Number(data.split('/')[0]);
    const month = Number(data.split('/')[1]);
    const year = Number(data.split('/')[2]);

    const thisDate = new Date();
    const thisYear = thisDate.getFullYear();

    if (
        day <= 0
        || day > 31
        || month <= 0
        || month > 12
        || year < 1800
        || year >= thisYear
        || (month == 2 && day > 29)
    ) {
        return false;
    }

    return true;
}

export function validaRg(rg: string) {
    const onlyNumbersRG = rg.replace(/\D/g, "");
    
    if (rg.length < 4) {
        return false;
    } else if (rg.length == onlyNumbersRG.length) {
        if (Number(rg) == 0) {
            return false;
        }
    }
    return true;
}

export function maskInputPass(pass: string) {
    let maskPass = pass.split('').map(e => { return '*'; }).join('');
    return maskPass;
}