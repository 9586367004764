import React, { useEffect, useRef, useState } from "react";
import imagemFaq from "../../assets/img/image-faq.png";
import Faq from "../../componentes/molecules/Faq";
import ImagemFundoDetalhe from "../../componentes/organisms/ImagemFundoDetalhe";
import Menu from "../../componentes/organisms/Menu";
import Rodape from "../../componentes/organisms/Rodape";
import RodapeConta from "../../componentes/organisms/RodapeConta";
import { RodapeUsemais } from "../../componentes/organisms/RodapeUsemais";
import "./style.scss";
import { getCentrais } from "../../actions/assistamais/api";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowLeft, faCaretLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from "tailwind-merge";
export default function CentraisAtendimento() {
  const [centraisAt, setCentraisAt] = useState([]);

  useEffect(() => {
    const centrais = async () => {
      let centrais = await getCentrais();
      console.log(centrais?.payload?.data?.centrais);
      setCentraisAt(centrais?.payload?.data?.centrais);
    };
    centrais();
  }, []);

  const menuRef = useRef();
  const history = useHistory();

  return (
    <div>
      <div className={"flex flex-col"}>
        <div className={twMerge(
          "mt-14 pl-5 grid w-full",
          "md:flex md:px-[80px] md:mt-36 justify-between ",
          "lg:px-44 lg:grid lg:grid-cols-2",
        )}>
          <h1 className={twMerge(
            "text-[#151A25] text-left font-bold text-[22px] self-center",
            "md:text-2xl"
          )}>
            {" "}
            Centrais de Atendimento
          </h1>
          <div className="hidden md:flex justify-self-end mlg:r-48 flex-col items-center gap-[4px]">
            <button
              className="bg-[#151A25] rounded-full w-[50px] h-[50px] hover:opacity-80"
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <span className="text-[14px] text-[#151A25] font-semibold">
              Voltar
            </span>
          </div>
        </div>
        <div className={twMerge(
          "w-full flex flex-col px-5 my-5 gap-[20px]",
          "md:grid md:grid-cols-2 md:gap-x-[40px] md:gap-y-[40px] md:px-[80px] md:my-10",
          "lg:px-44",
          // "grid-cols-2 w-full md:gap-x-24 gap-y-5 md:gap-y-10 flex-nowrap px-5 md:px-48 my-5 md:my-10",
          // "lg:grid grid-cols-2 w-full md:gap-x-24 gap-y-5 md:gap-y-10 flex-nowrap px-5 md:px-48 my-5 md:my-10",
        )}>
          {centraisAt.map((central) => {
            return (
              <div className={twMerge(
                "text-[#151A25] flex-col justify-center content-center p-4 lg:h-52 bg-white border border-[#BFBFBF] rounded-lg",
              )}>
                <h2 className={twMerge(
                  "font-bold text-[18px] mb-5",
                  "md:text-[20px]",
                  "lg:text-xl",
                )}>
                  {central.titulo}
                </h2>
                <p>
                  <b>Telefone: </b>
                  {central.telefone}
                </p>
                <p>
                  <b>WhatsApp: </b>
                  {central.whatsapp}
                </p>
                <p>
                  <b>Endereço: </b>
                  {central.endereco}
                </p>
                {/* <p>
                  <b>Horário de Atendimento: </b>
                  {central.atendimento}
                </p> */}
              </div>
            );
          })}
        </div>
        <div className="flex w-full md:flex-row">
          <h1
            className={twMerge(
              "mx-[20px] text-[#151A25] text-left text-[14px] mb-[100px]",
              "md:mx-[80px]",
              "lg:mx-48",
            )}
          >
            * Os horários de atendimento, estão sujeitos a alterações de acordo
            com o decreto de cada região.
          </h1>
        </div>
      </div>
      {/* <RodapeUsemais /> */}
    </div>
  );
}
