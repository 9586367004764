import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
    children: ReactNode,
    className?: string
}


export function Root({ children, ...rest }: Props) {
    return (
        <div className={twMerge(
            "flex flex-wrap px-20 pt-10 pb-10 my-auto h-full w-full text-xl bg-white rounded-[48px] max-md:px-5 max-md:mt-10 max-lg:max-w-full",
            rest.className
        )}>
            {children}
        </div>
    )
}