import React, { createContext, useContext, useState } from 'react';
import MensagemPadrao, { MessageType } from '../componentes/atoms/MensagemPadrao';
import { ToastContainer, toast } from 'react-toastify';
export const MessageContext = createContext();

export const MessageApiProvider = (props) => {

    const [message, setMessage] = useState();
    const [show, setShow] = useState(false);
    const [tipo, setTipo] = useState('');
    let timeout;

    const showMessage = (type, message, autoClose = 5000) => {

        const config = {
            autoClose
        }

        switch (type) {
            case 'success':
                toast.success(message, config)
                break;
            case 'error':
                toast.error(message, config)
                break;
            case 'warn':
                toast.warn(message, config)
                break;
            case 'info':
                toast.info(message, config)
                break;
        }

        // setMessage(text)
        // setTipo(MessageType.INFO);
        // setShow(true);
        // clearTimeout(timeout);
        // timeout = setTimeout(() => {
        //     closeMessage();
        // }, 5000)
    }

    const showWarning= (text, autoClose = 5000) => {

        toast.warn(text, {
            autoClose: autoClose,
        })

        // setMessage(text);
        // setTipo(MessageType.ERROR);
        // setShow(true);
        // clearTimeout(timeout);
        // timeout = setTimeout(() => {
        //     closeMessage();
        // }, 5000)
    }

    const showError = (text, autoClose = 5000) => {

        toast.error(text, {
            autoClose: autoClose,
        })

        // setMessage(text);
        // setTipo(MessageType.ERROR);
        // setShow(true);
        // clearTimeout(timeout);
        // timeout = setTimeout(() => {
        //     closeMessage();
        // }, 5000)
    }

    const showMessageFromResult = (result, successText, errorText, cb) => {
        return result.then(resultado => {
            //showError(result?.payload?.data.message)

            if (!resultado?.payload) {
                const error = data && data.message ? data.message : 'Algo inexperado aconteceu, tente novamente.';
                showError(errorText || error);
                if (cb) {
                    cb(data, false);
                }
                return false;
            }

            const { status, data } = resultado?.payload

            if (status && Math.trunc(status / 100) === 2) {
                if (successText) {
                    showMessage('success', successText, 3000);
                }
                if (cb) {
                    cb(data, true);
                }
                return true;
            } else {
                const error = data && data.message ? data.message : 'Algo inexperado aconteceu, tente novamente.';
                showError(errorText || error);
                if (cb) {
                    cb(data, false);
                }
                return false;
            }

        })
    }

    const closeMessage = () => {
        setShow(false);
    }

    return (
        <MessageContext.Provider value={[showMessage, showError, showMessageFromResult, showWarning]}>
            {/* {show && <MensagemPadrao texto={message} tipo={tipo} onClick={() => closeMessage()} />} */}
            {props.children}
            <ToastContainer style={{ zIndex: 99999 }} position="top-right" theme="colored" />
        </MessageContext.Provider>
    );
}

export const useMessage = () => {
    const useMessage = useContext(MessageContext)
    return useMessage;
}