import { twMerge } from 'tailwind-merge';
import closedIconBlack from '../../../assets/img/closed-icon-black.png';
import closedIcon from '../../../assets/img/closed-icon.png';
import MolduraPopup from '../../atoms/MolduraPopup';
import './style.scss';


const Popup = ({ titulo, subtitulo, children, onClose, type, className = '', classNameMoldura = '' }) => {
    const handleCloseClick = () => {
        if (onClose) {
            onClose();
        }
    }
    return (
        <MolduraPopup type={type} className={twMerge('', classNameMoldura)} >
            <img src={type === 'light' ? closedIconBlack : closedIcon} className="closed-icon" alt="X" onClick={handleCloseClick} />
            <div className={`popup-container ${className}`}>
                {titulo &&
                    <span className={`popup-titulo ${type === 'light' ? 'popup-titulo-light' : ''}`}>
                        {titulo}
                    </span>
                }
                {subtitulo &&
                    <div className={`popup-subtitulo ${type === 'light' ? 'popup-subtitulo-light' : ''}`}>
                        {subtitulo}
                    </div>
                }

                {children}
            </div>

        </MolduraPopup>
    )
};

export default Popup;