import { twMerge } from "tailwind-merge";

interface SubtitleProps {
    text: string,
    className?: string
}

export function Subtitle({ text, className }: SubtitleProps) {
    return (
        <div className={twMerge("mt-16 w-full text-2xl font-bold text-gray-900", className)}>
            {text}
        </div>
    );
}