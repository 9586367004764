import { SignUpAction } from "./SignUpAction";
import { SignUpActions } from "./SignUpActions";
import { SignUpIcon } from "./SignUpIcon";
import { SignUpLabel } from "./SignUpLabel";
import { SignUpPassValidation } from "./SignUpPassValidation";
import { SignUpPassValidations } from "./SignUpPassValidations";
import { SignUpRoot } from "./SignUpRoot";
import { SignUpSubtitle } from "./SignUpSubtitle";
import { SignUpTitle } from "./SignUpTitle";

export const SignUp = {
    Root: SignUpRoot,
    Action: SignUpAction,
    Actions: SignUpActions,
    Icon: SignUpIcon,
    Label: SignUpLabel,
    Title: SignUpTitle,
    Subtitle: SignUpSubtitle,
    Validations: SignUpPassValidations,
    Validation: SignUpPassValidation
}