import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useQuery } from "../../utils";
import PasswordScreen from "../../componentes/usemais/components/SignIn/passwordScreen";
import OnboardingPainel from "../../componentes/molecules/OnboardingPainel";
import { AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import OnBoardingPainel from "../../componentes/molecules/OnboardingPainel";
import { checkValidityToken } from "../../actions/assistamais/api";
import { tokenTypes } from "../../utils/tokens";
import { useMessage } from "../../contexts/MessageContext";

export default function ResetSenha() {
  const [token, setToken] = useState("");

  const trocarSenhaRef = useRef();

  const [showMessage] = useMessage();

  useEffect(() => {
    const query = useQuery();

    const params = {
      token: query.get("token"),
    };

    setToken(params.token);
    // trocarSenhaRef.current.open();
  }, []);

  useEffect(() => {
    if (token) {
      checkToken()
    }
  }, [token]);

  const checkToken = async () => {
    const result = await checkValidityToken(token, tokenTypes.RESET_PASSWORD)

    if (result?.payload?.status !== 204) {
      showMessage('error', result?.payload?.data?.message ?? "Token inválido", 3000)
    }
  }

  return (
    // <div className="reset-senha">

    //     <Home />
    //     <TrocarSenha ref={trocarSenhaRef} resetSenha={true} token={token} />

    // </div>
    <section>
      {/* <ToastContainer /> */}
      <div className="max-md:flex max-md:flex-col md:grid grid-cols-2 h-screen">

        <div className="hidden md:block w-full">
          <OnBoardingPainel />
        </div>
        
        {/* <AnimatePresence> */}
          <PasswordScreen token={token} />
        {/* </AnimatePresence> */}
      </div>
    </section>
  );
}
