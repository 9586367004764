import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import CardPopup from '../../../componentes/molecules/CardPopup';
import ImagemFundoDetalhe from '../../../componentes/organisms/ImagemFundoDetalhe';
import ListaAnunciosMeiaTiraHorizontal from '../../../componentes/organisms/ListaAnunciosMeiaTiraHorizontal';
import ListaFilmesGrid from '../../../componentes/organisms/ListaFilmesGrid';
import Menu from '../../../componentes/organisms/Menu';
import Rodape from '../../../componentes/organisms/Rodape';
import ListaAnunciosHorizontalTresPosicoes from '../../organisms/ListaAnunciosHorizontalTresPosicoes';
import RodapeConta from '../../organisms/RodapeConta';
import './style.scss';
import Footer from '../../usemais/Footer';

export default function ListaTitulosGrid({ fundo, titulos, tituloPagina, subtituloPagina, destaqueSubtitulo, anuncios, onCheckMonetizado }) {

    const cardPopup = useRef(null);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 961px)' });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = (e, data) => {

        const { innerWidth: width, innerHeight: height } = window;

        const x = e.pageX > width / 2 ? e.pageX - 432 + 20 : e.pageX;
        const y = e.clientY > height / 2 ? e.pageY - 481 + 20 : e.pageY;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        let x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        let y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;

        if (x < 0) x = 0;
        if (y < 0) y = 0;

        if (x + (432 * multiplicador) > width) {
            x = x - (x + (432 * multiplicador) - width) / 2 - 70 * multiplicador;
        }

        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }


    const cardsPorLinha = isTabletOrMobile ? 3 : 8;

    return (
        <div className="lista-titulos-plataforma bg-black">
            {/* <Menu simples={false} /> */}
            {/* <ImagemFundoDetalhe img={fundo} className="lista-titulos-plataforma-fundo" opacity={false}>
                <div className="titulo-pagina">
                    <div className="titulo-principal">{tituloPagina}</div>
                    <div className="titulo-secundario">{subtituloPagina} <span>{destaqueSubtitulo}</span></div>
                </div>
            </ImagemFundoDetalhe> */}

            <div
                style={{
                    backgroundImage: `url(${fundo})`,
                    backgroundRepeat: 'no-repeat',
                }}
                className='w-full min-h-[500px] flex'
            >
                <div className='w-full min-h-full flex flex-col items-center justify-center bg-white bg-opacity-30'>
                    <div className="text-[44px] font-bold">{tituloPagina}</div>
                    <div className="text-[32px] font-semibold">
                        {subtituloPagina} <span className='text-[#ff6a00]'>{destaqueSubtitulo}</span>
                    </div>
                </div>
            </div>

            <CardPopup ref={cardPopup} />

            <div className='relative'>

                <ListaFilmesGrid onMouseOver={handleMouseOver} filmes={titulos.slice(0, cardsPorLinha)} onClick={handleClick} />

                {/* <ListaAnunciosMeiaTiraHorizontal anuncios={anuncios} onCheckMonetizado={onCheckMonetizado} /> */}
                {/* <ListaAnunciosHorizontalTresPosicoes onCheckMonetizado={onCheckMonetizado} anuncios={anuncios} key="lista-anuncios" /> */}

                <ListaFilmesGrid onMouseOver={handleMouseOver} filmes={titulos.slice(cardsPorLinha)} onClick={handleClick} />
            </div>


            <Footer />
        </div>
    );
}


ListaTitulosGrid.propTypes = {

};

